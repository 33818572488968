import { Component, Input } from '@angular/core';

export enum PortfolioUnitStatus {
  OFFLINE = 0,
  OFFLINE_NON_CONFIG = 10,
  ONLINE = 1,
  ONLINE_NON_CONFIG = 11,
  SOME = 2,
  SOME_NON_CONFIG = 12,
  EMPTY = 3,
  UNKNOWN = 99,
  DELETED = 100,
}

const getStatusStyle: Record<PortfolioUnitStatus, string> = {
  [PortfolioUnitStatus.OFFLINE]: 'status-offline',
  [PortfolioUnitStatus.OFFLINE_NON_CONFIG]: 'status-offline-non-config',
  [PortfolioUnitStatus.ONLINE]: 'status-online',
  [PortfolioUnitStatus.ONLINE_NON_CONFIG]: 'status-online-non-config',
  [PortfolioUnitStatus.SOME]: 'status-some',
  [PortfolioUnitStatus.SOME_NON_CONFIG]: 'status-some-non-config',
  [PortfolioUnitStatus.EMPTY]: 'status-empty',
  [PortfolioUnitStatus.UNKNOWN]: 'status-unknown',
  [PortfolioUnitStatus.DELETED]: 'status-deleted',
};

const DEFAULT_STATUS_STYLE = getStatusStyle[PortfolioUnitStatus.OFFLINE_NON_CONFIG];

@Component({
  selector: 'app-status-circle',
  templateUrl: './status-circle.component.html',
  styleUrls: ['./status-circle.component.scss'],
})
export class StatusCircleComponent {
  @Input()
  set status(status: number) {
    this._status = status;
    this.statusStyle = getStatusStyle[status] ?? DEFAULT_STATUS_STYLE;
  }
  get status(): number {
    return this._status;
  }
  private _status: number;
  @Input()
  set isCard(isCard: boolean) {
    this._isCard = isCard;
    this.cardOrListStyle = isCard ? 'status-card' : 'status-list';
  }
  get isCard(): boolean {
    return this._isCard;
  }
  private _isCard: boolean;
  statusStyle = DEFAULT_STATUS_STYLE;
  cardOrListStyle = 'status-card';
  constructor() {}
}
