import { Injectable } from '@angular/core';
import { ApiWrapper } from '@service/common/api-wrapper.service';
import { Observable } from 'rxjs';
import { LegacyDeviceType } from '../utils/add-device-types';
import { AvailableAPI, RequestMethod, UseHeaderType } from '@class/commons/request-api.model';

@Injectable({
  providedIn: 'root',
})
export class LegacyDeviceService {
  constructor(private _api: ApiWrapper) {}

  /** ** ** ** ** HTTP API ** ** ** ** */

  /**
   * Sample Response
   * [{
      "id": "45dad372",
      "name": "Airconditioner LG DRED",
      "short_name": "AC LG DRED"
    }]
  */
  getLegacyDeviceTypes(): Observable<LegacyDeviceType[]> {
    return this._api.handleObservableRequest({
      useAPI: AvailableAPI.SWITCHDIN,
      url: `/api/v1/device-types/`,
      requestMethod: RequestMethod.GET,
      useHeader: UseHeaderType.AUTHORIZED_SWDIN,
      requestData: {},
    }) as Observable<LegacyDeviceType[]>;
  }

  /**
   *
   * POST API sample data
   * @param data {
      "device_type_id": "45dad372",
      "endpoint_id": "e68ad372"
      }
   * @returns
   *
   */
  addLegacyDeviceType(data: { device_type_id: string; endpoint_id: string }) {
    return this._api.handleObservableRequest({
      useAPI: AvailableAPI.SWITCHDIN,
      url: `/api/v1/devices/`,
      requestMethod: RequestMethod.POST,
      useHeader: UseHeaderType.AUTHORIZED_SWDIN,
      requestData: data,
    });
  }
}
