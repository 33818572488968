import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AddDeviceStore } from '../store/add-device.store';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { AddDeviceError, DeviceConfigScreen, InventoryDevicesListToDisplay } from '../utils/add-device-types';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { isEmpty } from 'lodash';
import { LegacyDeviceStore } from '../store/legacy-device.store';
import { GenericAlertsToastsService } from '@service/common/generic-alerts-toasts.service';
import { TranslationsService } from '@service/common/translations.service';
import { BrowserLogger } from '../../../../classes/core/browser-logger';
import { EndpointsApiService } from 'app/api-services/endpoints.api-service';

export interface SwitchdinDevice {
  endpoint_id: string;
  device_type_id: string;
  name: string;
}

interface BackButton {
  tx: string;
  onClick: () => void;
}

@Component({
  selector: 'app-add-device-modal',
  templateUrl: './add-device-modal.page.html',
  styleUrls: ['./add-device-modal.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDeviceModalPage implements OnInit, OnDestroy {
  readonly isEmpty = isEmpty;
  readonly PermissionKey = PermissionKey;

  @Input()
  set preSelectedEndpointMAC(id: string) {
    this.#preSelectedEndpointMAC.next(id);
  }

  #preSelectedEndpointMAC = new BehaviorSubject<string>(null);

  @Input() connectTimeoutMillis?: number;

  readonly destroy$ = new Subject<void>();

  deviceConfigView$: Observable<DeviceConfigScreen>;

  #backButton = new BehaviorSubject<BackButton>(null);
  backButton$ = this.#backButton.asObservable();

  addDeviceError$: Observable<AddDeviceError>;

  selectedLegacyDevice$: Observable<{
    device_type_id: string;
    endpoint_id: string;
  }>;

  discoveryDeviceForConfiguration$: Observable<InventoryDevicesListToDisplay>;

  readonly DeviceConfigScreen = DeviceConfigScreen;

  private _inventorySubscription: Subscription;

  private _subscriptions: Subscription[] = [];

  private _connectionAttempts = 0;

  #endpointsApiService = inject(EndpointsApiService);

  readonly endpointResult$ = this.#preSelectedEndpointMAC.asObservable().pipe(
    switchMap(
      (endpointUuid) =>
        this.#endpointsApiService.getEndpoint({
          endpointUuid,
        }).result$,
    ),
  );

  readonly endpoint$ = this.endpointResult$.pipe(map((res) => res?.data));

  readonly endpointDevices$ = this.endpoint$.pipe(map((endpoint) => endpoint?.devices));

  constructor(
    private _addDeviceStore: AddDeviceStore,
    private _legacyDeviceStore: LegacyDeviceStore,
    private _genericAlertsToastsService: GenericAlertsToastsService,
    private _trans: TranslationsService,
    private modalCtl: ModalController,
  ) {
    this.deviceConfigView$ = this._addDeviceStore.deviceConfigScreen$;
    this.addDeviceError$ = this._addDeviceStore.addDeviceError$;
    this.selectedLegacyDevice$ = this._legacyDeviceStore.selectedLegacyDevice$;
    this.discoveryDeviceForConfiguration$ = this._addDeviceStore.discoveryDeviceForConfiguration$;

    this.deviceConfigView$
      .pipe(
        withLatestFrom(this._addDeviceStore.dropletAvailablePortsToDiscoverDevice$),
        map(([view, droplet]): BackButton => {
          switch (view) {
            case DeviceConfigScreen.INITIAL_INVENTORY_NOT_RECEIVED:
            case DeviceConfigScreen.INITIAL_INVENTORY_RECEIVED:
            case DeviceConfigScreen.WAITING_FOR_INITIAL_INVENTORY:
              return {
                tx: 'General.Back',
                onClick: this.closeModal.bind(this),
              };
            case DeviceConfigScreen.LEGACY_DEVICES:
            case DeviceConfigScreen.DROPLET_IMAGE_PORT_LAYOUT:
            case DeviceConfigScreen.DEVICE_SETTINGS_FOR_CONFIGURATION:
              return {
                tx: 'General.Devices',
                onClick: () => this._addDeviceStore.showInitialInventoryView(),
              };
            case DeviceConfigScreen.MANUFACTURERS_LIST:
              if (droplet.ports.length) {
                return {
                  tx: 'AddDevice.SelectPort',
                  onClick: () => this._addDeviceStore.showDropletPortImageLayout(),
                };
              } else {
                return {
                  tx: 'General.Back',
                  onClick: this.closeModal.bind(this),
                };
              }
            case DeviceConfigScreen.SELECTED_MANUFACTURER_DEVICES:
              return {
                tx: 'AddDevice.Manufacturers',
                onClick: () => this._addDeviceStore.showManufacturersView(),
              };
            case DeviceConfigScreen.SELECTED_DEVICE_DISCOVERY_ATTRIBUTES:
              return {
                tx: 'AddDevice.ManufacturerDevices',
                onClick: () => this._addDeviceStore.showSelectedManufacturerDevicesView(),
              };
          }
        }),
        tap((backButton) => this.#backButton.next(backButton)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnInit(): void {
    BrowserLogger.log('AddDeviceModal', this.#preSelectedEndpointMAC.value);

    this._inventorySubscription = this._addDeviceStore.subscribeToInventory(this.#preSelectedEndpointMAC.value);

    if (this.connectTimeoutMillis) {
      this._addDeviceStore.patchState({
        connectTimeoutMillis: this.connectTimeoutMillis,
      });
    }

    this.tryConnectingToDroplet();
  }

  ngOnDestroy(): void {
    this._subscriptions.push(
      this._addDeviceStore.resetStateInventory(this.#preSelectedEndpointMAC.value),
      this._inventorySubscription,
    );

    this.cancelSubscriptions();

    this.destroy$.next();
    this.destroy$.complete();
  }

  private cancelSubscriptions() {
    this._subscriptions?.forEach((subscription) => subscription?.unsubscribe());
    this._subscriptions = [];
  }

  private tryConnectingToDroplet(): void {
    BrowserLogger.log('Connecting to droplet', `Attempt: ${this._connectionAttempts + 1}`);

    this.cancelSubscriptions();

    this._subscriptions.push(
      this._addDeviceStore.setDeviceConfigScreen(DeviceConfigScreen.WAITING_FOR_INITIAL_INVENTORY),
      this._addDeviceStore.getDropletDetails(this.#preSelectedEndpointMAC.value),
      this._legacyDeviceStore.getDropletDetails(this.#preSelectedEndpointMAC.value),
      this._addDeviceStore.startDiscoveryProcess(this.#preSelectedEndpointMAC.value),
    );

    this._connectionAttempts++;
  }

  showLegacyDevices(): void {
    this._addDeviceStore.showLegacyDevices();
  }

  onRetryButtonHandler() {
    BrowserLogger.log('AddDeviceModalPage.onRetryButtonHandler');

    this.tryConnectingToDroplet();
  }

  async onAddLegacyDeviceHandler(selectedLegacyDevice: { device_type_id: string; endpoint_id: string }): Promise<void> {
    const { AddingLegacyDevice, AddLegacyDeviceSuccess } = this._trans.instant('AddDevice.LegacyDevices.Alerts');

    const loadingToast = await this._genericAlertsToastsService.createLoaderWithCustomText(AddingLegacyDevice);

    await loadingToast.present();

    this._legacyDeviceStore.addLegacyDeviceType(selectedLegacyDevice);

    loadingToast.spinner = undefined;
    loadingToast.message = AddLegacyDeviceSuccess;

    setTimeout(async () => {
      await loadingToast.dismiss();

      this._addDeviceStore.showInitialInventoryView();
    }, 1000);
  }

  /** ** ** ** ** PRIVATE METHODS ** ** ** ** */
  private closeModal() {
    this.modalCtl.dismiss();
  }
}
