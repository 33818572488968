import { Inject, Injectable } from '@angular/core';
import { ThemeMap, THEMES, DEFAULT_THEME_NAME } from '../../../theme/theme-map';
import { environment } from '../../../environments/environment';
import { ThemeInterface } from '../../../theme/theme-interface';
import { DOCUMENT } from '@angular/common';
import { DomController, Platform } from '@ionic/angular';
import { CommonService } from '../common/common.service';
import { ProfileService } from '../profile/profile.service';
import {
  AppAssets,
  ThemeFooterIcons,
  getThemeIconsDark,
  getThemeIconsLight,
  ThemeMode,
} from '../../../theme/theme-map';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import themes from 'theme';
import { PlotColors } from '@class/commons/plotly.models';
import { applyThemeToDocument } from './utils';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private availableThemes: ThemeInterface[];
  private userTheme: ThemeInterface;
  /**
   * Getter $userTheme
   * @return {ThemeInterface}
   */
  public get $userTheme(): ThemeInterface {
    return this.userTheme;
  }
  public userTheme$ = new BehaviorSubject<ThemeInterface>(null);

  constructor(
    private domCtrl: DomController,
    @Inject(DOCUMENT) private document: Document,
    private commonService: CommonService,
    private platform: Platform,
    private profileService: ProfileService,
  ) {
    this.availableThemes = [...themes];
    this.profileService.profile$
      .pipe(
        map(({ profile }) => {
          return { skin: profile?.browser_skin, isDarkMode: profile?.dark_theme };
        }),
        distinctUntilChanged(),
      )
      .subscribe(({ skin, isDarkMode }) => {
        this.setThemeByName(skin, isDarkMode);
        this.setDarkModePreference(isDarkMode);
      });
  }

  setTheme(): void {
    const referrerThemeValue = this.getThemeValueGivenReferrerUrl(document.referrer);
    let theme: string = referrerThemeValue ? this.getThemeValueGivenReferrerUrl(document.referrer) : DEFAULT_THEME_NAME;
    const { profile } = this.profileService.getUserProfile();

    if (!this.commonService.loggedIn) {
      const queryParamTheme = this.platform.getQueryParam('theme');

      theme = queryParamTheme ? queryParamTheme : theme;
    } else {
      theme = profile.browser_skin;
    }

    this.setThemeByName(<ThemeMap>theme, profile.dark_theme);

    this.setDarkModePreference(profile.dark_theme);
  }

  setDarkModePreference(isDarkMode: boolean): void {
    isDarkMode ? this.setDarkTheme() : this.setLightTheme();
  }

  setThemeByName(profileBrowserSkin: string, isDarkMode: boolean): void {
    this.userTheme = this.getThemeByName(profileBrowserSkin);
    this.applyTheme(isDarkMode);
    this.userTheme$.next(this.$userTheme);
  }

  getThemeByName(themeName: string): ThemeInterface {
    // ensure it is a themeValue
    let theme: ThemeInterface = this.availableThemes[DEFAULT_THEME_NAME];
    if (THEMES.map((el) => <string>el.value).includes(themeName)) {
      // then name has type ThemeValues
      theme = this.availableThemes.find((theme) => theme.name === themeName);
    }

    if (!theme) {
      return this.availableThemes.find((theme) => theme.name === DEFAULT_THEME_NAME);
    }

    return theme;
  }

  applyTheme(isDarkMode: boolean): void {
    this.domCtrl.write(() => {
      applyThemeToDocument(this.userTheme, this.document);
    });

    this.$userTheme.headerLogo.path = isDarkMode
      ? getThemeIconsDark[this.$userTheme.name]
      : getThemeIconsLight[this.$userTheme.name];

    const footerPath =
      isDarkMode || this.$userTheme.type === ThemeMode.DARK ? AppAssets.FOOTER_DARK : AppAssets.FOOTER_LIGHT;
    const footerColoredPath = isDarkMode ? AppAssets.FOOTER_DARK : AppAssets.FOOTER_LIGHT;
    const footerLogo =
      this.$userTheme.name === DEFAULT_THEME_NAME ? ThemeFooterIcons.DEFAULT : ThemeFooterIcons.OTHER_THEMES;
    this.$userTheme.footerLogo.path = `${footerPath}${footerLogo}`;
    this.$userTheme.footerLogo.style.width = this.$userTheme.name === DEFAULT_THEME_NAME ? '7em' : '12em';

    this.$userTheme.footerColoredLogo = `${footerColoredPath}${footerLogo}`;

    this.$userTheme.iconsSvgPath = isDarkMode ? `${AppAssets.ICONS_SVG_DARK}` : `${AppAssets.ICONS_SVG_LIGHT}`;
  }

  setDarkTheme(): void {
    this.domCtrl.write(() => {
      this.document.body.classList.value = ThemeMode.DARK;
    });
  }

  setLightTheme(): void {
    this.domCtrl.write(() => {
      this.document.body.classList.value = ThemeMode.LIGHT;
    });
  }

  getChartColors() {
    return this.userTheme.chartColors;
  }

  getChartColorValues(): Array<PlotColors> {
    const colorValues: Array<PlotColors> = [];

    for (const key in this.userTheme.chartColors) {
      if (Object.prototype.hasOwnProperty.call(this.userTheme.chartColors, key)) {
        const color: { value: string; opacValue: string } = this.userTheme.chartColors[key];
        colorValues.push({ value: color.value, opacValue: color.opacValue });
      }
    }

    return colorValues;
  }
  getChartColorOpacValues(): Array<string> {
    const colorOpacValues: Array<string> = [];

    for (const key in this.userTheme.chartColors) {
      if (Object.prototype.hasOwnProperty.call(this.userTheme.chartColors, key)) {
        const color: { value: string; opacValue: string } = this.userTheme.chartColors[key];
        colorOpacValues.push(color.opacValue);
      }
    }

    return colorOpacValues;
  }

  getVPPStateChartBarColors() {
    return this.userTheme.vppChartColors;
  }

  getThemeValueGivenReferrerUrl(referrerUrl: string): ThemeMap {
    let themeValue: ThemeMap;
    switch (referrerUrl) {
      case environment.qcellsReferrerUrl:
        themeValue = ThemeMap.Q_CELLS;
        break;
      case environment.eguanaReferrerUrl:
        themeValue = ThemeMap.EGUANA;
        break;
      default:
        themeValue = null;
    }
    return themeValue;
  }
}
