export enum AlertButtonRoles {
  CANCEL = 'cancel',
  OK = 'ok',
  NO = 'no',
  YES = 'yes',
  RETRY = 'retry',
  CLOSE = 'close',
  REMOVE = 'remove',
}

export type AlertButtons = {
  role: AlertButtonRoles;
  text: string;
};
