import { Injectable } from '@angular/core';
import { BrowserLogger } from '@class/core/browser-logger';
import { RequestMethod } from '@service/common/api-wrapper.service';
import { Endpoint } from '@service/units/units.types';
import { BaseApiService } from 'app/api-services/base.api-service';

@Injectable({
  providedIn: 'root',
})
export class EndpointsApi extends BaseApiService {
  constructor() {
    super('/api/v1/endpoints');
  }

  // TODO - strongly typed return value
  getEndpointServiceLogsTunnels(endpointUUID: string) {
    BrowserLogger.log('UnitsService.getEndpointServiceLogsTunnels', { endpointUUID });

    return this.request({
      path: `${endpointUUID}/device_access_logs/`,
      keepWrapped: true,
    });
  }

  // TODO - strongly typed return value
  upgradeEndpoint(endpointId: string) {
    BrowserLogger.log('UnitsService.upgradeEndpoint', { endpointId });
    return this.request({
      path: `${endpointId}/upgrade/`,
      method: RequestMethod.POST,
      keepWrapped: true,
    });
  }

  getEndpointData(endpointId: string) {
    BrowserLogger.log('UnitsService.getEndpointData', { endpointId });
    return this.request<Endpoint>({
      path: endpointId,
      keepWrapped: true,
    });
  }

  // TODO - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateDeviceControls(dropletUuid: string, data: any) {
    BrowserLogger.log('UnitsService.updateDeviceControls', { dropletUUID: dropletUuid, data });
    return this.request({
      path: `00000000-0000-0000-0000-${dropletUuid}/send_control/`,
      method: RequestMethod.POST,
      data,
      keepWrapped: true,
    });
  }

  getUnitEndpoints(unitId: string) {
    BrowserLogger.log('UnitsService.getUnitEndpoints', { unitId });

    return this.request<Endpoint[]>({
      baseUrl: '/api/v1/units',
      path: `${unitId}/endpoints/`,
      keepWrapped: true,
    });
  }
}
