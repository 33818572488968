import { Platform } from '@ionic/angular';

/* | Platform Name   | Description                        |
 * |-----------------|------------------------------------|
 * | android         | on a device running Android.       |
 * | capacitor       | on a device running Capacitor.     |
 * | cordova         | on a device running Cordova.       |
 * | ios             | on a device running iOS.           |
 * | ipad            | on an iPad device.                 |
 * | iphone          | on an iPhone device.               |
 * | phablet         | on a phablet device.               |
 * | tablet          | on a tablet device.                |
 * | electron        | in Electron on a desktop device.   |
 * | pwa             | as a PWA app.                      |
 * | mobile          | on a mobile device.                |
 * | mobileweb       | on a mobile device in a browser.   |
 * | desktop         | on a desktop device.               |
 * | hybrid          | is a cordova or capacitor app.     |
 *
 */
enum PlatformNames {
  ANDROID = 'android',
  CAPACITOR = 'capacitor',
  CORDOVA = 'cordova',
  IOS = 'ios',
  IPAD = 'ipad',
  IPHONE = 'iphone',
  PHABLET = 'phablet',
  TABLET = 'tablet',
  ELECTRON = 'electron',
  PWA = 'pwa',
  MOBILE = 'mobile',
  MOBILE_WEB = 'mobileweb',
  DESKTOP = 'desktop',
  HYBRID = 'hybrid',
}
export class IsPlatform {
  private static _ANDROID: boolean;
  public static get ANDROID(): boolean {
    return this._ANDROID;
  }

  private static _CAPACITOR: boolean;
  public static get CAPACITOR(): boolean {
    return IsPlatform._CAPACITOR;
  }

  private static _CORDOVA: boolean;
  public static get CORDOVA(): boolean {
    return IsPlatform._CORDOVA;
  }

  private static _DESKTOP: boolean;
  public static get DESKTOP(): boolean {
    return IsPlatform._DESKTOP;
  }

  private static _ELECTRON: boolean;
  public static get ELECTRON(): boolean {
    return IsPlatform._ELECTRON;
  }

  private static _HYBRID: boolean;
  public static get HYBRID(): boolean {
    return IsPlatform._HYBRID;
  }

  private static _IOS: boolean;
  public static get IOS(): boolean {
    return IsPlatform._IOS;
  }

  private static _IPAD: boolean;
  public static get IPAD(): boolean {
    return IsPlatform._IPAD;
  }

  private static _IPHONE: boolean;
  public static get IPHONE(): boolean {
    return IsPlatform._IPHONE;
  }

  private static _MOBILE: boolean;
  public static get MOBILE(): boolean {
    return IsPlatform._MOBILE;
  }

  private static _MOBILE_WEB: boolean;
  public static get MOBILE_WEB(): boolean {
    return IsPlatform._MOBILE_WEB;
  }

  private static _PHABLET: boolean;
  public static get PHABLET(): boolean {
    return IsPlatform._PHABLET;
  }

  private static _PWA: boolean;
  public static get PWA(): boolean {
    return IsPlatform._PWA;
  }

  private static _TABLET: boolean;
  public static get TABLET(): boolean {
    return IsPlatform._TABLET;
  }

  public static setPlatformValues(platform: Platform): void {
    this._ANDROID = platform.is(PlatformNames.ANDROID);
    this._CAPACITOR = platform.is(PlatformNames.CAPACITOR);
    this._CORDOVA = platform.is(PlatformNames.CORDOVA);
    this._DESKTOP = platform.is(PlatformNames.DESKTOP);
    this._ELECTRON = platform.is(PlatformNames.ELECTRON);
    this._HYBRID = platform.is(PlatformNames.HYBRID);
    this._IOS = platform.is(PlatformNames.IOS);
    this._IPAD = platform.is(PlatformNames.IPAD);
    this._IPHONE = platform.is(PlatformNames.IPHONE);
    this._MOBILE = platform.is(PlatformNames.MOBILE);
    this._MOBILE_WEB = platform.is(PlatformNames.MOBILE_WEB);
    this._PHABLET = platform.is(PlatformNames.PHABLET);
    this._PWA = platform.is(PlatformNames.PWA);
    this._TABLET = platform.is(PlatformNames.TABLET);
  }
  // public static printAll(): void {
  //   console.log('android:', this.ANDROID);
  //   console.log('capacitor:', this.CAPACITOR);
  //   console.log('cordova:', this.CORDOVA);
  //   console.log('desktop:', this.DESKTOP);
  //   console.log('electron:', this.ELECTRON);
  //   console.log('hybrid:', this.HYBRID);
  //   console.log('ios:', this.IOS);
  //   console.log('ipad:', this.IPAD);
  //   console.log('iphone:', this.IPHONE);
  //   console.log('mobile:', this.MOBILE);
  //   console.log('mobileweb:', this.MOBILE_WEB);
  //   console.log('phablet:', this.PHABLET);
  //   console.log('pwa:', this.PWA);
  //   console.log('tablet:', this.TABLET);
  // }
}
