import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { AddDeviceStore } from '../store/add-device.store';
import { Observable, Subject, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs';
import {
  DeviceConfigScreen,
  DeviceConnectionTypesNames,
  DropletLayoutPort,
  ManufacturerAndDevicesForDiscovery,
  ManufacturerDeviceType,
} from '../utils/add-device-types';
import { isEmpty } from 'lodash';
import { DataTypeStrings } from '@class/commons/enums';
import { ButtonType } from 'app/components/buttons/button/button.component';
import { ManufactureAndDevicesForDiscoveryStore } from '../store/manufacturer-devices-for-discovery.store';
import { AlertController, InputChangeEventDetail } from '@ionic/angular';
import { BrowserLogger } from '@class/core/browser-logger';
import { TranslationsService } from '@service/common/translations.service';

@Component({
  selector: 'app-discoverable-devices-list',
  templateUrl: './discoverable-devices-list.component.html',
  styleUrls: ['./discoverable-devices-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscoverableDevicesListComponent implements OnDestroy {
  readonly IsEmpty = isEmpty;
  readonly DataTypeStrings = DataTypeStrings;
  readonly DeviceConnectionTypesNames = DeviceConnectionTypesNames;
  readonly ButtonType = ButtonType;
  readonly DeviceConfigScreen = DeviceConfigScreen;

  readonly dropletSelectedPort$: Observable<DropletLayoutPort>;
  readonly discoverableDevicesViewModel$: Observable<{
    manufacturers: ManufacturerAndDevicesForDiscovery[];
    filteredManufacturers: ManufacturerAndDevicesForDiscovery[];
    selectedManufacturer: ManufacturerAndDevicesForDiscovery;
    selectedDevice: ManufacturerDeviceType;
  }>;
  readonly deviceConfigView$: Observable<DeviceConfigScreen>;

  readonly deviceToDiscover$ = this._addDeviceStore.deviceToDiscover$;

  readonly destroy$ = new Subject<void>();

  constructor(
    private _addDeviceStore: AddDeviceStore,
    private _manufacturerDevicesStore: ManufactureAndDevicesForDiscoveryStore,
    private _alertController: AlertController,
    private t: TranslationsService,
  ) {
    this.discoverableDevicesViewModel$ = this._manufacturerDevicesStore.discoverableDevicesViewModel$;
    this.dropletSelectedPort$ = this._addDeviceStore.dropletSelectedPort$;
    this.deviceConfigView$ = this._addDeviceStore.deviceConfigScreen$;
  }

  ngOnInit() {
    this.deviceToDiscover$
      .pipe(
        distinctUntilChanged((prev, curr) => prev?.status === curr?.status),
        filter((value) => ['success', 'fail'].some((status) => status === value?.status)),
        takeUntil(this.destroy$),
        tap(async (value) => {
          const status = value.status;

          const alert = await this._alertController.create({
            header: this.t.instant(status === 'success' ? 'General.Success' : 'AddDevice.Error.FailedToDiscoverDevice'),
            message: this.t.instant(value.message),
            buttons: ['OK'],
          });

          await alert.present();

          this._addDeviceStore.setDeviceConfigScreen(DeviceConfigScreen.INITIAL_INVENTORY_RECEIVED);
          this._addDeviceStore.resetDeviceToDiscover();
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._manufacturerDevicesStore.resetManufacturerState();
    this.destroy$.next();
    this.destroy$.complete();
  }

  onManufacturerHandler(manufacturer: ManufacturerAndDevicesForDiscovery): void {
    this._manufacturerDevicesStore.selectManufacturerAndShowDevices(manufacturer);

    // if only one device then show the attributes view
    // as the device will be auto selected
    manufacturer.device_types.length === 1
      ? this._addDeviceStore.showSelectedDeviceAttributesView()
      : this._addDeviceStore.showSelectedManufacturerDevicesView();
  }

  onDeviceTypeHandler(deviceType: ManufacturerDeviceType): void {
    this._manufacturerDevicesStore.selectDeviceTypeAndShowAttributes(deviceType);
    this._addDeviceStore.showSelectedDeviceAttributesView();
  }

  onDeviceTypeAttributeValueChange(
    key: string,
    value: string | number | boolean,
    device: ManufacturerDeviceType,
  ): void {
    const updatedDevice = {
      ...device,
      attributeValues: {
        ...device.attributeValues,
        [key]: value,
      },
    };
    this._manufacturerDevicesStore.updateSelectedDeviceForDiscovery(updatedDevice);
  }

  onResetDeviceTypeConnectionAttributesForLanWifi(device: ManufacturerDeviceType): void {
    const updatedDevice = {
      ...device,
      attributeValues: {
        ...device.attributeValues,
        ['port']: parseInt(
          device.device_type_connection_attributes[DeviceConnectionTypesNames.LAN_WIFI]?.PortNumber?.default,
        ),
        ['slaveid']: parseInt(
          device.device_type_connection_attributes[DeviceConnectionTypesNames.LAN_WIFI]?.SlaveID?.default,
        ),
      },
    };
    this._manufacturerDevicesStore.updateSelectedDeviceForDiscovery(updatedDevice);
  }

  onFindButtonHandler(device: ManufacturerDeviceType, port: DropletLayoutPort): void {
    BrowserLogger.log('onFindButtonHandler', { device, port });

    this._addDeviceStore.sendDeviceForDiscovery({ device, port });
  }

  handleManufacturerSearchInputChange(event: CustomEvent<InputChangeEventDetail>) {
    BrowserLogger.log('handleManufacturerSearchInputChange', event.detail.value);

    this._manufacturerDevicesStore.searchManufacturers(event.detail.value);
  }

  handleManufacturerDevicesSearchInputChange(event: CustomEvent<InputChangeEventDetail>) {
    BrowserLogger.log('handleManufacturerDevicesSearchInputChange', event.detail.value);

    this._manufacturerDevicesStore.searchDeviceTypes(event.detail.value ?? '');
  }
}
