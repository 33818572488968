import { Injectable } from '@angular/core';
import { AccountService } from '../accounts/account.service';
import { BehaviorSubject } from 'rxjs';
import { DEFAULT_THEME_NAME } from 'theme/theme-map';
import { UserFavDTO } from '@service/common/favourites.service';

/**
 * This name of this type changed to UserProfile when Pritha changed the
 * structure of the object in the backend. It would be unclear
 * to call it Profile since it has an attribute named profile.
 *
 * There is an argument to be made about renaming the profile.page and
 * profile.service for the time being we will keep the names as is
 */
export type UserProfile = {
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  groups: string[];
  is_staff: boolean;
  profile: {
    browser_skin: string;
    address: string | null;
    contact_phone: string | null;
    language: number;
    language_name: string;
    favourites: UserFavDTO[];
    api_token: string;
    dark_theme: boolean;
    mute_alerts: boolean;
    organisation: {
      uuid: string;
      code: string;
      name: string;
    };
  };
  permissions: {
    unit: boolean;
    portfolio: boolean;
    vpp: boolean;
    staff: boolean;
    installer: boolean;
    transaction: boolean;
    organisation: boolean;
  };
};

const emptyUserProfile: UserProfile = {
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  groups: [],
  is_staff: false,
  profile: {
    browser_skin: DEFAULT_THEME_NAME, // switchdin-rebrand is the default theme
    address: '',
    contact_phone: '',
    language: 1,
    language_name: '',
    favourites: [],
    api_token: '',
    dark_theme: false,
    mute_alerts: false,
    organisation: {
      uuid: '',
      code: '',
      name: '',
    },
  },
  permissions: {
    unit: false,
    portfolio: false,
    vpp: false,
    staff: false,
    installer: false,
    transaction: false,
    organisation: false,
  },
};

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private persistedUserProfile: UserProfile;

  public profile$ = new BehaviorSubject<UserProfile>(emptyUserProfile);
  /**
   * Getter $darkTheme
   * @return {boolean}
   */
  public get $isDarkMode(): boolean {
    const userProfile = this.getUserProfile();
    return userProfile.profile.dark_theme;
  }
  constructor(private accountsService: AccountService) {}

  getUserProfile(): UserProfile {
    return this.persistedUserProfile ? this.persistedUserProfile : emptyUserProfile;
  }

  resetService() {
    let emptyUserProfile: UserProfile;
    this.persistedUserProfile = emptyUserProfile;
  }

  async updateLoggedInProfile(newProfile: UserProfile): Promise<void> {
    const res = await this.accountsService.updateUserProfile(newProfile);
    this.persistedUserProfile = res.data;
    this.profile$.next(this.persistedUserProfile);
  }

  /**
   * This deep copy only works correctly when there are no Date objects,
   * functions, undefined, Infinity, RegExps, Maps, Sets, Blobs, FileLists,
   * sparse Arrays, Typed Arrays or other complex types within the object
   * to be copied.
   *
   * In this case since we are retrieving the Profile from the db all the
   * values are numbers, strings, string arrays, or null
   * (or sub-objects containing only these types) which complies
   */
  jsonDeepCopyUserProfile(profile: UserProfile): UserProfile {
    return <UserProfile>JSON.parse(JSON.stringify(profile));
  }

  setPersistedUserProfile(userProfile: UserProfile): void {
    this.persistedUserProfile = {
      username: userProfile.username,
      first_name: userProfile.first_name,
      last_name: userProfile.last_name,
      email: userProfile.email,
      groups: userProfile.groups,
      is_staff: userProfile.is_staff,
      profile: {
        browser_skin: userProfile.profile.browser_skin,
        address: userProfile.profile.address,
        contact_phone: userProfile.profile.contact_phone,
        language: userProfile.profile.language,
        language_name: userProfile.profile.language_name,
        favourites: userProfile.profile.favourites,
        api_token: userProfile.profile.api_token,
        dark_theme: userProfile.profile.dark_theme,
        mute_alerts: userProfile.profile.mute_alerts,
        organisation: userProfile.profile.organisation,
      },
      permissions: {
        unit: userProfile.permissions.unit,
        portfolio: userProfile.permissions.portfolio,
        vpp: userProfile.permissions.vpp,
        staff: userProfile.permissions.staff,
        installer: userProfile.permissions.installer,
        transaction: userProfile.permissions.transaction,
        organisation: userProfile.permissions.organisation,
      },
    };
    this.profile$.next(this.persistedUserProfile);
  }

  getPersistedUserProfile(): UserProfile {
    return this.persistedUserProfile;
  }

  clearUserProfileAndRoles(): void {
    this.setPersistedUserProfile({
      username: '',
      first_name: '',
      last_name: '',
      email: '',
      groups: [],
      is_staff: false,
      profile: {
        browser_skin: '',
        address: '',
        contact_phone: '',
        language: 1,
        language_name: '',
        favourites: [],
        api_token: '',
        dark_theme: false,
        mute_alerts: false,
        organisation: {
          uuid: '',
          code: '',
          name: '',
        },
      },
      permissions: {
        unit: false,
        portfolio: false,
        vpp: false,
        staff: false,
        installer: false,
        transaction: false,
        organisation: false,
      },
    });
  }

  getUserFavourites() {
    if (this.persistedUserProfile) {
      return this.persistedUserProfile.profile.favourites;
    } else {
      return [];
    }
  }

  darkThemeSelected(): boolean {
    const userProfile = this.getUserProfile();
    return userProfile.profile.dark_theme;
  }

  async deleteUserAccount(): Promise<unknown> {
    return await this.accountsService.deleteUserProfile();
  }
}
