import { inject } from '@angular/core';
import { injectMutation, injectQuery, injectQueryClient } from '@ngneat/query';
import { ApiWrapper, AvailableAPI, RequestMethod, UseHeaderType } from '@service/common/api-wrapper.service';
import qs from 'qs';
import { Observable } from 'rxjs';

export interface RequestOptions {
  useApi: AvailableAPI;
  useHeader: UseHeaderType;
}

export interface ConfigureRequestParams extends Partial<RequestOptions> {
  path: string;
  method?: RequestMethod;
  baseUrl?: string;
  // eslint-disable-next-line
  data?: any;
  // eslint-disable-next-line
  query?: any;
  encodeQuery?: boolean;
  keepWrapped?: boolean;
  disableURIEncoding?: boolean;
}

const defaultRequestOptions = {
  useApi: AvailableAPI.SWITCHDIN,
  useHeader: UseHeaderType.AUTHORIZED_SWDIN,
} satisfies RequestOptions;

export abstract class BaseApiService {
  protected _apiWrapper = inject(ApiWrapper);
  protected _query = injectQuery();
  protected _queryClient = injectQueryClient();
  protected _mutation = injectMutation();
  protected _baseUrl: string;
  protected _requestOptions: RequestOptions;

  public constructor(baseUrl: string, requestOptions: Partial<RequestOptions> = defaultRequestOptions) {
    this._baseUrl = baseUrl;

    if (this._baseUrl.endsWith('/')) {
      this._baseUrl.substring(0, this._baseUrl.length - 1);
    }

    this._requestOptions = {
      ...defaultRequestOptions,
      ...requestOptions,
    };
  }

  protected async request<T = unknown>({
    keepWrapped,
    ...rest
  }: { keepWrapped: true } & Omit<ConfigureRequestParams, 'keepWrapped'>): Promise<{ data: T }>;
  protected async request<T = unknown>(params: ConfigureRequestParams): Promise<T>;
  protected async request<T = unknown>({
    path,
    method = RequestMethod.GET,
    baseUrl = this._baseUrl,
    useApi = this._requestOptions.useApi,
    useHeader = this._requestOptions.useHeader,
    data = {},
    query,
    keepWrapped = false,
    encodeQuery = true,
  }: ConfigureRequestParams) {
    // remove leading and trailing slash from path
    path = path.replace(/\/$/, '');
    path = path.replace(/^\//, '');

    let url = `${baseUrl}/${path}`;

    // make sure url ends in a slash
    if (!url.endsWith('/')) {
      url += '/';
    }

    // make sure url does not contain double slashes
    url = url.replaceAll(/\/\//g, '/');

    // add query if present
    if (query) {
      url +=
        '?' +
        qs.stringify(query, {
          encode: encodeQuery,
        });
    }

    const res = await this._apiWrapper.handleRequest<T>(useApi, url, method, useHeader, data);

    if (keepWrapped) {
      return res;
    } else {
      return res.data;
    }
  }
  protected requestObs<T = unknown>({
    path,
    method = RequestMethod.GET,
    baseUrl = this._baseUrl,
    useApi = this._requestOptions.useApi,
    useHeader = this._requestOptions.useHeader,
    data = {},
    query,
    disableURIEncoding = false,
  }: ConfigureRequestParams): Observable<T> {
    // remove leading and trailing slash from path
    path = path.replace(/\/$/, '');
    path = path.replace(/^\//, '');

    let url = `${baseUrl}/${path}`;

    // make sure url ends in a slash
    if (!url.endsWith('/')) {
      url += '/';
    }

    // make sure url does not contain double slashes
    url = url.replaceAll(/\/\//g, '/');

    // add query if present
    if (query) {
      url += '?' + qs.stringify(query, { encode: !disableURIEncoding });
    }

    return this._apiWrapper.handleObservableRequest<T>({
      useAPI: useApi,
      url,
      requestMethod: method,
      useHeader,
      requestData: data,
      disableURIEncoding,
    });
  }
}
