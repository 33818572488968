import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { ManufacturerAndDevicesForDiscovery, ManufacturerDeviceType } from '../utils/add-device-types';
import { Injectable } from '@angular/core';
import { Observable, switchMap, tap } from 'rxjs';
import { ManufacturerDevicesForDiscoveryService } from '../services/manufacturer-devices-for-discovery.service';
import { produce } from 'immer';

interface ManufacturerAndDevicesForDiscoveryState {
  manufacturers: ManufacturerAndDevicesForDiscovery[];
  selectedManufacturer: ManufacturerAndDevicesForDiscovery;
  selectedDevice: ManufacturerDeviceType;
  manufacturerSearchQuery: string;
  deviceTypeSearchQuery: string;
}

const defaultState: ManufacturerAndDevicesForDiscoveryState = {
  manufacturers: [],
  selectedManufacturer: null,
  selectedDevice: null,
  manufacturerSearchQuery: String(),
  deviceTypeSearchQuery: String(),
};

@Injectable()
export class ManufactureAndDevicesForDiscoveryStore extends ComponentStore<ManufacturerAndDevicesForDiscoveryState> {
  constructor(private _manufacturerDevicesService: ManufacturerDevicesForDiscoveryService) {
    super(defaultState);
    this.getManufacturers();
  }

  /** ** ** ** ** ** SELECTORS ** ** ** ** ** */
  readonly manufacturers$ = this.select((state) => state.manufacturers);

  readonly manufacturerSearchQuery$ = this.select((state) => state.manufacturerSearchQuery);

  readonly deviceTypeSearchQuery$ = this.select((state) => state.deviceTypeSearchQuery);

  readonly filteredManufacturers$: Observable<ManufacturerAndDevicesForDiscovery[]> = this.select(
    this.manufacturers$,
    this.manufacturerSearchQuery$,
    (manufacturers, searchQuery) => {
      return manufacturers.filter((manufacturer) =>
        manufacturer.name.toLowerCase().includes(searchQuery.toLowerCase()),
      );
    },
  );

  private readonly selectedManufacturer$ = this.select(({ selectedManufacturer }) => selectedManufacturer);

  readonly deviceTypes$ = this.select(this.selectedManufacturer$, (manufacturer) => manufacturer?.device_types ?? []);

  readonly filteredDeviceTypes$ = this.select(
    this.deviceTypes$,
    this.deviceTypeSearchQuery$,
    (deviceTypes, searchQuery) =>
      deviceTypes.filter((deviceType) => deviceType.display_name.toLowerCase().includes(searchQuery.toLowerCase())),
  );

  private readonly selectedDevice$ = this.select(({ selectedDevice }) => selectedDevice);

  readonly discoverableDevicesViewModel$ = this.select(
    this.manufacturers$,
    this.filteredManufacturers$,
    this.selectedManufacturer$,
    this.selectedDevice$,
    this.filteredDeviceTypes$,
    (manufacturers, filteredManufacturers, selectedManufacturer, selectedDevice, filteredDeviceTypes) => ({
      manufacturers,
      filteredManufacturers,
      filteredDeviceTypes,
      selectedManufacturer,
      selectedDevice,
    }),
  );

  /** ** ** ** ** ** PUBLIC EFFECTS ** ** ** ** ** */
  readonly selectManufacturerAndShowDevices = this.effect(
    (manufacturer$: Observable<ManufacturerAndDevicesForDiscovery>) => {
      return manufacturer$.pipe(
        tap((manufacturer: ManufacturerAndDevicesForDiscovery) => {
          this.setDiscoverableDevicesViewModelManufacturer(manufacturer);
        }),
      );
    },
  );
  readonly selectDeviceTypeAndShowAttributes = this.effect((deviceType$: Observable<ManufacturerDeviceType>) => {
    return deviceType$.pipe(
      tap((deviceType: ManufacturerDeviceType) => {
        this.setDiscoverableDevicesViewModelManufacturerDeviceType(deviceType);
      }),
    );
  });
  readonly updateSelectedDeviceForDiscovery = this.effect((deviceType$: Observable<ManufacturerDeviceType>) => {
    return deviceType$.pipe(
      tap((deviceType: ManufacturerDeviceType) => {
        this.updateSelectedDeviceForDiscoveryInState(deviceType);
      }),
    );
  });
  readonly resetManufacturerState = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      tap(() => {
        this.resetState();
      }),
    );
  });

  readonly searchManufacturers = this.effect((searchQuery$: Observable<string>) => {
    return searchQuery$.pipe(
      tap<string>((searchQuery) => {
        this.setManufacturerSearchQuery(searchQuery);
      }),
    );
  });

  readonly searchDeviceTypes = this.updater<string>((state, query) => {
    return produce(state, (state) => {
      state.deviceTypeSearchQuery = query;
    });
  });

  /** ** ** ** ** ** PRIVATE METHODS ** ** ** ** ** */
  private resetState(): void {
    this.setState((state: ManufacturerAndDevicesForDiscoveryState) => {
      return {
        ...defaultState,
        // need to leave this in the state as we don't want to get this data again and again
        manufacturers: state.manufacturers,
      };
    });
  }

  /** ** ** ** ** ** PRIVATE EFFECTS ** ** ** ** ** */
  private readonly getManufacturers = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      switchMap(() =>
        this._manufacturerDevicesService.getIEC61850Manufacturer().pipe(
          tapResponse(
            (res) => {
              this.setManufacturers(res);
            },
            (error) => console.log('error', error),
          ),
        ),
      ),
    );
  });

  /** ** ** ** ** ** PRIVATE UPDATERS ** ** ** ** ** */
  private readonly setManufacturers = this.updater((state, manufacturers: ManufacturerAndDevicesForDiscovery[]) => {
    return {
      ...state,
      manufacturers,
    };
  });
  private readonly setDiscoverableDevicesViewModelManufacturer = this.updater(
    (state, manufacturer: ManufacturerAndDevicesForDiscovery) => {
      return produce(state, (state) => {
        state.selectedManufacturer = manufacturer;
        state.selectedDevice =
          manufacturer.device_types.length === 1 ? manufacturer.device_types[0] : { ...state.selectedDevice };
        state.deviceTypeSearchQuery = String();
      });
    },
  );
  private readonly setDiscoverableDevicesViewModelManufacturerDeviceType = this.updater(
    (state, deviceType: ManufacturerDeviceType) => {
      return {
        ...state,
        selectedDevice: deviceType,
      };
    },
  );
  private readonly updateSelectedDeviceForDiscoveryInState = this.updater(
    (state, deviceType: ManufacturerDeviceType) => {
      return {
        ...state,
        selectedDevice: {
          ...state.selectedDevice,
          attributeValues: deviceType.attributeValues,
        },
      };
    },
  );
  private readonly setManufacturerSearchQuery = this.updater((state, searchQuery: string) => {
    return {
      ...state,
      manufacturerSearchQuery: searchQuery,
    };
  });
}
