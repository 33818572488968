import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestMethod } from '@service/common/api-wrapper.service';
import { BaseApiService } from 'app/api-services/base.api-service';

export interface AutomatedTestDetailQueryKey {
  testId: string;
  device: string;
}

export const queryKeys = {
  all: ['devices', 'automated-tests'] as const,
  details: () => [...queryKeys.all, 'details'],
  detail: ({ testId, device }: AutomatedTestDetailQueryKey) => [...queryKeys.details(), device, testId],
  latest: (deviceUuid) => [...queryKeys.all, 'latest', deviceUuid],
};

export enum AutomatedTestStatus {
  Pending = 'pending',
  Cancelled = 'cancelled',
  Passed = 'passed',
  Failed = 'failed',
  // for internal use, not coming from the backend
  Starting = 'starting',
  Stopping = 'stopping',
  InitialChecking = 'initial-checking',
}

export interface AutomatedTestResponse {
  device: string;
  uuid: string;
  test_created: string;
  test_updated: string;
  test_status: AutomatedTestStatus;
  test_message: string;
  test_result: Record<
    'tests',
    Array<{
      data: Record<'time' | string, Array<number>>;
    }>
  >;
}

export interface AutomatedTestStatusLatestResponse {
  previous: string | null;
  next: string | null;
  results: AutomatedTestResponse[];
}

export interface StartAutomatedTestRequestParams {
  device: string;
}

export interface StopAutomatedTestRequestParams {
  testId: string;
}

export interface GetAutomatedTestRequestParams {
  device?: string;
  testId?: string;
  refetchInterval?: number;
  initialData?: AutomatedTestResponse;
}

@Injectable({
  providedIn: 'root',
})
export class AutomatedTestingService extends BaseApiService {
  constructor() {
    super('/api/v2/devices/automated-tests');
  }

  startAutomatedTest() {
    return this._mutation({
      mutationFn: ({ device }: StartAutomatedTestRequestParams) =>
        this.request<AutomatedTestResponse>({
          path: '/',
          method: RequestMethod.POST,
          data: {
            device,
          },
          keepWrapped: true,
        }),
      onSuccess: (res) => {
        this._queryClient.setQueryData(queryKeys.detail({ testId: res.data.uuid, device: res.data.device }), res.data);
      },
    });
  }

  stopAutomatedTest() {
    return this._mutation({
      mutationFn: (params: StopAutomatedTestRequestParams) =>
        this.request<AutomatedTestResponse>({
          path: params.testId,
          method: RequestMethod.PATCH,
          keepWrapped: true,
          data: {
            test_status: AutomatedTestStatus.Cancelled,
          },
        }),
      onSuccess: (res) => {
        this._queryClient.setQueryData(queryKeys.detail({ testId: res.data.uuid, device: res.data.device }), res.data);
      },
    });
  }

  getAutomatedTest({ testId, device, refetchInterval = 3000, initialData }: GetAutomatedTestRequestParams) {
    return this._query({
      queryKey: queryKeys.detail({ testId, device }),
      queryFn: () =>
        this.request<AutomatedTestResponse>({
          path: testId,
        }),
      refetchInterval: (query) => {
        switch (query.state.data?.test_status) {
          case AutomatedTestStatus.Pending:
            return refetchInterval;
          default:
            return false;
        }
      },
      initialData,
      enabled: testId != null && device != null,
      throwOnError: (error: HttpErrorResponse) => error.status >= 500,
    });
  }

  getLatestAutomatedTestByDeviceUuid(deviceUuid: string) {
    return this._query({
      queryKey: queryKeys.latest(deviceUuid),
      queryFn: () =>
        this.request<AutomatedTestStatusLatestResponse>({
          path: '/',
          query: { device__uuid: deviceUuid },
        }),
      enabled: deviceUuid != null,
    });
  }
}
