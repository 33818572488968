import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { ImmerComponentStore } from 'ngrx-immer/component-store';
import {
  catchError,
  filter,
  finalize,
  first,
  interval,
  map,
  Observable,
  race,
  Subject,
  switchMap,
  takeWhile,
  tap,
  timer,
  withLatestFrom,
} from 'rxjs';
import { AddDeviceService } from '../services/add-device.service';
import {
  DropletMqttMessages,
  InventoryDevices,
  DeviceConfigScreen,
  InventoryMqttMessage,
  DeviceStates,
  DeviceConnectionTypes,
  DeviceTypeConfigurationAttributes,
  ManufacturerDeviceType,
  InventoryDeviceConnectionType,
  AddDeviceError,
  DeviceTypes,
  DropletDetail,
  DropletLayoutForDiscovery,
  DropletLayoutPort,
  InventoryDevicesListToDisplay,
  MqttMessage,
  DropletPortDeviceForDiscovery,
  SearchDeviceMqttMessage,
  DropletDetailDevices,
} from '../utils/add-device-types';
import { DropletPortTypes } from '@class/commons/enums';
import { isEmpty, isEqual, sortBy } from 'lodash';
import { UnitsService } from '@service/units/units.service';
import { adaptToDropletPortDeviceForDiscovery, getDeviceConfigurationPostApi } from '../utils/utils';
import { BrowserLogger } from '../../../../classes/core/browser-logger';
import { isApiError } from 'app/shared/api-utils';
import { TranslationsService } from '@service/common/translations.service';
import { GenericAlertsToastsService } from '@service/common/generic-alerts-toasts.service';
import { EndpointsApiService } from 'app/api-services/endpoints.api-service';
import { filterSuccessResult, injectQueryClient } from '@ngneat/query';
import { queryKeys as endpointsQueryKeys } from 'app/api-services/endpoints.api-service';
import { queryKeys as devicesQueryKeys } from 'app/api-services/devices/devices.api-service';

const CONNECT_TIMEOUT_MILLIS = 30000;

const filterMqttMessages = <M extends MqttMessage = MqttMessage>(type: MqttMessage['message_type']) =>
  filter<M>((msg) => msg.message_type === type);

interface AddDeviceState {
  deviceConfigView: DeviceConfigScreen;
  error: AddDeviceError;
  dropletDetails: DropletDetail;
  dropletInventory: InventoryMqttMessage;
  configuredDevices: InventoryDevicesListToDisplay[];
  discoveredDevices: InventoryDevicesListToDisplay[];
  staticConfiguredDevices: InventoryDevicesListToDisplay[];
  initialisedDevices: InventoryDevicesListToDisplay[];
  deviceConnectionTypes: DeviceConnectionTypes[];
  deviceConfigurationAttributes: {
    [deviceUniqueId: string]: { [deviceAttributeName: string]: DeviceTypeConfigurationAttributes };
  };
  deviceTypes: {
    [deviceUniqueId: string]: DeviceTypes;
  };
  selectedPort: DropletLayoutPort;

  dropletAvailablePortsToDiscoverDevice: DropletLayoutForDiscovery;
  dropletAllInterfacesPortsToDiscoverDevice: DropletLayoutPort;
  discoveryDeviceForConfiguration: InventoryDevicesListToDisplay;

  connectTimeoutId: NodeJS.Timeout | number;
  connectTimeoutMillis: number;

  configuring: Record<string, boolean>;
  removing: Record<string, boolean>;
  recentlyConfigured: Record<string, boolean>;

  deviceToDiscover?: Partial<{
    status: 'idle' | 'discovering' | 'success' | 'fail';
    target: DropletPortDeviceForDiscovery;
    message: string;
  }>;
}

const defaultState: AddDeviceState = {
  deviceConfigView: DeviceConfigScreen.WAITING_FOR_INITIAL_INVENTORY,
  error: {
    initialInventory: '',
  },
  dropletDetails: {
    uuid: '',
    id: '',
    devices: {},
  },
  dropletInventory: {} as InventoryMqttMessage,
  configuredDevices: [] as InventoryDevicesListToDisplay[],
  discoveredDevices: [] as InventoryDevicesListToDisplay[],
  staticConfiguredDevices: [] as InventoryDevicesListToDisplay[],
  initialisedDevices: [] as InventoryDevicesListToDisplay[],
  deviceConnectionTypes: [] as DeviceConnectionTypes[],
  deviceConfigurationAttributes: {} as {
    // key will be device unique id
    [key: string]: { [deviceAttributeName: string]: DeviceTypeConfigurationAttributes };
  },
  deviceTypes: {} as {
    [deviceUniqueId: string]: DeviceTypes;
  },
  selectedPort: {} as DropletLayoutPort,

  dropletAvailablePortsToDiscoverDevice: {} as DropletLayoutForDiscovery,
  dropletAllInterfacesPortsToDiscoverDevice: {} as DropletLayoutPort,
  discoveryDeviceForConfiguration: {} as InventoryDevicesListToDisplay,

  connectTimeoutMillis: CONNECT_TIMEOUT_MILLIS,
  connectTimeoutId: 0,

  configuring: {},
  removing: {},
  recentlyConfigured: {},
};

@Injectable()
export class AddDeviceStore extends ImmerComponentStore<AddDeviceState> {
  #queryClient = injectQueryClient();

  constructor(
    private _addDeviceService: AddDeviceService,
    private _unitsService: UnitsService,
    private _translationsService: TranslationsService,
    private _alertsToastsService: GenericAlertsToastsService,
    private _endpointsApiService: EndpointsApiService,
  ) {
    super(defaultState);
    this.getDeviceConnectionTypes();
    this.manageConnectTimeout(this.deviceConfigScreen$);
  }

  /** ** ** ** SELECTORS ** ** ** */
  readonly deviceToDiscover$ = this.select(({ deviceToDiscover }) => deviceToDiscover);

  readonly deviceConfigScreen$: Observable<DeviceConfigScreen> = this.select((state) => state.deviceConfigView);

  readonly addDeviceError$: Observable<AddDeviceError> = this.select((state) => state.error);

  private readonly sortDevices = (devices: InventoryDevicesListToDisplay[]) =>
    sortBy(devices, ['info.manufacturer', 'info.model_name']);

  readonly configuredDevices$: Observable<InventoryDevicesListToDisplay[]> = this.select(
    (state) => state.configuredDevices,
  );

  readonly dropletDetails$ = this.select((state) => state.dropletDetails);

  readonly endpoint$ = this.dropletDetails$.pipe(
    switchMap(
      (droplet) =>
        this._endpointsApiService.getEndpoint({
          endpointUuid: droplet.uuid,
        }).result$,
    ),
    map((res) => res.data),
  );

  readonly discoveredDevices$: Observable<InventoryDevicesListToDisplay[]> = this.select(
    (state) => state.discoveredDevices,
  );

  readonly staticConfiguredDevices$: Observable<InventoryDevicesListToDisplay[]> = this.select(
    (state) => state.staticConfiguredDevices,
  );

  readonly initialisedDevices$: Observable<InventoryDevicesListToDisplay[]> = this.select(
    (state) => state.initialisedDevices,
  );

  readonly dropletAvailablePortsToDiscoverDevice$: Observable<DropletLayoutForDiscovery> = this.select(
    (state) => state.dropletAvailablePortsToDiscoverDevice,
  );

  readonly discoveryDeviceForConfiguration$: Observable<InventoryDevicesListToDisplay> = this.select(
    (state) => state.discoveryDeviceForConfiguration,
  );

  readonly dropletAllInterfacesPortsToDiscoverDevice$: Observable<DropletLayoutPort> = this.select(
    (state) => state.dropletAllInterfacesPortsToDiscoverDevice,
  );

  readonly dropletSelectedPort$: Observable<DropletLayoutPort> = this.select((state) => state.selectedPort);

  private readonly connectTimeoutId$ = this.select((state) => state.connectTimeoutId);

  private readonly connectTimeoutMillis$ = this.select((state) => state.connectTimeoutMillis);

  readonly setDeviceConfigScreen = this.updater((state, deviceConfigView: DeviceConfigScreen) => ({
    ...state,
    deviceConfigView,
  }));

  readonly deviceDeleted$ = new Subject<InventoryDevicesListToDisplay>();

  readonly fetchEndpoint = this._endpointsApiService.getEndpoint.bind(this._endpointsApiService);

  readonly mqttMessage$ = new Subject<MqttMessage>();

  /** ** ** ** PUBLIC EFFECTS/ACTIONS ** ** ** */
  readonly getDropletDetails = this.effect((uuid$: Observable<string>) => {
    return uuid$.pipe(
      switchMap((uuid) => {
        return this.fetchEndpoint({
          endpointUuid: uuid,
        }).result$.pipe(
          filterSuccessResult(),
          map((res): DropletDetail => {
            const droplet = res.data;

            const devices = {};

            const filteredDevices = droplet?.devices.filter((device) => device.droplet_device_unique_id);
            filteredDevices.forEach((device) => {
              devices[device.droplet_device_unique_id] = {
                id: device.id,
                uuid: device.uuid,
                automatedTestSupported: device.automated_test_supported,
              };
            });

            BrowserLogger.log('getDropletDetails', filteredDevices);

            return {
              uuid: droplet?.long_uuid,
              id: droplet?.id,
              devices,
              hardwareVersion: droplet?.endpoint_attributes?.hw_version,
            } satisfies DropletDetail;
          }),
          tapResponse(
            (res) => {
              BrowserLogger.log('droplets: ', res);
              this.setDropletDetail(res);
              this.setConfiguredDevices();
            },
            (error) => console.log('error', error),
          ),
        );
      }),
    );
  });
  readonly subscribeToInventory = this.effect((uuid$: Observable<string>) => {
    return uuid$.pipe(
      switchMap((uuid: string) => {
        return this._addDeviceService.getInventory(uuid).pipe(
          tapResponse(
            (res) => {
              this.mqttMessageHandler(res);

              this.mqttMessage$.next(res);
            },
            (error) => console.log('error', error),
          ),
        );
      }),
    );
  });

  readonly startDiscoveryProcess = this.effect((uuid$: Observable<string>) => {
    return uuid$.pipe(
      switchMap((uuid: string) =>
        this._addDeviceService.startDiscovery(uuid).pipe(
          tapResponse(
            (res) => {
              BrowserLogger.log('AddDeviceStore.startDiscoveryProcess', res);
            },
            (error) => console.log('error', error),
          ),
        ),
      ),
    );
  });

  readonly stopDiscoveryProcess = this.effect<string>((uuid$) => {
    return uuid$.pipe(
      switchMap((uuid) =>
        this._addDeviceService.stopDiscovery(uuid).pipe(
          tapResponse(
            () => {},
            (error) => console.log('error', error),
          ),
        ),
      ),
    );
  });

  readonly resetStateInventory = this.effect((uuid$: Observable<string>) => {
    return uuid$.pipe(
      tap<string>((uuid) => {
        this.resetState();
        this.stopDiscoveryProcess(uuid);
      }),
    );
  });

  refetchEndpointQuery(uuid?: string) {
    if (uuid) {
      BrowserLogger.log('invalidating endpoint query', uuid);

      this.#queryClient.refetchQueries({
        queryKey: endpointsQueryKeys.detail({
          endpointUuid: uuid,
        }),
      });
    }
  }

  invalidateDeviceQuery(deviceId: string) {
    this.#queryClient.removeQueries({
      queryKey: devicesQueryKeys.detail({ deviceId }),
    });
  }

  readonly configureDiscoveredDevice = this.effect((device$: Observable<InventoryDevicesListToDisplay>) => {
    return device$.pipe(
      map((device) =>
        getDeviceConfigurationPostApi(device, this.get().dropletDetails, this.get().deviceConnectionTypes),
      ),
      withLatestFrom(this.deviceConfigScreen$, this.endpoint$),
      switchMap(([device, deviceConfigScreen, endpoint]) => {
        this.patchState({
          removing: {
            [device.device_info.device_unique_id]: false,
          },
          configuring: {
            [device.device_info.device_unique_id]: true,
          },
          recentlyConfigured: {
            [device.device_info.device_unique_id]: false,
          },
        });

        return this._addDeviceService.configureDevice(device).pipe(
          map((res) => {
            if (res.isError) {
              throw res.error;
            }

            return res;
          }),
          switchMap(() =>
            interval(1000).pipe(
              takeWhile((_, index) => index < 20),
              withLatestFrom(this.configuredDevices$),
              filter(([, configuredDevices]) => {
                return !!configuredDevices.find((d) => d.device_unique_id === device.device_info.device_unique_id);
              }),
              first(),
            ),
          ),
          tap((res) => {
            BrowserLogger.log('configureDiscoveredDevice: ', res);

            if (deviceConfigScreen === DeviceConfigScreen.DEVICE_SETTINGS_FOR_CONFIGURATION) {
              this.patchState({
                deviceConfigView: DeviceConfigScreen.INITIAL_INVENTORY_RECEIVED,
              });
            }

            this._unitsService.getEndpointsOfSelectedUnit();

            this.refetchEndpointQuery(endpoint.long_uuid);
            this.getDropletDetails(endpoint.long_uuid);

            this.patchState({
              removing: {
                [device.device_info.device_unique_id]: false,
              },
              configuring: {
                [device.device_info.device_unique_id]: false,
              },
              recentlyConfigured: {
                [device.device_info.device_unique_id]: true,
              },
            });
          }),
          catchError(async (error: HttpErrorResponse) => {
            const { Header: heading, Subheader: subheading } = this._translationsService.instant(
              'AddDevice.ConfigureDeviceAlert.Fail',
            );

            this._unitsService.getEndpointsOfSelectedUnit();

            this.refetchEndpointQuery(endpoint.long_uuid);
            this.getDropletDetails(endpoint.long_uuid);

            this.patchState({
              removing: {
                [device.device_info.device_unique_id]: false,
              },
              configuring: {
                [device.device_info.device_unique_id]: false,
              },
              recentlyConfigured: {
                [device.device_info.device_unique_id]: true,
              },
            });

            let message: string;
            if (isApiError(error)) {
              message = error.error.Msg;
            } else {
              console.error(error);

              message = this._translationsService.instant('General.UnknownError');
            }

            const errorAlert = await this._alertsToastsService.createErrorAlertWithOkButton({
              heading,
              subheading,
              message,
            });

            await errorAlert.present();
          }),
        );
      }),
    );
  });

  readonly removeDevice = this.effect<{ deviceId?: string; deviceUniqueId: string; showAlerts?: boolean }>(
    (params$) => {
      return params$.pipe(
        withLatestFrom(this.endpoint$),
        map(([params, endpoint]) => {
          this.patchState({
            configuring: {
              [params.deviceUniqueId]: false,
            },
            removing: {
              [params.deviceUniqueId]: true,
            },
          });

          const result = params.deviceId
            ? this._addDeviceService.removeConfiguredDevice(params.deviceId).pipe(map(() => {}))
            : this._addDeviceService.removeConfiguredDeviceFromDroplet(endpoint.long_uuid, params.deviceUniqueId);

          return result
            .pipe(
              switchMap(() =>
                interval(1000).pipe(
                  takeWhile((_, index) => index < 30),
                  withLatestFrom(this.configuredDevices$),
                  filter(([, configuredDevices]) => {
                    return !configuredDevices.some((d) => d.device_unique_id === params.deviceUniqueId);
                  }),
                  first(),
                ),
              ),
              tap(async () => {
                if (!params.showAlerts) {
                  return;
                }

                const alertData = this._translationsService.instant('AddDevice.RemoveDeviceAlert.Successful');

                const successAlert = await this._alertsToastsService.createAlertWithOkButton(
                  alertData.Header,
                  alertData.Subheader,
                  alertData.Message,
                );

                successAlert.present();
              }),
              tap(() => {
                this._unitsService.getEndpointsOfSelectedUnit();

                BrowserLogger.log('invalidateEndpointQuery', endpoint);

                this.refetchEndpointQuery(endpoint.long_uuid);
                this.invalidateDeviceQuery(params.deviceId);
              }),
              finalize(() => {
                this.patchState({
                  removing: {
                    [params.deviceUniqueId]: false,
                  },
                  configuring: {
                    [params.deviceUniqueId]: false,
                  },
                });
              }),
              catchError((error) => {
                console.error('catch', error);

                return error;
              }),
            )
            .subscribe();
        }),
      );
    },
  );

  readonly showDiscoverDeviceView = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      tap(() => {
        this.setDiscoverDeviceView();
      }),
    );
  });
  readonly updateConfigSettingsForDiscoveredDevice = this.effect(
    (device$: Observable<InventoryDevicesListToDisplay>) => {
      return device$.pipe(map((device) => this.setConfigSettingsForDiscoveredDevice(device)));
    },
  );
  readonly selectPortAndUpdateTheViewToShowManufacturers = this.effect((port$: Observable<DropletLayoutPort>) => {
    BrowserLogger.info('selectPortAndUpdateTheViewToShowManufacturers');

    return port$.pipe(
      tap((port: DropletLayoutPort) => {
        this.setDeviceConfigScreen(DeviceConfigScreen.MANUFACTURERS_LIST);
        this.setSelectedPort(port);
      }),
    );
  });
  readonly showManufacturersView = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      tap(() => {
        this.setDeviceConfigScreen(DeviceConfigScreen.MANUFACTURERS_LIST);
      }),
    );
  });
  readonly showSelectedManufacturerDevicesView = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      tap(() => {
        this.setDeviceConfigScreen(DeviceConfigScreen.SELECTED_MANUFACTURER_DEVICES);
      }),
    );
  });
  readonly showSelectedDeviceAttributesView = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      tap(() => {
        this.setDeviceConfigScreen(DeviceConfigScreen.SELECTED_DEVICE_DISCOVERY_ATTRIBUTES);
      }),
    );
  });

  readonly sendDeviceForDiscovery = this.effect(
    (deviceAndPort$: Observable<{ device: ManufacturerDeviceType; port: DropletLayoutPort }>) => {
      return deviceAndPort$.pipe(
        map(({ device, port }) => adaptToDropletPortDeviceForDiscovery(device, port)),
        tap((deviceToDiscover) => {
          BrowserLogger.log('deviceToDiscover: ', deviceToDiscover);

          this.setDeviceToDiscover({
            target: deviceToDiscover,
            status: 'discovering',
          });
        }),
        withLatestFrom(this.dropletDetails$),
        switchMap(([deviceToDiscover, dropletDetails]) =>
          this._addDeviceService.sendDeviceForDiscovery(dropletDetails.uuid, deviceToDiscover).pipe(
            switchMap(() =>
              race(
                this.mqttMessage$.pipe(
                  filterMqttMessages<SearchDeviceMqttMessage>(DropletMqttMessages.SEARCH_DEVICE),
                  first(),
                ),
                timer(45000).pipe(
                  first(),
                  map(() => ({
                    timeout: true,
                  })),
                ),
              ),
            ),
            map((res) => ({
              res,
              deviceToDiscover,
            })),
          ),
        ),
        tapResponse<{
          res:
            | SearchDeviceMqttMessage
            | {
                timeout: boolean;
              };
          deviceToDiscover: DropletPortDeviceForDiscovery;
        }>(
          ({ res, deviceToDiscover }) => {
            if ('timeout' in res) {
              this.setDeviceToDiscover({
                status: 'fail',
                message: 'Timeout',
              });
            } else {
              const ports = res.port ? [res.port] : (res.ports ?? []);
              const port = ports.find(
                (port) => port.port_number === deviceToDiscover.port_number && port.type === deviceToDiscover.type,
              );
              const onAdapter = port && deviceToDiscover.adapters && deviceToDiscover.adapters.length > 0;
              const devices = onAdapter ? port.adapters[0].devices : (port.devices ?? []);
              const device = devices && devices[0];

              const ok = device?.device_state === DeviceStates.DISCOVERED;

              if (ok) {
                this.setDeviceToDiscover({
                  status: 'success',
                  message: 'Device added successfully',
                });
              } else {
                const setDeviceFailed = () =>
                  this.setDeviceToDiscover({
                    status: 'fail',
                    message: device?.error_codes ?? 'Failed to add device',
                  });

                if (device?.device_unique_id) {
                  this.removeDevice({ deviceUniqueId: device.device_unique_id }).add(setDeviceFailed);
                } else {
                  setDeviceFailed();
                }
              }
            }
          },
          (error) => console.log('error', error),
        ),
      );
    },
  );

  readonly showLegacyDevices = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      tap(() => {
        this.setDeviceConfigScreen(DeviceConfigScreen.LEGACY_DEVICES);
      }),
    );
  });
  readonly showInitialInventoryView = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      tap(() => {
        this.setDeviceConfigScreen(DeviceConfigScreen.INITIAL_INVENTORY_RECEIVED);
      }),
    );
  });
  readonly showDropletPortImageLayout = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      tap(() => {
        this.setDeviceConfigScreen(DeviceConfigScreen.DROPLET_IMAGE_PORT_LAYOUT);
      }),
    );
  });
  readonly showDeviceSettingsForConfiguration = this.effect((device$: Observable<InventoryDevicesListToDisplay>) => {
    return device$.pipe(
      tap((device: InventoryDevicesListToDisplay) => {
        this.setDiscoveredDeviceForConfigurationSettingsAndUpdateView(device);
      }),
    );
  });

  /** ** ** ** ** ** PRIVATE METHODS ** ** ** ** ** */
  private mqttMessageHandler(msg: MqttMessage): void {
    BrowserLogger.log('AddDeviceStore.inventoryMqttMsgHandler', msg);

    switch (msg.message_type) {
      case DropletMqttMessages.START_DISCOVERY:
        // means it's the start of the discovery, now have to request inventory
        // otherwise droplet won't send inventory
        this.requestInitialInventory();
        break;
      case DropletMqttMessages.INVENTORY_REQUEST:
      case DropletMqttMessages.INVENTORY_CHANGE:
        // update the inventory in the state
        this.setDropletInventory(msg);

        // initial inventory has been received
        // need to update the view
        this.setViewOnInventoryReceived();

        // set discovered, configured, and static configured devices
        this.setConfiguredDevices();
        this.setDiscoveredDevices();
        this.setStaticConfiguredDevices();
        this.setInitialisedDevices();
        this.setdropletAvailablePortsToDiscoverDevice();
        this.setDropletAllInterfacesPortToDiscoverDevice();

        // need to check for discovered devices
        // and if there are any, have to call the device attributes for them if they don't exist
        // in state
        // otherwise get them and save them in state
        // this is for the case when we have discovered devices but we don't have their attributes
        // this implementation can be improved as we should not be using get() - leaving it for now
        // will come back to it later
        BrowserLogger.log('state: ', this.get());

        const devicesToGetAttributes = this.getListOfDiscoveredDevicesForWhichNoDeviceAttributesExist(msg);

        devicesToGetAttributes.forEach((device) => {
          this.getDeviceTypeConfigAttributes(device);
        });

        break;
      default:
        BrowserLogger.warn('AddDeviceStore.mqttMessageHandler', 'Unknown message', msg);
    }
  }
  private getFilteredListOfDevices(
    dropletInventory: InventoryMqttMessage,
    deviceState: DeviceStates,
    deviceConfigurationAttributes: {
      [deviceUniqueId: string]: { [deviceAttributeName: string]: DeviceTypeConfigurationAttributes };
    },
    devTypes: {
      [deviceUniqueId: string]: DeviceTypes;
    },
    discoveredDevices: InventoryDevicesListToDisplay[],
    dropletDevicesIds?: {
      [droplet_device_unique_id: string]: DropletDetailDevices;
    },
  ): InventoryDevicesListToDisplay[] {
    BrowserLogger.log('getFilteredListOfDevices', {
      dropletInventory,
      deviceState,
      deviceConfigurationAttributes,
      devTypes,
      discoveredDevices,
      dropletDevicesIds,
    });

    let devicesList = [] as InventoryDevicesListToDisplay[];
    dropletInventory?.ports?.forEach((dropletPort) => {
      const { port_number, type } = dropletPort;
      const devicesOnPort =
        dropletPort.devices?.length > 0
          ? [{ devices: dropletPort.devices }]
          : dropletPort.adapters?.length > 0
            ? dropletPort.adapters
            : [];
      devicesOnPort.forEach((adapterPort) => {
        const devices = adapterPort.devices
          .map((device) => {
            const deviceToBeReturned: InventoryDevicesListToDisplay = { ...device, type, port_number };
            if (deviceState === DeviceStates.DISCOVERED) {
              const deviceConfigs = this.isDeviceAlreadyExistInStateForDiscoveredDevices(device, discoveredDevices);
              deviceToBeReturned.deviceConfigAttributes =
                deviceConfigs && !isEmpty(deviceConfigs?.deviceConfigAttributes)
                  ? deviceConfigs.deviceConfigAttributes
                  : (deviceConfigurationAttributes[device.device_unique_id] ??
                    ({} as { [deviceAttributeName: string]: DeviceTypeConfigurationAttributes }));
              deviceToBeReturned.deviceTypes =
                deviceConfigs && !isEmpty(deviceConfigs?.deviceTypes)
                  ? deviceConfigs.deviceTypes
                  : (devTypes[device.device_unique_id] ?? ({} as DeviceTypes));
            }
            if (deviceState === DeviceStates.CONFIGURED) {
              deviceToBeReturned.deviceId = dropletDevicesIds[device.device_unique_id]?.id;
              deviceToBeReturned.deviceUuid = dropletDevicesIds[device.device_unique_id]?.uuid;
              deviceToBeReturned.automatedTestSupported =
                dropletDevicesIds[device.device_unique_id]?.automatedTestSupported;
            }
            return {
              ...deviceToBeReturned,
            };
          })
          .filter((device) => device.device_state === deviceState);
        devicesList = devicesList.concat(devices);
      });
    });
    return devicesList;
  }
  private isDeviceTypeConfigAttributesExistInState(device: InventoryDevices) {
    BrowserLogger.log(
      'this.get().deviceConfigurationAttributes[device.device_unique_id]: ',
      device.device_unique_id,
      this.get().deviceConfigurationAttributes[device.device_unique_id],
    );
    BrowserLogger.log(
      'this.get().deviceConfigurationAttributes[device.device_unique_id]: ',
      device.device_unique_id,
      !!this.get().deviceConfigurationAttributes[device.device_unique_id],
    );
    return !!this.get().deviceConfigurationAttributes[device.device_unique_id];
  }
  private getListOfDiscoveredDevicesForWhichNoDeviceAttributesExist(msg: InventoryMqttMessage): InventoryDevices[] {
    let devicesList = [] as InventoryDevices[];
    msg?.ports.forEach((dropletPort) => {
      if (dropletPort.devices) {
        const devices = dropletPort.devices.filter(
          (device) =>
            device.device_state === DeviceStates.DISCOVERED && !this.isDeviceTypeConfigAttributesExistInState(device),
        );
        devicesList = devicesList.concat(devices);
      } else if (dropletPort.adapters) {
        dropletPort.adapters.forEach((adapterPort) => {
          const devices = adapterPort.devices.filter(
            (device) =>
              device.device_state === DeviceStates.DISCOVERED && !this.isDeviceTypeConfigAttributesExistInState(device),
          );
          devicesList = devicesList.concat(devices);
        });
      }
    });
    return devicesList;
  }

  private getDeviceTypeConfigAttributes(device: InventoryDevices): void {
    this._addDeviceService
      .getDeviceTypeConfigAttributes(device)
      .pipe(
        tapResponse(
          (res) => {
            this.setDeviceConfigurationAttributes({ [device.device_unique_id]: res.deviceConfigAttributes });
            this.setDeviceTypes({ [device.device_unique_id]: res.deviceTypes });
            // updated discovered devices as we now have some settings to be configured
            this.setDiscoveredDevices();
          },
          (error) => console.log('error', error),
        ),
      )
      .subscribe();
  }

  private isDeviceAlreadyExistInStateForDiscoveredDevices(
    device: InventoryDevices,
    discoveredDevices: InventoryDevicesListToDisplay[],
  ): {
    deviceTypes: DeviceTypes;
    deviceConfigAttributes: {
      [deviceAttributeName: string]: DeviceTypeConfigurationAttributes;
    };
  } | null {
    const foundDevice = discoveredDevices.find((d) => d.device_unique_id === device.device_unique_id);
    return foundDevice
      ? {
          deviceTypes: foundDevice.deviceTypes,
          deviceConfigAttributes: foundDevice.deviceConfigAttributes,
        }
      : null;
  }

  private resetState(): void {
    BrowserLogger.info('AddDeviceStore.resetState');

    this._addDeviceService.destroyInventoryMqttSubscription();
    this.setState((state: AddDeviceState) => {
      return {
        ...defaultState,
        // need to leave this in the state as we don't want to get this data again and again
        deviceConnectionTypes: [...state.deviceConnectionTypes],
        deviceConfigurationAttributes: { ...state.deviceConfigurationAttributes },
        deviceTypes: { ...state.deviceTypes },
      };
    });
  }

  /** ** ** ** ** ** PRIVATE EFFECTS ** ** ** ** ** */
  private readonly manageConnectTimeout = this.effect((deviceConfigScreen$: Observable<DeviceConfigScreen>) => {
    return deviceConfigScreen$.pipe(
      withLatestFrom(this.connectTimeoutId$, this.connectTimeoutMillis$),
      tap(([view, timeoutId, timeoutMillis]) => {
        if (timeoutId) {
          clearTimeout(timeoutId);

          this.setConnectTimeoutId(0);
        }

        if (view == DeviceConfigScreen.WAITING_FOR_INITIAL_INVENTORY) {
          const newTimeoutId = setTimeout(() => this.dropletConnectTimeout(), timeoutMillis ?? CONNECT_TIMEOUT_MILLIS);

          this.setConnectTimeoutId(newTimeoutId);
        }
      }),
    );
  });

  private readonly requestInitialInventory = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      withLatestFrom(this.select((state) => state.dropletDetails.uuid)),
      tap<[void, string]>(([, dropletUuid]) => {
        BrowserLogger.log('AddDeviceStore.requestInitialInventory', dropletUuid);

        return this._addDeviceService.requestInitialInventory(dropletUuid).pipe(
          tapResponse(
            () => {},
            (error) => console.log('error', error),
          ),
        );
      }),
    );
  });

  private readonly getDeviceConnectionTypes = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      switchMap(() =>
        this._addDeviceService.getConnectionTypes().pipe(
          tapResponse(
            (res) => {
              this.setDeviceConnectionTypes(res);
            },
            (error) => console.log('error', error),
          ),
        ),
      ),
    );
  });

  /** ** ** ** ** ** PRIVATE UPDATERS ** ** ** ** ** */
  private readonly setConnectTimeoutId = this.updater<NodeJS.Timeout | number>((state, value) => {
    state.connectTimeoutId = value;
  });

  private readonly dropletConnectTimeout = this.updater((state) => {
    const { deviceConfigView } = state;

    if (deviceConfigView === DeviceConfigScreen.WAITING_FOR_INITIAL_INVENTORY) {
      state.error = {
        initialInventory: String(),
      };
      state.deviceConfigView = DeviceConfigScreen.INITIAL_INVENTORY_NOT_RECEIVED;
      state.connectTimeoutId = 0;
    }
  });

  private readonly setDropletInventory = this.updater((state, dropletInventory: InventoryMqttMessage) => {
    state.dropletInventory = dropletInventory;
  });

  private getDevicesKey(deviceState: DeviceStates) {
    switch (deviceState) {
      case DeviceStates.CONFIGURED:
        return 'configuredDevices' as const;
      case DeviceStates.DISCOVERED:
        return 'discoveredDevices' as const;
      case DeviceStates.STATIC_CONFIGURED:
        return 'staticConfiguredDevices' as const;
      case DeviceStates.INITIALIZED:
        return 'initialisedDevices' as const;
      default:
        return undefined;
    }
  }

  private updateDevicesForState(
    state: AddDeviceState,
    deviceState: DeviceStates,
    newDevices: InventoryDevicesListToDisplay[],
  ) {
    const key = this.getDevicesKey(deviceState);

    if (key == null) {
      return state;
    }

    this.sortDevices(newDevices).forEach((device, i) => {
      if (!isEqual(device, state[key][i])) {
        state[key][i] = device;
      }
    });

    state[key].length = newDevices.length;

    return state;
  }

  private readonly setDevicesForState = this.updater<DeviceStates>((state, deviceState) => {
    const { dropletInventory, deviceConfigurationAttributes, deviceTypes, dropletDetails, discoveredDevices } = state;

    const newDevices = this.getFilteredListOfDevices(
      dropletInventory,
      deviceState,
      deviceConfigurationAttributes,
      deviceTypes,
      discoveredDevices,
      dropletDetails.devices,
    );

    return this.updateDevicesForState(state, deviceState, newDevices);
  });

  private readonly setConfiguredDevices = () => this.setDevicesForState(DeviceStates.CONFIGURED);

  private readonly setDiscoveredDevices = () => this.setDevicesForState(DeviceStates.DISCOVERED);

  private readonly setStaticConfiguredDevices = () => this.setDevicesForState(DeviceStates.STATIC_CONFIGURED);

  private readonly setInitialisedDevices = () => this.setDevicesForState(DeviceStates.INITIALIZED);

  private readonly setDeviceConnectionTypes = this.updater((state, deviceConnectionTypes: DeviceConnectionTypes[]) => {
    state.deviceConnectionTypes = deviceConnectionTypes;
  });

  private readonly setDeviceConfigurationAttributes = this.updater(
    (
      state,
      deviceConfigAttr: {
        [deviceUniqueId: string]: {
          [key: string]: DeviceTypeConfigurationAttributes;
        };
      },
    ) => {
      const { deviceConfigurationAttributes } = state;

      state.deviceConfigurationAttributes = { ...deviceConfigurationAttributes, ...deviceConfigAttr };
    },
  );

  private readonly setDeviceTypes = this.updater(
    (
      state,
      devTypes: {
        [deviceUniqueId: string]: {
          selectedValue: string;
          options: {
            name: string;
            value: string;
          }[];
        };
      },
    ) => {
      const { deviceTypes } = state;

      state.deviceTypes = { ...deviceTypes, ...devTypes };
    },
  );

  private readonly setDropletDetail = this.updater((state, dropletDetails: DropletDetail) => {
    state.dropletDetails = dropletDetails;
  });

  private readonly setConfigSettingsForDiscoveredDevice = this.updater(
    (state, device: InventoryDevicesListToDisplay) => {
      const { discoveredDevices } = state;
      const tempDiscoverdDevices = [...discoveredDevices];
      for (let index = 0; index < tempDiscoverdDevices.length; index++) {
        const tempDevice = tempDiscoverdDevices[index];
        if (tempDevice.device_unique_id === device.device_unique_id) {
          tempDevice.deviceConfigAttributes = { ...device.deviceConfigAttributes };
          tempDevice.deviceTypes = { ...device.deviceTypes };
          break;
        }
      }

      state.discoveredDevices = tempDiscoverdDevices;
    },
  );

  private readonly setSelectedPort = this.updater((state, selectedPort: DropletLayoutPort) => {
    BrowserLogger.log('setSelectedPort', { selectedPort });

    const { hardwareVersion } = state.dropletDetails;

    state.selectedPort = { ...selectedPort, hardwareVersion };
  });

  private readonly setdropletAvailablePortsToDiscoverDevice = this.updater((state) => {
    const { dropletInventory } = state;

    const { uuid, hardwareVersion } = state.dropletDetails;
    const ports = dropletInventory?.ports
      ?.filter(
        (port) =>
          (port.type === DropletPortTypes.USB &&
            port.adapters?.length > 0 &&
            port.adapters[0].type === InventoryDeviceConnectionType.SERIAL) ||
          port.type === DropletPortTypes.RS_485_IO,
      )
      .map((port) => {
        const { port_number, type } = port;
        const adapterType = port.adapters?.length > 0 ? port.adapters[0].type : null;

        return {
          port_number,
          type,
          adapterType,
          hardwareVersion,
        } satisfies DropletLayoutPort;
      });

    state.dropletAvailablePortsToDiscoverDevice = { dropletUuid: uuid, ports, hardwareVersion };
  });

  private readonly setDropletAllInterfacesPortToDiscoverDevice = this.updater((state) => {
    const { dropletInventory } = state;
    const allInterfacesPort = dropletInventory?.ports?.find((port) => port.type === DropletPortTypes.ALL_INTERFACES);

    BrowserLogger.info('setDropletAllInterfacesPortToDiscoverDevice');

    state.dropletAllInterfacesPortsToDiscoverDevice = allInterfacesPort;
  });

  private readonly setDiscoverDeviceView = this.updater((state) => {
    const { dropletAvailablePortsToDiscoverDevice } = state;
    const { ports } = dropletAvailablePortsToDiscoverDevice;
    const selectedPort = ports.length === 1 ? ports[0] : ({} as DropletLayoutPort);

    BrowserLogger.log('setDiscoverDeviceView', { selectedPort });

    state.deviceConfigView = isEmpty(selectedPort)
      ? DeviceConfigScreen.DROPLET_IMAGE_PORT_LAYOUT
      : DeviceConfigScreen.MANUFACTURERS_LIST;

    state.selectedPort = selectedPort;
  });

  private readonly setDiscoveredDeviceForConfigurationSettingsAndUpdateView = this.updater(
    (state, discoveryDeviceForConfiguration: InventoryDevicesListToDisplay) => {
      state.discoveryDeviceForConfiguration = discoveryDeviceForConfiguration;
      state.deviceConfigView = DeviceConfigScreen.DEVICE_SETTINGS_FOR_CONFIGURATION;
    },
  );

  private readonly setViewOnInventoryReceived = this.updater((state) => {
    const { deviceConfigView } = state;
    // but only update it if the view is in initial view
    // don't need to update the view if it's discovering device or adding legacy device
    if (
      deviceConfigView === DeviceConfigScreen.WAITING_FOR_INITIAL_INVENTORY ||
      deviceConfigView === DeviceConfigScreen.INITIAL_INVENTORY_NOT_RECEIVED
    ) {
      state.deviceConfigView = DeviceConfigScreen.INITIAL_INVENTORY_RECEIVED;
    }
  });

  private readonly setDeviceToDiscover = this.updater<Partial<AddDeviceState['deviceToDiscover']>>((state, device) => {
    if (!state.deviceToDiscover) {
      state.deviceToDiscover = {};
    }

    if ('status' in device) {
      state.deviceToDiscover.status = device.status;
    }

    if ('target' in device) {
      state.deviceToDiscover.target = device.target;
    }

    if ('message' in device) {
      state.deviceToDiscover.message = device.message;
    }
  });

  readonly resetDeviceToDiscover = () =>
    this.setDeviceToDiscover({
      status: 'idle',
      target: undefined,
      message: undefined,
    });
}
