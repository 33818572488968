import './init/sentry';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => {
      console.log(err);
    });
};

// Cordova app requires the 'deviceready' event before plugins will be usable.
// The current manual bootstrap process uses LoadConfigurationService which uses the
// Native HTTP plugin, so we need to ensure that the plugin is ready to
// be used prior to bootstrapping the app.
// Normally you would use the ionic paltform.ready() promise before commencing your app,
// so a possible alternate solution may be to move the LoadConfigurationService into that flow.
// But that will require more effort, so for now ensure the cordova app is ready
// prior to bootstrapping

if (typeof window['cordova'] !== 'undefined') {
  // cordova app, wait for deviceready event before bootstrapping
  document.addEventListener(
    'deviceready',
    () => {
      bootstrap();
    },
    false,
  );
} else {
  // not cordova, bootstrap immediately
  bootstrap();
}
