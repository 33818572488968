import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AddDeviceStore } from '../store/add-device.store';
import { DropletLayoutPort } from '../utils/add-device-types';
import upperFirst from 'lodash/upperFirst';
import { map } from 'rxjs';

@Component({
  selector: 'app-droplet-port-layout',
  templateUrl: './droplet-port-layout.component.html',
  styleUrls: ['./droplet-port-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropletPortLayoutComponent {
  private readonly _addDeviceStore = inject(AddDeviceStore);

  readonly upperFirst = upperFirst;

  readonly dropletAvailablePortsToDiscoverDevice$ = this._addDeviceStore.dropletAvailablePortsToDiscoverDevice$;
  readonly ports$ = this.dropletAvailablePortsToDiscoverDevice$.pipe(map((droplet) => droplet.ports));

  onPortHandler(port: DropletLayoutPort): void {
    this._addDeviceStore.selectPortAndUpdateTheViewToShowManufacturers(port);
  }
}
