import { environment } from '../../../environments/environment';

export class Common {
  /**
   * Getter $baseURL
   * @return {String}
   */
  public get $baseURL(): string {
    return this.baseURL;
  }

  /**
   * Setter $baseURL
   * @param {String} value
   */
  public set $baseURL(value: string) {
    this.baseURL = value;
  }
  private baseURL: string;

  /**
   * Getter $dropletAPIURL
   * @returns {string}
   */
  public get $dropletAPIURL(): string {
    return this.dropletApiUrl;
  }
  /**
   * Getter $token
   * @return {String}
   */
  public get $token(): string {
    return this.token;
  }

  /**
   * Setter $token
   * @param {String} value
   */
  public set $token(value: string) {
    this.token = value;
  }
  private token: string;

  /**
   * Setter $dropletAPIURL
   * @param {string} value
   */
  public set $dropletAPIURL(value: string) {
    this.dropletApiUrl = value;
  }
  private dropletApiUrl: string;

  private weatherAPIURL: string;
  public set $weatherAPIURL(url) {
    this.weatherAPIURL = url;
  }
  public get $weatherAPIURL() {
    return this.weatherAPIURL;
  }

  private appWindowWidth: number;
  /**
   * Getter $appWindowWidth
   * @return {number}
   */
  public get $appWindowWidth(): number {
    return this.appWindowWidth;
  }

  /**
   * Setter $appWindowWidth
   * @param {number} value
   */
  public set $appWindowWidth(value: number) {
    this.appWindowWidth = value;
  }
  private appWindowWidthSize: string;

  /**
   * Getter $appWindowWidthSize
   * @return {string}
   */
  public get $appWindowWidthSize(): string {
    return this.appWindowWidthSize;
  }

  /**
   * Setter $appWindowWidthSize
   * @param {string} value
   */
  public set $appWindowWidthSize(value: string) {
    this.appWindowWidthSize = value;
  }

  private appWindowHeight: number;

  /**
   * Getter $appWindowHeight
   * @return {number}
   */
  public get $appWindowHeight(): number {
    return this.appWindowHeight;
  }

  /**
   * Setter $appWindowHeight
   * @param {number} value
   */
  public set $appWindowHeight(value: number) {
    this.appWindowHeight = value;
  }

  private appWindowHeightName: number;

  /**
   * Getter $appWindowHeightName
   * @return {number}
   */
  public get $appWindowHeightName(): number {
    return this.appWindowHeightName;
  }

  /**
   * Setter $appWindowHeightName
   * @param {number} value
   */
  public set $appWindowHeightName(value: number) {
    this.appWindowHeightName = value;
  }

  constructor() {
    this.baseURL = environment.apiUrl;
    this.weatherAPIURL = environment.weatherAPIURL;

    this.dropletApiUrl = environment.dropletApiUrl;
  }

  // no need of this - replace this one with loadash isEmpty()
  public isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }
}

export class apiStatus {
  private callMade: boolean;
  private dataExist: boolean;
  private callFailed: boolean;
  private error: {};
  private errorText: string;

  /**
   * Getter $callMade
   * @return {boolean}
   */
  public get $callMade(): boolean {
    return this.callMade;
  }

  /**
   * Setter $callMade
   * @param {boolean} value
   */
  public set $callMade(value: boolean) {
    this.callMade = value;
  }

  /**
   * Getter $dataExist
   * @return {boolean}
   */
  public get $dataExist(): boolean {
    return this.dataExist;
  }

  /**
   * Setter $dataExist
   * @param {boolean} value
   */
  public set $dataExist(value: boolean) {
    this.dataExist = value;
  }

  /**
   * Getter $callFailed
   * @return {boolean}
   */
  public get $callFailed(): boolean {
    return this.callFailed;
  }

  /**
   * Setter $callFailed
   * @param {boolean} value
   */
  public set $callFailed(value: boolean) {
    this.callFailed = value;
  }

  /**
   * Getter $error
   * @return {{}}
   */
  public get $error(): {} {
    return this.error;
  }

  /**
   * Setter $error
   * @param {{}} value
   */
  public set $error(value: {}) {
    this.error = value;
  }

  /**
   * Getter $errorText
   * @return {string}
   */
  public get $errorText(): string {
    return this.errorText;
  }

  /**
   * Setter $errorText
   * @param {string} value
   */
  public set $errorText(value: string) {
    this.errorText = value;
  }
  constructor() {
    this.reset();
  }
  public reset() {
    this.$dataExist = false;
    this.$callMade = false;
    this.$callFailed = false;
    this.$error = {};
    this.$errorText = '';
  }
}
