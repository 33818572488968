import { Injectable } from '@angular/core';
import { BaseApiService } from './base.api-service';
import { TimePeriodResolution } from '@class/commons/constants-datetime';
import { ChartDataDTO } from '@class/units/unitanalytics/unit-analytics.models';

export interface GetChartdataParams {
  field: string;
  uuid: string;
  metrics: string | string[];
  period: TimePeriodResolution;
  from: number | Date;
  to?: number | Date;
  extraColumns?: string[];
}

const queryKeys = {
  all: ['chartdata'] as const,
  details: () => [...queryKeys.all, 'details'] as const,
  detail: (params: GetChartdataParams) => [...queryKeys.details(), params] as const,
};

@Injectable({
  providedIn: 'root',
})
export class ChartdataApiService extends BaseApiService {
  constructor() {
    super('/api/v2/chartdata');
  }

  getChartdata(params: GetChartdataParams) {
    return this._query({
      queryKey: queryKeys.detail(params),
      queryFn: () => {
        const { to = new Date() } = params;

        return this.request<ChartDataDTO>({
          path: '/',
          query: {
            field: params.field,
            uuid: params.uuid,
            metrics: typeof params.metrics === 'string' ? params.metrics : `(${params.metrics.join(',')})`,
            period: params.period,
            fromts: params.from instanceof Date ? params.from.getTime() : params.from,
            tots: to instanceof Date ? to.getTime() : to,
            extra_columns: params.extraColumns,
          },
          encodeQuery: false,
          keepWrapped: true,
        });
      },
    });
  }
}
