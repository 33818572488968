export enum ListDisplayMode {
  LIST = 1,
  GRID = 2,
}

export enum CrudType {
  CREATE,
  UPDATE,
  DELETE,
}

//  sm	540px	Set grid width to 540px when (min-width: 576px)
//  md	720px	Set grid width to 720px when (min-width: 768px)
//  lg	960px	Set grid width to 960px when (min-width: 992px)
//  xl	1140px	Set grid width to 1140px when (min-width: 1200px)
// column breakpoints
export enum ColBreakpoint {
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
}

export enum HomeCardDataType { // settings the data type, as home card component will be using for portfolio, unit so have to tell the component what type of data is
  UNIT,
  PORTFOLIO,
}

export enum SessionStorage {
  RECENT_PORTFOLIOS = 'recentPortfolios',
  RECENT_UNITS = 'recentUnits',
}

export enum HomepageCards {
  PORTFOLIOS_NUMBER = 6,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  UNITS_NUMBER = 6,
}

// Add_Device connection to droplet via mqtt settings
export enum EndpointConnection {
  TAKING_WHILE_TIMEOUT_MS = 4 * 1000, // 4sec
  TIMEOUT_MS = 10 * 1000, // 10sec
}

export enum DataTypeStrings {
  CHAR = 'char',
  VARCHAR = 'varchar',
  STRING = 'string',
  TEXT = 'text',
  INT = 'int',
  NUMBER = 'number',
  FLOAT = 'float',
  BOOL = 'bool',
  BOOLEAN = 'boolean',
}

export enum BooleanStrings {
  TRUE = 'true',
  FALSE = 'false',
}

export enum PlotlyTraceModes {
  LINES = 'lines',
  MARKERS = 'markers',
}

export enum PlotlyTraceType {
  SCATTER = 'scatter',
}

export enum PlotlyFillMode {
  TO_ZERO_Y = 'tozeroy',
}
// for Portfolio create or edit form
export enum Mode {
  VIEW,
  CREATE,
  EDIT,
  REMOVE,
}

export enum DropletPortTypes {
  USB = 'usb',
  WIFI = 'wifi',
  ETHERNET = 'ethernet',
  BLUETOOTH = 'bluetooth',
  ALL_INTERFACES = 'all-interfaces',
  UNKNOWN = 'unknown',
  RS_485_IO = 'rs-485',
}
export enum DropletHardwareVersion {
  DROPLET_ONE = 'droplet_one 1.0',
  DROPLET_PLUS_ONE = 'droplet_plus 1.0',
  DROPLET_PLUS_TWO = 'droplet_plus 2.0',
  DROPLET_PLUS_TWO_DOT_1 = 'droplet_plus 2.0.1',
}

export enum InstallerButtonActions {
  SCAN_QR_CODE = 'scan-qr-code',
  ENTER_CODE_MANUALLY = 'enter-code-manually',
  REGISTER_API_DEVICE = 'register-api-device',
  BACK_TO_WELCOME = 'back-to-welcome',
  CONNECT_TO_WIFI = 'connect-to-wifi',
  CONNECT_TO_ETHERNET = 'connect-to-ethernet',
}

// backend states are 'Not Configured' | 'Active' | 'Inactive' | 'Deleted' | 'Purged'
// frontend states are 'active' | 'inactive' | 'not configured' | 'deleted' | 'purged'
// setting them to lower case just to avoid any confusion if someone mistakenly changed something in the backend
export enum UnitOperationalStates {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  NOT_CONFIGURED = 'not configured',
  DELETED = 'deleted',
  PURGED = 'purged',
}

export enum InstallerContentView {
  // Identify the Droplet Views
  WELCOME_TO_SWITCHDIN = 1,
  SCAN_CODE = 2,
  ENTER_CODE_MANUALLY = 3,

  // Register API Device
  REGISTER_API_DEVICE = 4,

  // Connect to internet
  CONNECT_TO_INTERNET = 5,
  // Connect via wifi
  CONNECT_VIA_WIFI = 6,
  // Connect via ethernet
  CONNECT_VIA_ETHERNET = 7,
}
