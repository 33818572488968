import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AutomatedTestStatus } from '@service/devices/automated-testing.service';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent, ButtonType } from '../buttons/button/button.component';

interface AutomatedTestUISettings {
  buttonType: ButtonType;
  buttonLabel: string;
  testStatus: string;

  // Optional settings
  isButtonDisabled?: boolean;
  isButtonLoading?: boolean;
  showTestingSpinner?: boolean;
  ionIcon?: 'checkmark-circle' | 'alert-circle' | 'stop-circle';
  ionIconColor?: 'danger' | 'brand-primary';
  showAdditionalInfo?: boolean;
  hideTestButton?: boolean;
  initialLoading?: boolean;
  buttonAction?: 'start-test' | 'stop-test';
}
const getAutomatedTestUiSettings: Record<AutomatedTestStatus | 'default', AutomatedTestUISettings> = {
  [AutomatedTestStatus.Starting]: {
    buttonType: ButtonType.PRIMARY_ROUND_SOLID_DEFAULT,
    buttonLabel: 'AutomatedTesting.StartingTest',
    isButtonDisabled: true,
    isButtonLoading: true,
    testStatus: 'AutomatedTesting.StartingTest',
  },
  [AutomatedTestStatus.Pending]: {
    buttonType: ButtonType.PRIMARY_ROUND_OUTLINE_DEFAULT,
    buttonLabel: 'AutomatedTesting.StopTest',
    testStatus: 'AutomatedTesting.TestingCommunication',
    showTestingSpinner: true,
    buttonAction: 'stop-test',
  },
  [AutomatedTestStatus.Stopping]: {
    buttonType: ButtonType.PRIMARY_ROUND_OUTLINE_DEFAULT,
    buttonLabel: 'AutomatedTesting.StoppingTest',
    isButtonDisabled: true,
    isButtonLoading: true,
    testStatus: 'AutomatedTesting.StoppingTest',
  },
  [AutomatedTestStatus.Passed]: {
    buttonType: ButtonType.PRIMARY_ROUND_SOLID_DEFAULT,
    buttonLabel: 'AutomatedTesting.StartTest',
    testStatus: 'AutomatedTesting.TestSuccessful',
    ionIcon: 'checkmark-circle',
    ionIconColor: 'brand-primary',
    buttonAction: 'start-test',
  },
  [AutomatedTestStatus.Cancelled]: {
    buttonType: ButtonType.PRIMARY_ROUND_SOLID_DEFAULT,
    buttonLabel: 'AutomatedTesting.StartTest',
    testStatus: 'AutomatedTesting.TestStopped',
    ionIcon: 'stop-circle',
    buttonAction: 'start-test',
  },
  [AutomatedTestStatus.Failed]: {
    buttonType: ButtonType.PRIMARY_ROUND_SOLID_DEFAULT,
    buttonLabel: 'AutomatedTesting.StartTest',
    testStatus: 'AutomatedTesting.TestFailed',
    ionIcon: 'alert-circle',
    ionIconColor: 'danger',
    showAdditionalInfo: true,
    buttonAction: 'start-test',
  },
  [AutomatedTestStatus.InitialChecking]: {
    buttonType: ButtonType.PRIMARY_ROUND_SOLID_DEFAULT,
    buttonLabel: '',
    testStatus: '',
    hideTestButton: true,
    initialLoading: true,
  },
  default: {
    buttonType: ButtonType.PRIMARY_ROUND_SOLID_DEFAULT,
    buttonLabel: 'AutomatedTesting.StartTest',
    testStatus: 'AutomatedTesting.NoPreviousTest',
    buttonAction: 'start-test',
  },
};
@Component({
  selector: 'app-device-automated-testing',
  templateUrl: './device-automated-testing.component.html',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, ButtonComponent],
})
export class DeviceAutomatedTestingComponent {
  automatedTestUiSettings: AutomatedTestUISettings = getAutomatedTestUiSettings.default;

  @Input()
  set status(value: AutomatedTestStatus) {
    this.automatedTestUiSettings = getAutomatedTestUiSettings[value] ?? getAutomatedTestUiSettings.default;
  }
  @Input() testMessage: string = '';

  @Output() onClick = new EventEmitter<'start-test' | 'stop-test'>();

  onAutomatedTestButtonClick(buttonAction: 'start-test' | 'stop-test') {
    this.onClick.emit(buttonAction);
  }
}
