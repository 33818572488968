<div *ngIf="invalid && (dirty || touched)" class="alert alert-danger">
  <div *ngIf="errors?.required">{{ label }} {{ 'InputValidationErrors.IsRequired' | translate }}</div>

  <!-- {min: {min: 3, actual: 2}} -->
  <div *ngIf="errors?.min">{{ 'InputValidationErrors.MinValueIs' | translate }} {{ errors?.min?.min }}</div>

  <!-- {max: {max: 15, actual: 16}} -->
  <div *ngIf="errors?.max">{{ 'InputValidationErrors.MaxValueIs' | translate }} {{ errors?.max?.max }}</div>

  <!-- Tests the value using a [regular
   * expression](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions)
   * pattern suitable for common usecases. The pattern is based on the definition of a valid email
   * address in the [WHATWG HTML
   * specification](https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address) with
   * some enhancements to incorporate more RFC rules (such as rules related to domain names and the
   * lengths of different parts of the address).
   {email: true} -->
  <div *ngIf="errors?.email">{{ 'InputValidationErrors.EmailAddress' | translate }}</div>

  <!-- {minlength: {requiredLength: 3, actualLength: 2}} -->
  <div *ngIf="errors?.minlength">
    {{ 'InputValidationErrors.MinLength' | translate }} {{ errors?.minlength?.requiredLength }}
  </div>

  <!-- {maxlength: {requiredLength: 3, actualLength: 2}} -->
  <div *ngIf="errors?.maxlength">
    {{ 'InputValidationErrors.MaxLength' | translate }} {{ errors?.maxlength?.requiredLength }}
  </div>

  <!-- {pattern: {requiredPattern: '^[a-zA-Z ]+$', actualValue: 'a1'}} -->
  <div *ngIf="errors?.pattern">{{ label }} {{ 'InputValidationErrors.PatternIsInvalid' | translate }}</div>

  <ng-content>
    <!-- any custom validation error goes here from parent component -->
  </ng-content>
</div>
