import { BrowserLogger } from '@class/core/browser-logger';
import { ControllerInventoryMqttMsgDTO, DropletController } from './droplet-controller.model';
import { DeviceDetails } from './device-details.model';
import { DropletAttribute } from './droplet-attribute.model';
import { DropletControllerInventoryRequest } from './controller-inventory-request/droplet-controller-inventory-request.model';
import { EndpointControllerDTO, DropletType } from './../endpoint/endpoint-payload.model';
import { IMqttMessage } from 'ngx-mqtt';
import { TranslationsService } from '../../../services/common/translations.service';

export class DropletOverview {
  deviceNumber: number;
  id: string;
  uuid: string;
  unit_uuid: string;
  statusKeys: string[];
  lastSeenFromServer: string;
  triggerFirmwareUpdate: boolean;
  type: string;
}

export class DropletDisplay {
  overview: DropletOverview;
  attributes: DropletAttribute;
  controllers: DropletController[];
  devices: DeviceDetails[];
  controllersOutOfSync?: boolean;

  public processControllerInventoryMqttMessage(mqttMessage: IMqttMessage): void {
    BrowserLogger.log('DropletDisplay.processControllerInventoryMqttMessage', { mqttMessage, dropletDisplay: this });
    this.controllersOutOfSync = false; // reset flag (checking each controller sync status below)
    this.removeControllersNotInDatabase();
    if (mqttMessage) {
      const controllerInventoryMqttMsg: ControllerInventoryMqttMsgDTO[] = JSON.parse(mqttMessage.payload.toString());
      if (this.controllers?.length || controllerInventoryMqttMsg?.length) {
        this.controllers.forEach((controller) => {
          controller.applyMqttInventoryMsg(controllerInventoryMqttMsg);
          // out-of-sync version check (if not already out-of-sync)
          if (!this.controllersOutOfSync && controller.controllerExistOnDroplet && !controller.controllerVersionSync) {
            this.controllersOutOfSync = true;
          }
        });
        this.addControllersFromMqttMsgNotInDatabase(controllerInventoryMqttMsg);
      }
    }
  }

  private removeControllersNotInDatabase(): void {
    BrowserLogger.log('DropletDisplay.removeControllersNotInDatabase', { dropletDisplay: this });
    // popping out the controllers which are not in database
    // they'll be added back in again once the it completes the check
    // controllerExistInDatabase this variable means that the controller is not in database
    // they are added to the list so that user can view them and remove them
    // they have been added because they exist in mqtt msg
    // so if the mqtt msg comes in, popping them out
    // if they don't exist anymore, will not be displayed
    // otherwise will be added back again
    this.controllers = this.controllers.filter((controller) => {
      // return only controllers that exist in the database
      return controller.controllerExistInDatabase;
    });
  }

  public applyEndpointControllerDTO(
    endpointControllerDTO: EndpointControllerDTO,
    translationsService: TranslationsService,
  ): DropletController {
    // Apply the controller from dbase to the droplet matching controller (replace only, not add)
    const controller = this.controllers.find((c) => c.id == endpointControllerDTO.id);
    BrowserLogger.log('DropletDisplay.applyEndpointControllerDTO', {
      droplet: this,
      endpointControllerDTO,
      controller,
    });
    if (controller) {
      const newController = EndpointControllerDTO.adaptToDropletController(endpointControllerDTO, translationsService);
      Object.assign(controller, newController);
    }
    return controller;
  }

  public applyControllersPayload(
    controllersPayload: EndpointControllerDTO[],
    translationsService: TranslationsService,
  ): void {
    BrowserLogger.log('DropletDisplay.applyControllersPayload', { droplet: this, controllersPayload });
    // Apply the updated list of controllers from dbase to the droplet
    this.controllers = EndpointControllerDTO.adaptToDropletControllers(controllersPayload, translationsService);
  }

  private addControllersFromMqttMsgNotInDatabase(mqttMessage: ControllerInventoryMqttMsgDTO[]): void {
    const missingControllers = DropletController.getMissingMqttControllers(mqttMessage, this.controllers);
    this.controllers = this.controllers.concat(missingControllers);
    BrowserLogger.log('DropletDisplay.addControllersFromMqttMsgNotInDatabase', { mqttMessage, self: this });
  }

  public static findByUuid(droplets: DropletDisplay[], uuid: string): DropletDisplay {
    const result = droplets.find((droplet) => droplet.overview.uuid === uuid);
    BrowserLogger.log('DropletDisplay.findByUuid', { result, uuid, droplets });
    return result;
  }

  public static isType(self: DropletDisplay, type: DropletType): boolean {
    const result = self?.overview?.type === type;
    BrowserLogger.log('DropletDisplay.isType', { result, type, self });
    return result;
  }

  public static canAddDevice(self: DropletDisplay): boolean {
    // Can only add Devices on physical droplets (not cloud droplets)
    const result = DropletDisplay.isType(self, DropletType.DROPLET);
    BrowserLogger.log('DropletDisplay.canAddDevice', { result, self });
    return result;
  }

  public static canUseControllerInventory(self: DropletDisplay): boolean {
    // Can only use controller inventory on physical droplets (not cloud droplets)
    const result = DropletDisplay.isType(self, DropletType.DROPLET);
    BrowserLogger.log('DropletDisplay.canUseControllerInventory', { result, self });
    return result;
  }

  public static filterControllerInventoryDroplets(droplets: Array<DropletDisplay>): Array<DropletDisplay> {
    const result = droplets.filter((droplet) => {
      return DropletDisplay.canUseControllerInventory(droplet);
    });
    BrowserLogger.log('DropletDisplay.filterControllerInventoryDroplets', { result, droplets });
    return result;
  }

  public static isMyControllerInventoryRequest(
    controllerInventoryRequest: DropletControllerInventoryRequest,
    droplet: DropletDisplay,
  ): boolean {
    // simple check that a DropletControllerInventoryRequest is for the given droplet
    // this logic is used in a few places so encapsulated here
    const result = controllerInventoryRequest?.dropletUuid === droplet.overview.uuid;
    BrowserLogger.log('DropletDisplay.isMyControllerInventoryRequest', {
      result,
      controllerInventoryRequest,
      droplet,
    });
    return result;
  }
}
