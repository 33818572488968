import { Environment } from './environment.type';
import base from './environment.base';

export const environment: Environment = {
  ...base,
  name: 'Production',
  production: true,
  serviceWorker: true,
  apiUrl: 'https://app.switchdin.com',
  qcellsReferrerUrl: '_', // underscore to prevent an empty URL forcing a theme...
  eguanaReferrerUrl: '_',
  mqttConfig: {
    url: 'wss://app.switchdin.com:443/ws/',
    connectTimeout: 300 * 1000,
    keepalive: 30,
    clientId: 'CX-PWA-Prod-' + Date.now(),
  },
  features: {
    analytics: {
      pageViews: true,
      errors: true,
    },
    environmentInfo: {
      enabled: false,
    },
    browserLogger: {
      enabled: false,
    },
  },
};
