import moment from 'moment';

export class Alert {
  public active: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public alert_definition: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public alert_details: any;
  public alert_level: string;
  public alert_text: string;
  public device: string;
  public device_serialNo: string;
  public pk: number;
  public time_deactivated: string;
  public time_registered: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public unit: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public user_has_seen: any;
  public uuid: string;

  // additional attributes
  public time_relative: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public duration: any;
  public alert_title: string;

  constructor(
    active: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    alert_definition: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    alert_details: any,
    alert_level: string,
    alert_text: string,
    device: string,
    device_serialNo: string,
    pk: number,
    time_deactivated: string,
    time_registered: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    unit: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    user_has_seen: any,
    uuid: string,
  ) {
    this.active = active;
    this.alert_definition = alert_definition;
    this.alert_details = alert_details;
    this.alert_level = alert_level;
    this.alert_text = alert_text;
    this.device = device;
    this.device_serialNo = device_serialNo;
    this.pk = pk;
    this.time_deactivated = time_deactivated;
    this.time_registered = time_registered;
    this.unit = unit;
    this.user_has_seen = user_has_seen;
    this.uuid = uuid;

    this.time_relative = moment(this.time_registered).fromNow();

    if (this.time_deactivated) {
      this.duration = moment
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .duration(<any>moment(this.time_deactivated) - <any>moment(this.time_registered))
        .humanize();
    } else {
      this.duration = 'ongoing';
    }

    if (this.alert_definition) {
      this.alert_title = this.alert_definition.name;
    } else {
      this.alert_title = this.alert_text;
    }
  }
}
