/* eslint-disable @typescript-eslint/no-explicit-any */

import { DateTime } from 'luxon';

// Basic logger service
// Used in place of console logging, no logging in production
// https://github.com/DanWahlin/angular-architecture/blob/11f5cf671f543071c93aff777b7d3447bbb5618d/demos/src/app/core/services/browser-logger.service.ts
import { environment } from '../../../environments/environment';

export class BrowserLogger {
  public static info(message?: any, ...optionalParams: any): void {
    this.writeMessage(console.info, message, ...optionalParams);
  }

  public static log(message?: any, ...optionalParams: any): void {
    this.writeMessage(console.log, message, ...optionalParams);
  }

  public static warn(message?: any, ...optionalParams: any): void {
    this.writeMessage(console.warn, message, ...optionalParams);
  }

  public static error(message?: any, ...optionalParams: any): void {
    this.writeMessage(console.error, message, ...optionalParams);
  }

  public static trace(message?: any, ...optionalParams: any): void {
    this.writeMessage(console.trace, message, ...optionalParams);
  }

  private static writeMessage(logFunction: typeof console.log, message?: any, ...optionalParams: any) {
    // modified to logging only if manually enabled
    if (environment.features.browserLogger.enabled) {
      // ensure we have a log function and a message
      if (logFunction && message) {
        const timestamp = DateTime.local().toFormat('[yyyy-MM-dd hh:mm:ss]');

        logFunction(timestamp, message, ...(optionalParams ?? []));
      }
    }
  }
}
