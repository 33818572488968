<!-- Warning msg card -->
<ion-row>
  <ion-col>
    <ion-card class="card-design card-warning-background">
      <ion-card-header class="warning-container">
        <div class="warning-icon-container warning-container">
          <ion-icon class="warning-icon" src="assets/icons/svg/alert-warning-icon.svg"></ion-icon>
        </div>
      </ion-card-header>
      <ion-card-content class="ion-text-center">
        <ion-text>
          <p class="text-primary-lg">
            {{ 'Droplet.Setup.UnableToConnectToDroplet' | translate }}
          </p>
        </ion-text>
        <ion-text>
          <p class="text-secondary-md">
            {{ error }} ERR_0002: {{ 'AddDevice.Error.FailedOnGettingInventory' | translate }}
          </p>
        </ion-text>
        <app-retry-button (click)="retryHandler()"></app-retry-button>
      </ion-card-content>
    </ion-card>
  </ion-col>
</ion-row>
<!-- Troubleshooting header  -->
<ion-row class="mt-20">
  <ion-col class="ion-no-padding">
    <ion-item lines="none" color="bkg-theme">
      <ion-label class="ion-text-center">
        <ion-text class="text-primary-xl">
          {{ 'Droplet.Setup.TroubleShootingTitle' | translate }}
        </ion-text>
      </ion-label>
    </ion-item>
  </ion-col>
</ion-row>
<!-- Troubleshooting tips card -->
<ion-row>
  <ion-col>
    <ion-card class="card-design">
      <ion-card-content class="ion-no-padding">
        <ion-list>
          <ion-item lines="full" class="pb-8">
            <ion-icon slot="start" src="assets/icons/svg/green-dots.svg"></ion-icon>
            <ion-text class="ion-text-wrap fw-400 fs-14" color="text-primary">
              {{ 'Droplet.Setup.TroubleShootingLightsGreen' | translate }}
            </ion-text>
          </ion-item>
          <ion-item lines="none">
            <ion-text class="ion-text-wrap fw-400 fs-14" color="text-primary">
              {{ 'Droplet.Setup.TroubleShootingLightsBlue' | translate }}
            </ion-text>
            <ion-icon slot="start" src="assets/icons/svg/blue-light.svg"></ion-icon>
          </ion-item>
        </ion-list>
      </ion-card-content>
    </ion-card>
  </ion-col>
</ion-row>
