// import * as Sentry from '@sentry/capacitor';
// import * as SentryAngular from '@sentry/angular-ivy';
// import { environment } from '../environments/environment';

// const sentryConfig = environment.services.sentry;

// if (environment.production) {
//   Sentry.init(
//     {
//       ...sentryConfig,
//       // To set your release and dist versions
//       release: `stormcloudapp@${environment.version}`,
//       // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
//       // We recommend adjusting this value in production.
//       tracesSampleRate: 1.0,
//       integrations: [
//         SentryAngular.browserTracingIntegration({
//           // Set "tracePropagationTargets" to control for which URLs distributed tracing should be enabled
//           tracePropagationTargets: ['localhost', 'stormcloudapp'],
//           // routingInstrumentation: SentryAngular.routingInstrumentation,
//         }),
//       ],
//       environment: environment.name,
//     },
//     // Forward the init method to the sibling Framework.
//     SentryAngular.init,
//   );
// }

import * as Sentry from '@sentry/angular';
import { environment } from '../environments/environment';
const sentryConfig = environment.services.sentry;
if (environment.production) {
  Sentry.init({
    ...sentryConfig,
    // To set your release and dist versions
    release: `stormcloudapp@${environment.version}`,
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production.
    tracesSampleRate: 1.0,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      Sentry.replayIntegration(),
    ],
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
      'localhost',
      'stormcloudapp',
      '/^https://*.switchdin.com/*',
      '/^https://switchdin-test2.firebaseapp.com/*',
    ],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: environment.name,
  });
}
