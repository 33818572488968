import { Component } from '@angular/core';
import { SkeletonPlaceholder } from '@class/commons/constants';
import { FacadeStates } from '../../classes/commons/facade-states';
import { TransactionsService, TransactionStatus } from '../../services/profile/transactions/transactions.service';

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss'],
})
export class TransactionListComponent {
  public transactions$ = this.transactionService.transactions$;
  public readonly TransactionStatus = TransactionStatus;
  public readonly FacadeStates = FacadeStates;
  readonly SkeletonPlaceholder = SkeletonPlaceholder;
  constructor(private transactionService: TransactionsService) {}
}
