import { AppState } from '@app-state/index';
import {
  PermissionKey,
  PermissionStore,
  getStorePermissionAreaFromKey,
} from '@class/commons/permissions/permission-constants';
import { createSelector } from '@ngrx/store';

export const selectAllPermissions = (state: AppState): PermissionStore => state.userPermissions;
export const userHasPermission = (permissionKey: PermissionKey | PermissionKey[], checkAllPerms: boolean = true) =>
  createSelector(selectAllPermissions, (permissions) => {
    const permissionKeys = Array.isArray(permissionKey) ? permissionKey : [permissionKey];

    return checkAllBreakOn(permissionKeys, !checkAllPerms, permissions);
  });

function checkAllBreakOn(
  permissionKeys: Array<PermissionKey>,
  breakOnTrueOrFalse: boolean,
  permissionStore: PermissionStore,
): boolean {
  let result: boolean = false;
  if (permissionKeys?.length > 0) {
    // check we have ALL of the permissions as true, ie stop checking on first failure
    for (let idx = 0, len = permissionKeys.length; idx < len; idx++) {
      result = check(permissionKeys[idx], permissionStore);
      if (result === breakOnTrueOrFalse) {
        break; // we got a breaking match, so break out of loop
      }
    }
  }

  return result;
}
function check(permissionKey: PermissionKey, permissionStore: PermissionStore): boolean {
  let result = false;
  const permissionAreaForStore = getStorePermissionAreaFromKey(permissionKey);
  const permissionSetToLookFrom = permissionStore[permissionAreaForStore];
  if (permissionAreaForStore && permissionSetToLookFrom) {
    result = permissionSetToLookFrom[permissionKey] || false;
  } else {
    // get the permission store areas
    // iterate each permission area and check for the permission
    const permissionStoreKeys = Object.keys(permissionStore);
    for (let i = 0, len = permissionStoreKeys.length; i < len; i++) {
      // get the permission area
      const permissionStoreKey = permissionStoreKeys[i];
      const permissionArea = permissionStore[permissionStoreKey];
      // check if the area has the permission
      result = permissionArea[permissionKey] || false;
      if (result) {
        break;
      }
    }
  }

  return result;
}
