import { Injectable } from '@angular/core';
import { ApiWrapper } from '../common/api-wrapper.service';
import { AvailableAPI, RequestMethod, UseHeaderType } from '../../classes/commons/request-api.model';
import {
  AlertDefinition,
  NonCustomAlertDefinition,
} from '../../pages/unit-view/unit-alerts/alert-definitions-list/alert-definition.model';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslationsService } from '../common/translations.service';
import { Alert } from '../../pages/unit-view/unit-alerts/alert/alert.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  alertDefinitions = {
    definitions: [],
    apiCall: false,
    dataExist: false,
    apiCallFailed: false,
    error: {},
    ready: new BehaviorSubject(null),
  };

  alerts = {
    alerts: [],
    meta: {
      count: null,
      next: null,
      previous: null,
    },
    apiCall: false,
    dataExist: false,
    apiCallFailed: false,
    error: {},
    ready: new BehaviorSubject(null),
  };

  loading: any;

  constructor(
    private api: ApiWrapper,
    private alertController: AlertController,
    private translationsService: TranslationsService,
    private loadingController: LoadingController,
  ) {}

  resetService() {
    this.resetAlertDefinitions();
    this.resetAlerts();
  }

  resetAlertDefinitions(): void {
    this.alertDefinitions = {
      definitions: [],
      apiCall: false,
      dataExist: false,
      apiCallFailed: false,
      error: {},
      ready: new BehaviorSubject(null),
    };
  }

  resetAlerts() {
    this.alerts = {
      alerts: [],
      meta: {
        count: null,
        next: null,
        previous: null,
      },
      apiCall: false,
      dataExist: false,
      apiCallFailed: false,
      error: {},
      ready: new BehaviorSubject(null),
    };
  }

  async addDefinition(def: AlertDefinition | NonCustomAlertDefinition) {
    const buttons = [
      {
        text: this.translationsService.instant('Alerts.Acknowledgment'),
        handler: () => {},
      },
    ];

    await this.presentLoading(this.translationsService.instant('Alerts.CreatingAlertDef'));

    const recipientObjs = def.recipients;
    const recipientIds = def.recipients.map((el) => {
      return el.id;
    });

    this.createAlertDefinition(def, recipientIds)
      .then((res) => {
        this.presentAlert(
          this.translationsService.instant('Alerts.Success'),
          this.translationsService.instant('Alerts.CreatedDefinition'),
          buttons,
        );
        def.recipients = recipientObjs;
        def.pk = res.data.pk; // set the pk of the new definition
        this.alertDefinitions.definitions.push(def);
      })
      .catch(() => {
        this.presentAlert(
          this.translationsService.instant('Alerts.Error'),
          this.translationsService.instant('Alerts.CreateDefError'),
          buttons,
        );
      })
      .finally(() => {
        this.loading.dismiss();
      });
  }

  getDefinitions(unitId: string) {
    this.getUnitsAlertDefinitions(unitId)
      .then((defs) => {
        this.alertDefinitions.apiCall = true;
        this.alertDefinitions.definitions = defs.data.map((el) => {
          return new AlertDefinition(
            el.category,
            unitId,
            el.pk,
            el.name,
            el.criticality,
            el.isActive,
            el.remediation,
            el.condition,
            el.metric,
            el.metric_key,
            el.expression,
            el.value,
            el.value2,
            el.period,
            el.period_unit,
            [],
            el.recipients,
          );
        });
        this.alertDefinitions.dataExist = true;
        this.alertDefinitions.ready.next(true);
      })
      .catch((err) => {
        this.alertDefinitions.apiCallFailed = true;
        this.alertDefinitions.dataExist = false;
        this.alertDefinitions.error = err;
      });
  }

  async updateDefinition(def: AlertDefinition | NonCustomAlertDefinition) {
    const buttons = [
      {
        text: this.translationsService.instant('Alerts.Acknowledgment'),
        handler: () => {},
      },
    ];

    await this.presentLoading(this.translationsService.instant('Alerts.UpdatingAlertDef'));

    const recipientObjs = def.recipients;
    const recipientIds = def.recipients.map((el) => {
      return el.id;
    });

    this.updateAlertDefinition(def.pk, def, recipientIds)
      .then(() => {
        def.recipients = recipientObjs;
        // remove the old alert definition
        this.alertDefinitions.definitions = this.alertDefinitions.definitions.filter((el) => el.pk != def.pk);
        // add the new one in
        this.alertDefinitions.definitions.unshift(def);

        this.presentAlert(
          this.translationsService.instant('Alerts.Success'),
          this.translationsService.instant('Alerts.UpdateDefSuccess'),
          buttons,
        );
      })
      .catch(() => {
        this.presentAlert(
          this.translationsService.instant('Alerts.Error'),
          this.translationsService.instant('Alerts.UpdateDefError'),
          buttons,
        );
      })
      .finally(() => {
        this.loading.dismiss();
      });
  }

  deleteDefinition(pk): void {
    const buttons = [
      {
        text: this.translationsService.instant('General.No'),
        handler: () => {},
      },
      {
        text: this.translationsService.instant('General.Yes'),
        handler: () => {
          this.deleteDefinitionHandler(pk);
        },
      },
    ];
    this.presentAlert(
      this.translationsService.instant('Alerts.Confirmation'),
      this.translationsService.instant('Alerts.AreYouSure'),
      buttons,
    );
  }

  private async deleteDefinitionHandler(pk: string) {
    await this.presentLoading(this.translationsService.instant('Alerts.DeletingAlertDef'));
    this.deleteAlertDefinition(pk)
      .then((_res) => {
        this.alertDefinitions.definitions = this.alertDefinitions.definitions.filter(
          (def: AlertDefinition) => def.pk !== pk,
        );
      })
      .catch((_err) => {
        const buttons = [{ text: this.translationsService.instant('Alerts.Acknowledgment'), handler: () => {} }];
        this.presentAlert(
          this.translationsService.instant('Alerts.Error'),
          this.translationsService.instant('Alerts.DeleteDefError'),
          buttons,
        );
      })
      .finally(() => {
        this.loading.dismiss();
      });
  }

  private createAlertDefinition(
    data: AlertDefinition | NonCustomAlertDefinition,
    recipientsIds: string[],
  ): Promise<any> {
    /**
     * we do this to accommodate for the fact that the GET gives you recipients as
     * { id: string, email: string }[] and the PUT and POST take recipients as string[]
     */
    data.recipients = recipientsIds;
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/alerts/definitions/create/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }

  private getUnitsAlertDefinitions(unitid: string): Promise<any> {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      `/api/v1/alerts/definitions/unit/${unitid}/`,
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  private updateAlertDefinition(pk, data, recipientsIds) {
    /**
     * we do this to accommodate for the fact that the GET gives you recipients as
     * { id: string, email: string }[] and the PUT and POST take recipients as string[]
     */
    data.recipients = recipientsIds;
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      `/api/v1/alerts/definitions/${pk}/update/`,
      RequestMethod.PUT,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }

  private deleteAlertDefinition(pk) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      `/api/v1/alerts/definitions/${pk}/delete/`,
      RequestMethod.DELETE,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  private async presentAlert(
    header: string,
    message: string,
    buttons: { text: string; handler: () => void }[],
  ): Promise<any> {
    const alert = await this.alertController.create({
      header,
      message,
      buttons,
    });

    await alert.present();
  }

  async presentLoading(message: string) {
    this.loading = await this.loadingController.create({
      message,
    });
    await this.loading.present();
  }

  private getUnitsAlerts(unitId: string, suffix: string): Promise<any> {
    let url = `/api/v1/alerts/unit/${unitId}/`;
    url = suffix ? `${url}${suffix}` : url;
    return this.api.handleRequest(AvailableAPI.SWITCHDIN, url, RequestMethod.GET, UseHeaderType.AUTHORIZED_SWDIN, {});
  }

  getAlerts(unitId: string, suffix: string, infiniteScrollEvent): void {
    this.getUnitsAlerts(unitId, suffix)
      .then((defs) => {
        this.alerts.meta = {
          count: defs.data.count,
          next: defs.data.next,
          previous: defs.data.previous,
        };

        this.alerts.apiCall = true;

        defs.data.results.forEach((el) => {
          this.alerts.alerts.push(
            new Alert(
              el.active,
              el.alert_definition,
              el.alert_details,
              el.alert_level,
              el.alert_text,
              el.device,
              el.device_serialNo,
              el.pk,
              el.time_deactivated,
              el.time_registered,
              el.unit,
              el.user_has_seen,
              el.uuid,
            ),
          );
        });
        this.alerts.dataExist = true;
        this.alerts.ready.next(true);
      })
      .catch((err) => {
        this.alerts.apiCallFailed = true;
        this.alerts.dataExist = false;
        this.alerts.error = err;
      })
      .finally(() => {
        // once this call has been made stop the infinite scroll loader
        if (infiniteScrollEvent) {
          infiniteScrollEvent.target.complete();
        }
      });
  }
}
