///
/// Date-Time Constants/Enums
///

// Time Period Constants
// NOTE: Used with moment-js calls so need to ensure moment-js compatibility
export enum TimePeriodResolution {
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  DAYS = 'days',
  WEEK = 'week',
  WEEKS = 'weeks',
  MONTH = 'month',
  MONTHS = 'months',
  YEAR = 'year',
  YEARS = 'years',
}

// DateLabelPipe Period
export enum Period {
  FullDate = 1,
  Week = 2,
  Month = 3,
  Year = 4,
}

// Date Formats
export enum DateFormats {
  YEAR_MONTH_DAY_HOUR_MIN_SEC = 'YYYY/MM/DD HH:mm:ss',
  DAY_MONTH_YEAR = 'DD MMM YYYY',
  WEEK_YEAR = 'WW YYYY',
  MONTH_YEAR = 'MMM yyyy',
  DAY_NAME = 'cccc',
  DAY_MONTH_YEAR_LUXON = 'DDD',
  DAY_NAME_LUXON = 'EEEE',
}
// TimezoneNames

export enum TimeZoneNames {
  ETC_UTC = 'Etc/Utc',
}
