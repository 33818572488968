import { Pipe, PipeTransform } from '@angular/core';
import { DropletHardwareVersion, DropletPortTypes } from '@class/commons/enums';

// Droplet port number mapping
// the key is the port number from the droplet
// the value is the display port number
// this is used to display the correct port number for the droplet
// as the droplet has a different port number mapping
// for each hardware version
// e.g., on droplet plus 2.0, actual port number is 12 but it is displayed as 2 on hardware

const portNumberMapping = {
  152: 1,
  153: 2,
  11: 3,
  12: 2,
  13: 4,
  14: 3,
  15: 4,

  // Droplet Plus 2.0.1
  100: 'A',
  200: 'B',
};

@Pipe({
  name: 'dropletPortLabel',
  standalone: true,
})
export class DropletPortLabelPipe implements PipeTransform {
  transform(portType: DropletPortTypes, portNumber: number, hardwareVersion: DropletHardwareVersion): string {
    return `${portType.toUpperCase()} ${this.getDisplayPortNumber(portNumber, hardwareVersion)}`;
  }

  private getDisplayPortNumber(portNumber: number, hardwareVersion: DropletHardwareVersion): number | string {
    // droplet one and other droplet have same port numbers
    // 13 & 12
    // but there values are different
    // so checking if it's droplet one then return this port number
    if (hardwareVersion === DropletHardwareVersion.DROPLET_ONE && portNumber === 13) {
      return 2;
    }
    if (hardwareVersion === DropletHardwareVersion.DROPLET_ONE && portNumber === 12) {
      return 1;
    }

    return portNumberMapping[portNumber] || portNumber;
  }
}
