import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { RouterTestingModule } from '@angular/router/testing';
import { TranslateMockModule } from './services/__mocks/third-party-services/translate.service.mock';
import { Drivers } from '@ionic/storage';

export const DRIVER_ORDER = [Drivers.IndexedDB, Drivers.LocalStorage];
export const STORAGE_NAME = '__mydb';

export const SharedTestModules = [
  IonicModule,
  IonicStorageModule.forRoot({
    name: STORAGE_NAME,
    driverOrder: DRIVER_ORDER,
  }),
  RouterTestingModule,
  TranslateMockModule,
];
