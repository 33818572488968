import { VppDemandTypes } from './vpp-demand-type-mode.model';
import { VppFcasMarket } from './vpp-fcas-markets.model';
import { VppModes } from './vpp-demand-type-mode.model';
import { VppControlGroupScheduleControlGroup } from './vpp-control-group.model';
export interface VppControlGroupSchedule {
  auto: boolean;
  controlGroup: VppControlGroupScheduleControlGroup;
  current: boolean;
  id: string;
  schedule: VppSchedule;
  mode: VppModes;

  //DRM
  meritOrder?: VppControlGroupScheduleMerit;
  controlValue?: boolean | number | string;
  policies?: VppControlGroupSchedulePolicy[];

  controlPriority: number | null;

  //FCAS
  markets?: VppFcasMarket[];
}
interface VppControlGroupSchedulePolicy {
  id: string;
  name: string;
}
export interface VppControlGroupScheduleGetPayload {
  auto: boolean;
  control_group: VppControlGroupScheduleControlGroup;
  current: boolean;
  id: string;
  schedule: VppSchedule;
  mode: VppModes;

  //DRM
  merit_order?: VppControlGroupScheduleMerit;
  control_value?: boolean | number | string;
  policies?: VppControlGroupScheduleGetPayloadPolicy[];

  control_priority: number | null;

  //FCAS
  markets?: VppFcasMarket[];
}

interface VppControlGroupScheduleGetPayloadPolicy {
  policy_id: string;
  policy_name: string;
}

export interface VppControlGroupSchedulePostPayload {
  auto: boolean;
  control_group: string; //uuid
  schedule: string; //uuid
  mode: VppModes;

  //DRM
  policies?: string[];
  merit_order?: VppControlGroupScheduleMeritPostPayload;
  control_value?: boolean | number | string;

  control_priority: number | null;

  //FCAS
  markets?: string[];
}
export interface VppControlGroupScheduleMerit extends VppControlGroupScheduleMeritPostPayload {
  control_description: string;
  demand_type: VppDemandTypes;
}
export interface VppControlGroupScheduleMeritPostPayload {
  control_code: string;
  device_class: string;
  order: number;
  vpp_id: string;
  control_type?: MeritControlValueType;
}
export interface VppSchedule {
  description: string;
  end_date: string;
  id: string;
  name: string;
  recurrence: string;
  start_date: string;
  frequency_value?: number;
  intervals?: string[];
  weekly_schedules?: {
    day: string;
    intervals: string[];
  };
}

export enum VppScheduleRecurrencePattern {
  ONCE = 'Once',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
}
export enum MeritControlValueType {
  BOOLEAN = 'boolean',
  INT = 'int',
  FLOAT = 'float',
  STRING = 'string',
  TEXT = 'text',
}
