import { OrgUnitSummary, OrganisationUnit } from '@custom-types/org-settings/org-unit.model';
import {
  setFetchedOrgSettingsUnits,
  setFetchedOrgSummary,
  setOrgSettingsUnitLoadingState,
  setOrgSummaryLoadingState,
} from './org-settings-unit.action';
import { createReducer, on } from '@ngrx/store';

export interface OrgSettingsUnitState {
  orgSettingsUnits: {
    next: string | null;
    previous: string | null;
    units: OrganisationUnit[];
    isLoading?: boolean;
    isError?: boolean;
    error?: string | null;
  };
  orgSummary: {
    summary: OrgUnitSummary;
    isLoading?: boolean;
    isError?: boolean;
    error?: string | null;
  };
}

const initialState: OrgSettingsUnitState = {
  orgSettingsUnits: {
    next: null,
    previous: null,
    units: [],
  },
  orgSummary: null,
} as OrgSettingsUnitState;

export const appOrgSettingsUnitReducer = createReducer(
  initialState,
  on(setFetchedOrgSettingsUnits, (state, { next, previous, units }) => {
    let nextCursor = next;
    let prevCursor = previous;

    // next and previous is a full url
    // that looks like something
    // <url>?cursor=cD05ODQx&organisation_uuid=<uuid>
    // but we only need the value for cursor as we set the rest of the url already
    // slicing it until api sends the correct value in next and previous
    if (nextCursor && nextCursor.includes('cursor')) {
      nextCursor = nextCursor.split('cursor=')[1];
      nextCursor = nextCursor.includes('&') ? nextCursor.split('&')[0] : nextCursor;
    }
    if (prevCursor && prevCursor.includes('cursor')) {
      prevCursor = prevCursor.split('cursor=')[1];
      prevCursor = prevCursor.includes('&') ? prevCursor.split('&')[0] : prevCursor;
    }

    return {
      ...state,
      orgSettingsUnits: {
        ...state.orgSettingsUnits,
        next: nextCursor,
        previous: prevCursor,
        units,
      },
    };
  }),
  on(setOrgSettingsUnitLoadingState, (state, { isLoading, isError, error }) => ({
    ...state,
    orgSettingsUnits: {
      ...state.orgSettingsUnits,
      isLoading,
      isError,
      error: error?.message ?? null,
    },
  })),
  on(setFetchedOrgSummary, (state, { summary }) => ({
    ...state,
    orgSummary: {
      ...state.orgSummary,
      summary,
    },
  })),
  on(setOrgSummaryLoadingState, (state, { isLoading, isError, error }) => ({
    ...state,
    orgSummary: {
      ...state.orgSummary,
      isLoading,
      isError,
      error: error?.message ?? null,
    },
  })),
);
