<ion-item lines="none" class="bt-1 ion-margin-horizontal ion-no-padding item-inner-padding-end-0">
  <ion-spinner
    *ngIf="automatedTestUiSettings.showTestingSpinner"
    duration="1500"
    name="crescent"
    color="primary-svg"
    class="mr-10"
  >
  </ion-spinner>
  <ion-spinner
    *ngIf="automatedTestUiSettings.initialLoading"
    duration="1500"
    name="dots"
    color="primary-svg"
    class="mr-10"
    slot="end"
  >
  </ion-spinner>
  <ion-icon
    *ngIf="automatedTestUiSettings.ionIcon"
    [name]="automatedTestUiSettings.ionIcon"
    [color]="automatedTestUiSettings.ionIconColor"
    class="mr-10"
  />
  <ion-label class="ion-text-wrap">
    <ion-text>
      <p class="text-secondary-md m-0">{{ 'AutomatedTesting.ControlAutomatedTesting' | translate }}</p>
      <p class="text-primary-lg m-0">{{ automatedTestUiSettings.testStatus | translate }}</p>
    </ion-text>
  </ion-label>
  <app-button
    slot="end"
    label="{{ automatedTestUiSettings.buttonLabel | translate }}"
    [buttonType]="automatedTestUiSettings.buttonType"
    [isDisabled]="automatedTestUiSettings.isButtonDisabled"
    [loading]="automatedTestUiSettings.isButtonLoading"
    *ngIf="!automatedTestUiSettings.hideTestButton"
    (onClick)="onAutomatedTestButtonClick(automatedTestUiSettings.buttonAction)"
  ></app-button>
</ion-item>

<ion-item lines="none" color="danger-lite" class="ion-margin" *ngIf="testMessage?.length > 0">
  <ion-icon name="alert-circle" color="danger" class="mr-10" />
  <ion-label class="ion-text-wrap">
    <ion-text>
      <p class="text-primary-xl m-0">{{ 'General.Error.Failed' | translate }}</p>
      <p class="text-primary-md m-0">{{ testMessage }}</p>
    </ion-text>
  </ion-label>
</ion-item>
