<ng-container
  *ngrxLet="{
    diagnostic: diagnostic$,
    endpointDevice: endpointDevice$,
    testStatus: testStatus$,
    device: device$,
    testMessage: testMessage$
  } as vm"
>
  <ion-row>
    <ion-col>
      <ion-card class="card-design m-0">
        <!-- Display information for device if device is discovered or configured -->
        <ng-container
          *ngIf="
            vm.device.device_state === DeviceStates.DISCOVERED ||
            vm.device.device_state === DeviceStates.CONFIGURED ||
            vm.device.device_state === DeviceStates.INITIALIZED
          "
        >
          <ion-item lines="none">
            <ion-label class="br-2 ion-text-wrap">
              <ion-text>
                <p class="text-secondary-md m-0">{{ 'AddDevice.Manufacturer' | translate }}</p>
                <p class="text-primary-lg m-0">
                  {{ vm.device.info.manufacturer }}
                </p>
              </ion-text>
            </ion-label>
            <ion-label class="ml-20 ion-text-wrap">
              <ion-text>
                <p class="text-secondary-md m-0">{{ 'UnitPage.Model' | translate }}</p>
                <p class="text-primary-lg m-0 ion-text-wrap">{{ vm.device.info.model_name }}</p>
              </ion-text>
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-label class="ion-text-wrap">
              <ion-text>
                <p class="text-secondary-md m-0">{{ 'UnitPage.Serial' | translate }}</p>
                <p class="text-primary-lg m-0">{{ vm.device.info.serial_number }}</p>
              </ion-text>
            </ion-label>
          </ion-item>
        </ng-container>

        <!-- Static Configured Devices -->
        <ng-container *ngIf="vm.device.device_state === DeviceStates.STATIC_CONFIGURED">
          <ion-item lines="none">
            <ion-label class="ion-text-wrap">
              <ion-text>
                <p class="text-secondary-md">{{ 'UnitPage.DriverProtocol' | translate }}</p>
                <p class="text-primary-lg m-0">{{ vm.device.info.driver_identifier }}</p>
              </ion-text>
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-label class="ion-text-wrap">
              <ion-text>
                <p class="text-secondary-md mb-8" translate>AddDevice.RemoveDeviceContact</p>
              </ion-text>
            </ion-label>
          </ion-item>
        </ng-container>

        <!-- configure button -->
        <div class="ion-padding" *ngIf="vm.device.device_state === DeviceStates.DISCOVERED">
          <app-button
            (onClick)="handleConfigureClick(vm.device)"
            label="{{ (configuring ? 'AddDevice.ConfiguringDevice' : 'AddDevice.Buttons.AddDevice') | translate }}"
            [isDisabled]="configuring"
          ></app-button>
        </div>

        <!-- Device Automated Testing -->
        <ng-container *ifHasPermission="[PermissionKey.GLOBAL_INSTALLER] && vm.device.automatedTestSupported">
          <app-device-automated-testing
            *ngIf="vm.endpointDevice != null"
            expand="block"
            (onClick)="handleDiagnosticButtonClick($event, vm.endpointDevice, vm.diagnostic)"
            [status]="vm.testStatus"
            [testMessage]="vm.testMessage"
          />
        </ng-container>

        <!-- remove button -->
        <div class="ion-padding" *ngIf="vm.device.device_state === DeviceStates.CONFIGURED">
          <ng-container
            *ngrxLet="{
              disabled:
                removing ||
                vm.testStatus === AutomatedTestStatus.Pending ||
                vm.testStatus == AutomatedTestStatus.Starting ||
                vm.testStatus == AutomatedTestStatus.Stopping ||
                vm.testStatus == AutomatedTestStatus.InitialChecking
            } as removeButton"
          >
            <app-remove-button
              [size]="CustomButtonSize.LARGE"
              (click)="!removeButton.disabled && handleRemoveClick(vm.device)"
              label="{{ (removing ? 'AddDevice.RemovingDevice' : 'General.Remove') | translate }}"
              [isDisabled]="removeButton.disabled"
            >
            </app-remove-button>
          </ng-container>
        </div>
      </ion-card>
    </ion-col>
  </ion-row>
</ng-container>
