import { UnitOperationalStates } from '@class/commons/enums';
import { UnitOperationalStateApi } from '@custom-types/unit/unit-operational-state-api.model';

export const getUnitOperationalState = {
  [UnitOperationalStates.INACTIVE]: 'UnitOperationalStates.Inactive',
  [UnitOperationalStates.DELETED]: 'UnitOperationalStates.Deleted',
  [UnitOperationalStates.NOT_CONFIGURED]: 'UnitOperationalStates.NotConfigured',
};

export const getUnitPendingOperationalState = {
  [UnitOperationalStates.ACTIVE]: 'UnitOperationalStates.ActivationPending',
  [UnitOperationalStates.INACTIVE]: 'UnitOperationalStates.DeactivationPending',
  [UnitOperationalStates.DELETED]: 'UnitOperationalStates.Deleted',
};

// this is to determine if the action is pending and should we show the pending or not
// it is possible that we have a unit that is deleted but the action is pending
// but we only wanna show pending if unit is active or inactive
export const showActionPendingState = (actionPending: boolean, operationalState: UnitOperationalStates) =>
  actionPending &&
  (operationalState === UnitOperationalStates.ACTIVE || operationalState === UnitOperationalStates.INACTIVE);

export const getApiUnitOperationalStateFromUIState: Record<UnitOperationalStates, UnitOperationalStateApi> = {
  [UnitOperationalStates.ACTIVE]: 'Active',
  [UnitOperationalStates.INACTIVE]: 'Inactive',
  [UnitOperationalStates.DELETED]: 'Deleted',
  [UnitOperationalStates.NOT_CONFIGURED]: 'Not Configured',
  [UnitOperationalStates.PURGED]: 'Purged',
};
