import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AddDeviceModalPage } from './add-device-modal.page';
import { SharedModule } from 'app/app.shared.module';
import { TroubleshootCardComponent } from '../troubleshoot-card/troubleshoot-card.component';
import { AddDeviceModalPageRoutingModule } from './add-device-modal-routing.module';
import { LegacyDevicesComponent } from '../legacy-devices/legacy-devices.component';
import { DropletPortLayoutComponent } from '../droplet-port-layout/droplet-port-layout.component';
import { provideImmerComponentStore } from 'ngrx-immer/component-store';
import { AddDeviceStore } from '../store/add-device.store';
import { DeviceStateCardComponent } from '../device-state-card/device-state-card.component';
import { InventoryDevicesComponent } from '../inventory-devices/inventory-devices.component';
import { DiscoverableDevicesListComponent } from '../discoverable-devices-list/discoverable-devices-list.component';
import { DeviceConfigurationSettingsComponent } from '../device-configuration-settings/device-configuration-settings.component';
import { ManufactureAndDevicesForDiscoveryStore } from '../store/manufacturer-devices-for-discovery.store';
import { LegacyDeviceStore } from '../store/legacy-device.store';
import { ButtonComponent } from 'app/components/buttons/button/button.component';
import { DeviceAutomatedTestingComponent } from 'app/components/device-automated-testing/device-automated-testing.component';
import { DeviceStateCardListComponent } from '../device-state-card/device-state-card-list.component';
import { DropletImageSrcPipe } from 'app/pipes/droplet/droplet-image-src.pipe';
import { DropletPortLabelPipe } from 'app/pipes/droplet/droplet-port-label.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AddDeviceModalPageRoutingModule,
    SharedModule,
    ButtonComponent,
    DeviceAutomatedTestingComponent,
    DropletImageSrcPipe,
    DropletPortLabelPipe,
  ],
  declarations: [
    AddDeviceModalPage,
    TroubleshootCardComponent,
    LegacyDevicesComponent,
    DropletPortLayoutComponent,
    DeviceStateCardListComponent,
    DeviceStateCardComponent,
    InventoryDevicesComponent,
    DiscoverableDevicesListComponent,
    DeviceConfigurationSettingsComponent,
  ],
  providers: [provideImmerComponentStore(AddDeviceStore), ManufactureAndDevicesForDiscoveryStore, LegacyDeviceStore],
})
export class AddDeviceModalPageModule {}
