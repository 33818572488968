import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DEFAULT_TITLE } from '@class/commons/constants';
import { ImmerComponentStore } from 'ngrx-immer/component-store';
import { tap } from 'rxjs';

interface HeaderServiceStore {
  headerTitle: string;
  showBackButton: boolean;
  backDefaultHref: string;
  showInstallerBackButton: boolean;
}

const DEFAULT_HEADER_SERVICE_STORE: HeaderServiceStore = {
  headerTitle: DEFAULT_TITLE,
  showBackButton: true,
  showInstallerBackButton: false,
  backDefaultHref: 'home',
};

@Injectable({
  providedIn: 'root',
})
export class AppHeaderService extends ImmerComponentStore<HeaderServiceStore> {
  readonly headerTitle$ = this.select((state) => state.headerTitle);
  readonly showBackButton$ = this.select((state) => state.showBackButton);
  readonly backDefaultHref$ = this.select((state) => state.backDefaultHref);
  readonly showInstallerBackButton$ = this.select((state) => state.showInstallerBackButton);

  // public effects
  public readonly setHeaderTitle = this.effect<string>((headerTitle$) =>
    headerTitle$.pipe(
      tap((headerTitle: string) => this.updateHeaderTitle(headerTitle)),
      tap((headerTitle: string) => this._title.setTitle(headerTitle)),
    ),
  );

  public readonly setShowBackButton = this.effect<boolean>((showBackButton$) =>
    showBackButton$.pipe(tap((showBackButton: boolean) => this.updateShowBackButton(showBackButton))),
  );

  public readonly setBackDefaultHref = this.effect<string>((backDefaultHref$) =>
    backDefaultHref$.pipe(tap((backDefaultHref: string) => this.updateBackDefaultHref(backDefaultHref))),
  );

  public readonly setShowInstallerBackButton = this.effect<boolean>((showInstallerBackButton$) =>
    showInstallerBackButton$.pipe(
      tap((showInstallerBackButton: boolean) => this.updateShowInstallerBackButton(showInstallerBackButton)),
    ),
  );

  // private updaters
  private readonly updateHeaderTitle = this.updater((state, headerTitle: string) => {
    state.headerTitle = headerTitle;
  });

  private readonly updateShowBackButton = this.updater((state, showBackButton: boolean) => {
    state.showBackButton = showBackButton;
  });

  private readonly updateBackDefaultHref = this.updater((state, backDefaultHref: string) => {
    state.backDefaultHref = backDefaultHref;
  });

  private readonly updateShowInstallerBackButton = this.updater((state, showInstallerBackButton: boolean) => {
    state.showInstallerBackButton = showInstallerBackButton;
  });

  constructor(private _title: Title) {
    super(DEFAULT_HEADER_SERVICE_STORE);
  }
}
