import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { ImmerComponentStore } from 'ngrx-immer/component-store';
import { STORAGE_CONSTANTS } from '@class/commons/constants';
import { Observable, filter, map, tap } from 'rxjs';
import { DateTime } from 'luxon';
import { AppCredentialsApi } from '@custom-types/core/app-credentials-api.model';

type ApiKeyMqttCredStorageType = AppCredentialsApi & { expires: number; fresh?: boolean };

interface StorageServiceState {
  authToken: string;
  refreshToken: string;
  appApiKeysMqttCredentials: ApiKeyMqttCredStorageType;
}

const EMPTY_STATE = {
  authToken: null,
  refreshToken: null,
  appApiKeysMqttCredentials: {
    websocket: {
      username: null,
      password: null,
    },
    google_maps: {
      api_key: null,
    },
    posthog: {
      token: null,
    },
    expires: null,
  },
};

@Injectable({
  providedIn: 'root',
})
export class StorageService extends ImmerComponentStore<StorageServiceState> {
  // selectors
  readonly authToken$ = this.select((state) => state.authToken).pipe(filter((authToken) => !!authToken));
  readonly refreshToken$ = this.select((state) => state.refreshToken).pipe(filter((refreshToken) => !!refreshToken));
  readonly appApiKeyTokensMqttCredentials$ = this.select((state) => state.appApiKeysMqttCredentials).pipe(
    filter((appCredentials) => !!appCredentials),
  );
  readonly posthogToken$ = this.select((state) => state.appApiKeysMqttCredentials).pipe(
    filter((appCred) => !!appCred && !!appCred.posthog && !!appCred.posthog.token),
    map((appCred) => appCred.posthog.token),
  );
  readonly googleMapsApiKey$ = this.select((state) => state.appApiKeysMqttCredentials).pipe(
    filter((appCred) => !!appCred && !!appCred.google_maps && !!appCred.google_maps.api_key),
    map((appCred) => appCred.google_maps.api_key),
  );
  readonly rabbitMQWebSocketCredentials$ = this.select((state) => state.appApiKeysMqttCredentials).pipe(
    map((appCredentials) => ({
      ...appCredentials.websocket,
      expires: appCredentials.expires,
      fresh: appCredentials.fresh,
    })),
    filter((rabbitMQWebSocketCredentials) => !!rabbitMQWebSocketCredentials),
  );

  // effects
  setAuthToken = this.effect((authToken$: Observable<string>) => {
    return authToken$.pipe(
      tap(async (authToken: string) => {
        this.updateAuthToken(authToken);
        this.setLocalStorageItem(STORAGE_CONSTANTS.AUTH_TOKEN, authToken);
      }),
    );
  });

  setRefreshToken = this.effect((refreshToken$: Observable<string>) => {
    return refreshToken$.pipe(
      tap((refreshToken: string) => {
        this.updateRefreshToken(refreshToken);
        this.setLocalStorageItem(STORAGE_CONSTANTS.REFRESH_TOKEN, refreshToken);
      }),
    );
  });

  setAppApiKeysTokensCredentials = this.effect(
    (
      appCredentials$: Observable<{
        appCredentials: AppCredentialsApi;
        expires: number;
      }>,
    ) => {
      return appCredentials$.pipe(
        tap(({ appCredentials, expires }: { appCredentials: AppCredentialsApi; expires: number }) => {
          this.updateAppCredentials({
            ...appCredentials,
            expires,
            fresh: false,
          });
          this.setLocalStorageItem(STORAGE_CONSTANTS.APP_API_KEYS_TOKEN_CREDENTIALS, {
            ...appCredentials,
            expires,
          });
        }),
      );
    },
  );

  // update the state
  private readonly updateAuthToken = this.updater((state, authToken: string) => {
    state.authToken = authToken;
  });
  private readonly updateRefreshToken = this.updater((state, refreshToken: string) => {
    state.refreshToken = refreshToken;
  });
  private readonly updateAppCredentials = this.updater((state, appCredentials: ApiKeyMqttCredStorageType) => {
    state.appApiKeysMqttCredentials = appCredentials;
  });

  constructor(private storage: Storage) {
    super(EMPTY_STATE);
    this.init();
  }

  async init(): Promise<void> {
    // create the storage instance
    await this.storage.create();
    // get the stored values
    const [authToken, refreshToken, appApiKeyTokensMqttCredentials] = await Promise.all([
      this.storage.get(STORAGE_CONSTANTS.AUTH_TOKEN),
      this.storage.get(STORAGE_CONSTANTS.REFRESH_TOKEN),
      this.storage.get(STORAGE_CONSTANTS.APP_API_KEYS_TOKEN_CREDENTIALS),
    ]);
    const cachedAppCredentialsAreValid = !!(
      appApiKeyTokensMqttCredentials && DateTime.fromMillis(appApiKeyTokensMqttCredentials.expires) > DateTime.now()
    );
    // set the store values
    this.setState({
      authToken,
      refreshToken,
      appApiKeysMqttCredentials: { ...appApiKeyTokensMqttCredentials, fresh: !cachedAppCredentialsAreValid },
    });
  }

  async setLocalStorageItem<T>(key: string, value: T) {
    try {
      await this.storage.set(key, value);
    } catch (error) {
      console.error(`Error setting item of key ${key} and value ${value} to local storage: ${error}`);
    }
  }

  async getFromLocalStorage<T>(key: string): Promise<T | undefined> {
    try {
      return (await this.storage.get(key)) as Promise<T>;
    } catch (error) {
      console.error(`Error getting item of key ${key} from local storage: ${error}`);
    }

    return undefined;
  }

  async getFromLocalOrSessionStorage<T>(key: string): Promise<T | undefined> {
    try {
      let value = await this.storage.get(key);
      if (!value) {
        value = sessionStorage.getItem(key);
      }
      return value;
    } catch (error) {
      console.error(`Error getting item of key ${key} from local or session storage: ${error}`);
    }

    return undefined;
  }

  async getLocalStorageLength() {
    try {
      return await this.storage.length();
    } catch (error) {
      console.error(`Error getting length from local storage: ${error}`);
      return null;
    }
  }

  removeSessionStorageItem(key: string) {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      console.error(`Error removing key ${key} from session storage: ${error}`);
    }
  }

  async removeLocalStorageItem(key: string) {
    try {
      await this.storage.remove(key);
    } catch (error) {
      console.error(`Error removing key ${key} from local storage: ${error}`);
    }
  }

  async clearLocalAndSessionStorage() {
    try {
      this.setState(EMPTY_STATE);
      await this.storage.clear();
      sessionStorage.clear();
    } catch (error) {
      console.error(`Error clearing local or session storage: ${error}`);
    }
  }
}
