import { Injectable } from '@angular/core';
import { AlertButtonRoles, AlertButtons } from '@class/alerts-toasts-props/alert-props';
import { ToastDuration, ToastPosition } from '@class/alerts-toasts-props/toast-props';
import { BrowserLogger } from '@class/core/browser-logger';
import { ErrorMessageOptions } from '@class/error/http-error-types';
import { AlertController, IonicSafeString, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { ComponentProps, ComponentRef } from '@ionic/core';
import { TranslationsService } from './translations.service';

@Injectable({
  providedIn: 'root',
})
export class GenericAlertsToastsService {
  constructor(
    private toastController: ToastController,
    private trans: TranslationsService,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private modalController: ModalController,
  ) {}

  /**
   * TOASTS
   */

  async createCustomToast(
    message: string,
    timeInMillis: number = ToastDuration.DEFAULT_MS,
    position: ToastPosition = ToastPosition.DEFAULT,
  ): Promise<HTMLIonToastElement> {
    const toast: HTMLIonToastElement = await this.toastController.create({
      message,
      duration: timeInMillis,
      position,
    });
    return toast;
  }

  /**
   * ALERTS
   */
  async createErrorAlertWithOkButton({
    heading,
    subheading,
    message,
  }: ErrorMessageOptions): Promise<HTMLIonAlertElement> {
    return await this.createAlertWithOkButton(heading, subheading, message);
  }
  async createAlertWithOkButton(
    heading: string,
    subheading: string,
    message: string | IonicSafeString,
  ): Promise<HTMLIonAlertElement> {
    const { OK } = this.trans.instant('General');
    const alert: HTMLIonAlertElement = await this.alertController.create({
      header: heading,
      subHeader: subheading,
      message: message,
      buttons: [
        {
          role: AlertButtonRoles.OK,
          text: OK,
        },
      ],
    });
    return alert;
  }
  async createNotificationAlertWithOkButton(
    heading: string,
    subheading: string,
    message: string | IonicSafeString,
  ): Promise<HTMLIonAlertElement> {
    const { OK } = this.trans.instant('General');
    const alert: HTMLIonAlertElement = await this.alertController.create({
      header: heading,
      subHeader: subheading,
      message: message,
      cssClass: 'notification-alert',
      buttons: [
        {
          role: AlertButtonRoles.OK,
          text: OK,
          cssClass: 'notification-alert-button',
        },
      ],
    });
    return alert;
  }
  async createAlertWithYesNoButton(
    heading: string,
    subheading: string,
    message: string | IonicSafeString,
  ): Promise<HTMLIonAlertElement> {
    const { Yes, No } = this.trans.instant('General');
    const alert: HTMLIonAlertElement = await this.alertController.create({
      header: heading,
      subHeader: subheading,
      message: message,
      buttons: [
        {
          role: AlertButtonRoles.NO,
          text: No,
        },
        {
          role: AlertButtonRoles.YES,
          text: Yes,
        },
      ],
    });
    return alert;
  }
  async createAlertWithRetryCancelButton(
    heading: string,
    subheading: string,
    message: string | IonicSafeString,
  ): Promise<HTMLIonAlertElement> {
    const { Retry, Cancel } = this.trans.instant('General');
    const alert: HTMLIonAlertElement = await this.alertController.create({
      header: heading,
      subHeader: subheading,
      message: message,
      buttons: [
        {
          role: AlertButtonRoles.RETRY,
          text: Retry,
        },
        {
          role: AlertButtonRoles.CANCEL,
          text: Cancel,
        },
      ],
    });
    return alert;
  }
  async createAlertWithCancelRemoveButton(
    heading: string,
    subheading: string,
    message: string | IonicSafeString,
  ): Promise<HTMLIonAlertElement> {
    const { Remove, Cancel } = this.trans.instant('General');
    const alert: HTMLIonAlertElement = await this.alertController.create({
      header: heading,
      subHeader: subheading,
      message: message,
      buttons: [
        {
          role: AlertButtonRoles.CANCEL,
          text: Cancel,
        },
        {
          role: AlertButtonRoles.REMOVE,
          text: Remove,
        },
      ],
    });
    return alert;
  }
  async createAlertWithCloseCancelButton(
    heading: string,
    subheading: string,
    message: string | IonicSafeString,
  ): Promise<HTMLIonAlertElement> {
    const { Close, Cancel } = this.trans.instant('General');
    const alert: HTMLIonAlertElement = await this.alertController.create({
      header: heading,
      subHeader: subheading,
      message: message,
      buttons: [
        {
          role: AlertButtonRoles.CLOSE,
          text: Close,
        },
        {
          role: AlertButtonRoles.CANCEL,
          text: Cancel,
        },
      ],
    });
    return alert;
  }
  async createAlertWithCustomButton(
    heading: string,
    subheading: string,
    message: string | IonicSafeString,
    buttons: Array<AlertButtons>,
  ): Promise<HTMLIonAlertElement> {
    const alert: HTMLIonAlertElement = await this.alertController.create({
      header: heading,
      subHeader: subheading,
      message: message,
      buttons: buttons,
    });
    return alert;
  }

  /**
   * LOADER
   */
  async createWaitingLoader(): Promise<HTMLIonLoadingElement> {
    const { Wait } = this.trans.instant('General');
    const loading = await this.loadingController.create({
      message: Wait,
    });
    return loading;
  }
  async createWaitingLoaderWithCustomText(customText: string): Promise<HTMLIonLoadingElement> {
    const { Wait } = this.trans.instant('General');
    const loading = await this.loadingController.create({
      message: `${customText}, ${Wait}`,
    });
    return loading;
  }
  async createLoaderWithCustomText(customText: string): Promise<HTMLIonLoadingElement> {
    const loading = await this.loadingController.create({
      message: customText,
    });
    return loading;
  }

  /**
   * MODAL
   */
  // dismiss modal (either supplied or default)
  dismissModal(modalElement?: HTMLIonModalElement): void {
    BrowserLogger.log('GenericAlertsToastsService.dismissModal');
    if (modalElement) {
      modalElement.dismiss();
    } else {
      this.modalController.dismiss();
    }
  }

  // display modals which use the <app-modal-container> component
  async presentModalContainerComponent(
    componentRef: ComponentRef,
    componentProps: ComponentProps = null,
  ): Promise<HTMLIonModalElement> {
    BrowserLogger.log('GenericAlertsToastsService.presentModalContainerComponent');
    try {
      const modal = await this.modalController.create({
        backdropDismiss: false,
        component: componentRef,
        componentProps,
      });
      await modal.present();
      return modal;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
}
