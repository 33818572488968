import { TranslationsService } from '../../../services/common/translations.service';
import {
  DisplayConfigJson,
  NumericParameterConfig,
  StringParameterConfig,
  ToggleParameterConfig,
} from './../endpoint/endpoint-config-json.model';
import { BrowserLogger } from '@class/core/browser-logger';
import { BooleanStrings, DataTypeStrings } from '@class/commons/enums';

export interface DeviceControlOrParam {
  id: string;
  name: string;
  description: string;
  type: DropletControlType;
  displayConfig: string;
  displayConfigJson: {
    numeric?: NumericParameterConfig;
    toggle?: ToggleParameterConfig;
    string?: StringParameterConfig;
  };
  placeholder?: string; // used by <app-device-control> to display help info
  value?: string | boolean;
  backendValue?: number | string;
  setKey?: string;
  statusKey?: string;
  statusKeyWithDeviceNumber?: string;
  isSetting?: boolean;
  isIec61850?: boolean;
  isValueValid?: boolean;
  isManualControlRequested?: boolean;
}

export interface DropletDeviceControlValueCheckResult {
  isValid: boolean;
  title?: string;
  message?: string;
}

export enum DropletControlType {
  UNKNOWN = 1,
  NUMERIC = 2,
  TOGGLE = 3,
  STRING = 4,
  NUMERIC_NULLABLE = 5,
  TOGGLE_NULLABLE = 6,
  STRING_NULLABLE = 7,
}
export const CONTROL_AUTO_MODE_STRING = 'null';
export class DropletDeviceControl implements DeviceControlOrParam {
  description: string;
  displayConfig: string;
  displayConfigJson: DisplayConfigJson;
  setKey: string;
  statusKey: string;
  statusKeyWithDeviceNumber: string;
  id: string;
  isIec61850: boolean;
  name: string;
  type: DropletControlType;
  isSetting: boolean;
  backendValue?: number | string;
  placeholder: string;

  public static getControlTypeFromDisplayConfig(displayConfigJson: any): DropletControlType {
    // get the DropletControlType based on displayConfigJson.numeric/toggle/string
    let result = DropletControlType.UNKNOWN;
    if (displayConfigJson?.numeric) {
      result = displayConfigJson.numeric.nullable ? DropletControlType.NUMERIC_NULLABLE : DropletControlType.NUMERIC;
    } else if (displayConfigJson?.toggle) {
      result = displayConfigJson.toggle.nullable ? DropletControlType.TOGGLE_NULLABLE : DropletControlType.TOGGLE;
    } else if (displayConfigJson?.string) {
      result = displayConfigJson.string.nullable ? DropletControlType.STRING_NULLABLE : DropletControlType.STRING;
    }
    BrowserLogger.log('DropletDeviceControl.getControlTypeFromDisplayConfig', { result, displayConfigJson });
    return result;
  }

  public static getControlPlaceholderFromDisplayConfig(
    displayConfigJson: DisplayConfigJson,
    controlType: DropletControlType,
    translationsService: TranslationsService,
  ): string {
    let result: string;
    // Build placeholder to include Numeric Min/Max values (any maybe Nullable)
    if (DropletDeviceControl.isNumericType(controlType)) {
      const { Min, Max } = translationsService.instant('UnitPage');
      result = `${Min}: ${displayConfigJson.numeric.min}, ${Max}: ${displayConfigJson.numeric.max}`;
    } else if (DropletDeviceControl.isStringType(controlType)) {
      result = displayConfigJson.string.placeholder;
    }
    BrowserLogger.log('DropletDeviceControl.getControlPlaceholderFromDisplayConfig', {
      result,
      controlType,
      displayConfigJson,
    });
    return result;
  }

  private static isValueValid(control: DeviceControlOrParam, value: string | number | boolean | null): boolean {
    // Check a 'value'is valid against a control type
    // note: appears to be required to be called along with 'isValueTypeValid' to give a true indication of validity.
    //       leaving as is but should probably be refactored into something that is not reliant on another function.
    let result = false;
    // handle NULL values (only valid for nullable types)
    if (value == null) {
      return DropletDeviceControl.isNullableType(control.type);
    }
    // handle NON-NULL values
    const typeofValue = typeof value;
    // Numeric
    if (DropletDeviceControl.isNumericType(control.type)) {
      if (control.displayConfigJson.numeric) {
        const parsedValue = typeof value === 'number' ? value : parseFloat(value as string);
        const { min, max } = control.displayConfigJson.numeric;
        if (parsedValue >= min && parsedValue <= max) {
          result = true;
        }
      }
    }
    // Toggle
    else if (DropletDeviceControl.isToggleType(control.type)) {
      if (typeofValue === DataTypeStrings.BOOLEAN) {
        result = true;
      } else if (typeofValue === DataTypeStrings.NUMBER) {
        result = value === 0 || value === 1;
      } else if (typeofValue === DataTypeStrings.STRING) {
        result = value === BooleanStrings.TRUE || value === BooleanStrings.FALSE;
      }
    }
    // String
    else if (DropletDeviceControl.isStringType(control.type)) {
      result = true;
    }
    BrowserLogger.log('DropletDeviceControl.isValueValid', { result, value, typeofValue, control });
    return result;
  }

  static isValueEnabled(control: DeviceControlOrParam, value: string | number | boolean | null): boolean {
    // Check a 'value'is valid against a control type
    // note: appears to be required to be called along with 'isValueTypeValid' to give a true indication of validity.
    //       leaving as is but should probably be refactored into something that is not reliant on another function.
    let result = DropletDeviceControl.isValueValid(control, value);
    if (!result) {
      return result;
    }

    // handle NULL values (only valid for nullable types)
    if (value == null) {
      return false;
    }
    // handle NON-NULL values
    const typeofValue = typeof value;
    // Numeric, any numeric value means enabled
    if (DropletDeviceControl.isNumericType(control.type)) {
      return true;
    }
    // Toggle, has to be enabled (true/1/"true")
    else if (DropletDeviceControl.isToggleType(control.type)) {
      if (typeofValue === DataTypeStrings.BOOLEAN) {
        result = value === true;
      } else if (typeofValue === DataTypeStrings.NUMBER) {
        result = value === 1;
      } else if (typeofValue === DataTypeStrings.STRING) {
        result = value === BooleanStrings.TRUE;
      }
    }
    // String, any non empty string means enabled
    else if (DropletDeviceControl.isStringType(control.type)) {
      result = value?.toString().length > 0;
    }
    BrowserLogger.log('DropletDeviceControl.isValueEnabled', { result, value, typeofValue, control });
    return result;
  }

  private static isValueTypeValid(controlType: DropletControlType, value: string | number | boolean): boolean {
    // Check the 'type' of the value is valid against a control type
    // note: appears to be required to be called along with 'isValueValid' to give a true indication of validity.
    //       leaving as is but should probably be refactored into something that is not reliant on another function.
    let result = false;
    // handle NULL values (only valid for nullable types)
    if (value == null) {
      return DropletDeviceControl.isNullableType(controlType);
    }
    // handle NON-NULL values
    const typeofValue = typeof value;
    if (DropletDeviceControl.isNumericType(controlType)) {
      if (typeofValue === DataTypeStrings.NUMBER) {
        result = true;
      } else {
        result = !isNaN(parseFloat(value as string));
      }
    } else if (DropletDeviceControl.isStringType(controlType)) {
      result = typeofValue === DataTypeStrings.STRING;
    } else if (DropletDeviceControl.isToggleType(controlType)) {
      if (typeofValue === DataTypeStrings.BOOLEAN) {
        result = true;
      } else if (typeofValue === DataTypeStrings.NUMBER) {
        result = value === 0 || value === 1;
      } else if (typeofValue === DataTypeStrings.STRING) {
        result = value === BooleanStrings.TRUE || value === BooleanStrings.FALSE;
      }
    }
    BrowserLogger.log('DropletDeviceControl.isValueTypeValid', { result, value, controlType, typeofValue });
    return result;
  }

  public static castValueToCorrectType(
    controlType: DropletControlType,
    value: number | string | null,
  ): string | number | null {
    let result: string | number | null;
    const typeofValue = typeof value;
    // Handle NULL values
    if (value === null) {
      result = value;
    } else {
      // check for 'null' string for nullable types and return actual null
      if (
        DropletDeviceControl.isNullableType(controlType) &&
        typeofValue === DataTypeStrings.STRING &&
        (value as string).toLowerCase() === CONTROL_AUTO_MODE_STRING
      ) {
        result = null;
      } else {
        // return NUMERIC/TOGGLE as a number
        if (DropletDeviceControl.isNumericType(controlType) || DropletDeviceControl.isToggleType(controlType)) {
          result = typeofValue === DataTypeStrings.NUMBER ? value : parseFloat(value as string);
        } else if (DropletDeviceControl.isStringType(controlType)) {
          result = typeofValue === DataTypeStrings.STRING ? value : String(value);
        } else {
          BrowserLogger.error('DropletDeviceControl.castValueToCorrectType: Unknown Type', { value, controlType });
          throw 'Unknown type!';
        }
      }
    }
    BrowserLogger.log('DropletDeviceControl.castValueToCorrectType', { result, value, typeofValue, controlType });
    return result;
  }

  public static isNullableType(controlType: DropletControlType): boolean {
    const result =
      controlType === DropletControlType.NUMERIC_NULLABLE ||
      controlType === DropletControlType.TOGGLE_NULLABLE ||
      controlType === DropletControlType.STRING_NULLABLE;
    BrowserLogger.log('DropletDeviceControl.isNullable', { result, controlType });
    return result;
  }

  public static isNumericType(controlType: DropletControlType): boolean {
    const result = controlType === DropletControlType.NUMERIC || controlType === DropletControlType.NUMERIC_NULLABLE;
    BrowserLogger.log('DropletDeviceControl.isNumericType', { result, controlType });
    return result;
  }

  public static isStringType(controlType: DropletControlType): boolean {
    const result = controlType === DropletControlType.STRING || controlType === DropletControlType.STRING_NULLABLE;
    BrowserLogger.log('DropletDeviceControl.isStringType', { result, controlType });
    return result;
  }

  public static isToggleType(controlType: DropletControlType): boolean {
    const result = controlType === DropletControlType.TOGGLE || controlType === DropletControlType.TOGGLE_NULLABLE;
    BrowserLogger.log('DropletDeviceControl.isToggleType', { result, controlType });
    return result;
  }

  public static supportsManualControl(controlType: DropletControlType): boolean {
    // manual control is supported by nullable controls
    const result = DropletDeviceControl.isNullableType(controlType);
    BrowserLogger.log('DropletDeviceControl.supportsManualControl', { result, controlType });
    return result;
  }

  public static getAutoModeValue(controlType: DropletControlType): null | undefined {
    // return NULL for all nullable types
    // undefined if not nullable
    let result = undefined;
    if (DropletDeviceControl.supportsManualControl(controlType)) {
      result = null;
    } else {
      BrowserLogger.warn('DropletDeviceControl.getAutoModeValue - non nullable control type!', { controlType });
    }
    BrowserLogger.log('DropletDeviceControl.getAutoModeValue', { result, controlType });
    return result;
  }

  public static checkValue(
    control: DeviceControlOrParam,
    value: string | number | boolean | null,
  ): DropletDeviceControlValueCheckResult {
    const result = {
      isValid: true,
    } as DropletDeviceControlValueCheckResult;
    // Required Value check (invalid NULL value against a non-nullable control type)
    if (value == null && !DropletDeviceControl.isNullableType(control.type)) {
      result.isValid = false;
      result.title = 'UnitPage.InvalidValue';
      result.message = 'UnitPage.IsRequired';
    }
    // Invalid Value 'Type'
    if (!DropletDeviceControl.isValueTypeValid(control.type, value)) {
      result.isValid = false;
      result.title = 'UnitPage.InvalidValue';
      result.message = DropletDeviceControl.isNullableType(control.type)
        ? 'UnitPage.InvalidNullOrNumericValue'
        : 'UnitPage.InvalidType';
    }
    // Invalid Value
    if (!DropletDeviceControl.isValueValid(control, value)) {
      result.isValid = false;
      result.title = 'UnitPage.InvalidValue';
      result.message = 'UnitPage.NumberOutOfRange';
    }
    BrowserLogger.log('DropletDeviceControl.checkValue', { result, value, control });
    return result;
  }

  public static checkValueWithMqtt(
    control: DeviceControlOrParam,
    value: string | number | boolean | null,
    mqttValue: string | number | null,
  ): DropletDeviceControlValueCheckResult {
    // extension of DropletDeviceControl.checkValue but with an additional MQTT value check
    const result = DropletDeviceControl.checkValue(control, value);
    // if standard checkValue is successful, check mqtt value too
    if (result.isValid) {
      // MQTT Offline check (null mqtt value but a not-nullable control)
      if (mqttValue == null && !DropletDeviceControl.isNullableType(control.type)) {
        result.isValid = false;
        result.title = 'UnitPage.DeviceOffline';
        result.message = 'UnitPage.DeviceControlOffline';
      }
    }
    BrowserLogger.log('DropletDeviceControl.checkValueWithMqtt', { result, mqttValue });
    return result;
  }
}
