import { DeviceDetails, DeviceOverview } from './device-details.model';
import { DropletControllerControlKey } from './droplet-controller-control-key.model';
import { DropletControllerParameter } from './droplet-controller-parameter.model';
import { DropletDisplay } from './droplet-display.model';
import { DropletMetric } from './droplet-metric.model';
import {
  EndpointControllerTypeDTO,
  EndpointControllerAddDTO,
  EndpointControllerUpdateDTO,
  EndpointControllerResponseDTO,
} from './../endpoint/endpoint-payload.model';
import { PermissionsService } from '@service/permissions/permissions.service';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { ApiWrapper, AvailableAPI, RequestMethod, UseHeaderType } from '@service/common/api-wrapper.service';
import { BrowserLogger } from '@class/core/browser-logger';
import { DropletControllerInventoryRequest } from './controller-inventory-request/droplet-controller-inventory-request.model';
import { DropletItemBase } from './droplet-device.model';

export enum ControllerMqttMessageType {
  CONFIGURE_CONTROLLER = 'configure_controller', // for post & put controller
  DELETE_CONTROLLER = 'delete_controller', // for removing controller
  INVENTORY = 'inventory',
}

export type ControllerInventoryMqttMsgDTO = {
  controller_version_number: number;
  controller_name: string;
  controller_unique_id: string;
  error_message: string;
};

export type RemoveControllerFromDropletParamsDTO = {
  endpoint_id: string;
  controller_unique_id: string;
};

export type DropletControllerAddUpdateEvent = {
  payload: EndpointControllerAddDTO | EndpointControllerUpdateDTO;
  droplet: DropletDisplay;
};

export class DropletController extends DropletItemBase {
  controlKeys: DropletControllerControlKey[];
  controlMetrics: DropletMetric[];
  controllerType: string;
  controllerTypeId: string;
  controllerUniqueId: string;
  controllerVersionNumber: number;
  description: string;
  deviceIds: string[];
  parameters: DropletControllerParameter[];
  statusValue: boolean;
  targetEndpointId: string;
  // non payload properties
  devices: DeviceOverview[];
  controllerExistOnDroplet: boolean;
  controllerExistInDatabase: boolean;
  controllerVersionSync: boolean;
  errorFromDroplet: string;

  public setDeviceOverviews(devices: DeviceDetails[]): void {
    this.devices = devices
      .filter((dd) => this.deviceIds?.includes(dd.deviceOverview.id))
      .map((dd) => {
        return dd.deviceOverview;
      });
    BrowserLogger.log('DropletController.generateDeviceOverviews', { self: this, devices });
  }

  public applyMqttInventoryMsg(mqttMessage: ControllerInventoryMqttMsgDTO[]): void {
    BrowserLogger.log('DropletController.applyMqttInventoryMsg', { controller: this, mqttMessage });
    // controller defaults
    this.controllerExistOnDroplet = false; // assume not on droplet (will confirm below)
    this.controllerVersionSync = false; // assume version out-of-sync (will confirm below)
    this.controllerExistInDatabase = true; // assume in database as object exists in memory
    const controllerMsg: ControllerInventoryMqttMsgDTO = mqttMessage.find(
      (msgController: ControllerInventoryMqttMsgDTO) => msgController.controller_unique_id === this.controllerUniqueId,
    );
    if (controllerMsg) {
      this.controllerExistOnDroplet = true;
      if (controllerMsg.controller_version_number === this.controllerVersionNumber) {
        this.controllerVersionSync = true;
      }
      this.errorFromDroplet = controllerMsg.error_message;
      BrowserLogger.log('DropletController.applyMqttInventoryMsg.applied', { controller: this, controllerMsg });
    }
  }

  public static existsOnDroplet(self: DropletController): boolean {
    const result = self?.controllerExistOnDroplet || false;
    BrowserLogger.log('DropletController.existsOnDroplet', { result });
    return result;
  }

  public static existsOnDatabase(self: DropletController): boolean {
    const result = self?.controllerExistInDatabase || false;
    BrowserLogger.log('DropletController.existsOnDatabase', { result });
    return result;
  }

  public static isCorrectVersion(self: DropletController): boolean {
    const result = self?.controllerVersionSync || false;
    BrowserLogger.log('DropletController.isCorrectVersion', { result });
    return result;
  }

  public static hasDropletError(self: DropletController): boolean {
    const result = self?.errorFromDroplet?.length > 0 || false;
    BrowserLogger.log('DropletController.hasDropletError', { result });
    return result;
  }

  public static canAdd(
    permissions: PermissionsService,
    controllerInventoryRequest: DropletControllerInventoryRequest,
  ): boolean {
    // can ADD controller if (1) Have Permission, (2) Controller Inventory Received
    // this is intended to be called from a DropletControllerInventoryService.state handler
    // NOTE: cloud droplets do not support inventory, hence they will also be excluded from
    //       adding as their inventory is never received

    // permission check
    let result = permissions.has(PermissionKey.UNIT_ADD_CONTROLLER) && controllerInventoryRequest?.received;
    BrowserLogger.log('DropletController.canAdd', { result, controllerInventoryRequest });
    return result;
  }

  public static canResendConfig(self: DropletController, permissions: PermissionsService): boolean {
    const result = self ? self.controllerExistInDatabase && permissions.has(PermissionKey.UNIT_SYNC_CONTROLLER) : false;
    BrowserLogger.log('DropletController.canResendConfig', { result, self });
    return result;
  }

  public static canViewControls(self: DropletController, permissions: PermissionsService): boolean {
    const result = self?.controlKeys?.length && permissions.has(PermissionKey.UNIT_VIEW_DEVICECONTROL);
    BrowserLogger.log('DropletController.canViewControls', { result, self });
    return result;
  }

  public static getMqttKeys(self: DropletController): string[] {
    const result = self ? self.controlMetrics?.map((metric: DropletMetric) => metric.metricKey) : [];
    BrowserLogger.log('DropletController.getMqttKeys', { result, controlMetrics: self?.controlMetrics });
    return result;
  }

  public static findDropletControllerByUniqueId(
    droplets: DropletDisplay[],
    dropletUuid: string,
    controllerUniqueId: string,
  ): DropletController {
    const droplet = DropletDisplay.findByUuid(droplets, dropletUuid);
    const result = DropletController.findByUniqueId(droplet.controllers, controllerUniqueId);
    BrowserLogger.log('DropletController.findDropletControllerByUniqueId', {
      result,
      droplets,
      dropletUuid,
      controllerUniqueId,
    });
    return result;
  }

  private static findByUniqueId(controllers: DropletController[], controllerUniqueId: string): DropletController {
    const result =
      controllers?.length && controllerUniqueId
        ? controllers.find((cnt) => cnt.controllerUniqueId === controllerUniqueId)
        : null;
    BrowserLogger.log('DropletController.findByUniqueId', { result, controllers, controllerUniqueId });
    return result;
  }

  public static removeFromArray(controllers: DropletController[], controllerUniqueId: string): void {
    let indexToRemove = -1;
    if (controllerUniqueId && controllers?.length) {
      indexToRemove = controllers?.findIndex((c) => {
        return c.controllerUniqueId === controllerUniqueId;
      });
    }
    if (indexToRemove >= 0) {
      controllers.splice(indexToRemove, 1);
    }
    BrowserLogger.log('DropletController.removeFromArray', { indexToRemove, controllers, controllerUniqueId });
  }

  public static async sendApiCreateRequest(
    api: ApiWrapper,
    payload: EndpointControllerAddDTO,
  ): Promise<EndpointControllerResponseDTO> {
    BrowserLogger.log('DropletController.sendApiCreateRequest', { payload });
    const response = (await api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/site-controllers/configure/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      payload,
    )) as EndpointControllerResponseDTO;
    return response;
  }

  public static async sendApiUpdateRequest(
    api: ApiWrapper,
    controller: DropletController,
    payload: EndpointControllerUpdateDTO,
  ): Promise<EndpointControllerResponseDTO> {
    BrowserLogger.log('DropletController.sendApiUpdateRequest', { payload });
    const response = (await api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/site-controllers/controllers/' + controller.id + '/',
      RequestMethod.PUT,
      UseHeaderType.AUTHORIZED_SWDIN,
      payload,
    )) as EndpointControllerResponseDTO;
    return response;
  }

  public static async sendApiSyncRequest(
    api: ApiWrapper,
    controllerId: string,
  ): Promise<EndpointControllerResponseDTO> {
    BrowserLogger.log('DropletController.sendApiSyncRequest', { controllerId });
    // return type EndpointControllerResponseDTO
    const response = (await api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/site-controllers/controllers/' + controllerId + '/sync/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    )) as EndpointControllerResponseDTO;
    return response;
  }

  public static async sendApiRemoveFromDropletRequest(
    api: ApiWrapper,
    data: RemoveControllerFromDropletParamsDTO,
  ): Promise<unknown> {
    // Remove Controller API - remove from DROPLET ONLY!
    // NOTE: The API will also trigger an MQTT Inventory Update after successful completion
    BrowserLogger.log('DropletController.sendApiRemoveFromDropletRequest', { data });
    return api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/site-controllers/delete-message/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }

  public static async sendApiRemoveRequest(api: ApiWrapper, controllerId: string): Promise<void> {
    // Remove Controller API - remove from both DROPLET and DATABASE
    // NOTE: The API will also trigger an MQTT Inventory Update after successful completion
    BrowserLogger.log('DropletController.sendApiRemoveRequest', { controllerId });
    await api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/site-controllers/controllers/' + controllerId + '/',
      RequestMethod.DELETE,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  public static getControllerType(
    controllerTypeId: string,
    controllerTypeList: EndpointControllerTypeDTO[],
  ): EndpointControllerTypeDTO {
    const result = controllerTypeList.find((c) => {
      return c.id === controllerTypeId;
    });
    BrowserLogger.log('DropletController.getControllerType', { result, controllerTypeId, controllerTypeList });
    return result;
  }

  public static containsDeviceId(controller: DropletController, deviceId: string): boolean {
    const result = controller?.deviceIds?.includes(deviceId);
    BrowserLogger.log('DropletController.containsDeviceId', { result, controller, deviceId });
    return result;
  }

  public static hasValidIec61850DeviceTypeId(
    controllerType: EndpointControllerTypeDTO,
    deviceDetails: DeviceDetails,
  ): boolean {
    const result = controllerType?.valid_iec61850_device_type_ids.find(
      (id) => id === deviceDetails.deviceOverview.iec61850DeviceTypeId,
    )
      ? true
      : false;
    BrowserLogger.log('DropletController.hasValidIec61850DeviceTypeId', {
      result,
      deviceDetails,
      controllerType,
    });
    return result;
  }

  public static hasValidLegacyDeviceTypeId(
    controllerType: EndpointControllerTypeDTO,
    deviceDetails: DeviceDetails,
  ): boolean {
    const result = controllerType?.valid_legacy_device_type_ids.find(
      (id) => id === deviceDetails.deviceOverview.deviceTypeId,
    )
      ? true
      : false;
    BrowserLogger.log('DropletController.hasValidLegacyDeviceTypeId', {
      result,
      deviceDetails,
      controllerType,
    });
    return result;
  }

  public static getMissingMqttControllers(
    mqttMessage: ControllerInventoryMqttMsgDTO[],
    existingControllers: DropletController[],
  ): DropletController[] {
    const result: DropletController[] = [];
    // look for controllers that are in the droplet mqtt message
    // but do not exist in the database, adding them and
    // flag the droplet as having controllers out-of-sync
    mqttMessage.forEach((controllerMsg: any) => {
      const existingController = existingControllers.find(
        (controller) => controller.controllerUniqueId === controllerMsg.controller_unique_id,
      );
      if (!existingController) {
        // setup the missing controller
        const missingController = new DropletController();
        missingController.name = controllerMsg.controller_name
          ? controllerMsg.controller_name
          : controllerMsg.controller_unique_id;
        missingController.controllerUniqueId = controllerMsg.controller_unique_id;
        missingController.controllerExistInDatabase = false;
        missingController.controllerExistOnDroplet = true;
        missingController.controllerVersionSync = true;
        // add to controllers list
        result.push(missingController);
      }
    });
    BrowserLogger.log('DropletController.getMissingMqttControllers', { result, existingControllers });
    return result;
  }
}
