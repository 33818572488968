import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AddDeviceStore } from '../store/add-device.store';
import { BehaviorSubject, Observable, combineLatestWith, map } from 'rxjs';
import { ButtonType } from 'app/components/buttons/button/button.component';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { isEmpty, keyBy } from 'lodash';
import { InventoryDevicesListToDisplay, DropletLayoutForDiscovery, DropletLayoutPort } from '../utils/add-device-types';
import { BrowserLogger } from '@class/core/browser-logger';
import { EndpointDeviceDTO } from '@class/units/endpoint/endpoint-payload.model';
import { produce } from 'immer';

@Component({
  selector: 'app-inventory-devices',
  templateUrl: './inventory-devices.component.html',
  styleUrls: ['./inventory-devices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryDevicesComponent {
  readonly ButtonType = ButtonType;
  readonly PermissionKey = PermissionKey;
  readonly isEmpty = isEmpty;

  #endpointDevices = new BehaviorSubject<EndpointDeviceDTO[]>([]);

  @Input() set endpointDevices(value: EndpointDeviceDTO[]) {
    this.#endpointDevices.next(value);
  }

  configuredDevices$: Observable<InventoryDevicesListToDisplay[]>;
  discoveredDevices$: Observable<InventoryDevicesListToDisplay[]>;
  staticConfiguredDevices$: Observable<InventoryDevicesListToDisplay[]>;
  initialisedDevices$: Observable<InventoryDevicesListToDisplay[]>;
  dropletAvailablePortsToDiscoverDevice$: Observable<DropletLayoutForDiscovery>;
  dropletAllInterfacesPortsToDiscoverDevice$: Observable<DropletLayoutPort>;

  constructor(private _addDeviceStore: AddDeviceStore) {
    this.configuredDevices$ = this._addDeviceStore.configuredDevices$.pipe(
      combineLatestWith(this.#endpointDevices.asObservable()),
      map(([configuredDevices, endpointDevices]) => {
        return produce(configuredDevices, (devices) => {
          const endpointDevicesMap = keyBy(endpointDevices, 'droplet_device_unique_id');

          for (const device of devices) {
            const endpointDevice = endpointDevicesMap[device.device_unique_id];
            if (endpointDevice) {
              device.deviceId = endpointDevice.id;
              device.deviceUuid = endpointDevice.uuid;
            }
          }
        });
      }),
    );
    this.discoveredDevices$ = this._addDeviceStore.discoveredDevices$;
    this.staticConfiguredDevices$ = this._addDeviceStore.staticConfiguredDevices$;
    this.initialisedDevices$ = this._addDeviceStore.initialisedDevices$;
    this.dropletAvailablePortsToDiscoverDevice$ = this._addDeviceStore.dropletAvailablePortsToDiscoverDevice$;
    this.dropletAllInterfacesPortsToDiscoverDevice$ = this._addDeviceStore.dropletAllInterfacesPortsToDiscoverDevice$;
  }

  async onRemoveDeviceHandler(device: InventoryDevicesListToDisplay): Promise<void> {
    BrowserLogger.log('delete device: ', device);

    this._addDeviceStore.removeDevice({
      deviceId: device.deviceId,
      deviceUniqueId: device.device_unique_id,
      showAlerts: true,
    });
  }

  onConfigureDeviceHandler(device: InventoryDevicesListToDisplay): void {
    BrowserLogger.log('device: ', device);

    if (!isEmpty(device.deviceConfigAttributes)) {
      this._addDeviceStore.showDeviceSettingsForConfiguration(device);
    } else {
      this._addDeviceStore.configureDiscoveredDevice(device);
    }
  }

  onDiscoverDeviceButtonHandler(): void {
    this._addDeviceStore.showDiscoverDeviceView();
  }

  onAllInterfacesDiscoverDeviceButtonHandler(port: DropletLayoutPort): void {
    this._addDeviceStore.selectPortAndUpdateTheViewToShowManufacturers(port);
  }
}
