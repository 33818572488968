import { InstallerContentView } from '@class/commons/enums';
import { createAction, props } from '@ngrx/store';

// expect droplet uuid in full format
// e.g. 00000000-0000-0000-0000-b827eb46dea1
export const checkDropletIsClaimed = createAction(
  '[Installer] Check Droplet Is Claimed',
  props<{ dropletUuid: string }>(),
);

export const setDropletIsClaimed = createAction(
  '[Installer] Set Droplet Is Claimed',
  props<{
    isDropletClaimed: boolean | null;
    isUserHasPermission: boolean | null;
  }>(),
);

export const setIdentifyTheDropletViewLoadingState = createAction(
  '[Installer] Set Identify The Droplet View Loading State',
  props<{ isLoading: boolean; isError: boolean; error: Error | null }>(),
);

export const setInstallerContentView = createAction(
  '[Installer] Set Installer Content View',
  props<{ contentView: InstallerContentView }>(),
);

export const setDropletDoesNotExist = createAction(
  '[Installer] Set Droplet Does Not Exist',
  props<{ dropletDoesNotExist: boolean }>(),
);

export const setIsDropletConnectedToInternet = createAction(
  '[Installer] Set Droplet Connected To Internet',
  props<{ isDropletConnectedToInternet: boolean }>(),
);

export const establishConnectionWithDropletOverMqtt = createAction(
  '[Installer] Establish Connection With Droplet Over MQTT',
  props<{ dropletUuid: string }>(),
);

export const resetInstallerState = createAction('[Installer] Reset Installer State');
