<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>{{ 'General.UserSettings' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-segment
    mode="ios"
    [value]="isProfile ? 'profile' : 'transactions'"
    *ngIf="(profile$ | async)?.permissions?.transaction"
    class="m-a mt-10"
    [swipeGesture]="false"
  >
    <ion-segment-button value="profile" (click)="goToProfileTab(true)">
      <ion-label>{{ 'ProfilePage.YourProfile' | translate }}</ion-label>
    </ion-segment-button>
    <ion-segment-button value="transactions" (click)="goToProfileTab(false)">
      <ion-label>{{ 'General.FinancialAccount' | translate }}</ion-label>
    </ion-segment-button>
  </ion-segment>
</ion-header>

<ion-content>
  <app-profile-component
    *ngIf="isProfile || !(profile$ | async)?.permissions?.transaction; else transactions"
    id="profile"
  ></app-profile-component>
  <ng-template #transactions>
    <app-transactions id="transaction"></app-transactions>
  </ng-template>
</ion-content>
