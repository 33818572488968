import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IsPlatformValues } from '@class/commons/is-platform-values';
import { BehaviorSubject, defer, Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { FacadeStates } from '../../../classes/commons/facade-states';
import { ApiWrapper, AvailableAPI, RequestMethod, UseHeaderType } from '../../common/api-wrapper.service';

interface UnitFinancialAccountState {
  isSetup: boolean | undefined;
  email?: string | undefined;
  status: FacadeStates;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class UnitFinancialAccountFacadeService extends IsPlatformValues {
  public isAccountSetup$: Observable<UnitFinancialAccountState> = defer(() => this.getStripeAccountStatus()).pipe(
    map((res) => this.adaptToAccountStatus(res)),
    startWith({
      isSetup: undefined,
      status: FacadeStates.LOADING,
      message: null,
    }),
    catchError((err) => this.catchAccountStatusApiError(err)),
  );

  public isStripeLinkLoading$ = new BehaviorSubject<boolean>(false);

  constructor(private api: ApiWrapper) {
    super();
  }

  private async getStripeAccountStatus(): Promise<{ account_setup: boolean; stripe_email: string }> {
    const res = (await this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      `/api/v1/payments/accounts/status/`,
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
    )) as { data: { account_setup: boolean; stripe_email: string } };
    return res?.data;
  }

  private catchAccountStatusApiError(err: any): Observable<UnitFinancialAccountState> {
    const message = err instanceof HttpErrorResponse ? `${err.status} ${err.statusText}` : err;
    return of({
      isSetup: undefined,
      status: FacadeStates.ERROR,
      message,
    });
  }

  private adaptToAccountStatus(data: {
    account_setup: boolean;
    stripe_email: string | null;
  }): UnitFinancialAccountState {
    if (data?.account_setup == null) {
      throw Error('Error: unknown account status');
    }
    const { account_setup, stripe_email } = data;
    return {
      isSetup: account_setup,
      email: stripe_email,
      status: FacadeStates.COMPLETE,
      message: null,
    };
  }

  public async getStripeLink(): Promise<void> {
    this.isStripeLinkLoading$.next(true);
    try {
      const link = await this.stripeSetupLink();
      if (link == null) return Promise.reject('Unable to receive setup link');
      window.open(link, '_self');
      setTimeout(() => {
        this.isStripeLinkLoading$.next(false);
      }, 20000);
    } catch (err) {
      this.isStripeLinkLoading$.next(false);
      throw Error(err);
    }
  }
  public async stripeSetupLink(): Promise<string> {
    // since window.location.href doesnt work for mobile phones, we'll use the fixed pwa domain
    const referralURL = this.IS_PLATFORM_CORDOVA
      ? 'https://pwa.switchdin.com' + window.location.pathname
      : window.location.href;
    const res = (await this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      `/api/v1/payments/accounts/stripe-payee-setup-redirect/`,
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      { redirect_url: referralURL },
    )) as { data: { url: string } };
    return res.data.url;
  }
}
