import { Component } from '@angular/core';
import { IsPlatformValues } from '@class/commons/is-platform-values';
import { GenericAlertsToastsService } from '@service/common/generic-alerts-toasts.service';
import { StorageService } from '../../services/common/storage.service';
import { TranslationsService } from '../../services/common/translations.service';

const MIN_DISPLAY_DATE = new Date('August 22, 2022 00:00:00');
const MAX_DISPLAY_DATE = new Date('August 31, 2022 00:00:00');
const MSG_CHANGE_OVER_DATE = new Date('September 07, 2022 00:00:00');
const LAST_DISPLAYED_STORAGE_KEY = 'LastDisplayedNotificationAlert';
const MIN_DAYS_TILL_NEXT_MESSAGE = 1;
const ANDROID_APP_LINK =
  "<a href='https://play.google.com/store/apps/details?id=com.switchdin.mobile.ionic&hl=en&gl=US' target='_blank'>Stormcloud by SwitchDin</a>";
const IOS_APP_LINK =
  "<a href='https://apps.apple.com/au/app/switchdin/id1474588735' target='_blank'>Stormcloud by SwitchDin</a>";
@Component({
  selector: 'app-notification-alert',
  templateUrl: './notification-alert.component.html',
  styleUrls: ['./notification-alert.component.scss'],
})
export class NotificationAlertComponent extends IsPlatformValues {
  constructor(
    private storageService: StorageService,
    private trans: TranslationsService,
    private genericAlertsToastsService: GenericAlertsToastsService,
  ) {
    super();
    this.showNotificationAsRequired();
  }

  private async showNotificationAsRequired(): Promise<void> {
    if (await this.canPresentNotification()) {
      await this.presentNotification();
      await this.setLastDisplayedDateInSession();
    }
  }

  private async canPresentNotification(): Promise<boolean> {
    const dateNow = new Date();
    const lastDisplayDate = await this.getLastDisplayedDateFromSession();
    const isMinIntervalSinceLastMessage = lastDisplayDate
      ? lastDisplayDate.setDate(lastDisplayDate.getDate() + MIN_DAYS_TILL_NEXT_MESSAGE) <= dateNow.getTime()
      : true;
    return dateNow >= MIN_DISPLAY_DATE && dateNow <= MAX_DISPLAY_DATE && isMinIntervalSinceLastMessage;
  }

  private async presentNotification(): Promise<void> {
    const { header, message } = this.getNotificationAlertHeaderAndMessage();

    const alert = await this.genericAlertsToastsService.createNotificationAlertWithOkButton(header, '', message);
    await alert.present();
  }

  private getNotificationAlertHeaderAndMessage(): { header: string; message: string } {
    const dateNow = new Date();
    const { header, message } = dateNow >= MSG_CHANGE_OVER_DATE ? this.getAppRetiredMsg() : this.getAppRetiringMsg();
    const msgWithLink = message + (this.IS_PLATFORM_ANDROID ? ANDROID_APP_LINK : IOS_APP_LINK);
    return { header, message: msgWithLink };
  }
  private getAppRetiringMsg(): { header: string; message: string } {
    const { Header, Message } = this.trans.instant('NotificationAlert.AppRetiring');
    const header = Header,
      message = Message;

    return { header, message };
  }
  private getAppRetiredMsg(): { header: string; message: string } {
    const { Header, Message } = this.trans.instant('NotificationAlert.AppRetired');
    const header = Header,
      message = Message;
    return { header, message };
  }

  private async getLastDisplayedDateFromSession(): Promise<Date> {
    return await this.storageService.getFromLocalOrSessionStorage(LAST_DISPLAYED_STORAGE_KEY);
  }
  private async setLastDisplayedDateInSession(): Promise<void> {
    await this.storageService.setLocalStorageItem(LAST_DISPLAYED_STORAGE_KEY, new Date());
  }
}
