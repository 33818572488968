import { Injectable } from '@angular/core';
import { LegacyDeviceType } from '../utils/add-device-types';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Observable, combineLatest, map, switchMap, tap } from 'rxjs';
import { LegacyDeviceService } from '../services/legacy-device.service';
import { UnitsService } from '@service/units/units.service';
import { BrowserLogger } from '@class/core/browser-logger';

interface LegacyDevice {
  device_type_id: string;
  endpoint_id: string;
}

interface LegacyDeviceState {
  legacyDevices: LegacyDeviceType[];
  selectedLegacyDevice?: LegacyDevice;
  legacyDeviceFilterText: string;
  dropletId: string;
}

const defaultState: LegacyDeviceState = {
  legacyDevices: [],
  legacyDeviceFilterText: '',
  dropletId: '',
};

@Injectable()
export class LegacyDeviceStore extends ComponentStore<LegacyDeviceState> {
  constructor(
    private _legacyDeviceService: LegacyDeviceService,
    private _unitsService: UnitsService,
  ) {
    super(defaultState);
    this.getLegacyDevices();
    this.resetSelectedLegacyDeviceIfNotInList();
  }

  private readonly filterText$ = this.select((state) => state.legacyDeviceFilterText);

  private readonly legacyDevices$ = this.select((state) => state.legacyDevices);

  private readonly filteredLegacyDevices$ = this.select(
    this.legacyDevices$,
    this.filterText$,
    (legacyDevices, filterText) =>
      legacyDevices.filter((device) => device.name.toLowerCase().includes(filterText.toLowerCase())),
  );

  /** ** ** ** ** ** SELECTORS ** ** ** ** ** */
  readonly legacyDevicesData$: Observable<{ legacyDevices: LegacyDeviceType[]; legacyDeviceFilterText: string }> =
    this.select(this.filteredLegacyDevices$, this.filterText$, (legacyDevices, legacyDeviceFilterText) => ({
      legacyDevices,
      legacyDeviceFilterText,
    }));

  readonly selectedLegacyDevice$: Observable<LegacyDevice> = this.select((state) => state.selectedLegacyDevice);

  /** ** ** ** ** ** PUBLIC EFFECTS ** ** ** ** ** */
  readonly getDropletDetails = this.effect((uuid$: Observable<string>) => {
    return uuid$.pipe(
      switchMap((uuid) => {
        return this._unitsService.endpointsReady$.pipe(
          map((dropletsData): string => {
            BrowserLogger.log('dropletsData', dropletsData);

            const droplet = dropletsData?.endpoints.find((endpoint) => endpoint.long_uuid === uuid);

            return droplet?.id;
          }),
          tapResponse(
            (res) => {
              this.setDropletDetail(res);
            },
            (error) => console.log('error', error),
          ),
        );
      }),
    );
  });
  readonly updateLegacyDeviceFilterTextAndFilteredLegacyDevices = this.effect((filteredText$: Observable<string>) => {
    return filteredText$.pipe(
      tap((text: string) => {
        this.searchLegacyDevices(text);
      }),
    );
  });
  readonly updateSelectedDevice = this.effect((deviceId$: Observable<string>) => {
    return deviceId$.pipe(
      tap((deviceId: string) => {
        this.setSelectedLegacyDevice(deviceId);
      }),
    );
  });
  readonly addLegacyDeviceType = this.effect((selectedLegacyDevice$: Observable<LegacyDevice>) => {
    return selectedLegacyDevice$.pipe(
      switchMap((selectedLegacyDevice) => {
        return this._legacyDeviceService.addLegacyDeviceType(selectedLegacyDevice).pipe(
          tapResponse(
            () => {
              this._unitsService.getEndpointsOfSelectedUnit();
            },
            () => {},
          ),
        );
      }),
    );
  });

  /** ** ** ** ** ** PRIVATE EFFECTS ** ** ** ** ** */
  private readonly getLegacyDevices = this.effect<void>((trigger$) => {
    return trigger$.pipe(
      switchMap(() =>
        this._legacyDeviceService.getLegacyDeviceTypes().pipe(
          tapResponse(
            (res) => {
              this.setLegacyDevicesAndFilteredLegacyDevices(res);
            },
            () => {},
          ),
        ),
      ),
    );
  });

  private readonly resetSelectedLegacyDeviceIfNotInList = this.effect(() => {
    return combineLatest([this.filteredLegacyDevices$, this.selectedLegacyDevice$]).pipe(
      tap<[LegacyDeviceType[], typeof defaultState.selectedLegacyDevice]>(
        ([filteredLegacyDevices, selectedLegacyDevice]) => {
          if (
            selectedLegacyDevice &&
            filteredLegacyDevices.findIndex((device) => device.id === selectedLegacyDevice.device_type_id) < 0
          ) {
            this.patchState({ selectedLegacyDevice: defaultState.selectedLegacyDevice });
          }
        },
      ),
    );
  });

  /** ** ** ** ** ** PUBLIC UPDATERS ** ** ** ** ** */
  readonly resetSelectedLegacyDevice = this.updater((state) => ({
    ...state,
    selectedLegacyDevice: defaultState.selectedLegacyDevice,
  }));

  readonly resetSearch = this.updater((state) => ({
    ...state,
    legacyDeviceFilterText: '',
  }));

  /** ** ** ** ** ** PRIVATE UPDATERS ** ** ** ** ** */
  private readonly setDropletDetail = this.updater((state, dropletId: string) => {
    return {
      ...state,
      dropletId,
    };
  });

  private readonly searchLegacyDevices = this.updater((state, filterText: string) => {
    // check if the selected device doesn't exist in the filtered list
    // then empty the selected device
    return {
      ...state,
      legacyDeviceFilterText: filterText.trim(),
    };
  });

  private setSelectedLegacyDevice = this.updater((state, deviceId: string) => {
    const device = state.legacyDevices.find((legacyDevice) => legacyDevice.id === deviceId);
    return {
      ...state,
      selectedLegacyDevice: {
        device_type_id: device.id,
        endpoint_id: state.dropletId,
      },
    };
  });

  private readonly setLegacyDevicesAndFilteredLegacyDevices = this.updater(
    (state, legacyDevices: LegacyDeviceType[]) => {
      return {
        ...state,
        legacyDevices,
      };
    },
  );
}
