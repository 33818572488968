/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// the interface ControlValueAccessor has functions that accepts any
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Directive, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, ValidationErrors, Validators } from '@angular/forms';

export type InputSelectOptions = {
  name: number | string;
  value: number | string;
};

enum InputTypes {
  DATE = 'date',
  DATETIME_LOCAL = 'datetime-local',
  EMAIL = 'email',
  MONTH = 'month',
  NUMBER = 'number',
  PASSWORD = 'password',
  SEARCH = 'search',
  TEL = 'tel',
  TEXT = 'text',
  TIME = 'time',
  URL = 'url',
  WEEK = 'week',
}

// Need to export this constant when we need to force set the [invalid] property via the [status].
// This is a hack until the [dirty] attribute is working correctly.
// Once resolved this export can be removed and any references fixed.
export const INPUT_STATUS_INVALID = 'INVALID';

@Directive()
export class InputsConfig implements ControlValueAccessor, Validators {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() ionItemExtraCss: string;
  @Input() inputType: InputTypes = InputTypes.TEXT;
  @Input() isDisable: boolean = false;
  @Input()
  get status(): string {
    return this._status;
  }
  set status(status: string) {
    this._status = status;
    this.invalid = status === INPUT_STATUS_INVALID;
  }
  private _status: string;

  @Input()
  get errors(): ValidationErrors | null {
    return this._errors;
  }
  set errors(errors: ValidationErrors | null) {
    this._errors = errors;
  }
  private _errors: ValidationErrors | null;

  @Input()
  get value(): string | number {
    return this._value;
  }
  set value(value: string | number) {
    this._value = value;
    this.onChange(value);
  }
  private _value: string | number;

  private _invalid: boolean;
  public get invalid(): boolean {
    return this._invalid;
  }
  public set invalid(value: boolean) {
    this._invalid = value;
  }

  private _dirty: boolean;
  public get dirty(): boolean {
    return this._dirty;
  }
  public set dirty(value: boolean) {
    this._dirty = value;
  }

  private _touched: boolean;
  public get touched(): boolean {
    return this._touched;
  }
  public set touched(value: boolean) {
    this._touched = value;
  }

  public writeValue(value: string | number): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private validate(control: AbstractControl): ValidationErrors | null {
    // extra validate check goes in here
    return null;
  }

  public ionBlur(): void {
    this.onTouched();
    this.touched = true;
  }

  public ionChange(): void {
    this.dirty = true;
  }
}
