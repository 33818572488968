<ng-container
  *ngrxLet="{deviceConfigView: deviceConfigView$, selectedLegacyDevice: selectedLegacyDevice$, backButton: backButton$, discoveryDeviceForConfiguration: discoveryDeviceForConfiguration$, endpointDevices: endpointDevices$} as vm"
>
  <ion-header class="ion-no-border">
    <ion-toolbar class="bkg-theme">
      <ion-button color="button-primary" (click)="vm.backButton.onClick()" slot="start" fill="clear">
        <ion-icon slot="icon-only" name="chevron-back-sharp"></ion-icon>
        {{ vm.backButton.tx | translate }}
      </ion-button>
      <ng-container *ifHasPermission="[PermissionKey.GLOBAL_STAFF]">
        <ion-button
          (click)="showLegacyDevices()"
          class="ion-text-capitalize text-lg"
          slot="end"
          fill="clear"
          color="button-primary"
          *ngIf="vm.deviceConfigView !== DeviceConfigScreen.LEGACY_DEVICES && vm.deviceConfigView !== DeviceConfigScreen.MANUFACTURERS_LIST"
        >
          {{ 'AddDevice.Buttons.AddLegacyDevice' | translate }}
        </ion-button>
      </ng-container>
    </ion-toolbar>
  </ion-header>
  <ion-content class="bkg-theme">
    <ion-grid class="p-0">
      <!-- Droplet Connection loader -->
      <ion-row *ngIf="vm.deviceConfigView === DeviceConfigScreen.WAITING_FOR_INITIAL_INVENTORY">
        <ion-col size="12" class="ion-text-center mt-35">
          <ion-spinner duration="1500" name="crescent" color="primary-svg"></ion-spinner>
        </ion-col>
        <ion-col size="12" class="ion-text-center">
          <ion-text class="text-primary-xl">{{ 'AddDevice.ConnectingToDroplet' | translate }}</ion-text>
        </ion-col>
      </ion-row>

      <!-- Droplet Connection Error -->
      <ng-container *ngIf="vm.deviceConfigView === DeviceConfigScreen.INITIAL_INVENTORY_NOT_RECEIVED">
        <app-troubleshoot-card
          [error]="(addDeviceError$ | async).initialInventory"
          (onRetryClick)="onRetryButtonHandler()"
        ></app-troubleshoot-card>
      </ng-container>

      <!-- Droplet Devices -->
      <ion-row *ngIf="vm.deviceConfigView === DeviceConfigScreen.INITIAL_INVENTORY_RECEIVED">
        <ion-col size="12">
          <!-- list of all inventory devices (configured, discovered, etc.) -->
          <app-inventory-devices [endpointDevices]="vm.endpointDevices"></app-inventory-devices>
        </ion-col>
      </ion-row>

      <!-- Droplet Discover a Device - droplet port layout -->
      <ion-row *ngIf="vm.deviceConfigView === DeviceConfigScreen.DROPLET_IMAGE_PORT_LAYOUT">
        <ion-col size="12">
          <app-droplet-port-layout></app-droplet-port-layout>
        </ion-col>
      </ion-row>

      <!-- Droplet Discover a Device - Discoverable Devices List -->
      <ion-row
        *ngIf="vm.deviceConfigView === DeviceConfigScreen.MANUFACTURERS_LIST
        || vm.deviceConfigView === DeviceConfigScreen.SELECTED_MANUFACTURER_DEVICES
        || vm.deviceConfigView === DeviceConfigScreen.SELECTED_DEVICE_DISCOVERY_ATTRIBUTES"
      >
        <ion-col size="12">
          <app-discoverable-devices-list></app-discoverable-devices-list>
        </ion-col>
      </ion-row>

      <!-- Legacy Devices -->
      <ion-row *ngIf="vm.deviceConfigView === DeviceConfigScreen.LEGACY_DEVICES">
        <ion-col size="12">
          <app-legacy-devices></app-legacy-devices>
        </ion-col>
      </ion-row>

      <!-- Discovered Device Configuration Settings -->
      <ion-row *ngIf="vm.deviceConfigView === DeviceConfigScreen.DEVICE_SETTINGS_FOR_CONFIGURATION">
        <ion-col size="12">
          <app-device-configuration-settings
            [device]="vm.discoveryDeviceForConfiguration"
          ></app-device-configuration-settings>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
  <ion-footer *ngIf="vm.deviceConfigView === DeviceConfigScreen.LEGACY_DEVICES" class="ion-no-border">
    <ion-toolbar class="bkg-theme">
      <ion-row>
        <ion-col size="12" class="ion-padding">
          <app-button
            label="{{ 'AddDevice.Buttons.AddLegacyDevice' | translate }}"
            [isDisabled]="isEmpty(vm.selectedLegacyDevice)"
            (onClick)="onAddLegacyDeviceHandler(vm.selectedLegacyDevice)"
          ></app-button>
        </ion-col>
      </ion-row>
    </ion-toolbar>
  </ion-footer>
</ng-container>
