import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { provideQueryDevTools } from '@ngneat/query-devtools';

import { MqttModule, MqttServiceConfig, MqttClientService } from 'ngx-mqtt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PortfoliosService } from './services/portfolios/portfolios.service';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader, TranslateCompiler } from '@ngx-translate/core';

import { LoadConfigurationService } from './services/common/load-configuration.service';
import { IonicStorageModule } from '@ionic/storage-angular';
import * as Sentry from '@sentry/angular';
import { SharedModule } from './app.shared.module';
import { STORAGE_NAME, DRIVER_ORDER } from './shared-test-modules';
import { AddDeviceModalPageModule } from './pages/modals/add-device-configuration/add-device-modal/add-device-modal.module';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { QueryClient, provideQueryClient } from '@ngneat/query';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appStateReducers } from '@app-state/index';
import { EffectsModule } from '@ngrx/effects';
import * as UserOrgMembershipEffects from '@app-state/user/user-organisation-membership/user-organisation-membership.effects';

import {
  OpenTelemetryInterceptorModule,
  OtelColExporterModule,
  W3CTraceContextPropagatorModule,
} from '@jufab/opentelemetry-angular-interceptor';

export function createTranslationLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function delayBootstrapping(loadConfig: LoadConfigurationService) {
  return () => loadConfig.initialise();
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30000,
    },
  },
});

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      innerHTMLTemplatesEnabled: true,
    }),
    IonicStorageModule.forRoot({ name: STORAGE_NAME, driverOrder: DRIVER_ORDER }),
    StoreModule.forRoot(appStateReducers),
    EffectsModule.forRoot([UserOrgMembershipEffects]),
    AppRoutingModule,
    SharedModule,
    MqttModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.serviceWorker,
      // enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslationLoader,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    AddDeviceModalPageModule,
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: !environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: true, // If set to true, the connection is established within the Angular zone
    }),
    OpenTelemetryInterceptorModule.forRoot({
      commonConfig: {
        console: !environment.production, // Display trace on console (only in DEV env)
        production: environment.production, // Send Trace with BatchSpanProcessor (true) or SimpleSpanProcessor (false)
        serviceName: 'stormcloud-pwa', // Service name send in trace
        probabilitySampler: '1',
        resourceAttributes: {
          'service.environment': environment.name,
          'service.version': environment.version,
        },
      },
      otelcolConfig: {
        url: `${environment.services.honeycomb.endpoint}/v1/traces`, // URL of opentelemetry collector
      },
      ignoreUrls: {
        urls: [/api\.ipify\.org/, /api\.openweathermap\.org/, /192\.168\.44\.1:88\/api/],
      },
    }),
    OtelColExporterModule,
    W3CTraceContextPropagatorModule,
  ],
  providers: [
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: delayBootstrapping,
      deps: [LoadConfigurationService, Sentry.TraceService],
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    PortfoliosService,
    // ngx-mqtt providers (required for manual connection)
    { provide: MqttServiceConfig, useValue: { connectOnCreate: false } },
    { provide: MqttClientService, useValue: undefined },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    provideQueryClient(queryClient as any),
    ...(environment.production ? [] : [provideQueryDevTools()]),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
