import { Injectable } from '@angular/core';
import { Common } from '../../classes/commons/common';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { STORAGE_CONSTANTS } from '../../classes/commons/constants';
import { Router } from '@angular/router';
import { IsPlatformValues } from '@class/commons/is-platform-values';
import { IsPlatform } from '@class/commons/is-platform';
import { Platform } from '@ionic/angular';
import { BrowserLogger } from '@class/core/browser-logger';
import { Observable } from 'rxjs';
import { tap, catchError, map, switchMap, take } from 'rxjs/operators';

export interface ObservableRequestConfig {
  useAPI: AvailableAPI;
  url: string;
  requestMethod: RequestMethod;
  useHeader: UseHeaderType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestData?: any;
  disableURIEncoding?: boolean;
}

// the response have a magic string attached to it, in the start
// for what reasons? maybe security
// so have to remove that to get the JSON response
const switchdinApiResponseHandler = (response: unknown) => {
  // if the response is already in JSON format then just return that
  let parsedResponse = response;
  if (typeof response == 'string' && response.length > 4) {
    parsedResponse = JSON.parse(response.substring(response.indexOf('\n')));
  }
  return { data: parsedResponse };
};

const switchdinApiResponseHandler2 = (response: unknown) => {
  // if the response is already in JSON format then just return that
  let parsedResponse = response;
  if (typeof response == 'string' && response.length > 4) {
    parsedResponse = JSON.parse(response.substring(response.indexOf('\n')));
  }
  return parsedResponse;
};

export enum RequestMethod {
  GET,
  POST,
  PUT,
  DELETE,
  PATCH,
}

export enum AvailableAPI {
  // devops or prod
  SWITCHDIN,
  // droplet link
  DROPLET,
  // open weather
  WEATHER,
  // to get the ip
  CLIENT_IP,
}

export enum UseHeaderType {
  // switchdin api's that don't need token in them
  // like auth-token, register, refresh, etc
  // weather api & client ip api don't need any extra thing in headers
  // API's
  // api-token-auth, api-token-refresh, register, reset-password
  // THESE ONE's as well
  // api.ipify.org
  DEFAULT,

  // normal auth header that will have toekn in them
  // ALL OTHER API's
  AUTHORIZED_SWDIN,

  // api's to talk to droplet to set wifi password
  // that doesn't require any auth token or something
  // API's:
  // enable_wifi, get_ssid, rescan_wifi
  DROPLET_SWDIN,

  // empty headers just in case if we don't wanted to send any headers
  // for some particular apis
  // i have mentioned the weather and client api for default
  // but not sure they'll take default or empty headers
  // these ONE's
  // openweathermap
  // Have to set empty headers for weather, otherwise we will get some CORS issue...
  EMPTY,
}

export class Request {
  constructor(
    public url: string,
    public header: Record<string, string> | HttpHeaders,
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class ApiWrapper extends IsPlatformValues {
  private common: Common;

  public constructor(
    private angularHttp: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private platform: Platform,
  ) {
    super();
    IsPlatform.setPlatformValues(this.platform);
    this.common = new Common();
  }

  public async handleRequest<T = unknown>(
    useAPI: AvailableAPI,
    url: string,
    requestMethod: RequestMethod,
    useHeader: UseHeaderType,
    requestData = {},
  ): Promise<{ data: T }> {
    // need to do this for IOS: iOS native doesn't handle spaces in URL's and treats it as a bad URL
    // hopefully that'll be fixed when we ditch the native http lib and use the angular http lib
    const encodedURL = encodeURI(url);

    const request: Request = await this.constructRequest(useAPI, encodedURL, useHeader);

    const requestMethodString = RequestMethod[requestMethod];

    return new Promise((resolve, reject) => {
      this.angularHttp
        .request(requestMethodString, request.url, {
          // checking for GET request to not include the body, this is needed for OpenWeather API etc.
          ...(requestMethod > 0 ? { body: requestData } : {}),
          headers: request.header,
          responseType: 'json',
        })
        .subscribe({
          next: (response: unknown) => {
            BrowserLogger.log(`ApiWrapper.handle${requestMethodString}.response`, {
              url: request.url,
              response,
              requestData,
            });
            resolve(switchdinApiResponseHandler(response) as { data: T });
          },
          error: (error) => {
            BrowserLogger.error(`ApiWrapper.handle${requestMethodString}`, { url: request.url, error, requestData });
            reject(error);
            this.handleErrors(error);
          },
        });
    });
  }

  private async constructRequest(api: AvailableAPI, url: string, useHeader: UseHeaderType) {
    return new Request(this.constructURL(api, url), await this.constructHeader(useHeader));
  }

  private constructURL(api: AvailableAPI, url: string) {
    let constructedURL = '';
    switch (api) {
      case AvailableAPI.SWITCHDIN:
        constructedURL = this.common.$baseURL;
        break;
      case AvailableAPI.DROPLET:
        constructedURL = this.common.$dropletAPIURL;
        break;
      case AvailableAPI.WEATHER:
        constructedURL = this.common.$weatherAPIURL;
        break;
      case AvailableAPI.CLIENT_IP:
        constructedURL = '';
        break;
    }

    if (url.toLowerCase().startsWith(constructedURL.toLowerCase())) {
      return url;
    }

    return constructedURL + url;
  }
  private async constructHeader(useHeader: UseHeaderType) {
    let headers = new HttpHeaders();
    switch (useHeader) {
      case UseHeaderType.AUTHORIZED_SWDIN:
        headers = headers.set('Content-Type', 'application/json');
        let token: string;
        try {
          token = await this.storageService.getFromLocalOrSessionStorage(STORAGE_CONSTANTS.AUTH_TOKEN);
        } catch (error) {
          console.error(`Error getting token to construct header: ${error}`);
        }
        headers = headers.set('Authorization', `JWT ${token}`);
        break;

      case UseHeaderType.DROPLET_SWDIN:
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        break;

      case UseHeaderType.EMPTY:
      case UseHeaderType.DEFAULT:
      default:
        break;
    }
    return headers;
  }

  private constructRequest2(api: AvailableAPI, url: string, useHeader: UseHeaderType, accessToken: string): Request {
    return new Request(this.constructURL(api, url), this.constructHeader2(useHeader, accessToken));
  }

  private constructHeader2(useHeader: UseHeaderType, token: string): HttpHeaders {
    let headers = new HttpHeaders();
    switch (useHeader) {
      case UseHeaderType.AUTHORIZED_SWDIN:
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Authorization', `JWT ${token}`);
        break;

      case UseHeaderType.DROPLET_SWDIN:
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        break;

      case UseHeaderType.EMPTY:
      case UseHeaderType.DEFAULT:
      default:
        break;
    }
    return headers;
  }

  handleObservableRequest<T = unknown>({
    useAPI,
    url,
    requestMethod,
    useHeader,
    requestData = {},
    disableURIEncoding = false,
  }: ObservableRequestConfig): Observable<T> {
    const encodedURL = disableURIEncoding ? url : encodeURI(url);
    const requestMethodString = RequestMethod[requestMethod];
    const constructRequest$ = this.storageService.authToken$.pipe(
      map((token) => this.constructRequest2(useAPI, encodedURL, useHeader, token)),
      take(1),
    );

    return constructRequest$.pipe(
      switchMap((constructRequest) => {
        return this.angularHttp
          .request(requestMethodString, constructRequest.url, {
            // checking for GET request to not include the body, this is needed for OpenWeather API etc.
            ...(requestMethod > 0 ? { body: requestData } : {}),
            headers: constructRequest.header,
            responseType: 'json',
          })
          .pipe(
            tap({
              next: (response) => {
                BrowserLogger.log(`ApiWrapper.handle${requestMethodString}.response`, {
                  url: constructRequest.url,
                  response,
                  requestData,
                });
              },
              error: (error) => {
                BrowserLogger.error(`ApiWrapper.handle${requestMethodString}`, {
                  url: constructRequest.url,
                  error,
                  requestData,
                });
                this.handleErrors(error);
              },
            }),
            map((response) => switchdinApiResponseHandler2(response) as T),
            catchError((error: HttpErrorResponse): Observable<unknown> => {
              throw error;
              // return throwError(error);
            }),
          ) as Observable<T>;
      }),
    );
  }

  private handleErrors(error: HttpErrorResponse): void {
    if (error.status === 503) {
      /**
       * it's a service not available http error
       * it will occur if api is not available
       * the only possible reasons I can think of is
       * 1. The api doesn't exist
       * 2. The server is down (for any reason maintenance, or don't know )
       * and navigate the user to 503 page
       */
      this.router.navigateByUrl('/error/maintenance');
    }
  }
}
