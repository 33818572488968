<div *ngIf="isAccountSetup$ | async as account" class="fullHeight flex-column">
  <ng-container [ngSwitch]="account.status">
    <ng-container *ngSwitchCase="FacadeStates.LOADING">
      <div class="p-20" id="loading-test-id" class="flex-grow">
        <app-skeleton-items [skeletonType]="SkeletonPlaceholder.CARD"></app-skeleton-items>
        <app-skeleton-items [skeletonType]="SkeletonPlaceholder.CARD"></app-skeleton-items>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="FacadeStates.COMPLETE">
      <ng-container *ngIf="account.isSetup; else signUp">
        <div class="account-linked bb-1 pt-20 pb-20" id="setup-test-id">
          <div>
            {{ 'UnitPage.FinancialAccount.LinkedTo' | translate }}
            <span id="email-text" class="fw-500">{{ account.email }}</span>
            {{ 'UnitPage.FinancialAccount.StripeAccount' | translate }}
          </div>
          <div>
            <a (click)="setupAccount()" class="cursor-pointer">{{
              'UnitPage.FinancialAccount.AccountSettings' | translate
            }}</a>
            <!-- |
              <a
                href="https://www.google.com/search?q=kittens&source=lnms&tbm=isch&sa=X&ved=2ahUKEwiFvfDfhr_0AhXVSmwGHbqCBgYQ_AUoAXoECAIQAw&biw=1848&bih=1068&dpr=1"
              >
                {{ 'UnitPage.FinancialAccount.MoreDetails' | translate }}</a
              > -->
          </div>
        </div>
        <div class="flex-grow">
          <app-transaction-list></app-transaction-list>
        </div>
      </ng-container>
      <ng-template #signUp>
        <div class="ion-text-center no-linked-account-div" id="signup-test-id">
          <div id="icons-div" class="mb-20">
            <img id="company-icon" [src]="headerLogoPath" [alt]="headerLogoAltName" /><ion-icon
              name="close-outline"
            ></ion-icon>
            <img id="stripe-icon" src="../../../assets/community-battery-widget/stripe.png" />
          </div>
          {{ 'UnitPage.FinancialAccount.NoLinkedAccount' | translate }} <br />
          {{ 'UnitPage.FinancialAccount.SetUpReceivePayment' | translate }}
          <ion-button
            [disabled]="facade.isStripeLinkLoading$ | async"
            color="button-primary"
            class="b-radius-3 fw-600 fs-12 setup-account-button ion-text-capitalize mt-20 width-50-pct"
            (click)="setupAccount()"
            id="setup-button"
          >
            {{ 'UnitPage.FinancialAccount.SetUpAccount' | translate }}
            <ion-spinner class="ml-10" *ngIf="facade.isStripeLinkLoading$ | async"></ion-spinner>
          </ion-button>
        </div>
      </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="FacadeStates.ERROR">
      <div class="no-linked-account-div flex-grow" id="error-test-id">
        <div>
          <ion-icon class="mr-10" src="assets/icons/svg/warning.svg"></ion-icon>
          <ion-label class="ion-text-wrap">
            <span class="fw-400 fs-13 text-light"> {{ 'UnitPage.FinancialAccount.Error' | translate }}: </span>
            <span class="fw-600 fs-13" id="error-msg"> {{ account.message }} </span>
          </ion-label>
        </div>
        <ion-button
          color="button-primary"
          class="b-radius-3 fs-12 fw-600 click-here-button ion-text-capitalize"
          href="https://mybatteryhub.ausgrid.com.au/Contact-Us-Page"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ 'UnitPage.FinancialAccount.Help' | translate }}?
        </ion-button>
      </div>
    </ng-container>
  </ng-container>
  <div class="width-100-pct p-20">
    <app-financial-help-information></app-financial-help-information>
  </div>
</div>
