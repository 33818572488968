import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputsConfig } from '../../../classes/commons/inputs';

@Component({
  selector: 'app-input-stacked-label',
  templateUrl: './input-stacked-label.component.html',
  styleUrls: ['./input-stacked-label.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputStackedLabelComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputStackedLabelComponent),
      multi: true,
    },
  ],
})
export class InputStackedLabelComponent extends InputsConfig {
  // all the inputs variables are in InputsConfig class
  @Input() enableClearIcon: boolean = false;
  @Input() autofocus: boolean = false;
}
