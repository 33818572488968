import { BooleanStrings, DataTypeStrings } from '@class/commons/enums';
import { BrowserLogger } from '@class/core/browser-logger';
import { DisplayConfigJson } from './../endpoint/endpoint-config-json.model';
import { EndpointControllerParameterAddUpdateDTO } from './../endpoint/endpoint-payload.model';
import { DeviceControlOrParam, DropletControlType } from './droplet-device-control.model';

export class DropletControllerParameter implements DeviceControlOrParam {
  controllerId?: string;
  defaultValue: string;
  description: string;
  displayConfig: string;
  displayConfigJson: DisplayConfigJson;
  id: string;
  name: string;
  parameterType?: string;
  value: string | boolean;
  type: DropletControlType;
  isValueValid?: boolean;
  placeholder: string;
  isManualControlRequested?: boolean;

  public static getParamsForAddUpdate(params: DropletControllerParameter[]): EndpointControllerParameterAddUpdateDTO[] {
    let result: EndpointControllerParameterAddUpdateDTO[] = [];
    if (params?.length) {
      result = getEnabledParams(params)?.map((param) => {
        return {
          parameter_type_id: param.id,
          value: getParamValueForAdd(param),
        };
      });
    }
    BrowserLogger.log('DropletControllerParameter.getParamsForAddUpdate', { result, params });
    return result;
  }

  public static supportsManualControl(param: DropletControllerParameter): boolean {
    const result =
      param.displayConfigJson.toggle?.optional ||
      param.displayConfigJson.numeric?.optional ||
      param.displayConfigJson.string?.optional;
    BrowserLogger.log('DropletControllerParameter.supportsManualControl', { result, param });
    return result;
  }
}

const getEnabledParams = (params: DropletControllerParameter[]): DropletControllerParameter[] => {
  // we do not care about controller params that are not in manual control (in default/auto mode)
  // i.e. we only want params that do not support manual control, or do and are under manual control
  const result = params?.filter((param) => {
    const supportsManualControl = DropletControllerParameter.supportsManualControl(param);
    return !supportsManualControl || (supportsManualControl && param.isManualControlRequested);
  });
  BrowserLogger.log('DropletControllerParameter.getEnabledParams', { result, params });
  return result;
};

const getParamValueForAdd = (param: DropletControllerParameter): string => {
  let result = param.value as string;
  if (param.parameterType === DataTypeStrings.BOOLEAN) {
    if (param.value === true || param.value === BooleanStrings.TRUE) {
      result = BooleanStrings.TRUE;
    } else {
      result = BooleanStrings.FALSE;
    }
  }
  BrowserLogger.log('DropletControllerParameter.getParamValueForAdd', { result, param });
  return result;
};
