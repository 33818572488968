import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UserProfile } from '../profile/profile.service';
import pDebounce from 'p-debounce';

import Analytics, { AnalyticsInstance } from 'analytics';
import { memoize, pick } from 'lodash';
import postHog from '@metro-fs/analytics-plugin-posthog';
import { StorageService } from './storage.service';
import { filter, tap } from 'rxjs';

function sanitaryUser(user: UserProfile) {
  const sanitised = pick(user, ['username', 'first_name', 'last_name', 'email', 'groups', 'is_staff', 'permissions']);
  return {
    ...sanitised,
    profile: pick(user.profile, ['browser_skin', 'language', 'dark_theme', 'mute_alerts', 'organisation']),
  };
}

export interface AnalyticsPlugins {
  posthog: ReturnType<typeof postHog>['methods'];
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private analytics: AnalyticsInstance;

  get plugins(): AnalyticsPlugins {
    // eslint-disable-next-line
    return this.analytics?.plugins as any;
  }

  // on accounts pages, there won't be a connection for posthog as we won't have the token at that point
  // we will have the token once we are logged in
  // this is to keep a record of those traces and send them once we have the token
  #offlineTraces: {
    logScreenView: string[];
    logError: { err: Error; name?: string }[];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    track: { eventName: string; payload?: any; options?: any; callback?: (...args: any[]) => void }[];
  } = {
    logScreenView: [],
    logError: [],
    track: [],
  };

  constructor(private _storageService: StorageService) {
    this.init();
  }

  private async init() {
    // first check if there is any offline traces
    // in local storage to update the offline traces local var
    const offlineTraces = localStorage.getItem('offlineTraces');
    if (offlineTraces) {
      this.#offlineTraces = JSON.parse(offlineTraces);
    }

    // create analytics without any plugin
    const analyticsInst = {
      app: environment.appName,
      version: environment.version as never,
      debug: !environment.production,
    };
    this.analytics = Analytics(analyticsInst);

    // should regenerate analytics once we have the token
    this._storageService.posthogToken$
      .pipe(
        filter((token) => !!token),
        tap((postHogToken) => {
          const plugins = [
            postHog({
              token: postHogToken,
              enabled: postHogToken?.length > 0,
              options: {
                debug: !environment.production,
              },
            }),
          ];
          this.analytics = Analytics({ ...analyticsInst, plugins });
        }),
        tap(() => {
          // publish the offline traces and clear the local storage
          if (this.isPosthogEnabled()) {
            this.#offlineTraces.track.forEach((trace) => {
              this.track(trace.eventName, trace.payload, trace.options, trace.callback);
            });
            this.#offlineTraces.logError.forEach((trace) => {
              this.logError(trace.err, trace.name);
            });
            this.#offlineTraces.logScreenView.forEach((url) => {
              this.logScreenView(url);
            });
            // clear localStorage and offline traces
            localStorage.removeItem('offlineTraces');
            this.#offlineTraces = {
              logScreenView: [],
              logError: [],
              track: [],
            };
          }
        }),
      )
      .subscribe();
  }
  private isPosthogEnabled = (): boolean => !!this.plugins.posthog;

  async setUser(profile: UserProfile): Promise<void> {
    // publish to userguiding
    if (environment.production) {
      // userguiding
      window['userGuiding'].identify(profile.email, {
        email: profile.email,
        name: `${profile.first_name} ${profile.last_name}`,
        is_staff: profile.is_staff,
        version: environment.version,
        company: {
          id: profile.profile.organisation?.uuid,
          name: profile.profile.organisation?.name,
          code: profile.profile.organisation?.code,
        },
      });
    }

    this.identify(profile.username, profile);
  }

  /**
   * Identify the current user
   *
   * Will only be called once per userId
   */
  private identify = memoize(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (userId: string, userProfile: UserProfile) => {
      this.analytics.identify(userId, sanitaryUser(userProfile));
    },
    (userId) => userId,
  );

  logScreenView = pDebounce(async (url: string): Promise<void> => {
    // if posthog is not enabled, save it in local var and in local storage
    // so we don't loose the traces
    if (!this.isPosthogEnabled()) {
      this.#offlineTraces.logScreenView.push(url);
      localStorage.setItem('offlineTraces', JSON.stringify(this.#offlineTraces));
    }
    this.analytics.page({
      url,
    });
  }, 50);

  async logError(err: Error, name?: string): Promise<void> {
    if (!this.isPosthogEnabled()) {
      this.#offlineTraces.logError.push({ err, name });
      localStorage.setItem('offlineTraces', JSON.stringify(this.#offlineTraces));
    }

    if (!environment.features.analytics.errors) {
      return;
    }

    this.analytics.track('error_event', { details: err, name });
  }

  /**
   * Track an analytics event
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  track = (eventName: string, payload?: any, options?: any, callback?: (...args: any[]) => void) => {
    if (!this.isPosthogEnabled()) {
      this.#offlineTraces.track.push({ eventName, payload, options, callback });
      localStorage.setItem('offlineTraces', JSON.stringify(this.#offlineTraces));
    }

    this.analytics.track(eventName, payload, options, callback);
  };
}
