import { defaultTheme } from './default-theme';
import { ThemeInterface } from './theme-interface';
import { getThemeIconsLight, ThemeMap } from './theme-map';

const simplyEnergyTheme: ThemeInterface = {
  name: ThemeMap.SIMPLY_ENERGY,
  type: defaultTheme.type,
  styles: {
    global: [{ themeVariable: '--ion-item-background', value: 'white' }],
    brandPrimary: [
      { themeVariable: '--ion-color-brand-primary', value: '#006CB7' },
      { themeVariable: '--ion-color-brand-primary-rgb', value: '0, 108, 183' },
      { themeVariable: '--ion-color-brand-primary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-brand-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-brand-primary-shade', value: '#005fa1' },
      { themeVariable: '--ion-color-brand-primary-tint', value: '#1a7bbe' },
    ],
    textPrimary: defaultTheme.styles.textPrimary,
    textTertiary: defaultTheme.styles.textTertiary,
    textSecondary: defaultTheme.styles.textSecondary,
    backgroundPrimary: [
      { themeVariable: '--ion-color-background-primary', value: '#F8F9F9' },
      { themeVariable: '--ion-color-background-primary-rgb', value: '248, 249, 249' },
      { themeVariable: '--ion-color-background-primary-contrast', value: '#000000' },
      { themeVariable: '--ion-color-background-primary-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-background-primary-shade', value: '#dadbdb' },
      { themeVariable: '--ion-color-background-primary-tint', value: '#f9fafa' },
    ],
    backgroundSecondary: [
      { themeVariable: '--ion-color-background-secondary', value: '#2662A9' },
      { themeVariable: '--ion-color-background-secondary-rgb', value: '38, 98, 169' },
      { themeVariable: '--ion-color-background-secondary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-background-secondary-contrast-rgb', value: '255,255,255' },
      { themeVariable: '--ion-color-background-secondary-shade', value: '#215695' },
      { themeVariable: '--ion-color-background-secondary-tint', value: '#3c72b2' },
    ],
    backgroundTertiary: [
      { themeVariable: '--ion-color-background-tertiary', value: '#2662A9' },
      { themeVariable: '--ion-color-background-tertiary-rgb', value: '38, 98, 169' },
      { themeVariable: '--ion-color-background-tertiary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-background-tertiary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-background-tertiary-shade', value: '#215695' },
      { themeVariable: '--ion-color-background-tertiary-tint', value: '#3c72b2' },
    ],
    subTabLabels: [
      { themeVariable: '--ion-color-sub-tab-labels', value: '#3A245E' },
      { themeVariable: '--ion-color-sub-tab-labels-rgb', value: '58,36,94' },
      // at the moment using the contrast value for the tab labels
      { themeVariable: '--ion-color-sub-tab-labels-contrast', value: '#666666' },
      { themeVariable: '--ion-color-sub-tab-labels-contrast-rgb', value: '102, 102, 102' },
      { themeVariable: '--ion-color-sub-tab-labels-shade', value: '#332053' },
      { themeVariable: '--ion-color-sub-tab-labels-tint', value: '#4e3a6e' },
    ],
    solar: defaultTheme.styles.solar,
    consumption: defaultTheme.styles.consumption,
    battery: defaultTheme.styles.battery,
    consumed: defaultTheme.styles.consumed,
  },
  headerLogo: {
    path: getThemeIconsLight[ThemeMap.SIMPLY_ENERGY],
    style: {
      height: '50px',
    },
  },
  footerLogo: defaultTheme.footerLogo,
  footerColoredLogo: defaultTheme.footerColoredLogo,
  iconsSvgPath: defaultTheme.iconsSvgPath,
  chartColors: {
    primary: { value: '#F9D649', opacValue: 'rgba(249, 214, 73, 0.4)' },
    light: { value: '#2E4C68', opacValue: 'rgba(46, 76, 104, 0.4)' },
    secondary: { value: '#2662A9', opacValue: 'rgba(38, 98, 169, 0.4)' },
    tertiary: { value: '#00b299', opacValue: 'rgba(0, 178, 153, 0.4)' },

    colorFive: { value: '#7BA24B', opacValue: 'rgba(123, 162, 75, 0.4)' },
    colorSix: { value: '#FF9B00', opacValue: 'rgba(255, 155, 0, 0.4)' },
    colorSeven: { value: '#AE33DE', opacValue: 'rgba(174, 51, 222, 0.4)' },
    colorEight: { value: '#6B9FAF', opacValue: 'rgba(107, 159, 175, 0.4)' },
    colorNine: { value: '#DE3355', opacValue: 'rgba(222, 51, 85, 0.4)' },
    colorTen: { value: '#96F70D', opacValue: 'rgba(150, 247, 13, 0.4)' },
  },
  vppChartColors: defaultTheme.vppChartColors,
  streamChartColor: '#006CB7',
  buttons: {
    primary: [
      { themeVariable: '--ion-color-button-primary', value: '#006CB7' },
      { themeVariable: '--ion-color-button-primary-rgb', value: '0, 108, 183' },
      { themeVariable: '--ion-color-button-primary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-button-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-button-primary-shade', value: '#005fa1' },
      { themeVariable: '--ion-color-button-primary-tint', value: '#1a7bbe' },
    ],
    secondary: [
      { themeVariable: '--ion-color-button-secondary', value: '#006CB7' },
      { themeVariable: '--ion-color-button-secondary-rgb', value: '0, 108, 183' },
      { themeVariable: '--ion-color-button-secondary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-button-secondary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-button-secondary-shade', value: '#005fa1' },
      { themeVariable: '--ion-color-button-secondary-tint', value: '#1a7bbe' },
    ],
    success: [
      { themeVariable: '--ion-color-success', value: '#006F45' },
      { themeVariable: '--ion-color-success-rgb', value: '0, 111, 69' },
      { themeVariable: '--ion-color-success-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-success-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-success-shade', value: '#00623d' },
      { themeVariable: '--ion-color-success-tint', value: '#1a7d58' },
    ],
    warning: [
      { themeVariable: '--ion-color-warning', value: '#E69435' },
      { themeVariable: '--ion-color-warning-rgb', value: '230, 148, 53' },
      { themeVariable: '--ion-color-warning-contrast', value: '#000000' },
      { themeVariable: '--ion-color-warning-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-warning-shade', value: '#ca822f' },
      { themeVariable: '--ion-color-warning-tint', value: '#e99f49' },
    ],
    danger: [
      { themeVariable: '--ion-color-danger', value: '#E31E30' },
      { themeVariable: '--ion-color-danger-rgb', value: '227, 30, 48' },
      { themeVariable: '--ion-color-danger-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-danger-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-danger-shade', value: '#c81a2a' },
      { themeVariable: '--ion-color-danger-tint', value: '#e63545' },
    ],
    successLite: [
      { themeVariable: '--ion-color-success-lite', value: '#9AD9AD' },
      { themeVariable: '--ion-color-success-lite-rgb', value: '154, 217, 173' },
      { themeVariable: '--ion-color-success-lite-contrast', value: '#000000' },
      { themeVariable: '--ion-color-success-lite-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-success-lite-shade', value: '#88bf98' },
      { themeVariable: '--ion-color-success-lite-tint', value: '#a4ddb5' },
    ],
    warningLite: [
      { themeVariable: '--ion-color-warning-lite', value: '#FEEACB' },
      { themeVariable: '--ion-color-warning-lite-rgb', value: '254, 234, 203' },
      { themeVariable: '--ion-color-warning-lite-contrast', value: '#000000' },
      { themeVariable: '--ion-color-warning-lite-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-warning-lite-shade', value: '#e0ceb3' },
      { themeVariable: '--ion-color-warning-lite-tint', value: '#feecd0' },
    ],
    dangerLite: [
      { themeVariable: '--ion-color-danger-lite', value: '#FFDFDF' },
      { themeVariable: '--ion-color-danger-lite-rgb', value: '255, 223, 223' },
      { themeVariable: '--ion-color-danger-lite-contrast', value: '#000000' },
      { themeVariable: '--ion-color-danger-lite-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-danger-lite-shade', value: '#e0c4c4' },
      { themeVariable: '--ion-color-danger-lite-tint', value: '#ffe2e2' },
    ],
  },
  svgIcons: {
    primarySvg: [
      { themeVariable: '--ion-color-primary-svg', value: '#2662A9' },
      { themeVariable: '--ion-color-primary-svg-rgb', value: '38, 98, 169' },
      { themeVariable: '--ion-color-primary-svg-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-primary-svg-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-primary-svg-shade', value: '#215695' },
      { themeVariable: '--ion-color-primary-svg-tint', value: '#3c72b2' },
    ],
  },
  tablesRow: {
    primaryTile: [
      { themeVariable: '--ion-color-table-primary-tile', value: '#F8F9F9' },
      { themeVariable: '--ion-color-table-primary-tile-rgb', value: '248, 249, 249' },
      { themeVariable: '--ion-color-table-primary-tile-contrast', value: '#000000' },
      { themeVariable: '--ion-color-table-primary-tile-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-table-primary-tile-shade', value: '#dadbdb' },
      { themeVariable: '--ion-color-table-primary-tile-tint', value: '#f9fafa' },
    ],
    secondaryTile: [
      { themeVariable: '--ion-color-table-secondary-tile', value: '#F2F4F4' },
      { themeVariable: '--ion-color-table-secondary-tile-rgb', value: '242, 244, 244' },
      { themeVariable: '--ion-color-table-secondary-tile-contrast', value: '#000000' },
      { themeVariable: '--ion-color-table-secondary-tile-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-table-secondary-tile-shade', value: '#d5d7d7' },
      { themeVariable: '--ion-color-table-secondary-tile-tint', value: '#f3f5f5' },
    ],
    grayTile: [
      { themeVariable: '--ion-color-table-gray-tile', value: '#FCFCFC' },
      { themeVariable: '--ion-color-table-gray-tile-rgb', value: '252, 252, 252' },
      { themeVariable: '--ion-color-table-gray-tile-contrast', value: '#000000' },
      { themeVariable: '--ion-color-table-gray-tile-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-table-gray-tile-shade', value: '#dedede' },
      { themeVariable: '--ion-color-table-gray-tile-tint', value: '#fcfcfc' },
    ],
  },
};

export { simplyEnergyTheme };
