export enum ToastPosition {
  BOTTOM = 'bottom',
  MIDDLE = 'middle',
  TOP = 'top',
  DEFAULT = 'top',
}

export enum ToastDuration {
  DEFAULT_MS = 1000,
  INSTALLER_MS = 3000,
}
