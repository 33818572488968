import { Injectable, inject } from '@angular/core';
import { BaseApiService } from '../base.api-service';
import { UnitDTO } from '@class/commons/unit.model';
import { EndpointsApi } from 'app/api/units/endpoints.api';
import { RequestMethod } from '@service/common/api-wrapper.service';

interface GetUnitRequestParams {
  unitUuid: string;
}

interface GetUnitEndpointsRequestParams {
  unitId: string;
}

interface PatchUnitRequestParams {
  uuid: string;
  data: Partial<UnitDTO>;
}

interface PurgeUnitRequestParams {
  uuid: string;
}

export const queryKeys = {
  all: ['units'] as const,
  details: () => [...queryKeys.all, 'details'] as const,
  detail: (params: GetUnitRequestParams) => [...queryKeys.details(), params.unitUuid] as const,
  patch: (params: PatchUnitRequestParams) => [...queryKeys.details(), 'patch', params.uuid] as const,
  endpoints: (params: GetUnitEndpointsRequestParams) => [...queryKeys.all, 'endpoints', params.unitId] as const,
};

@Injectable({
  providedIn: 'root',
})
export class UnitsApiService extends BaseApiService {
  readonly #endpointsApi = inject(EndpointsApi);

  constructor() {
    super('/api/v1/units');
  }

  getUnit(params: GetUnitRequestParams) {
    return this._query<UnitDTO>({
      queryKey: queryKeys.detail(params),
      queryFn: () =>
        this.request({
          path: params.unitUuid,
        }),
    });
  }

  patchUnit() {
    return this._mutation({
      mutationFn: (params: PatchUnitRequestParams) =>
        this.requestObs<UnitDTO>({
          path: params.uuid,
          method: RequestMethod.PATCH,
          data: params.data,
        }),
      onSuccess: (res) => {
        this._queryClient.setQueryData(queryKeys.detail({ unitUuid: res.uuid }), res);
      },
    });
  }
  purgeUnit() {
    return this._mutation({
      mutationFn: (params: PurgeUnitRequestParams) =>
        this.requestObs<void>({
          path: params.uuid,
          method: RequestMethod.DELETE,
          query: { purge: true },
        }),
    });
  }

  getEndpoints(params: GetUnitEndpointsRequestParams) {
    return this._query({
      queryKey: queryKeys.endpoints(params),
      queryFn: async () => {
        const res = await this.#endpointsApi.getUnitEndpoints(params.unitId);

        return res.data;
      },
    });
  }
}
