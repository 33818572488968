<ng-container [ngSwitch]="status">
  <div *ngSwitchCase="FacadeStates.LOADING" class="flex-center-column fullHeight">
    <ion-spinner color="brand-primary" name="crescent"></ion-spinner>
  </div>
  <ng-container *ngSwitchCase="FacadeStates.ONGOING">
    <form (ngSubmit)="submit(form.value)" [formGroup]="form" *ngIf="form" id="controlTargetForm">
      <div class="scroll-content">
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-item>
                <ion-input
                  [value]="form?.value.firstName"
                  [disabled]="true"
                  label="{{ 'ProfilePage.FirstName' | translate }}"
                  labelPlacement="stacked"
                ></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-item>
                <ion-input
                  [value]="form?.value.lastName"
                  [disabled]="true"
                  label="{{ 'ProfilePage.LastName' | translate }}"
                  labelPlacement="stacked"
                ></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-item>
                <ion-input
                  formControlName="phone"
                  type="tel-local"
                  label="{{ 'ProfilePage.ContactPhone' | translate }}"
                  labelPlacement="stacked"
                ></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-item>
                <ion-input
                  formControlName="address"
                  label="{{ 'ProfilePage.Address' | translate }}"
                  labelPlacement="stacked"
                ></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-item>
                <ion-select
                  formControlName="theme"
                  interface="popover"
                  label="{{ 'ProfilePage.Theme' | translate }}"
                  labelPlacement="stacked"
                >
                  <ion-select-option *ngFor="let theme of THEMES" [value]="theme.value">
                    {{ 'Themes.' + theme.name | translate }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-item>
                <ion-toggle
                  justify="space-between"
                  formControlName="isDarkTheme"
                  color="button-secondary"
                  labelPlacement="start"
                  mode="ios"
                >
                  {{ 'ProfilePage.DarkMode' | translate }}
                </ion-toggle>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-item>
                <ion-toggle
                  justify="space-between"
                  formControlName="isMuteAlerts"
                  color="button-secondary"
                  labelPlacement="start"
                  mode="ios"
                >
                  {{ 'UnitDetails.MuteAlarms' | translate }}
                </ion-toggle>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-item>
                <ion-select
                  *ngIf="(languages$ | async)?.data as languages; else loadingLanguages"
                  formControlName="language"
                  interface="popover"
                  [compareWith]="compareWith"
                  id="language-select"
                  label="{{ 'ProfilePage.Language' | translate }}"
                  labelPlacement="stacked"
                >
                  <ion-select-option *ngFor="let language of languages" [value]="language.id">
                    {{ language.name }}
                  </ion-select-option>
                </ion-select>

                <ng-template #loadingLanguages>
                  <ion-spinner class="ion-margin"></ion-spinner>
                </ng-template>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row [hidden]="!form?.value.apiToken">
            <ion-col>
              <ion-item>
                <ion-input
                  [value]="form?.value.apiToken"
                  [disabled]="true"
                  label="{{ 'ProfilePage.ApiToken' | translate }}"
                  labelPlacement="stacked"
                ></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label>{{ 'ProfilePage.DeleteYourAccount' | translate }}</ion-label>
                <app-remove-button (onClick)="presentAccountDeleteConfirmationAlert()" slot="end"></app-remove-button>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>

      <div class="footer-buttons border-top-1">
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-button
                mode="ios"
                fill="outline"
                color="button-primary"
                size="large"
                (click)="close()"
                expand="block"
              >
                {{ 'ProfilePage.Cancel' | translate }}
              </ion-button>
            </ion-col>

            <ion-col>
              <ion-button
                mode="ios"
                [disabled]="form.invalid"
                color="button-primary"
                size="large"
                type="submit"
                expand="block"
              >
                {{ 'ProfilePage.Save' | translate }}
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </form>
  </ng-container>
  <div *ngSwitchCase="FacadeStates.ERROR" class="flex-center-column fullHeight ion-padding">
    {{ error }}
  </div>
  <div *ngSwitchCase="FacadeStates.COMPLETE" class="flex-center-column fullHeight ion-padding">
    {{ 'ProfilePage.ProfileUpdated' | translate }}
  </div>
</ng-container>
