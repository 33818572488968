import { Component, Input, OnDestroy } from '@angular/core';
import { ThemeService } from '@service/themes/theme.service';
import { Subscription } from 'rxjs';
import { HeaderFooterLogosStyle, ThemeInterface } from 'theme/theme-interface';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-powered-by-footer',
  templateUrl: './powered-by-footer.component.html',
  styleUrls: ['./powered-by-footer.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule],
})
export class PoweredByFooterComponent implements OnDestroy {
  @Input() showColoredLogo = false;

  footerLogoPath: string;
  footerLogoAltName: string;
  footerLogoStyle: HeaderFooterLogosStyle;

  private themeSubscription: Subscription;

  constructor(private theme: ThemeService) {
    this.setLogosIcons();
  }

  ngOnDestroy(): void {
    if (this.themeSubscription) this.themeSubscription.unsubscribe();
  }

  private setLogosIcons(): void {
    this.themeSubscription = this.theme.userTheme$.subscribe((theme: ThemeInterface | null) => {
      if (theme) {
        this.footerLogoPath = this.showColoredLogo ? theme.footerColoredLogo : theme.footerLogo.path;
        this.footerLogoAltName = theme.name;
        this.footerLogoStyle = theme.footerLogo.style;
      }
    });
  }
}
