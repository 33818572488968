import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs';
import {
  fetchUserOrganisationMemberships,
  setUserOrganisationMemberships,
} from './user-organisation-membership.action';
import { filterSuccessResult } from '@ngneat/query';
import { UserOrgMembershipsApiService } from 'app/api-services/user-accounts/user-org-memberships.api-service';

export const fetchUserOrgMemberships = createEffect(
  (actions$ = inject(Actions), userOrgMembershipApiService = inject(UserOrgMembershipsApiService)) => {
    return actions$.pipe(
      ofType(fetchUserOrganisationMemberships),
      exhaustMap(() =>
        userOrgMembershipApiService.getUserOrganisationMemberships().result$.pipe(
          filterSuccessResult(),
          map(({ data }) => setUserOrganisationMemberships({ memberships: data })),
        ),
      ),
    );
  },
  { functional: true },
);
