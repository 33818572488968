import { ToastInputSettings } from '@custom-types/core/app-toast-notifications.model';
import { createReducer, on } from '@ngrx/store';
import { setAppToastNotification } from './app-toast-notifications.actions';

const initialState = null as ToastInputSettings;

export const appToastNotificationsReducer = createReducer(
  initialState,
  on(setAppToastNotification, (state, { toastSettings }) => {
    return toastSettings;
  }),
);
