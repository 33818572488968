<!-- Configured Devices -->
<ion-row *ngIf="configuredDevices$ | async as configuredDevices">
  <ion-col size="12" class="bb-2">
    <ng-container *ngIf="configuredDevices.length > 0; else noDevices">
      <ion-item lines="none" class="bkg-transparent">
        <ion-text class="text-primary-lg" translate [translateParams]="{ deviceCount: configuredDevices.length }">
          AddDevice.InventoryDevices.NDevicesAdded
        </ion-text>
      </ion-item>
      <app-device-state-card-list
        [devicesList]="configuredDevices"
        (removeDeviceClick)="onRemoveDeviceHandler($event)"
      />
    </ng-container>
    <ng-template #noDevices>
      <ion-item lines="none" class="bkg-transparent">
        <ion-text class="text-primary-lg" translate>AddDevice.InventoryDevices.NoDevicesAdded</ion-text>
      </ion-item>
    </ng-template>
  </ion-col>
</ion-row>

<!-- Discovered Devices -->
<ion-row *ngIf="discoveredDevices$ | async as discoveredDevices">
  <ion-col size="12" class="bb-2">
    <ng-container *ngIf="discoveredDevices.length > 0; else noDevices">
      <ion-item lines="none" class="bkg-transparent">
        <ion-text class="text-primary-lg" translate [translateParams]="{ deviceCount: discoveredDevices.length }">
          AddDevice.InventoryDevices.NDevicesDiscovered
        </ion-text>
        <ion-spinner name="dots" slot="end" />
      </ion-item>
      <app-device-state-card-list
        [devicesList]="discoveredDevices"
        (configureDeviceClick)="onConfigureDeviceHandler($event)"
      />
    </ng-container>
    <ng-template #noDevices>
      <ion-item lines="none" class="bkg-transparent">
        <ion-text class="text-primary-lg" translate>AddDevice.InventoryDevices.NoDevicesDiscovered</ion-text>
        <ion-spinner name="dots" slot="end" />
      </ion-item>
    </ng-template>
  </ion-col>
</ion-row>

<!-- Discover A Serial Device -->
<ng-container *ngIf="dropletAvailablePortsToDiscoverDevice$ | async as dropletAvailablePortsToDiscoverDevice">
  <ion-row *ngIf="dropletAvailablePortsToDiscoverDevice?.ports?.length > 0" class="ion-padding-vertical">
    <ion-col size="12">
      <app-button
        label="{{ 'AddDevice.InventoryDevices.AddAUsbDevice' | translate }}"
        [buttonType]="ButtonType.PRIMARY_BLOCK_OUTLINE"
        (onClick)="onDiscoverDeviceButtonHandler()"
      />
    </ion-col>
  </ion-row>
</ng-container>

<!-- Static Configured Devices -->
<ng-container *ngIf="staticConfiguredDevices$ | async as staticConfiguredDevices">
  <ion-row *ngIf="staticConfiguredDevices.length > 0">
    <ion-col size="12">
      <ion-item lines="none" class="bkg-transparent">
        <ion-text class="text-primary-lg" translate [translateParams]="{ deviceCount: staticConfiguredDevices.length }">
          AddDevice.InventoryDevices.NPreconfiguredDevices
        </ion-text>
      </ion-item>
      <app-device-state-card-list
        [devicesList]="staticConfiguredDevices"
        (removeDeviceClick)="onRemoveDeviceHandler($event)"
      />
    </ion-col>
  </ion-row>
</ng-container>

<!-- STAFF ONLY -->
<ng-container *ifHasPermission="[PermissionKey.GLOBAL_STAFF]">
  <!-- All Interfaces Port -->
  <ng-container *ngrxLet="dropletAllInterfacesPortsToDiscoverDevice$ as allInterfacesPort">
    <ion-row *ngIf="!isEmpty(allInterfacesPort)" class="ion-padding-vertical">
      <ion-col size="12">
        <app-button
          label="{{ 'AddDevice.InventoryDevices.AllInterfaces' | translate }}"
          [buttonType]="ButtonType.PRIMARY_BLOCK_OUTLINE"
          (onClick)="onAllInterfacesDiscoverDeviceButtonHandler(allInterfacesPort)"
        />
      </ion-col>
    </ion-row>
  </ng-container>

  <!-- Initialised  Devices -->
  <ng-container *ngIf="initialisedDevices$ | async as initialisedDevices">
    <ion-row *ngIf="initialisedDevices.length > 0">
      <ion-col size="12">
        <ion-item lines="none" class="bkg-transparent">
          <ion-text class="text-primary-lg" translate [translateParams]="{ deviceCount: initialisedDevices.length }">
            AddDevice.InventoryDevices.TryingToCommunicateWithNInitialisedDevices
          </ion-text>
        </ion-item>
        <app-device-state-card-list [devicesList]="initialisedDevices" />
      </ion-col>
    </ion-row>
  </ng-container>
</ng-container>
