<ng-container *ngIf="size === CustomButtonSize.SMALL">
  <ion-button (click)="handleClick()" color="danger-lite" class="remove-button" mode="ios" [disabled]="isDisabled">
    <span class="fs-12 fw-600 ion-text-capitalize"> {{ 'General.Remove' | translate }} </span>
    <ion-icon class="ml-7" src="assets/icons/svg/trash.svg"></ion-icon>
  </ion-button>
</ng-container>
<ng-container *ngIf="size === CustomButtonSize.LARGE">
  <ion-button class="remove-button-large" mode="ios" [disabled]="isDisabled" (click)="handleClick()">
    <ion-icon src="assets/icons/svg/trash.svg"></ion-icon>
    <span *ngIf="!label" class="text">{{ 'General.Remove' | translate }}</span>
    <span *ngIf="label" class="text">{{ label }}</span>
  </ion-button>
</ng-container>
