<ion-content *ngIf="transactions$ | async as transactions" class="fullHeight flex-column background pt-14 pb-14">
  <ng-container [ngSwitch]="transactions.status">
    <ng-container *ngSwitchCase="FacadeStates.LOADING">
      <div id="loading">
        <app-skeleton-items [skeletonType]="SkeletonPlaceholder.CARD"></app-skeleton-items>
        <app-skeleton-items [skeletonType]="SkeletonPlaceholder.CARD"></app-skeleton-items>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="FacadeStates.COMPLETE" id="complete">
      <ng-container *ngIf="transactions?.data?.length > 0; else noTransactions">
        <ion-card
          *ngFor="let transaction of transactions.data"
          class="pt-16 pb-16 pl-20 pr-20 card-design ml-20 mr-20 mt-6 mb-0 transaction-card"
        >
          <ion-grid>
            <ion-row>
              <ion-col>
                <div>{{ transaction.date | date: 'medium' }}</div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12" size-md="3">
                <span class="text-dark fw-600 fs-14">{{ transaction?.description }} </span>
              </ion-col>
              <ion-col size="4" size-md="3">
                <span
                  class="fs-12 payment-status"
                  [ngClass]="{
                    paid: transaction.status === TransactionStatus.PAID,
                    pending: transaction.status === TransactionStatus.PENDING,
                    canceled: transaction.status === TransactionStatus.FAILED
                  }"
                  >{{ transaction.status }}</span
                >
              </ion-col>
              <ion-col size="7" offset="1" size-md="4" offset-md="2" class="ion-text-end">
                <span class="text-dark fw-600 fs-20 ml-a mr-0 transaction-amount"> {{ transaction.amount }} </span>
                <span class="text-light ion-text-uppercase mr-0 transaction-currency">{{ transaction.currency }}</span>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <span class="text-light transaction-type">{{ transaction.type }}</span>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card>
      </ng-container>
      <ng-template #noTransactions>
        <div class="flex-center-column m-20">
          {{ 'UnitPage.FinancialAccount.NoTransactions' | translate }}
        </div>
        <div class="flex-center-row m-20">
          <span>{{ 'UnitPage.FinancialAccount.NoTransactionsSupport' | translate }}</span>
          <ion-button
            color="button-primary"
            class="ml-20 b-radius-3 fs-12 fw-600 click-here-button ion-text-capitalize"
            href="https://mybatteryhub.ausgrid.com.au/Contact-Us-Page"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ 'UnitPage.FinancialAccount.Click' | translate }}
          </ion-button>
        </div>
      </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="FacadeStates.ERROR">
      <div class="flex-center-column fullHeight" id="error">
        <ion-icon class="error-icon" src="assets/icons/svg/warning.svg"></ion-icon>
        <div class="fw-400 fs-13 text-light">{{ 'UnitPage.FinancialAccount.Error' | translate }}:</div>
        <div class="fw-600 fs-13" id="error-msg">
          {{ transactions.message }}
        </div>
      </div>
    </ng-container>
  </ng-container>
</ion-content>
