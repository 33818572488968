import { Unit } from './unit.model';
import { HomeCardDataType } from './enums';
import { PortfolioUnitStatus } from 'app/components/status-circle/status-circle.component';
import { BrowserLogger } from '@class/core/browser-logger';

type PortfolioUnitSetDTO = {
  readonly id: string;
  readonly uuid: string;
  name: string;
  latitude: number;
  longitude: number;
  address: string;
  online_status: number;
  image: string | null;
  timezone: string;
  soc: number | null;
  external_identifier: string;
};

type PortfolioUnitStatusConstants = {
  translateText: string;
  status: number;
};
export type PortfolioUnitStatusDisplay = PortfolioUnitStatusConstants & {
  count: number;
};

export class PortfolioDTO {
  readonly id: string;
  name: string;
  location_description: string;
  unit_count: number;
  online_status: number;
  unit_statuses: { [index: string]: number };

  readonly uuid: string;
  permissions: Array<[string, string]>;
  unit_set: Array<PortfolioUnitSetDTO>;
  mute_alerts: boolean | null;

  public static adaptToPortfolio(portfolio: PortfolioDTO): Portfolio {
    const result = new Portfolio(
      portfolio.id,
      portfolio.uuid ?? '',
      portfolio.name,
      portfolio.location_description,
      portfolio.online_status,

      // Why all these conditions?
      // There are possibilities that the data saved in user fav will have old data model
      // and they won't have unit_statuses as favourites are stored as JSON string
      // this is just a fall back for that
      // so instead of showing unknown, show the online_status for now
      portfolio.online_status === PortfolioUnitStatus.EMPTY
        ? [
            {
              status: PortfolioUnitStatus.EMPTY,
              count: null,
              translateText: 'Portfolio.Status.EmptyPortfolio',
            },
          ]
        : portfolio.unit_statuses
          ? Object.entries(portfolio.unit_statuses)?.map((element: [UnitStatusCount, number]) => {
              const unitStat = getStat[element[0]];
              return {
                status: unitStat.status,
                count: element[1],
                translateText: unitStat.translateText,
              };
            })
          : portfolio.online_status != null
            ? [
                {
                  status: portfolio.online_status,
                  count: null,
                  translateText: getLegacyStat[portfolio.online_status],
                },
              ]
            : [
                {
                  status: PortfolioUnitStatus.UNKNOWN,
                  count: null,
                  translateText: 'Portfolio.Status.Unknown',
                },
              ],
      portfolio.unit_count,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      portfolio.unit_set ? portfolio.unit_set.map((unit: any) => new Unit(unit)) : null,
      portfolio.permissions ?? null,
      HomeCardDataType.PORTFOLIO,
      portfolio.mute_alerts,
    );
    BrowserLogger.log('PortfolioDTO.adaptToPortfolio', { result, portfolio });
    return result;
  }
}

export interface PortfolioUpdateDTO {
  name: string;
  location_description: string;
  mute_alerts?: boolean;
}

/*
id: "e8dad372"
location_description: "Newcastle, NSW, Australia"
name: "Whistler Trials"
online_status: 0
unit_count: 3
view_count: 6
*/

enum UnitStatusCount {
  ONLINE_COUNT = 'online_count',
  OFFLINE_COUNT = 'offline_count',
  NOT_CONFIGURED_COUNT = 'not_configured_count',
}
const getStat: Record<UnitStatusCount, PortfolioUnitStatusConstants> = {
  [UnitStatusCount.ONLINE_COUNT]: { status: PortfolioUnitStatus.ONLINE, translateText: 'General.Online' },
  [UnitStatusCount.OFFLINE_COUNT]: { status: PortfolioUnitStatus.OFFLINE, translateText: 'General.Offline' },
  [UnitStatusCount.NOT_CONFIGURED_COUNT]: {
    status: PortfolioUnitStatus.SOME_NON_CONFIG,
    translateText: 'General.NotConfigured',
  },
};
const getLegacyStat: Record<PortfolioUnitStatus, string> = {
  [PortfolioUnitStatus.OFFLINE]: 'Portfolio.Status.AllOffline',
  [PortfolioUnitStatus.OFFLINE_NON_CONFIG]: 'Portfolio.Status.AllOfflineSomeInactive',
  [PortfolioUnitStatus.ONLINE]: 'Portfolio.Status.AllOnline',
  [PortfolioUnitStatus.ONLINE_NON_CONFIG]: 'Portfolio.Status.AllOnlineSomeInactive',
  [PortfolioUnitStatus.SOME]: 'Portfolio.Status.SomeOffline',
  [PortfolioUnitStatus.SOME_NON_CONFIG]: 'Portfolio.Status.SomeOfflineSomeInactive',
  [PortfolioUnitStatus.EMPTY]: 'Portfolio.Status.EmptyPortfolio',
  [PortfolioUnitStatus.UNKNOWN]: 'Portfolio.Status.Unknown',
  [PortfolioUnitStatus.DELETED]: 'Portfolio.Status.Deleted',
};

// NOTE: Portfolio needs to be a class (not interface) as type checks are done
export class Portfolio {
  constructor(
    public readonly id: string,
    public readonly uuid: string,
    public name: string,
    public locationDescription: string,
    public onlineStatus: number,
    public unitStatus: PortfolioUnitStatusDisplay[],
    public unitCount: number,
    public unitSet: Unit[],
    public permissions: Array<[string, string]>,
    public dataType: HomeCardDataType,
    public muteAlerts: boolean | null,
  ) {}
}

export const EMPTY_PORTFOLIO: Readonly<Portfolio> = {
  id: null,
  uuid: null,
  name: null,
  locationDescription: null,
  onlineStatus: null,
  unitStatus: null,
  unitCount: null,
  unitSet: null,
  permissions: null,
  dataType: null,
  muteAlerts: null,
};
