// ------------------------------------------------------------------------------------------------------------
// UserPermissions Class
// - we are storing ALL permissions in a single Permissions Store (across all permission areas)
// - prefix each permission when it is applied to avoid any duplications
// - one store means we can easily check for permissions
// ------------------------------------------------------------------------------------------------------------

import { BrowserLogger } from '@class/core/browser-logger';
import {
  getStorePermissionAreaFromKey,
  getPermissionAreaKeyPrefix,
  PermissionArea,
  PermissionDTO,
  PermissionKey,
  PermissionSet,
  PermissionStore,
} from './permission-constants';

export class UserPermissions {
  // permissions for the user (across all permission areas)
  private _permissionStore: PermissionStore = null;

  constructor() {
    // console.log('UserPermissions.constructor');
    this._permissionStore = this.createEmptyPermissionStore();
  }

  ///
  /// Apply the incoming permissions to the user for the given permission area
  /// - ensure the permission is prefixed by the area so there are no clashes across different areas
  /// - supports either:
  ///   1. 'object' (child permission keys as boolean values), or
  ///   2. 'Array' (containing permission keys)
  /// NOTE: 'arrayIndex' is only used if the incoming permissionData is an array, and defines which
  ///       index of the array the permission key is at (default to the first index 0)
  ///
  apply(permissionArea: PermissionArea, permissionData: PermissionSet | Array<PermissionDTO>): void {
    if (typeof permissionData === 'object') {
      // get the permission area prefix
      const keyPrefix = getPermissionAreaKeyPrefix(permissionArea);

      // start a new permission set
      const permissionSet: PermissionSet = {} as PermissionSet;

      // PermissionDTO (Array)
      // - if the permission data comes back from the API then it is assumed to be an enabled permission
      // - use the given array index as the array index to check for the permission key lookup
      if (Array.isArray(permissionData)) {
        permissionData.forEach((p) => {
          const permissionKey = keyPrefix + p[0]; // use the first property of the PermissionDTO
          permissionSet[permissionKey] = true;
        });
      }
      // PermissionSet (object)
      // - Permission Data is in the format { 'permissionsString': boolean }
      // - Add to the permission set if enabled
      else {
        for (const [permStr, isPermEnabled] of Object.entries(permissionData)) {
          if (isPermEnabled) {
            permissionSet[`${keyPrefix}${permStr}`] = true;
          }
        }
      }

      // Apply the new permission set to the store
      this.applyPermissionSetToStore(permissionSet, permissionArea);

      BrowserLogger.log('UserPermissions.apply', {
        permissionArea,
        keyPrefix,
        permissionData,
        permissionStore: this._permissionStore,
      });
    } else {
      console.error('UserPermissions.apply: UNSUPPORTED TYPE!', typeof permissionData);
    }
  }

  ///
  /// clear the users permissions for the give permission area (all areas if not given)
  /// when permission area is given, ensure the permission is prefixed for the area so there are no clashes
  ///
  clear(permissionArea: PermissionArea): void {
    // reset the object at the relevant permission area
    // (much quicker than iterating and clearing each permission)
    // IMPORTANT: When adding a new PermissionArea this needs to be updated!

    this._permissionStore[permissionArea] = {} as PermissionSet;
  }

  ///
  /// Check User Permissions against a single permission
  /// Return true if is are enabled
  ///
  has(permission: PermissionKey): boolean {
    let result = this.check(permission);

    // debug log
    // console.log('UserPermissions.has', { result, permission, userPermissions: this._permissionStore });

    return result;
  }

  ///
  /// Check User Permissions against a list of permissions
  /// Return true if ANY are enabled
  ///
  any(permissions: Array<PermissionKey>): boolean {
    return this.checkAllBreakOn(permissions, true);
  }

  ///
  /// Check User Permissions against a list of permissions
  /// Return true if ALL are enabled
  ///
  all(permissions: Array<PermissionKey>): boolean {
    return this.checkAllBreakOn(permissions, false);
  }

  ///
  /// Check a set of permissions against an externally supplied permission list
  /// IMPORTANT
  /// This is a temporary fix for vpp stuff, not all  just on the vpp list page & for vpp edit
  /// We are providing Edit & Delete function on vpp list page so can not set the perms in vpp of USER_PERMS
  /// because we have not selected any vpp yet so doing this unless we change it
  /// hope fully the new layout we are doing we will move the edit and delete inside the vpp
  /// instead of on vpp list page then we can get rid of this
  ///
  anyExternal(permissionsToCheck: Array<PermissionKey>, externalPermissionsList: Array<PermissionDTO>): boolean {
    let result: boolean = false;
    const extPermsArray = externalPermissionsList.map((perm) => perm[0]);
    for (let i = 0, len = permissionsToCheck.length; i < len; i++) {
      const permissionToCheck = permissionsToCheck[i].toString();
      if (extPermsArray.indexOf(permissionToCheck) >= 0) {
        result = true;
        break;
      }
    }
    // debug log
    // console.log('UserPermissions.anyExternal', { result, permissionsToCheck, externalPermissionsList, extPermsArray });

    return result;
  }

  ///
  /// check all permissions against our permission store but break on the first (true or false) match we hit
  /// this means that we either have all true or all false, so we can either:
  /// match ANY (break on first true); or match ALL (break on first false)
  ///
  private checkAllBreakOn(permissions: Array<PermissionKey>, breakOnTrueOrFalse: boolean): boolean {
    let result: boolean = false;
    if (permissions?.length > 0) {
      // check we have ALL of the permissions as true, ie stop checking on first failure
      for (let idx = 0, len = permissions.length; idx < len; idx++) {
        result = this.check(permissions[idx]);
        if (result === breakOnTrueOrFalse) {
          break; // we got a breaking match, so break out of loop
        }
      }
    }

    // debug log
    // console.log('UserPermissions.checkAllBreakOn', {
    //   result,
    //   permissions,
    //   breakOnTrueOrFalse,
    //   permissionStore: this._permissionStore,
    // });

    return result;
  }

  ///
  /// check each object property as a permission key if enabled (ie is a boolean 'true')
  /// as the permissionStore has multiple permission areas we need to
  /// iterate each area within the store
  ///
  private check(permissionKey: PermissionKey): boolean {
    let result = false;
    const permissionAreaForStore = getStorePermissionAreaFromKey(permissionKey);
    const permissionSetToLookFrom = this._permissionStore[permissionAreaForStore];
    if (permissionAreaForStore && permissionSetToLookFrom) {
      result = permissionSetToLookFrom[permissionKey] || false;
    } else {
      // get the permission store areas
      // iterate each permission area and check for the permission
      const permissionStoreKeys = Object.keys(this._permissionStore);
      for (let i = 0, len = permissionStoreKeys.length; i < len; i++) {
        // get the permission area
        const permissionStoreKey = permissionStoreKeys[i];
        const permissionArea = this._permissionStore[permissionStoreKey];
        // check if the area has the permission
        result = permissionArea[permissionKey] || false;
        if (result) {
          break;
        }
      }
    }

    // debug log
    // console.log('UserPermissions.check', { result, permissionKey, userPermissions: this._permissionStore });

    return result;
  }

  ///
  /// Create an EMPTY Permission Store for each of the keys in the PermissionArea enum
  ///
  private createEmptyPermissionStore(): PermissionStore {
    const permissionStore = {}; // create empty store
    // create object property for each PermissionArea enum
    for (const key in PermissionArea) {
      if (Object.prototype.hasOwnProperty.call(PermissionArea, key)) {
        const element = PermissionArea[key];
        permissionStore[element] = {};
      }
    }
    // debug log
    // console.log('UserPermissions.createEmptyPermStore', { permissionStore });
    return permissionStore as PermissionStore;
  }

  ///
  /// Apply the given Permission Set to the Permission Store
  ///
  private applyPermissionSetToStore(permissionSet: PermissionSet, permissionArea: PermissionArea): void {
    this._permissionStore[permissionArea] = permissionSet;
  }
}
