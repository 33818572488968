import { UnitsService } from './../units/units.service';
import { CommonService } from './../common/common.service';
import { Injectable, inject } from '@angular/core';
import { EMPTY_PORTFOLIO, Portfolio, PortfolioDTO } from '../../classes/commons/portfolio.model';
import { ApiWrapper } from '../common/api-wrapper.service';
import { AvailableAPI, RequestMethod, UseHeaderType } from '../../classes/commons/request-api.model';
import { FavKeys, FavouritesService } from '../common/favourites.service';
import { PermissionsService } from '@service/permissions/permissions.service';
import { PermissionArea, PermissionKey } from '@class/commons/permissions/permission-constants';
import { isEmpty } from 'lodash';
import { Router } from '@angular/router';
import { ChartdataApiService } from 'app/api-services/chartdata.api-service';
import { filterSuccessResult } from '@ngneat/query';
import { TimePeriodResolution } from '@class/commons/constants-datetime';
import { first, firstValueFrom, map } from 'rxjs';

export interface GetPortfolioChartdataParams {
  portfolioUuid: string;
  metric: string;
  timePeriod?: TimePeriodResolution;
  start: number;
  end: number;
}

// TODO: This definitely requires attention
// no-type data, poorly managed state

export type PortfolioPublish = { portfolio: Portfolio; apiCall: boolean; dataExist: boolean; apiCallFailed: boolean };

@Injectable({
  providedIn: 'root',
})
export class PortfoliosService {
  // Define Properties
  portfoliosList = {
    portfolios: [], // all the portfolios list
    apiCall: false, // if the api call has been made or not
    dataExist: false, // data is null or undefined
    apiCallFailed: false, // if by any chance the api call didn't go through, internet is down or server error
    error: {}, // if any error occured,
  };
  selectedPortfolio = {
    portfolio: EMPTY_PORTFOLIO,
    units: [],
    users: null,
    apiCall: false,
    dataExist: false,
    apiCallFailed: false,
    error: {},
  };
  serviceInitialized = false;

  private _chartdataApiService = inject(ChartdataApiService);

  constructor(
    private api: ApiWrapper,
    private commonService: CommonService,
    private permissionsService: PermissionsService,
    private unitsService: UnitsService,
    private favouritesService: FavouritesService,
    private router: Router,
  ) {}

  navigateToPortfolios(): void {
    this.router.navigateByUrl(`/portfolios`);
  }

  resetService() {
    this.portfoliosList = {
      portfolios: [], // all the portfolios list
      apiCall: false, // if the api call has been made or not
      dataExist: false, // data is null or undefined
      apiCallFailed: false, // if by any chance the api call didn't go through, internet is down or server error
      error: {}, // if any error occured,
    };
    this.resetSelectedPortfolio();
    this.serviceInitialized = false;
    this.permissionsService.clear(PermissionArea.PORTFOLIO);
  }

  // only getting used in installer
  // will remove this when fix the installer section
  init(): void {
    this.allPortfolios();
    this.serviceInitialized = true;
  }

  allPortfolios(event = null): void {
    this.portfoliosList.apiCall = false;
    this.portfoliosList.dataExist = false;
    this.portfoliosList.apiCallFailed = false;
    this.portfoliosList.portfolios = [];

    if (this.permissionsService.any([PermissionKey.GLOBAL_PORTFOLIO])) {
      this.commonService.getPortfolioList('', true).then(
        (response: { data: PortfolioDTO[] }) => {
          if (event) {
            event.target.complete();
          }
          this.portfoliosList.apiCall = true;
          if (response.data.length > 0) {
            this.portfoliosList.portfolios = response.data.map((data) => PortfolioDTO.adaptToPortfolio(data));
            this.portfoliosList.dataExist = true;
          } else {
            this.portfoliosList.portfolios = [];
            this.portfoliosList.dataExist = false;
          }
        },
        (error) => {
          if (window.location.href.includes('localhost')) {
            console.error(error);
          }
          this.portfoliosList.apiCall = true;
          this.portfoliosList.apiCallFailed = true;
        },
      );
    }
  }

  resetSelectedPortfolio(): void {
    this.selectedPortfolio = {
      portfolio: EMPTY_PORTFOLIO,
      units: [],
      users: [],
      apiCall: false,
      dataExist: false,
      apiCallFailed: false,
      error: {},
    };
    this.permissionsService.clear(PermissionArea.PORTFOLIO);
  }

  /**
   * Pass in a headerService value of null if you want to
   * update a portfolio without changing the header
   */
  getSelectedPortfolioData(portfolioId: string) {
    this.selectedPortfolio.apiCall = false;
    this.selectedPortfolio.dataExist = false;
    this.selectedPortfolio.apiCallFailed = false;
    this.commonService.getPortfolioList(portfolioId, false).then(
      (response: { data: PortfolioDTO }) => {
        this.selectedPortfolio.apiCall = true;
        if (!isEmpty(response.data)) {
          this.selectedPortfolio.portfolio = PortfolioDTO.adaptToPortfolio(response.data);
          this.permissionsService.apply(PermissionArea.PORTFOLIO, response.data.permissions);

          this.selectedPortfolio.units = this.selectedPortfolio.portfolio.unitSet;
          this.selectedPortfolio.dataExist = true;
        } else {
          this.selectedPortfolio.dataExist = false;
          this.selectedPortfolio.units = [];
        }
      },
      () => {
        this.selectedPortfolio.apiCall = true;
        this.selectedPortfolio.apiCallFailed = true;
        this.selectedPortfolio.units = [];
        // this is temporary redirection fix:
        // reason : staff user 'can see portfolios' but do not have access to unit parent portfolio (will see unit only)
        // do one or the other
        // either fix the perms in unit list or give access to the portfolio even if they have access to a unit of any portfolio
        this.navigateToPortfolios();
      },
    );
  }

  getChartData({
    portfolioUuid,
    metric,
    timePeriod = TimePeriodResolution.HOUR,
    start,
    end,
  }: GetPortfolioChartdataParams) {
    const result = this._chartdataApiService
      .getChartdata({
        field: 'portfolio_uuid',
        uuid: portfolioUuid,
        metrics: [metric],
        period: timePeriod,
        from: start,
        to: end,
        extraColumns: ['unit_uuid'],
      })
      .result$.pipe(
        filterSuccessResult(),
        first(),
        map((result) => result.data),
      );

    return firstValueFrom(result);
  }

  getPortfolioData(portfolioId: string) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/portfolios/' + portfolioId + '/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
    );
  }

  inviteUser(portfolioId: string, data) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/portfolios/' + portfolioId + '/invite_user/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }

  revokeUser(membershipId: string) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/portfolio-memberships/' + membershipId + '/',
      RequestMethod.DELETE,
      UseHeaderType.AUTHORIZED_SWDIN,
    );
  }

  // only getting used from portfolio settings
  // need to improve this
  async deleteSelectedPortfolio(portfolio: Portfolio) {
    if (!this.permissionsService.any([PermissionKey.PORTFOLIO_DELETE_PORTFOLIO])) {
      return Promise.reject('No permission to delete portfolios.');
    }
    const selectedPortfolioID = portfolio.id;
    if (!selectedPortfolioID) {
      const errorMessage = `Warning, attempting to delete a portfolio that has not been selected, id: ${selectedPortfolioID}`;

      return Promise.reject(errorMessage);
    }

    try {
      if (this.selectedPortfolio.units.length > 0) {
        const deletePromises = portfolio.unitSet.map((unit: { id: string }) => {
          return this.unitsService.deleteUnit(unit.id);
        });
        await Promise.allSettled(deletePromises);
      }

      await this.favouritesService.remove(portfolio, FavKeys.PORTFOLIOS);

      return this.api.handleRequest(
        AvailableAPI.SWITCHDIN,
        `/api/v1/portfolios/${selectedPortfolioID}/`,
        RequestMethod.DELETE,
        UseHeaderType.AUTHORIZED_SWDIN,
        {},
      );
    } catch (err) {
      console.log(err);
      return Promise<unknown>;
    }
  }
}
