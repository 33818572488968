import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProfileService } from '../../services/profile/profile.service';

@Component({
  selector: 'app-user-setting',
  templateUrl: './user-setting.component.html',
  styleUrls: ['./user-setting.component.scss'],
})
export class UserSettingComponent {
  @Input() isProfile = true;
  profile$ = this.profileService.profile$;

  constructor(
    private modalController: ModalController,
    private profileService: ProfileService,
  ) {}

  public goToProfileTab(isProfileTab: boolean): void {
    this.isProfile = isProfileTab;
  }

  public async close(): Promise<void> {
    await this.modalController.dismiss();
  }
}
