export enum RequestMethod {
  GET,
  POST,
  PUT,
  DELETE,
  PATCH,
}

export enum AvailableAPI {
  // devops or prod
  SWITCHDIN,
  // droplet link
  DROPLET,
  // open weather
  WEATHER,
  // to get the ip
  CLIENT_IP,
}

export enum UseHeaderType {
  // switchdin api's that don't need token in them
  // like auth-token, register, refresh, etc
  // weather api & client ip api don't need any extra thing in headers
  // API's
  // api-token-auth, api-token-refresh, register, reset-password
  // THESE ONE's as well
  // api.ipify.org
  DEFAULT,

  // normal auth header that will have toekn in them
  // ALL OTHER API's
  AUTHORIZED_SWDIN,

  // api's to talk to droplet to set wifi password
  // that doesn't require any auth token or something
  // API's:
  // enable_wifi, get_ssid, rescan_wifi
  DROPLET_SWDIN,

  // empty headers just in case if we don't wanted to send any headers
  // for some particular apis
  // i have mentioned the weather and client api for default
  // but not sure they'll take default or empty headers
  // these ONE's
  // openweathermap
  // Have to set empty headers for weather, otherwise we will get some CORS issue...
  EMPTY,
}

export class Request {
  public url = '';
  public header = {};
  constructor(url: any, header: any) {
    this.url = url;
    this.header = header;
  }
}
