export enum VppModes {
  DRM = 'DRM',
  FCAS = 'FCAS',
}
export enum VppDemandTypes {
  FCAS_RAISE = 'FR',
  FCAS_LOWER = 'FL',
  DEMAND_INCREASE = 'DI',
  DEMAND_REDUCTION = 'DR',
}

export const isVppDemandTypeDrm = (demandType: VppDemandTypes): boolean => {
  return DEMAND_TYPE_TO_MODE[demandType] === VppModes.DRM;
};
export const isVppDemandTypeFcas = (demandType: VppDemandTypes): boolean => {
  return DEMAND_TYPE_TO_MODE[demandType] === VppModes.FCAS;
};

export const isVppDemandIncrease = (demandType: VppDemandTypes | VppModes): boolean => {
  return demandType === VppDemandTypes.DEMAND_INCREASE;
};
export const isVppDemandReduction = (demandType: VppDemandTypes | VppModes): boolean => {
  return demandType === VppDemandTypes.DEMAND_REDUCTION;
};
export const isVppFcasRaise = (demandType: VppDemandTypes | VppModes): boolean => {
  return demandType === VppDemandTypes.FCAS_RAISE;
};
export const isVppFcasLower = (demandType: VppDemandTypes | VppModes): boolean => {
  return demandType === VppDemandTypes.FCAS_LOWER;
};

export const isVppModeFcas = (mode: VppModes): boolean => {
  return mode === VppModes.FCAS;
};
export const isVppModeDrm = (mode: VppModes): boolean => {
  return mode === VppModes.DRM;
};

export const returnVppDemandTypeGivenMode = (mode: VppModes): VppDemandTypes[] => {
  return MODE_TO_DEMAND_TYPE[mode];
};
export const returnRaiseVppDemandTypeGivenMode = (mode: VppModes): VppDemandTypes => {
  return MODE_TO_RAISE_DEMAND_TYPE[mode];
};
export const returnLowerVppDemandTypeGivenMode = (mode: VppModes): VppDemandTypes => {
  return MODE_TO_LOWER_DEMAND_TYPE[mode];
};

export const getVppDemandTypeTranslationString = (demandType: VppDemandTypes) => {
  return VPP_DEMAND_TYPE_TO_STRING?.[demandType] ?? ('VirtualPowerPlant.UnknownDemandType' as const);
};

const DEMAND_TYPE_TO_MODE: Record<VppDemandTypes, VppModes> = {
  [VppDemandTypes.DEMAND_INCREASE]: VppModes.DRM,
  [VppDemandTypes.DEMAND_REDUCTION]: VppModes.DRM,
  [VppDemandTypes.FCAS_RAISE]: VppModes.FCAS,
  [VppDemandTypes.FCAS_LOWER]: VppModes.FCAS,
};

const MODE_TO_RAISE_DEMAND_TYPE: Record<VppModes, VppDemandTypes> = {
  [VppModes.DRM]: VppDemandTypes.DEMAND_INCREASE,
  [VppModes.FCAS]: VppDemandTypes.FCAS_RAISE,
};

const MODE_TO_LOWER_DEMAND_TYPE: Record<VppModes, VppDemandTypes> = {
  [VppModes.DRM]: VppDemandTypes.DEMAND_REDUCTION,
  [VppModes.FCAS]: VppDemandTypes.FCAS_LOWER,
};

const MODE_TO_DEMAND_TYPE: Record<VppModes, VppDemandTypes[]> = {
  [VppModes.DRM]: [VppDemandTypes.DEMAND_INCREASE, VppDemandTypes.DEMAND_REDUCTION],
  [VppModes.FCAS]: [VppDemandTypes.FCAS_RAISE, VppDemandTypes.FCAS_LOWER],
};

type VppDemandTypeStrings =
  | 'VirtualPowerPlant.DemandIncrease'
  | 'VirtualPowerPlant.DemandReduction'
  | 'VirtualPowerPlant.FCASRaise'
  | 'VirtualPowerPlant.FCASLower';

const VPP_DEMAND_TYPE_TO_STRING: Record<VppDemandTypes, VppDemandTypeStrings> = {
  [VppDemandTypes.DEMAND_INCREASE]: 'VirtualPowerPlant.DemandIncrease',
  [VppDemandTypes.DEMAND_REDUCTION]: 'VirtualPowerPlant.DemandReduction',
  [VppDemandTypes.FCAS_RAISE]: 'VirtualPowerPlant.FCASRaise',
  [VppDemandTypes.FCAS_LOWER]: 'VirtualPowerPlant.FCASLower',
};
