<div class="light-brand-primary-div-background pl-16 pr-16 pt-5 pb-5 b-radius-6">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-item lines="none" class="pl-0 pr-0 bkg-transparent">
          <ion-icon class="mr-10" src="assets/icons/svg/info.svg"></ion-icon>
          <ion-text>
            <span class="fw-400 fs-13 text-light"> {{ 'CommunityBattery.MoreInfo' | translate }} </span>
            <span class="fw-600 fs-13 text-light"> {{ 'CommunityBattery.VisitHub' | translate }}</span>
          </ion-text>
        </ion-item>
      </ion-col>
      <ion-col size-md="3" size="12" class="p-0 flex-center-row">
        <ion-button
          color="button-primary"
          class="b-radius-3 fs-12 fw-600 click-here-button ion-text-capitalize"
          href="https://mybatteryhub.ausgrid.com.au/Contact-Us-Page"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ 'UnitPage.FinancialAccount.Click' | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
