import { Pipe, PipeTransform } from '@angular/core';
import { DropletHardwareVersion } from '@class/commons/enums';

const hardwareVersionImageSrc: Record<DropletHardwareVersion, string> = {
  [DropletHardwareVersion.DROPLET_ONE]: 'assets/droplets/droplet-one/droplet-one-ports.svg',
  [DropletHardwareVersion.DROPLET_PLUS_ONE]: 'assets/droplets/droplet-plus-one/droplet-plus-one-ports.svg',
  [DropletHardwareVersion.DROPLET_PLUS_TWO]: 'assets/droplets/droplet-plus-2/droplet-plus-2-ports.png',
  [DropletHardwareVersion.DROPLET_PLUS_TWO_DOT_1]:
    'assets/droplets/droplet-plus-2-dot-1/droplet-plus-2-dot-1-ports.png',
};

@Pipe({
  name: 'dropletImageSrc',
  standalone: true,
})
export class DropletImageSrcPipe implements PipeTransform {
  transform(hardwareVersion: DropletHardwareVersion, dropletUuid: string): string {
    const imageSrc = hardwareVersion ? hardwareVersionImageSrc[hardwareVersion] : this.pickFromUuid(dropletUuid);
    return imageSrc ?? hardwareVersionImageSrc[DropletHardwareVersion.DROPLET_ONE];
  }

  // hopefully in the future, the image links for the droplet will come from backend
  // and we would not have to figure this out as this is not ideal
  // for every new hardware, we have to release a new version of the app
  private pickFromUuid(uuid: string): string {
    let imageSrc = hardwareVersionImageSrc[DropletHardwareVersion.DROPLET_ONE];
    if (uuid.includes('0001c03')) {
      imageSrc = hardwareVersionImageSrc[DropletHardwareVersion.DROPLET_PLUS_TWO];
    } else if (uuid.includes('0001c0')) {
      imageSrc = hardwareVersionImageSrc[DropletHardwareVersion.DROPLET_PLUS_ONE];
    }
    return imageSrc;
  }
}
