import { Injectable } from '@angular/core';
import { ApiWrapper } from '../common/api-wrapper.service';
import { AvailableAPI, RequestMethod, UseHeaderType } from '../../classes/commons/request-api.model';
import { UserProfile } from '../profile/profile.service';
import { BaseApiService } from 'app/api-services/base.api-service';

export const queryKeys = {
  all: ['users'] as const,
  details: () => [...queryKeys.all, 'details'] as const,
  detail: (params: { userId: string }) => [...queryKeys.details(), params.userId] as const,
};

export interface UserLoginParams {
  email: string;
  password: string;
}

export interface RegisterUserDto {
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  token: string;
}

export interface ConfirmResetPasswordDto {
  email: string;
  password: string;
  token: string;
}

export interface ResetPasswordDto {
  email: string;
}

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseApiService {
  public constructor(private api: ApiWrapper) {
    super('/api/v2');
  }

  login(data: UserLoginParams) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api-token-auth/',
      RequestMethod.POST,
      UseHeaderType.DEFAULT,
      { username: data.email, password: data.password },
    );
  }

  getLoggedInUserDetails() {
    return this._query({
      queryKey: queryKeys.detail({ userId: 'me' }),
      queryFn: () =>
        this.request<UserProfile>({
          path: 'user-profile',
        }),
    });
  }

  updateUserProfile(data: UserProfile): Promise<{ data: UserProfile }> {
    return <Promise<{ data: UserProfile }>>(
      this.api.handleRequest(
        AvailableAPI.SWITCHDIN,
        '/api/v2/user-profile/',
        RequestMethod.PUT,
        UseHeaderType.AUTHORIZED_SWDIN,
        data,
      )
    );
  }

  // the response is 204 without any data, so just typed unknown there
  deleteUserProfile(): Promise<unknown> {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v2/user-profile/',
      RequestMethod.DELETE,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  verifyEmail(data: { email: string }) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/verify-email/',
      RequestMethod.POST,
      UseHeaderType.DEFAULT,
      data,
    );
  }

  registerUser(data: RegisterUserDto) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/register/',
      RequestMethod.POST,
      UseHeaderType.DEFAULT,
      data,
    );
  }

  // send instruction / link to reset the password
  resetPassword(data: ResetPasswordDto) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/reset-password/',
      RequestMethod.POST,
      UseHeaderType.DEFAULT,
      data,
    );
  }

  saveResetPassword(data: ConfirmResetPasswordDto) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/reset-password-confirm/',
      RequestMethod.POST,
      UseHeaderType.EMPTY,
      data,
    );
  }
}
