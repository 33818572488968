export const STORAGE_CONSTANTS = {
  AUTH_TOKEN: 'authToken',
  REFRESH_TOKEN: 'refreshToken',
  APP_API_KEYS_TOKEN_CREDENTIALS: 'app_api_keys_token_credentials',
} as const;

export const PORTFOLIO_CONSTANTS = {
  PORTFOLIOS_GRID_VIEW_LIMIT: 15,
  UNITS_GRID_VIEW_LIMIT: 15,
} as const;

export const REGEX = {
  EMAIL: '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$',
  DROPLET: '^([0-9A-Fa-f]{2}){5}([0-9A-Fa-f]{2})$',

  // NMI is 11 digit number
  NMI: /^\d{11}$/,
} as const;

export const DEFAULT_TITLE = 'Stormcloud';

export const TOTAL_COL = 12;

export const MAX_WIDTH_WIDE = 1400;

export enum DropletItemMode {
  DEVICE = 1,
  CONTROLLER = 2,
}

export enum ModalIdentifier {
  UNKNOWN = 1,
  DEVICE_DETAILS,
  CONTROLLER_ADD,
  CONTROLLER_DETAILS,
  PORTFOLIO_CREATE,
  PORTFOLIO_EDIT,
  UNIT_CREATE,
}

export enum States {
  INITIAL_LOADING,
  INITIAL_DATA_RECEIVED,
  INITIAL_ERROR,
  REQUEST_IN_PROCESS,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
}

export enum SkeletonPlaceholder {
  UNKNOWN = 1,
  CARD,
  TABLE_HEADER_FOUR_COLUMN,
  SINGLE_ROW,
  TABLE_WITH_HEADER_AND_FOOTER,
  TABLE_WITH_HEADER,
  WIDGET,
  CARD_WITH_COLUMN_AND_ROW,
}
