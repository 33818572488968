import { HttpErrorResponse } from '@angular/common/http';
import { isApiError } from 'app/shared/api-utils';

export type ErrorMessageOptions = {
  heading: string;
  subheading: string;
  message: string;
};

export type ErrorMessageTranslationString = {
  Heading: string;
  SubHeading: string;
  Message: string;
};

const DATA_TYPE_OBJECT = 'object';
export const HTTP_ERRORS_TRANSLATION_HEADING = 'HttpErrors';

// add the http status/code here for which you don't want to show the server error
// e.g., for 500, internal server error, we don't wanna show to user what the error from server is
const AVOID_SERVER_ERROR_LIST = [500];

export const generateHttpErrorMessage = (
  error: HttpErrorResponse,
  httpErrorsTranslateObj: { [index: string]: ErrorMessageTranslationString },
): ErrorMessageOptions => {
  const errorMessageObject: ErrorMessageOptions = {
    heading: '',
    subheading: '',
    message: '',
  };

  const errorStatus = error.status?.toString() ?? 'Default';
  const errorMsgStrings: ErrorMessageTranslationString = httpErrorsTranslateObj[errorStatus];
  let errorMsgFromServer = '';

  if (isApiError(error)) {
    errorMsgFromServer = error.error.Msg;
  } else if (error?.error && AVOID_SERVER_ERROR_LIST.findIndex((x: number) => x === Number(errorStatus)) < 0) {
    if (Array.isArray(error.error)) {
      error.error.forEach((e) => {
        const errMsg = typeof e === DATA_TYPE_OBJECT ? JSON.stringify(e) : e;

        if (errMsg) {
          errorMsgFromServer += ` ${errMsg}`;
        }
      });
    } else if (typeof error.error === DATA_TYPE_OBJECT) {
      for (const key of Object.keys(error.error)) {
        const errMsg =
          typeof error.error[key] === DATA_TYPE_OBJECT ? JSON.stringify(error.error[key]) : error.error[key];

        if (errMsg) {
          errorMsgFromServer += ` ${errMsg}`;
        }
      }
    } else if (typeof error.error === 'string') {
      errorMsgFromServer = error.error;
    }
  }

  errorMessageObject.heading = errorMsgStrings.Heading.trim();
  errorMessageObject.subheading = `${errorMsgStrings.SubHeading} ${error.statusText} ${error.status}`.trim();
  errorMessageObject.message = `${errorMsgStrings.Message} ${errorMsgFromServer}`.trim();

  return errorMessageObject;
};
