import { PermissionArea, PermissionSet, PermissionDTO } from '@class/commons/permissions/permission-constants';
import { createAction, props } from '@ngrx/store';

export const clearUserPermissions = createAction('[User Permissions] Clear');

export const applyUserPermissions = createAction(
  '[User Permissions] Apply',
  props<{ permissionArea: PermissionArea; permissionData: PermissionSet | Array<PermissionDTO> }>(),
);
export const clearUserPermissionsArea = createAction(
  '[User Permissions] Clear Area',
  props<{ permissionArea: PermissionArea }>(),
);
