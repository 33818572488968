import { VppUnitDeviceTypes } from '../../vpp/vpp-unit.model';

export enum DeviceMqttKey {
  GRID = 'SWDINPV.MMXN3.TotW.instMag[MX]',
  SOLAR = 'SWDINPV.MMXN1.TotW.instMag[MX]',
  BESS = 'SWDINPV.ZBAT.TotW.instMag[MX]', // batteryTotalPower
  SOC = 'SWDINPV.ZBAT.SoC.instMag[MX]', // stored, batterySoC
  LOAD = 'SWDINPV.MMXN2.TotW.instMag[MX]',
  TEMP = 'SWDINPV.SITE.Temp[C].Avg', // site temperature
  DEMAND_REDUCTION_TOTAL = 'SWDINPV.DT[DR].TotW[Total]',
  DEMAND_INCREASE_TOTAL = 'SWDINPV.DT[DI].TotW[Total]',
}

export const DEVICE_TYPE_TO_MQTT_KEY = {
  [VppUnitDeviceTypes.PV_INV]: DeviceMqttKey.SOLAR,
  [VppUnitDeviceTypes.BESS]: DeviceMqttKey.BESS,
} as const;

export type DropletMetric = {
  values: number[];
  times: Date[];
  lastValue?: string | number;
  valueUpdated: boolean;
  viewInStreamTab: boolean;
  dashboardConfig: string;
  displayDecimals: number;
  displayOrder: number;
  metricKey: string;
  metricKeyWithoutDeviceNumber: string;
  streamName: string;
  unitsAbbr: string;
  unitsDesc: string;
  isIec61850: boolean;
  name: string;
};
