import { Input, Component, ChangeDetectionStrategy, inject, Output, EventEmitter } from '@angular/core';
import { InventoryDevicesListToDisplay } from '../utils/add-device-types';
import { AddDeviceStore } from '../store/add-device.store';

@Component({
  selector: 'app-device-state-card-list',
  template: `
    <ng-container *ngrxLet="vm$ as vm">
      <ng-container *ngFor="let device of devicesList">
        <app-device-state-card
          [device]="device"
          [configuring]="vm.configuring[device.device_unique_id]"
          [removing]="vm.removing[device.device_unique_id]"
          [recentlyConfigured]="vm.recentlyConfigured[device.device_unique_id]"
          (configureClick)="handleConfigureDeviceClick($event)"
          (removeClick)="handleRemoveDeviceClick($event)"
        />
      </ng-container>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceStateCardListComponent {
  @Input() devicesList: InventoryDevicesListToDisplay[];

  @Output() configureDeviceClick = new EventEmitter<InventoryDevicesListToDisplay>();
  @Output() removeDeviceClick = new EventEmitter<InventoryDevicesListToDisplay>();

  readonly _addDeviceStore = inject(AddDeviceStore);

  readonly vm$ = this._addDeviceStore.select(({ configuring, removing, recentlyConfigured }) => ({
    configuring,
    removing,
    recentlyConfigured,
  }));

  handleConfigureDeviceClick(device: InventoryDevicesListToDisplay) {
    this.configureDeviceClick.emit(device);
  }

  handleRemoveDeviceClick(device: InventoryDevicesListToDisplay) {
    this.removeDeviceClick.emit(device);
  }
}
