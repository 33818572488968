import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-input-validation-errors',
  templateUrl: './input-validation-errors.component.html',
  styleUrls: ['./input-validation-errors.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class InputValidationErrorsComponent {
  @Input() label: string;
  @Input() invalid: boolean;
  @Input() dirty: boolean;
  @Input() touched: boolean;
  @Input() errors: ValidationErrors | null;
}
