import { ThemeInterface, ThemeVariable } from '@theme/theme-interface';

export function applyThemeToDocument(theme: ThemeInterface, document: Document) {
  Object.keys(theme.styles).forEach((themeStyle) => {
    theme.styles[themeStyle].forEach((style: ThemeVariable) => {
      document.documentElement.style.setProperty(style.themeVariable, style.value);
    });
  });

  Object.keys(theme.buttons).forEach((themeStyle) => {
    theme.buttons[themeStyle].forEach((style: ThemeVariable) => {
      document.documentElement.style.setProperty(style.themeVariable, style.value);
    });
  });

  Object.keys(theme.svgIcons).forEach((themeStyle) => {
    theme.svgIcons[themeStyle].forEach((style: ThemeVariable) => {
      document.documentElement.style.setProperty(style.themeVariable, style.value);
    });
  });

  Object.keys(theme.tablesRow).forEach((themeStyle) => {
    theme.tablesRow[themeStyle].forEach((style: ThemeVariable) => {
      document.documentElement.style.setProperty(style.themeVariable, style.value);
    });
  });
}
