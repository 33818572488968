export enum ProgramNames {
  SMARTER_HOMES = 'smarter home program',
  COMMUNITY_BATTERY = 'community battery program',
  FLEXIBLE_EXPORT_SAPN = 'flexible exports program - sapn',
  FLEXIBLE_EXPORT_AUSNET = 'flexible exports program - ausnet',
}
export enum ControllerAction {
  NONE,
  UPDATE,
  CREATE,
}

export interface Slides {
  heading: string;
  index: number;
  skipOnBack: boolean;
  skipToSlide: number | null;
  showBackButton: boolean;
  isFirstSlide: boolean;
  isLastSlide: boolean;
}

export interface FlexibleExportConnection {
  name: string;
  shortDesc: string;
  status: number;
  error: string;
  metricKey: string;
  metricKeyFinder: string;
}

export enum SapnStatus {
  CHECKING = 1,
  OFFLINE = 0,
  ONLINE = 2,
}

export enum RegistrationApiResponseStatus {
  REGISTRATION_FAILED = 'Registration Failed',
  NOT_READY = 'Not Ready',
  REGISTERED = 'Registered',
}

export type AgentsServerType = {
  code: string;
  name: string;
  uuid: string;
};

export type RegistrationApiType = {
  Status: RegistrationApiResponseStatus;
  Code: number;
  Data: {
    LFDI: boolean;
    nmi: boolean;
  };
  Msg: string;
};

class Programs {
  agents?: Array<AgentsServerType>;
  jurisdiction?: string;
  mandatory?: boolean;
  name?: string;
  notify?: boolean;
  uuid?: string;

  constructor(item: Programs) {
    this.agents = item.agents;
    this.jurisdiction = item.jurisdiction;
    this.mandatory = item.mandatory;
    this.name = item.name;
    this.notify = item.notify;
    this.uuid = item.uuid;
  }
}

export class ProgramsServerType extends Programs {
  // TODO:
  // need to create controller-types type
  // at the moment we are using the same controller type on other places
  // unit controller, so need to see how it's been used there
  // and then need to create type
  controller_types?: Array<any>;
  data?: null;

  constructor(item: ProgramsWizard) {
    super(item);
    this.controller_types = item.controllerTypes;
  }
}

export class ProgramsWizard extends Programs {
  // TODO:
  // same here as above comment
  controllerTypes: Array<any>;

  constructor(item: ProgramsServerType) {
    super(item);
    this.controllerTypes = item.controller_types;
  }

  convertToServerType(item: ProgramsWizard): ProgramsServerType {
    return new ProgramsServerType(item);
  }
}

export class Registration {
  status: RegistrationApiResponseStatus;
  code: number;
  data: {
    LFDI: boolean;
    nmi: boolean;
  };
  msg: string;
  constructor(item: RegistrationApiType) {
    this.status = item.Status;
    this.code = item.Code;
    this.data = item.Data;
    this.msg = item.Msg;
  }
}
