import { UserOrgMembership } from '@custom-types/user-accounts/user-org-membership.model';
import { createAction, props } from '@ngrx/store';

export const fetchUserOrganisationMemberships = createAction('[User Organisation Memberships] Fetch All');
export const setUserOrganisationMemberships = createAction(
  '[User Organisation Memberships] Set Organisations',
  props<{ memberships: UserOrgMembership[] }>(),
);
export const setSelectedUserOrganisationMembership = createAction(
  '[User Organisation Memberships] Set Selected Organisation',
  props<{ orgUuid: string }>(),
);
