import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { STORAGE_CONSTANTS } from '../classes/commons/constants';
import { CommonService } from '../services/common/common.service';
import { StorageService } from '../services/common/storage.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard {
  constructor(
    private router: Router,
    private commonService: CommonService,
    private storageService: StorageService,
  ) {}
  async canActivate(): Promise<boolean> {
    try {
      const token = await this.storageService.getFromLocalOrSessionStorage(STORAGE_CONSTANTS.AUTH_TOKEN);
      if (!token) {
        this.commonService.loggedIn = false;
        return true;
      } else {
        this.commonService.loggedIn = true;
        this.router.navigateByUrl('');
        return false;
      }
    } catch (error) {
      console.error(`Error grabbing token for logged in guard: ${error}`);
      return false;
    }
  }
}
