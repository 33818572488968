import { VirtualPowerPlantTypes } from '../../services/virtualPowerPlants/virtual-power-plants.service';
import { VppMarketGetPayload } from './vpp-markets.model';
import { VppUnitDeviceTypes } from './vpp-unit.model';
import { VppThresholdType } from './vpp.model';

export type VppControlGroupUnitAvailableControls = {
  // TODO: not sure what's coming in this at the moment
  // need to assign correct types
  PV_INV: Array<any>;
  'A/C': Array<any>;
  BESS: Array<any>;
  HW: Array<any>;
};

export type VppControlGroupUnitDevices = {
  category: VppUnitDeviceTypes;
  controls: Array<string>;
  device_type_name: string;
  groups: Array<string>;
  id: string;
  number: number;
  uuid: string;
};

export type VppControlGroupUnitDemandValuesType = {
  'A/C': number;
  'A/CAvail': number;
  'A/CDisp': number;
  BESS: number;
  BESSAvail: number;
  BESSDisp: number;
  HW: number;
  HWAvail: number;
  HWDisp: number;
  'PV-INV': number;
  'PV-INVAvail': number;
  'PV-INVDisp': number;
};

export type VppControlGroupUnitCG = {
  [index: string]: VppControlGroupUnitDevices[];
};

export type VppControlGroupUnitType = {
  'PV-INV_pwr': string;
  pv_pwr: string;
  ac: number;
  bess: number;
  hw: number;
  pv: number;
  acSelected: boolean;
  bessSelected: boolean;
  pvSelected: boolean;
  hwSelected: boolean;
  activity: boolean;
  control: string;
  demandIncrease: string;
  demandReduction: string;
  deviceClassDemandIncrease: string;
  deviceClassDemandIncreaseAvail: string;
  deviceClassDemandIncreaseDisp: string;
  deviceClassDemandReduction: string;
  deviceClassDemandReductionAvail: string;
  deviceClassDemandReductionDisp: string;
  groupCountAC: number;
  groupCountBESS: number;
  groupCountHW: number;
  groupCountPV: number;
  id: string;
  name: string;
  uuid: string;
  DIValues: VppControlGroupUnitDemandValuesType;
  DRValues: VppControlGroupUnitDemandValuesType;
  available_control: VppControlGroupUnitAvailableControls;
  devices: Array<VppControlGroupUnitDevices>;
  control_groups: VppControlGroupUnitCG;
};

export type VppControlGroupType = {
  active: boolean;
  demandIncrease: string;
  demandReduction: string;
  description: string;
  id: string;
  name: string;
  num_devices: number;
  num_units: number;
  toggle: boolean;
  vpp: string;
  units: Array<VppControlGroupUnitType>;
};

// vpp type (vpp data)
export class VppMetricData {
  public DiAvail: string | number | null;
  public DiDispatch: string | number | null;
  public DrAvail: string | number | null;
  public DrDispatch: string | number | null;
  public Demand: string | number | null;
  public currentDemand: string | number | null;
  public demandTimeNow: string | null;
  public timeNow: string | null;
  constructor() {
    this.DiAvail = null;
    this.DiDispatch = null;
    this.DrAvail = null;
    this.DrDispatch = null;
    this.Demand = null;
    this.currentDemand = null;
    this.demandTimeNow = null;
    this.timeNow = null;
  }
}

export class VppScadaServerType {
  public device_id: string;
  public device_name?: string;
  public id?: string;
  public unit_id: string;
  public vpp_id?: string;
  constructor(item: VppScada) {
    this.device_id = item.deviceId;
    this.unit_id = item.unitId;
    if (item.id !== null) {
      this.id = item.id;
    }
  }
}
export class VppScada {
  public deviceId: string;
  public deviceName?: string;
  public id?: string;
  public unitId: string;
  public vppId?: string;
  public unitDevices?: Array<any>;
  constructor(item: VppScadaServerType) {
    this.deviceId = item.device_id;
    this.deviceName = item.device_name;
    this.id = item.id;
    this.unitId = item.unit_id;
    this.vppId = item.vpp_id;
  }
}

export class VppServerType {
  public timezone: string;
  public admt_max_demand: number;
  public admt_min_demand: number;
  public description: string;
  public id: string;
  public name: string;
  public reference_device: string | null;
  public reference_metric: string | null;
  public reference_metric_unit: string | null;
  public reference_unit: string | null;
  public num_devices: number;
  public num_portfolios: number;
  public num_units: number;
  public permissions: [string, string][];
  public scada: Array<VppScadaServerType>;
  public users: Array<number>;
  public uuid: string;
  public threshold_type: VppThresholdType;
  public spot_max_price: number;
  public spot_min_price: number;
  public market_region: VppMarketGetPayload;
  constructor(data: Vpp) {
    this.timezone = data.timezone;
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.reference_unit = data.referenceUnit;
    this.reference_device = data.referenceDevice;
    this.reference_metric = data.referenceMetric;
    this.admt_max_demand = data.admtMaxDemand;
    this.admt_min_demand = data.admtMinDemand;
    this.threshold_type = data.thresholdType;
    this.spot_max_price = data.maxPrice;
    this.spot_min_price = data.minPrice;
    this.market_region = data.market;
    if (data.scada && data.scada.length > 0) {
      this.scada = data.scada.map((point: VppScada) => new VppScadaServerType(point));
    }
  }
}
export class Vpp {
  public timezone: string;
  public admtMaxDemand: number;
  public admtMinDemand: number;
  public description: string;
  public id: string;
  public name: string;
  public referenceDevice: string | null;
  public referenceMetric: string | null;
  public referenceMetricUnit: string | null;
  public referenceUnit: string | null;
  public numDevices: number;
  public numPortfolios: number;
  public numUnits: number;
  public permissions: [string, string][];
  public scada: Array<VppScada>;
  public users: Array<number>;
  public uuid: string;
  public vppMetricData: VppMetricData;
  public controller: VirtualPowerPlantTypes | null;
  public deviceMetricIdCombination: string | null;
  public thresholdType: VppThresholdType;
  public maxPrice: number;
  public minPrice: number;
  public market: VppMarketGetPayload;
  constructor(item: VppServerType) {
    this.timezone = item.timezone;
    this.admtMaxDemand = item.admt_max_demand;
    this.admtMinDemand = item.admt_min_demand;
    this.description = item.description;
    this.id = item.id;
    this.name = item.name;
    this.referenceDevice = item.reference_device;
    this.referenceMetric = item.reference_metric;
    this.referenceMetricUnit = item.reference_metric_unit;
    this.referenceUnit = item.reference_unit;
    this.numDevices = item.num_devices;
    this.numPortfolios = item.num_portfolios;
    this.numUnits = item.num_units;
    this.permissions = item.permissions;
    this.scada = item.scada.map((scada: VppScadaServerType) => new VppScada(scada));
    this.users = item.users;
    this.uuid = item.uuid;
    this.vppMetricData = new VppMetricData();
    this.controller = null;
    this.deviceMetricIdCombination = null;
    this.thresholdType = item.threshold_type;
    this.maxPrice = item.spot_max_price;
    this.minPrice = item.spot_min_price;
    this.market = item.market_region;
  }
}

export class NewVppServer {
  public name: string;
  public description: string;
  public reference_unit?: string | null;
  public reference_device?: string | null;
  public reference_metric?: string | null;
  public admt_max_demand: number | null;
  public admt_min_demand: number | null;
  public scada?: Array<VppScadaServerType> | null;
  constructor(data: Vpp) {
    this.name = data.name;
    this.description = data.description;
    this.reference_unit = data.referenceUnit;
    this.reference_device = data.referenceDevice;
    this.reference_metric = data.referenceMetric;
    this.admt_min_demand = data.admtMinDemand;
    this.admt_max_demand = data.admtMaxDemand;
    if (data.scada && data.scada.length > 0) {
      this.scada = data.scada.map((point: VppScada) => new VppScadaServerType(point));
    }
  }
}
