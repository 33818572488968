import { ThemeInterface } from './theme-interface';
import { defaultTheme } from './default-theme';
import { getThemeIconsLight, ThemeMap } from './theme-map';

const asfgJacanaTheme: ThemeInterface = {
  name: ThemeMap.ASFG_JACANA,
  type: defaultTheme.type,
  styles: {
    global: [{ themeVariable: '--ion-item-background', value: 'white' }],
    brandPrimary: [
      { themeVariable: '--ion-color-brand-primary', value: '#0B3B60' },
      { themeVariable: '--ion-color-brand-primary-rgb', value: '11, 59, 96' },
      { themeVariable: '--ion-color-brand-primary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-brand-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-brand-primary-shade', value: '#0a3454' },
      { themeVariable: '--ion-color-brand-primary-tint', value: '#234f70' },
    ],
    textPrimary: [
      { themeVariable: '--ion-color-text-primary', value: '#000000' },
      { themeVariable: '--ion-color-text-primary-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-text-primary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-text-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-text-primary-shade', value: '#000000' },
      { themeVariable: '--ion-color-text-primary-tint', value: '#1a1a1a' },
    ],
    textTertiary: defaultTheme.styles.textTertiary,
    textSecondary: defaultTheme.styles.textSecondary,
    backgroundPrimary: [
      { themeVariable: '--ion-color-background-primary', value: '#F8F8F8' },
      { themeVariable: '--ion-color-background-primary-rgb', value: '248, 248, 248' },
      { themeVariable: '--ion-color-background-primary-contrast', value: '#000000' },
      { themeVariable: '--ion-color-background-primary-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-background-primary-shade', value: '#dadada' },
      { themeVariable: '--ion-color-background-primary-tint', value: '#f9f9f9' },
    ],
    backgroundSecondary: [
      { themeVariable: '--ion-color-background-secondary', value: '#F36D23' },
      { themeVariable: '--ion-color-background-secondary-rgb', value: '243, 109, 35' },
      { themeVariable: '--ion-color-background-secondary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-background-secondary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-background-secondary-shade', value: '#d6601f' },
      { themeVariable: '--ion-color-background-secondary-tint', value: '#f47c39' },
    ],
    backgroundTertiary: [
      { themeVariable: '--ion-color-background-tertiary', value: '#0B3B60' },
      { themeVariable: '--ion-color-background-tertiary-rgb', value: '11, 59, 96' },
      { themeVariable: '--ion-color-background-tertiary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-background-tertiary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-background-tertiary-shade', value: '#0a3454' },
      { themeVariable: '--ion-color-background-tertiary-tint', value: '#234f70' },
    ],
    subTabLabels: [
      { themeVariable: '--ion-color-sub-tab-labels', value: '#0B3B60' },
      { themeVariable: '--ion-color-sub-tab-labels-rgb', value: '58,36,94' },
      // at the moment using the contrast value for the tab labels
      { themeVariable: '--ion-color-sub-tab-labels-contrast', value: '#0B3B60' },
      { themeVariable: '--ion-color-sub-tab-labels-contrast-rgb', value: '90, 40, 127' },
      { themeVariable: '--ion-color-sub-tab-labels-shade', value: '#332053' },
      { themeVariable: '--ion-color-sub-tab-labels-tint', value: '#4e3a6e' },
    ],
    solar: defaultTheme.styles.solar,
    battery: [
      { themeVariable: '--ion-color-battery', value: '#9AAA75' },
      { themeVariable: '--ion-color-battery-rgb', value: '154, 170, 117' },
      { themeVariable: '--ion-color-battery-contrast', value: '#000000' },
      { themeVariable: '--ion-color-battery-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-battery-shade', value: '#889667' },
      { themeVariable: '--ion-color-battery-tint', value: '#a4b383' },
    ],
    consumption: [
      { themeVariable: '--ion-color-consumption', value: '#37424A' },
      { themeVariable: '--ion-color-consumption-rgb', value: '55, 66, 74' },
      { themeVariable: '--ion-color-consumption-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-consumption-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-consumption-shade', value: '#303a41' },
      { themeVariable: '--ion-color-consumption-tint', value: '#4b555c' },
    ],
    consumed: [
      { themeVariable: '--ion-color-consumed', value: '#00A8B4' },
      { themeVariable: '--ion-color-consumed-rgb', value: '0, 168, 180' },
      { themeVariable: '--ion-color-consumed-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-consumed-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-consumed-shade', value: '#00949e' },
      { themeVariable: '--ion-color-consumed-tint', value: '#1ab1bc' },
    ],
  },
  headerLogo: {
    path: getThemeIconsLight[ThemeMap.ASFG_JACANA],
    style: {
      height: '33px',
    },
  },
  footerLogo: defaultTheme.footerLogo,
  footerColoredLogo: defaultTheme.footerColoredLogo,
  iconsSvgPath: defaultTheme.iconsSvgPath,
  chartColors: {
    primary: { value: '#FF9B00', opacValue: 'rgba(255, 155, 0, 0.4)' },
    light: { value: '#F36D23', opacValue: 'rgba(243, 109, 35, 0.4)' },
    secondary: { value: '#37424A', opacValue: 'rgba(55, 66, 74, 0.4)' },
    tertiary: { value: '#9AAA75', opacValue: 'rgba(154, 170, 117, 0.4)' },

    colorFive: { value: '#7BA24B', opacValue: 'rgba(123, 162, 75, 0.4)' },
    colorSix: { value: '#3933DE', opacValue: 'rgba(57, 51, 222, 0.4)' },
    colorSeven: { value: '#2E4C68', opacValue: 'rgba(46, 76, 104, 0.4)' },
    colorEight: { value: '#6B9FAF', opacValue: 'rgba(107, 159, 175, 0.4)' },
    colorNine: { value: '#DE3355', opacValue: 'rgba(222, 51, 85, 0.4)' },
    colorTen: { value: '#96F70D', opacValue: 'rgba(150, 247, 13, 0.4)' },
  },
  vppChartColors: defaultTheme.vppChartColors,
  streamChartColor: '#F36D23',
  buttons: {
    primary: [
      { themeVariable: '--ion-color-button-primary', value: '#0B3B60' },
      { themeVariable: '--ion-color-button-primary-rgb', value: '11, 59, 96' },
      { themeVariable: '--ion-color-button-primary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-button-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-button-primary-shade', value: '#0a3454' },
      { themeVariable: '--ion-color-button-primary-tint', value: '#234f70' },
    ],
    secondary: [
      { themeVariable: '--ion-color-button-secondary', value: '#F36D23' },
      { themeVariable: '--ion-color-button-secondary-rgb', value: '243, 109, 35' },
      { themeVariable: '--ion-color-button-secondary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-button-secondary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-button-secondary-shade', value: '#d6601f' },
      { themeVariable: '--ion-color-button-secondary-tint', value: '#f47c39' },
    ],
    success: [
      { themeVariable: '--ion-color-success', value: '#889D50' },
      { themeVariable: '--ion-color-success-rgb', value: '136, 157, 80' },
      { themeVariable: '--ion-color-success-contrast', value: '#000000' },
      { themeVariable: '--ion-color-success-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-success-shade', value: '#788a46' },
      { themeVariable: '--ion-color-success-tint', value: '#94a762' },
    ],
    warning: [
      { themeVariable: '--ion-color-warning', value: '#F36D23' },
      { themeVariable: '--ion-color-warning-rgb', value: '243, 109, 35' },
      { themeVariable: '--ion-color-warning-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-warning-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-warning-shade', value: '#d6601f' },
      { themeVariable: '--ion-color-warning-tint', value: '#f47c39' },
    ],
    danger: [
      { themeVariable: '--ion-color-danger', value: '#E60D21' },
      { themeVariable: '--ion-color-danger-rgb', value: ' 230, 13, 33' },
      { themeVariable: '--ion-color-danger-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-danger-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-danger-shade', value: '#ca0b1d' },
      { themeVariable: '--ion-color-danger-tint', value: '#e92537' },
    ],
    successLite: [
      { themeVariable: '--ion-color-success-lite', value: '#DEEBBB' },
      { themeVariable: '--ion-color-success-lite-rgb', value: '222, 235, 187' },
      { themeVariable: '--ion-color-success-lite-contrast', value: '#000000' },
      { themeVariable: '--ion-color-success-lite-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-success-lite-shade', value: '#c3cfa5' },
      { themeVariable: '--ion-color-success-lite-tint', value: '#e1edc2' },
    ],
    warningLite: [
      { themeVariable: '--ion-color-warning-lite', value: '#FEEACB' },
      { themeVariable: '--ion-color-warning-lite-rgb', value: '254, 234, 203' },
      { themeVariable: '--ion-color-warning-lite-contrast', value: '#000000' },
      { themeVariable: '--ion-color-warning-lite-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-warning-lite-shade', value: '#e0ceb3' },
      { themeVariable: '--ion-color-warning-lite-tint', value: '#feecd0' },
    ],
    dangerLite: [
      { themeVariable: '--ion-color-danger-lite', value: '#FCD1D5' },
      { themeVariable: '--ion-color-danger-lite-rgb', value: '252, 209, 213' },
      { themeVariable: '--ion-color-danger-lite-contrast', value: '#000000' },
      { themeVariable: '--ion-color-danger-lite-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-danger-lite-shade', value: '#deb8bb' },
      { themeVariable: '--ion-color-danger-lite-tint', value: '#fcd6d9' },
    ],
  },
  svgIcons: {
    primarySvg: [
      { themeVariable: '--ion-color-primary-svg', value: '#F36D23' },
      { themeVariable: '--ion-color-primary-svg-rgb', value: '243, 109, 35' },
      { themeVariable: '--ion-color-primary-svg-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-primary-svg-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-primary-svg-shade', value: '#d6601f' },
      { themeVariable: '--ion-color-primary-svg-tint', value: '#f47c39' },
    ],
  },
  tablesRow: {
    primaryTile: [
      { themeVariable: '--ion-color-table-primary-tile', value: '#FAFAFA' },
      { themeVariable: '--ion-color-table-primary-tile-rgb', value: '250, 250, 250' },
      { themeVariable: '--ion-color-table-primary-tile-contrast', value: '#000000' },
      { themeVariable: '--ion-color-table-primary-tile-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-table-primary-tile-shade', value: '#dcdcdc' },
      { themeVariable: '--ion-color-table-primary-tile-tint', value: '#fbfbfb' },
    ],
    secondaryTile: [
      { themeVariable: '--ion-color-table-secondary-tile', value: '#F5F5F5' },
      { themeVariable: '--ion-color-table-secondary-tile-rgb', value: '245, 245, 245' },
      { themeVariable: '--ion-color-table-secondary-tile-contrast', value: '#000000' },
      { themeVariable: '--ion-color-table-secondary-tile-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-table-secondary-tile-shade', value: '#d8d8d8' },
      { themeVariable: '--ion-color-table-secondary-tile-tint', value: '#f6f6f6' },
    ],
    grayTile: [
      { themeVariable: '--ion-color-table-gray-tile', value: '#FCFCFC' },
      { themeVariable: '--ion-color-table-gray-tile-rgb', value: '252, 252, 252' },
      { themeVariable: '--ion-color-table-gray-tile-contrast', value: '#000000' },
      { themeVariable: '--ion-color-table-gray-tile-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-table-gray-tile-shade', value: '#dedede' },
      { themeVariable: '--ion-color-table-gray-tile-tint', value: '#fcfcfc' },
    ],
  },
};

export { asfgJacanaTheme };
