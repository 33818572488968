import { ThemeInterface } from './theme-interface';
import { defaultTheme } from './default-theme';
import { getThemeIconsLight, ThemeMap } from './theme-map';

const gmEnergyTheme: ThemeInterface = {
  name: ThemeMap.GM_ENERGY,
  type: defaultTheme.type,
  styles: {
    global: [{ themeVariable: '--ion-item-background', value: 'white' }],
    brandPrimary: [
      { themeVariable: '--ion-color-brand-primary', value: '#16375f' },
      { themeVariable: '--ion-color-brand-primary-rgb', value: '22, 55, 95' },
      { themeVariable: '--ion-color-brand-primary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-brand-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-brand-primary-shade', value: '#133054' },
      { themeVariable: '--ion-color-brand-primary-tint', value: '#2d4b6f' },
    ],
    textPrimary: [
      { themeVariable: '--ion-color-text-primary', value: '#373f42' },
      { themeVariable: '--ion-color-text-primary-rgb', value: '55, 63, 66' },
      { themeVariable: '--ion-color-text-primary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-text-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-text-primary-shade', value: '#30373a' },
      { themeVariable: '--ion-color-text-primary-tint', value: '#4b5255' },
    ],
    textTertiary: defaultTheme.styles.textTertiary,
    textSecondary: defaultTheme.styles.textSecondary,
    backgroundPrimary: [
      { themeVariable: '--ion-color-background-primary', value: '#e5f0fc' },
      { themeVariable: '--ion-color-background-primary-rgb', value: '229, 240, 252' },
      { themeVariable: '--ion-color-background-primary-contrast', value: '#000000' },
      { themeVariable: '--ion-color-background-primary-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-background-primary-shade', value: '#cad3de' },
      { themeVariable: '--ion-color-background-primary-tint', value: '#e8f2fc' },
    ],
    backgroundSecondary: [
      { themeVariable: '--ion-color-background-secondary', value: '#5cc9e3' },
      { themeVariable: '--ion-color-background-secondary-rgb', value: '92, 201, 227' },
      { themeVariable: '--ion-color-background-secondary-contrast', value: '#000000' },
      { themeVariable: '--ion-color-background-secondary-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-background-secondary-shade', value: '#51b1c8' },
      { themeVariable: '--ion-color-background-secondary-tint', value: '#6ccee6' },
    ],
    backgroundTertiary: [
      { themeVariable: '--ion-color-background-tertiary', value: '#0B3B60' },
      { themeVariable: '--ion-color-background-tertiary-rgb', value: '11, 59, 96' },
      { themeVariable: '--ion-color-background-tertiary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-background-tertiary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-background-tertiary-shade', value: '#0a3454' },
      { themeVariable: '--ion-color-background-tertiary-tint', value: '#234f70' },
    ],
    subTabLabels: [
      { themeVariable: '--ion-color-sub-tab-labels', value: '#0B3B60' },
      { themeVariable: '--ion-color-sub-tab-labels-rgb', value: '58,36,94' },
      // at the moment using the contrast value for the tab labels
      { themeVariable: '--ion-color-sub-tab-labels-contrast', value: '#0B3B60' },
      { themeVariable: '--ion-color-sub-tab-labels-contrast-rgb', value: '90, 40, 127' },
      { themeVariable: '--ion-color-sub-tab-labels-shade', value: '#332053' },
      { themeVariable: '--ion-color-sub-tab-labels-tint', value: '#4e3a6e' },
    ],
    solar: [
      { themeVariable: '--ion-color-solar', value: '#ffc038' },
      { themeVariable: '--ion-color-solar-rgb', value: '255, 192, 56' },
      { themeVariable: '--ion-color-solar-contrast', value: '#000000' },
      { themeVariable: '--ion-color-solar-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-solar-shade', value: '#e0a931' },
      { themeVariable: '--ion-color-solar-tint', value: '#ffc64c' },
    ],
    battery: [
      { themeVariable: '--ion-color-battery', value: '#04aec4 ' },
      { themeVariable: '--ion-color-battery-rgb', value: '4, 174, 196' },
      { themeVariable: '--ion-color-battery-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-battery-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-battery-shade', value: '#0499ac' },
      { themeVariable: '--ion-color-battery-tint', value: '#1db6ca' },
    ],
    consumption: defaultTheme.styles.consumption,
    consumed: defaultTheme.styles.consumed,
  },
  headerLogo: {
    path: getThemeIconsLight[ThemeMap.GM_ENERGY],
    style: {
      height: '33px',
    },
  },
  footerLogo: defaultTheme.footerLogo,
  footerColoredLogo: defaultTheme.footerColoredLogo,
  iconsSvgPath: defaultTheme.iconsSvgPath,
  chartColors: {
    ...defaultTheme.chartColors,
    primary: { value: '#ffc038', opacValue: 'rgba(255, 192, 56, 0.4)' }, // solar
    tertiary: { value: '#04aec4', opacValue: 'rgba(4, 174, 196, 0.4)' }, // battery
  },
  vppChartColors: defaultTheme.vppChartColors,
  streamChartColor: '#5cc9e3',
  buttons: {
    ...defaultTheme.buttons,
    primary: [
      { themeVariable: '--ion-color-button-primary', value: '#16375f' },
      { themeVariable: '--ion-color-button-primary-rgb', value: '22, 55, 95' },
      { themeVariable: '--ion-color-button-primary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-button-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-button-primary-shade', value: '#133054' },
      { themeVariable: '--ion-color-button-primary-tint', value: '#2d4b6f' },
    ],
    secondary: [
      { themeVariable: '--ion-color-button-secondary', value: '#5cc9e3' },
      { themeVariable: '--ion-color-button-secondary-rgb', value: '92, 201, 227' },
      { themeVariable: '--ion-color-button-secondary-contrast', value: '#000000' },
      { themeVariable: '--ion-color-button-secondary-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-button-secondary-shade', value: '#51b1c8' },
      { themeVariable: '--ion-color-button-secondary-tint', value: '#6ccee6' },
    ],
  },
  svgIcons: {
    primarySvg: [
      { themeVariable: '--ion-color-primary-svg', value: '#5cc9e3' },
      { themeVariable: '--ion-color-primary-svg-rgb', value: '92, 201, 227' },
      { themeVariable: '--ion-color-primary-svg-contrast', value: '#000000' },
      { themeVariable: '--ion-color-primary-svg-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-primary-svg-shade', value: '#51b1c8' },
      { themeVariable: '--ion-color-primary-svg-tint', value: '#6ccee6' },
    ],
  },
  tablesRow: {
    primaryTile: [
      { themeVariable: '--ion-color-table-primary-tile', value: '#FAFAFA' },
      { themeVariable: '--ion-color-table-primary-tile-rgb', value: '250, 250, 250' },
      { themeVariable: '--ion-color-table-primary-tile-contrast', value: '#000000' },
      { themeVariable: '--ion-color-table-primary-tile-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-table-primary-tile-shade', value: '#dcdcdc' },
      { themeVariable: '--ion-color-table-primary-tile-tint', value: '#fbfbfb' },
    ],
    secondaryTile: [
      { themeVariable: '--ion-color-table-secondary-tile', value: '#F5F5F5' },
      { themeVariable: '--ion-color-table-secondary-tile-rgb', value: '245, 245, 245' },
      { themeVariable: '--ion-color-table-secondary-tile-contrast', value: '#000000' },
      { themeVariable: '--ion-color-table-secondary-tile-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-table-secondary-tile-shade', value: '#d8d8d8' },
      { themeVariable: '--ion-color-table-secondary-tile-tint', value: '#f6f6f6' },
    ],
    grayTile: [
      { themeVariable: '--ion-color-table-gray-tile', value: '#FCFCFC' },
      { themeVariable: '--ion-color-table-gray-tile-rgb', value: '252, 252, 252' },
      { themeVariable: '--ion-color-table-gray-tile-contrast', value: '#000000' },
      { themeVariable: '--ion-color-table-gray-tile-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-table-gray-tile-shade', value: '#dedede' },
      { themeVariable: '--ion-color-table-gray-tile-tint', value: '#fcfcfc' },
    ],
  },
};

export { gmEnergyTheme };
