import { IsPlatform } from './is-platform';

// the values will be set in Api Wrapper Constructor for IsPlatform as that is the first constructor to be called
// if we are using this class variables somewhere before that
// it'll throw undefined
export class IsPlatformValues {
  private readonly _IS_PLATFORM_ANDROID: boolean;
  public get IS_PLATFORM_ANDROID(): boolean {
    return IsPlatform.ANDROID;
  }

  private readonly _IS_PLATFORM_CAPACITOR: boolean;
  public get IS_PLATFORM_CAPACITOR(): boolean {
    return IsPlatform.CAPACITOR;
  }

  private readonly _IS_PLATFORM_CORDOVA: boolean;
  public get IS_PLATFORM_CORDOVA(): boolean {
    return IsPlatform.CORDOVA;
  }

  private readonly _IS_PLATFORM_DESKTOP: boolean;
  public get IS_PLATFORM_DESKTOP(): boolean {
    return IsPlatform.DESKTOP;
  }

  private readonly _IS_PLATFORM_ELECTRON: boolean;
  public get IS_PLATFORM_ELECTRON(): boolean {
    return IsPlatform.ELECTRON;
  }

  private readonly _IS_PLATFORM_HYBRID: boolean;
  public get IS_PLATFORM_HYBRID(): boolean {
    return IsPlatform.HYBRID;
  }

  private readonly _IS_PLATFORM_IOS: boolean;
  public get IS_PLATFORM_IOS(): boolean {
    return IsPlatform.IOS;
  }

  private readonly _IS_PLATFORM_IPAD: boolean;
  public get IS_PLATFORM_IPAD(): boolean {
    return IsPlatform.IPAD;
  }

  private readonly _IS_PLATFORM_IPHONE: boolean;
  public get IS_PLATFORM_IPHONE(): boolean {
    return IsPlatform.IPHONE;
  }

  private readonly _IS_PLATFORM_MOBILE: boolean;
  public get IS_PLATFORM_MOBILE(): boolean {
    return IsPlatform.MOBILE;
  }

  private readonly _IS_PLATFORM_MOBILE_WEB: boolean;
  public get IS_PLATFORM_MOBILE_WEB(): boolean {
    return IsPlatform.MOBILE_WEB;
  }

  private readonly _IS_PLATFORM_PHABLET: boolean;
  public get IS_PLATFORM_PHABLET(): boolean {
    return IsPlatform.PHABLET;
  }

  private readonly _IS_PLATFORM_PWA: boolean;
  public get IS_PLATFORM_PWA(): boolean {
    return IsPlatform.PWA;
  }

  private readonly _IS_PLATFORM_TABLET: boolean;
  public get IS_PLATFORM_TABLET(): boolean {
    return IsPlatform.TABLET;
  }

  constructor() {}

  // public printAll(): void {
  //   console.log('android:', this.IS_PLATFORM_ANDROID);
  //   console.log('capacitor:', this.IS_PLATFORM_CAPACITOR);
  //   console.log('cordova:', this.IS_PLATFORM_CORDOVA);
  //   console.log('desktop:', this.IS_PLATFORM_DESKTOP);
  //   console.log('electron:', this.IS_PLATFORM_ELECTRON);
  //   console.log('hybrid:', this.IS_PLATFORM_HYBRID);
  //   console.log('ios:', this.IS_PLATFORM_IOS);
  //   console.log('ipad:', this.IS_PLATFORM_IPAD);
  //   console.log('iphone:', this.IS_PLATFORM_IPHONE);
  //   console.log('mobile:', this.IS_PLATFORM_MOBILE);
  //   console.log('mobileweb:', this.IS_PLATFORM_MOBILE_WEB);
  //   console.log('phablet:', this.IS_PLATFORM_PHABLET);
  //   console.log('pwa:', this.IS_PLATFORM_PWA);
  //   console.log('tablet:', this.IS_PLATFORM_TABLET);
  // }
}
