import { Injectable, inject } from '@angular/core';
import { ApiWrapper, AvailableAPI, RequestMethod, UseHeaderType } from '@service/common/api-wrapper.service';
import { IMqttMessage } from 'ngx-mqtt';
import { Observable, Subject, Subscription, map } from 'rxjs';
import {
  DeviceConfigurationPostApi,
  DeviceConnectionTypes,
  DeviceTypeConfigurationAttributes,
  DropletMqttMessages,
  DropletMqttTopics,
  InventoryDevices,
  DropletPortDeviceForDiscovery,
  ManufacturerDeviceType,
  MqttMessage,
} from '../utils/add-device-types';
import { NgxMqttWrapperService } from '@service/core/ngx-mqtt-wrapper.service';
import { isEmpty } from 'lodash';
import { DataTypeStrings } from '@class/commons/enums';
import { DevicesApiService } from 'app/api-services/devices/devices.api-service';
import qs from 'qs';
import { getCustomInputTypeFromDeviceConfigAttributeType } from '../utils/utils';

@Injectable({
  providedIn: 'root',
})
export class AddDeviceService {
  private _inventorySubscription: Subscription;
  private _mqttMessage: Subject<MqttMessage> = new Subject();

  private _deviceApiService = inject(DevicesApiService);

  constructor(
    private _api: ApiWrapper,
    private _ngxMqttWrapper: NgxMqttWrapperService,
  ) {}

  destroyInventoryMqttSubscription(): void {
    this._ngxMqttWrapper.unsubscribe(this._inventorySubscription);
  }

  /** ** ** ** ** MQTT ** ** ** ** */
  getInventory(uuid: string) {
    this._inventorySubscription = this._ngxMqttWrapper
      .observe(`${DropletMqttTopics.CONFIG}${uuid}${DropletMqttTopics.INVENTORY}` as const)
      .subscribe((msg: IMqttMessage) => {
        const message = JSON.parse(msg.payload.toString()) as MqttMessage;
        this._mqttMessage.next(message);
      });

    return this._mqttMessage.asObservable();
  }

  startDiscovery(uuid: string) {
    return this._ngxMqttWrapper.publish(
      `${DropletMqttTopics.CONFIG}${uuid}${DropletMqttTopics.COMMANDS_FROM_FRONTEND}`,
      JSON.stringify({ message_type: DropletMqttMessages.START_DISCOVERY }),
      { qos: 0 },
    );
  }

  requestInitialInventory(uuid: string) {
    return this._ngxMqttWrapper.publish(
      `${DropletMqttTopics.CONFIG}${uuid}${DropletMqttTopics.COMMANDS_FROM_FRONTEND}`,
      JSON.stringify({ message_type: DropletMqttMessages.INVENTORY_REQUEST }),
      { qos: 0 },
    );
  }

  sendDeviceForDiscovery(uuid: string, devicePort: DropletPortDeviceForDiscovery) {
    return this._ngxMqttWrapper.publish(
      `${DropletMqttTopics.CONFIG}${uuid}${DropletMqttTopics.COMMANDS_FROM_FRONTEND}`,
      JSON.stringify({ message_type: DropletMqttMessages.SEARCH_DEVICE, port: devicePort }),
      { qos: 0 },
    );
  }

  // only calling this if there is a device config that's on droplet but not on server
  // so it'll not be sync, so having this to have a functionality to remove the device from droplet
  // instead of asking user to call switchdin to get that config removed from droplet
  // this is basically a fallback scenario
  removeConfiguredDeviceFromDroplet(uuid: string, device_unique_id: string) {
    return this._ngxMqttWrapper.publish(
      `${DropletMqttTopics.CONFIG}${uuid}${DropletMqttTopics.COMMANDS_FROM_FRONTEND}`,
      JSON.stringify({
        message_type: DropletMqttMessages.DELETE_DEVICE,
        device_unique_id,
      }),
      { qos: 0 },
    );
  }

  retryDiscovery(uuid: string) {
    return this._ngxMqttWrapper.publish(
      `${DropletMqttTopics.CONFIG}${uuid}${DropletMqttTopics.COMMANDS_FROM_FRONTEND}`,
      JSON.stringify({ message_type: DropletMqttMessages.RETRY_DISCOVERY }),
      { qos: 0 },
    );
  }

  stopDiscovery(uuid: string) {
    return this._ngxMqttWrapper.publish(
      `${DropletMqttTopics.CONFIG}${uuid}${DropletMqttTopics.COMMANDS_FROM_FRONTEND}`,
      JSON.stringify({ message_type: DropletMqttMessages.STOP_DISCOVERY }),
      { qos: 0 },
    );
  }

  /** ** ** ** ** HTTP API ** ** ** ** */

  getConnectionTypes(): Observable<DeviceConnectionTypes[]> {
    return this._api
      .handleObservableRequest({
        useAPI: AvailableAPI.SWITCHDIN,
        url: `/api/v1/iec61850/connection-types/`,
        requestMethod: RequestMethod.GET,
        useHeader: UseHeaderType.AUTHORIZED_SWDIN,
        requestData: {},
      })
      .pipe(
        map((data: DeviceConnectionTypes[]) => {
          const connectionTypes = data.map((item) => {
            return { name: item.name, id: item.id };
          });
          return connectionTypes;
        }),
      );
  }

  /**
 * API response
 * [{
    "id": "07fad372",
    "uuid": "6d078e8e-dd27-11ea-8f8e-0242ac110004",
    "model_name": "SB5.0-1AV-41",
    "model_number": 9404,
    "default_slave_id": 3,
    "manufacturer_name": "SMA",
    "logical_device_type_name": "SMA Sunny Boy Inverter",
    "logical_device_type": "6cbac374-dd27-11ea-8f8e-0242ac110004",
    "logical_device_type_short_name": "SMA-SBxx-1AV-41",
    "logical_device_type_memory_map_type": "Modbus",
    "protocol": "SMAInverter",
    "droplet_driver": "eb8071ca-92aa-11eb-9f05-0242ac110003",
    "generic_device_type": "1-Phase PV Inverter",
    "device_type_connection_attributes": {},
    "device_type_attributes": {
      "meter_role": {
          "description": "Select meter role if meter connected",
          "attribute_type": "int",
          "attribute_group": "config",
          "options": [
            {
                "option_text": "Duplicate battery meter role",
                "value": 205
            },
            {
                "option_text": "Duplicate generator meter role",
                "value": 204
            },
            {
                "option_text": "Duplicate load meter role",
                "value": 203
            },
            {
                "option_text": "Duplicate hybrid meter role",
                "value": 202
            },
            {
                "option_text": "Duplicate PV meter role",
                "value": 201
            },
            {
                "option_text": "Duplicate grid meter role",
                "value": 200
            },
            {
                "option_text": "Battery meter role",
                "value": 105
            },
            {
                "option_text": "Generator meter role",
                "value": 104
            },
            {
                "option_text": "Load meter role",
                "value": 103
            },
            {
                "option_text": "Hybrid meter role",
                "value": 102
            },
            {
                "option_text": "PV meter role",
                "value": 101
            },
            {
                "option_text": "Grid meter role",
                "value": 100
            },
            {
                "option_text": "Ignore meter role",
                "value": 0
            }
        ]
      }
    },
    "approved": false,
    "logical_device_type_image": null,
    "qr_code_available": false,
    "onboarding_information": [],
    "display_name": "SMA Sunny Boy Inverter - SB5.0-1AV-41"
  }]
  */
  getDeviceTypeConfigAttributes({ info }: InventoryDevices): Observable<{
    deviceTypes: {
      selectedValue: string;
      options: {
        name: string;
        value: string;
      }[];
    };
    deviceConfigAttributes: {
      [deviceAttributeName: string]: DeviceTypeConfigurationAttributes;
    };
  }> {
    const { model_name, manufacturer, model_number } = info;

    const queryParams = qs.stringify(
      {
        model_name,
        manufacturer,
        model_number,
        attribute_group: 'config',
        is_cloud_asset: false,
      },
      {
        encode: false,
      },
    );

    return this._api
      .handleObservableRequest({
        useAPI: AvailableAPI.SWITCHDIN,
        url: `/api/v1/iec61850/device-types/?${queryParams}`,
        requestMethod: RequestMethod.GET,
        useHeader: UseHeaderType.AUTHORIZED_SWDIN,
        requestData: {},
      })
      .pipe(
        map((data: ManufacturerDeviceType[]) => {
          const deviceConfigAttributes = {} as { [deviceAttributeName: string]: DeviceTypeConfigurationAttributes };
          const deviceTypes = {} as {
            selectedValue: string;
            options: {
              name: string;
              value: string;
            }[];
          };
          if (data?.length > 1) {
            deviceTypes.options = data.map((deviceType) => ({
              value: deviceType.id,
              name: deviceType.display_name ?? deviceType.logical_device_type_name,
            }));
            deviceTypes.selectedValue = data[0].id;
          }
          data?.forEach((deviceType) => {
            if (deviceType?.device_type_attributes && !isEmpty(deviceType?.device_type_attributes)) {
              for (const key in deviceType?.device_type_attributes) {
                if (Object.prototype.hasOwnProperty.call(deviceType?.device_type_attributes, key)) {
                  const attribute = deviceType?.device_type_attributes[key];
                  // creating temp attributes to store the options
                  // checking for bool or boolean and if options are empty then adding true and false
                  // if options are empty for bool, it'll ask user to take input
                  // we don't want to do that for boolean
                  // so just adding these values here so user can select them from options
                  const tempAttributeOptions =
                    (attribute.attribute_type === DataTypeStrings.BOOL ||
                      attribute.attribute_type === DataTypeStrings.BOOLEAN) &&
                    attribute.options?.length <= 0
                      ? [
                          {
                            option_text: 'True',
                            value: true,
                          },
                          {
                            option_text: 'False',
                            value: false,
                          },
                        ]
                      : attribute.options;
                  deviceConfigAttributes[key] = {
                    name: key,
                    description: attribute.description,
                    attribute_type: attribute.attribute_type as DataTypeStrings,
                    options: tempAttributeOptions?.map((option) => ({
                      name: option.option_text,
                      value: option.value,
                    })),
                    selectedValue: tempAttributeOptions[0]?.value ?? null,
                    takeUserCustomInput: tempAttributeOptions && tempAttributeOptions?.length > 0 ? false : true,
                    customInputType: getCustomInputTypeFromDeviceConfigAttributeType(
                      attribute.attribute_type as DataTypeStrings,
                    ),
                  };
                }
              }
            }
          });
          return {
            deviceTypes,
            deviceConfigAttributes,
          };
        }),
      );
  }

  configureDevice(device: DeviceConfigurationPostApi) {
    const { mutate, result$ } = this._deviceApiService.configureDevice();

    mutate(device);

    return result$;
  }

  removeConfiguredDevice(deviceId: string) {
    const { mutate, result$ } = this._deviceApiService.deconfigureDevice();

    mutate({ deviceId });

    return result$;
  }
}
