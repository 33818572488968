<ion-label class="stack-label ion-padding-start">{{ label }}</ion-label>
<ion-item lines="none" [ngClass]="ionItemExtraCss">
  <ion-input
    [type]="inputType"
    class="stack-label-input"
    [ngClass]="{ 'input-error': invalid && (dirty || touched) }"
    [placeholder]="placeholder"
    [disabled]="isDisable"
    [clearInput]="enableClearIcon"
    [(ngModel)]="value"
    (ionChange)="ionChange()"
    (ionBlur)="ionBlur()"
    [autofocus]="autofocus"
  ></ion-input>
</ion-item>

<app-input-validation-errors
  class="width-100-pct"
  [label]="label"
  [invalid]="invalid"
  [dirty]="dirty"
  [touched]="touched"
  [errors]="errors"
></app-input-validation-errors>
