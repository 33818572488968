import { Injectable } from '@angular/core';
import { ApiWrapper, AvailableAPI, RequestMethod, UseHeaderType } from '@service/common/api-wrapper.service';
import { Observable, shareReplay, map } from 'rxjs';
import { ManufacturerAndDevicesForDiscovery, DeviceConnectionTypesNames } from '../utils/add-device-types';
import { isEmpty } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ManufacturerDevicesForDiscoveryService {
  constructor(private _api: ApiWrapper) {}

  /** ** ** ** ** HTTP API ** ** ** ** */
  getIEC61850Manufacturer(): Observable<ManufacturerAndDevicesForDiscovery[]> {
    return this._api
      .handleObservableRequest<ManufacturerAndDevicesForDiscovery[]>({
        useAPI: AvailableAPI.SWITCHDIN,
        url: '/api/v1/iec61850/manufacturers/?attribute_group=discovery',
        requestMethod: RequestMethod.GET,
        useHeader: UseHeaderType.AUTHORIZED_SWDIN,
        requestData: {},
      })
      .pipe(
        shareReplay(),
        map((manufacturers) => {
          const filteredManufacturers = manufacturers?.filter((manufacturer) => manufacturer.device_types?.length > 0);
          const mappedManufacturers = filteredManufacturers.map((manufacturer) => {
            return {
              ...manufacturer,
              device_types: manufacturer.device_types.map((deviceType) => {
                const attributeValues = {};
                if (!isEmpty(deviceType.device_type_attributes)) {
                  for (const key in deviceType.device_type_attributes) {
                    attributeValues[key] = null;
                  }
                }
                if (!isEmpty(deviceType.device_type_connection_attributes[DeviceConnectionTypesNames.LAN_WIFI])) {
                  attributeValues['port'] = parseInt(
                    deviceType.device_type_connection_attributes[DeviceConnectionTypesNames.LAN_WIFI]?.PortNumber
                      ?.default,
                  );
                  attributeValues['slaveid'] = parseInt(
                    deviceType.device_type_connection_attributes[DeviceConnectionTypesNames.LAN_WIFI]?.SlaveID?.default,
                  );
                }
                return {
                  ...deviceType,
                  attributeValues,
                };
              }),
            };
          });
          return mappedManufacturers;
        }),
      );
  }
}
