import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { CustomButtonSize } from '@class/commons/custom-buttons';
import { DeviceStates, InventoryDevicesListToDisplay } from '../utils/add-device-types';
import { isEmpty } from 'lodash';
import { BrowserLogger } from '@class/core/browser-logger';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import {
  AutomatedTestResponse,
  AutomatedTestStatus,
  AutomatedTestingService,
} from '@service/devices/automated-testing.service';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatestWith,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { DevicesApiService } from 'app/api-services/devices/devices.api-service';
import { EndpointDeviceDTO } from '@class/units/endpoint/endpoint-payload.model';
import { AnalyticsService } from '@service/common/analytics.service';
import { TranslationsService } from '@service/common/translations.service';

const DIAGNOSTIC_TIMEOUT_MS = 120000;

@Component({
  selector: 'app-device-state-card',
  templateUrl: './device-state-card.component.html',
  styleUrls: ['./device-state-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceStateCardComponent {
  readonly IsEmpty = isEmpty;
  readonly CustomButtonSize = CustomButtonSize;
  readonly DeviceStates = DeviceStates;
  readonly PermissionKey = PermissionKey;
  readonly AutomatedTestStatus = AutomatedTestStatus;

  readonly #device = new ReplaySubject<InventoryDevicesListToDisplay>();
  readonly device$ = this.#device.asObservable();

  readonly #deviceId = new BehaviorSubject<string>(null);
  readonly deviceId$ = this.#deviceId.asObservable();

  readonly #recentlyConfigured = new BehaviorSubject(false);
  readonly recentlyConfigured$ = this.#recentlyConfigured.asObservable();

  readonly #endpointDevice = new BehaviorSubject<EndpointDeviceDTO>(null);
  readonly endpointDevice$ = this.#endpointDevice.asObservable();

  readonly #diagnosticId = new BehaviorSubject<string>(null);
  readonly diagnosticId$ = this.#diagnosticId.asObservable();

  readonly #diagnostic = new BehaviorSubject<AutomatedTestResponse>(null);
  readonly diagnostic$ = this.#diagnostic.asObservable();

  readonly #testMessage = new BehaviorSubject<string>(null);
  readonly testMessage$ = this.#testMessage.asObservable();

  private readonly destroy$ = new Subject<void>();

  @Input() set device(value: InventoryDevicesListToDisplay) {
    this.#device.next(value);

    if (value.deviceId) {
      this.#deviceId.next(value.deviceId);
    }
  }

  @Input() removing: boolean;
  @Input() configuring: boolean;

  @Input() set recentlyConfigured(value: boolean | undefined) {
    this.#recentlyConfigured.next(value ?? false);
  }

  @Output() configureClick: EventEmitter<InventoryDevicesListToDisplay> = new EventEmitter();
  @Output() removeClick: EventEmitter<InventoryDevicesListToDisplay> = new EventEmitter();

  private readonly _automatedTestingService = inject(AutomatedTestingService);

  readonly #analyticsService = inject(AnalyticsService);
  readonly #translationsService = inject(TranslationsService);

  private readonly startDiagnostic = this._automatedTestingService.startAutomatedTest();

  private readonly stopDiagnostic = this._automatedTestingService.stopAutomatedTest();

  private readonly getDiagnostic = this._automatedTestingService.getAutomatedTest.bind(this._automatedTestingService);

  #devicesApiService = inject(DevicesApiService);

  #testStatus = new BehaviorSubject<AutomatedTestStatus>(null);
  testStatus$ = this.#testStatus.asObservable();

  ngOnInit() {
    if (this.#analyticsService.plugins.posthog.isFeatureEnabled('automated-testing')) {
      this.startDiagnostic.result$
        .pipe(
          filter((res) => res.isSuccess),
          map((res) => this.#diagnosticId.next(res.data?.data?.uuid)),
          takeUntil(this.destroy$),
        )
        .subscribe();

      this.recentlyConfigured$
        .pipe(
          filter((value) => !!value),
          combineLatestWith(this.endpointDevice$),
          map(([, device]) => device),
          filter((device) => device != null && device.automated_test_supported),
          tap((device) => this.startDiagnosticMutation(device.uuid)),
          take(1),
          takeUntil(this.destroy$),
        )
        .subscribe();

      this.deviceId$
        .pipe(
          filter((deviceId) => !!deviceId),
          switchMap(
            (deviceId) =>
              this.#devicesApiService.getDevice({
                deviceId,
              }).result$,
          ),
          map((res) => res.data),
          tap((device) => this.#endpointDevice.next(device)),
          takeUntil(this.destroy$),
        )
        .subscribe();

      this.diagnosticId$
        .pipe(
          combineLatestWith(this.endpointDevice$),
          filter(([testId, device]) => !!testId && !!device),
          switchMap(
            ([testId, device]) =>
              this.getDiagnostic({
                testId,
                device: device?.uuid,
              }).result$,
          ),
          map((res) => res.data),
          tap((diag) => this.#diagnostic.next(diag)),
          tap((diag) => this.#testStatus.next(diag?.test_status)),
          tap((diag) => this.#testMessage.next(diag?.test_message)),
          takeUntil(this.destroy$),
        )
        .subscribe();

      this.device$
        .pipe(
          filter((device) => !!device?.deviceUuid && !!device.automatedTestSupported),
          map((device) => device.deviceUuid),
          tap(() => this.#testStatus.next(AutomatedTestStatus.InitialChecking)),
          switchMap(
            (deviceUuid) => this._automatedTestingService.getLatestAutomatedTestByDeviceUuid(deviceUuid).result$,
          ),
          map((res) => res?.data?.results[0]),
          tap((diag) => this.#testStatus.next(diag?.test_status)),
          tap((diag) => this.#testMessage.next(diag?.test_message)),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async startDiagnosticMutation(deviceUuid: string) {
    this.#testStatus = new BehaviorSubject(AutomatedTestStatus.Starting);
    this.#testMessage.next(null);
    this.testStatus$ = this.#testStatus.asObservable();

    const { data: diagnostic } = await this.startDiagnostic.mutateAsync({
      device: deviceUuid,
    });

    setTimeout(() => {
      const currentDiagnostic = this.#diagnostic.getValue();

      if (diagnostic.uuid === currentDiagnostic.uuid && currentDiagnostic.test_status === AutomatedTestStatus.Pending) {
        this.#testStatus.next(AutomatedTestStatus.Failed);
        this.#testStatus.complete();
        this.#testMessage.next(this.#translationsService.instant('AutomatedTesting.TimeoutOccurred'));

        this.stopDiagnostic.mutate({
          testId: currentDiagnostic.uuid,
        });
      }
    }, DIAGNOSTIC_TIMEOUT_MS);
  }

  handleConfigureClick(device: InventoryDevicesListToDisplay): void {
    this.configureClick.emit(device);
  }

  handleRemoveClick(device: InventoryDevicesListToDisplay): void {
    BrowserLogger.log('handleRemoved', this.removeClick, device);
    this.removeClick.emit(device);
  }

  handleDiagnosticButtonClick(
    buttonAction: 'start-test' | 'stop-test',
    device: EndpointDeviceDTO,
    diagnostic?: AutomatedTestResponse,
  ) {
    BrowserLogger.log('handleDiagnosticButtonClick', buttonAction, device, diagnostic);

    switch (buttonAction) {
      case 'stop-test':
        this.#testStatus.next(AutomatedTestStatus.Stopping);
        this.stopDiagnostic.mutate({
          testId: diagnostic.uuid,
        });
        break;
      case 'start-test':
        this.startDiagnosticMutation(device.uuid);
        break;
    }
  }
}
