import { DataTypeStrings, DropletHardwareVersion, DropletPortTypes } from '@class/commons/enums';

export enum DeviceStates {
  DISCOVERED = 'discovered',
  CONFIGURED = 'configured',
  DISCOVERY_FAILED = 'discovery_failed',
  STATIC_CONFIGURED = 'statically_configured',
  INITIALIZED = 'initialised',
}

export enum DropletMqttMessages {
  START_DISCOVERY = 'start_discovery',
  INVENTORY_REQUEST = 'inventory_request',
  INVENTORY_CHANGE = 'inventory_change',
  SEARCH_DEVICE = 'search_device',
  RETRY_DISCOVERY = 'retry_discovery',
  CONFIGURE_DEVICE = 'configure_device',
  DELETE_DEVICE = 'delete_device',
  STOP_DISCOVERY = 'stop_discovery',
}

export enum DropletMqttTopics {
  CONFIG = 'config/',
  INVENTORY = '/inventory',
  COMMANDS_FROM_FRONTEND = '/commands',
}

export enum DeviceConfigScreen {
  WAITING_FOR_INITIAL_INVENTORY = 1,
  INITIAL_INVENTORY_RECEIVED,
  INITIAL_INVENTORY_NOT_RECEIVED,
  DROPLET_IMAGE_PORT_LAYOUT,
  MANUFACTURERS_LIST,
  SELECTED_MANUFACTURER_DEVICES,
  SELECTED_DEVICE_DISCOVERY_ATTRIBUTES,
  LEGACY_DEVICES,
  DEVICE_SETTINGS_FOR_CONFIGURATION,
}

export enum InventoryDeviceConnectionType {
  IP = 'ip',
  SPEED_WIRE = 'speedwire',
  SERIAL = 'serial',
  BLUETOOTH = 'bluetooth',
  USB = 'usb',
}

export enum DeviceConnectionTypesNames {
  USB = 'USB',
  SERIAL = 'Serial',
  CANBUS = 'Canbus',
  LAN_WIFI = 'LAN/WIFI',
  DIRECT_CONNECT_ETHERNET = 'Direct Connect Ethernet',
  BLUETOOTH = 'Bluetooth',
  RF_MESH = 'RF Mesh',
}

/** ** ** ** TYPES/INTERFACES ** ** ** */
export interface AddDeviceError {
  initialInventory: string;
}

export type DeviceTypes = {
  selectedValue: string;
  options: {
    name: string;
    value: string;
  }[];
};

export type InventoryDevicesListToDisplay = InventoryDevices & {
  type: DropletPortTypes;
  port_number: number;
  adapterType?: string | null;
  deviceConfigAttributes?: { [deviceAttributeName: string]: DeviceTypeConfigurationAttributes };

  // for some devices
  // the api will return multiple device types, so need to select what device type it is
  // this is just useless, it should be fixed on our end so we don't have to ask the user about this
  deviceTypes?: DeviceTypes;

  // need device id for deleting device from droplet
  // only to removed the device from server and droplet
  deviceId?: string;
  deviceUuid?: string;

  // for the UI
  // only show automated testing if device support that
  automatedTestSupported?: boolean;
};

export type DropletDetailDevices = {
  id: string;
  uuid: string;
  automatedTestSupported: boolean;
};

export type DropletDetail = {
  uuid: string;
  id: string;
  devices: {
    [droplet_device_unique_id: string]: DropletDetailDevices;
  };
  hardwareVersion?: DropletHardwareVersion;
};

export type DropletLayoutPort = {
  type: DropletPortTypes;
  port_number: number;
  adapterType?: string | null;
  hardwareVersion?: DropletHardwareVersion;
};

export type DropletLayoutForDiscovery = {
  dropletUuid: string;
  ports: DropletLayoutPort[];
  hardwareVersion?: DropletHardwareVersion;
};

/**
 * Sample Mqtt inventory msg
 *
 * {
    "message_type": "inventory_request",
    "ports": [
        {
            "type": "usb",
            "port_number": 12,
            "devices": []
        },
        {
            "type": "usb",
            "port_number": 13,
            "devices": []
        },
        {
            "type": "usb",
            "port_number": 14,
            "devices": []
        },
        {
            "type": "usb",
            "port_number": 15,
            "devices": []
        },
        {
            "type": "wifi",
            "port_number": 0,
            "devices": []
        },
        {
            "type": "ethernet",
            "port_number": 0,
            "devices": [
                {
                    "device_unique_id": "discovery_agent_ip/192.168.4.21_5",
                    "device_state": "discovery_failed",
                    "info": {},
                    "error_codes": "QCellsG2Hybrid: 'Device type'",
                    "connection_descriptor": {
                        "type": "ip",
                        "ip": "192.168.4.21",
                        "device": "eth0",
                        "connection_is_local": false,
                        "mac": "12:34:56:78:90:21"
                    }
                },
                {
                    "device_unique_id": "discovery_agent_ip/192.168.4.127_6",
                    "device_state": "discovery_failed",
                    "info": {},
                    "error_codes": "QCellsG3Inverter: Failed to read from inverter, check external control is enabled",
                    "connection_descriptor": {
                        "type": "ip",
                        "ip": "192.168.4.127",
                        "device": "eth0",
                        "connection_is_local": false,
                        "mac": "4c:ba:d7:1a:62:be"
                    }
                },
                {
                    "device_unique_id": "QCells_HSHP4601QAJ09002AB",
                    "device_state": "configured",
                    "info": {
                        "model_name": "HSHP-5001",
                        "model_number": 5001,
                        "serial_number": "HSHP4601QAJ09002AB",
                        "extra_info": {
                            "software_version": 263184
                        },
                        "manufacturer": "QCells",
                        "driver_identifier": "QCellsG2Hybrid"
                    },
                    "error_codes": "",
                    "connection_descriptor": {
                        "start_type": "ethernet",
                        "start_port": "0",
                        "adapter_key": "direct",
                        "type": "ip",
                        "ip": "192.168.4.21",
                        "device": "eth0",
                        "connection_is_local": false,
                        "mac": "12:34:56:78:90:21"
                    }
                }
            ]
        },
        {
            "type": "bluetooth",
            "port_number": 0,
            "devices": [
                {
                    "device_unique_id": "SMA_BT_2100415010",
                    "device_state": "configured",
                    "info": {
                        "dev_type": "BT",
                        "model_name": "SMA001d",
                        "serial_number": "2100415010",
                        "manufacturer": "SMA",
                        "driver_identifier": "SmaBluetooth"
                    },
                    "error_codes": "",
                    "connection_descriptor": {
                        "start_type": "bluetooth",
                        "start_port": "0",
                        "adapter_key": "direct",
                        "type": "bluetooth",
                        "mac": "00:80:25:19:C8:CE",
                        "name": "SMA001d SN: 2100415010 SN2100415010"
                    }
                }
            ]
        },
        {
            "type": "unknown",
            "port_number": 0,
            "devices": []
        },
        {
            "type": "all-interfaces",
            "port_number": 0,
            "devices": []
        }
    ]
}

  devices adaptors
  {
    "message_type": "inventory_request",
    "ports": [
        {
            "type": "usb",
            "port_number": 12,
            "devices": []
        },
        {
            "type": "usb",
            "port_number": 13,
            "adapters": [
                {
                    "type": "serial",
                    "devices": [
                        {
                            "device_unique_id": "Eastron_22998022",
                            "device_state": "configured",
                            "info": {
                                "model_name": "EASTRON3P",
                                "serial_number": 22998022,
                                "is_meter": true,
                                "connection_descriptor": {
                                    "slaveid": 1
                                },
                                "extra_info": {
                                    "system_type": "3.0"
                                },
                                "manufacturer": "Eastron",
                                "driver_identifier": "EastronPowerMeter"
                            },
                            "error_codes": "",
                            "connection_descriptor": {
                                "start_type": "usb",
                                "start_port": "13",
                                "adapter_key": "serial",
                                "type": "serial",
                                "device": "/dev/ttySWDIN13",
                                "model_name": "EASTRON3P",
                                "manufacturer_name": "Eastron",
                                "model_number": null,
                                "default_slave_id": 1,
                                "logical_device_type_name": "Eastron Power Meter",
                                "protocol": "EastronPowerMeter",
                                "generic_device_type": "3-Phase Power Meter",
                                "device_type_connection_attributes": {},
                                "approved": true
                            }
                        }
                    ]
                }
            ]
        },
        {
            "type": "usb",
            "port_number": 14,
            "devices": []
        },
        {
            "type": "usb",
            "port_number": 15,
            "adapters": [
                {
                    "type": "ethernet",
                    "devices": [
                        {
                            "device_unique_id": "Eguana_ET0000030",
                            "device_state": "configured",
                            "info": {
                                "model_name": "Eguana PCS",
                                "serial_number": "ET0000030",
                                "manufacturer": "Eguana",
                                "driver_identifier": "EguanaPcs"
                            },
                            "error_codes": "",
                            "connection_descriptor": {
                                "start_type": "usb",
                                "start_port": "15",
                                "adapter_key": "ethernet",
                                "type": "ip",
                                "ip": "10.42.3.35",
                                "device": "ethswdin15",
                                "connection_is_local": false,
                                "connection_is_internet": false,
                                "mac": "00:15:f5:00:00:01"
                            }
                        }
                    ]
                }
            ]
        },
        {
            "type": "wifi",
            "port_number": 0,
            "devices": []
        },
        {
            "type": "ethernet",
            "port_number": 0,
            "devices": [
                {
                    "device_unique_id": "Fronius_30457638",
                    "device_state": "discovered",
                    "info": {
                        "manufacturer": "Fronius",
                        "model_name": "Primo",
                        "serial_number": "30457638",
                        "connection_descriptor": {
                            "slaveid": 1
                        },
                        "agent_suffix": "29ea6fe2c25f6b041a3662df94019ca5f1e6a4e4",
                        "driver_identifier": "FroniusInverter",
                        "agent_unique_id": "Fronius_29ea6fe2c25f6b041a3662df94019ca5f1e6a4e4"
                    },
                    "error_codes": "",
                    "connection_descriptor": {
                        "type": "ip",
                        "mac": "cc:f9:57:fd:32:ed",
                        "port": 502,
                        "ip": "192.168.1.104",
                        "device": "eth0",
                        "connection_is_local": false,
                        "connection_is_internet": false,
                        "slaveid": 1
                    }
                },
                {
                    "device_unique_id": "SMA_3006247034",
                    "device_state": "discovered",
                    "info": {
                        "model_name": "SB5.0-1AV-41",
                        "model_number": 9404,
                        "serial_number": 3006247034,
                        "manufacturer": "SMA",
                        "driver_identifier": "SMAInverter"
                    },
                    "error_codes": "",
                    "connection_descriptor": {
                        "type": "ip",
                        "mac": "d8:a9:8b:71:21:aa",
                        "port": 502,
                        "modbus_connection_type": "MAC",
                        "timeout": 0.5,
                        "ip": "192.168.1.129",
                        "device": "eth0",
                        "connection_is_local": false,
                        "connection_is_internet": false,
                        "host": "SMA3006247034.local"
                    }
                }
            ]
        },
        {
            "type": "bluetooth",
            "port_number": 0,
            "devices": []
        },
        {
            "type": "unknown",
            "port_number": 0,
            "devices": []
        },
        {
            "type": "all-interfaces",
            "port_number": 0,
            "devices": []
        }
    ]
}
 */

type InventoryDeviceInfo = {
  dev_type: string;
  model_name: string;
  serial_number: string;
  manufacturer: string;
  driver_identifier: string;
  model_number: number;
  extra_info?: {
    software_version?: number;
  };
  connection_descriptor: {
    slave_id: number;
  };
  agent_suffix: string;
  agent_unique_id: string;
};

type InventoryDeviceConnectionDescriptor = {
  start_type: string;
  start_port: string;
  adapter_key: string;
  type: InventoryDeviceConnectionType;
  mac: string;
  name: string;
  device: string;
  connection_is_local: boolean;
  ip: string;
  connection_is_internet: boolean;
  slave_id: number;
  port: number;
};

export type InventoryDevices = {
  device_state: DeviceStates;
  device_unique_id: string;
  error_codes: string;
  info: InventoryDeviceInfo;
  connection_descriptor: InventoryDeviceConnectionDescriptor;
};

export type InventoryMqttMessage = {
  message_type: DropletMqttMessages.INVENTORY_CHANGE | DropletMqttMessages.INVENTORY_REQUEST;
  ports: {
    type: DropletPortTypes;
    port_number: number;
    devices?: InventoryDevices[];
    adapters?: {
      type: string;
      devices: InventoryDevices[];
    }[];
  }[];
};

export interface StartDiscoveryMqttMessage {
  message_type: DropletMqttMessages.START_DISCOVERY;
}

interface Adapter {
  devices: InventoryDevices[];
}

interface Port {
  port_number: number;
  type: string; // You might want to define a more specific type based on the possible values
  devices?: InventoryDevices[];
  adapters?: Adapter[];
}

export interface SearchDeviceMqttMessage {
  message_type: DropletMqttMessages.SEARCH_DEVICE;
  port?: Port;
  ports?: Port[];
}

export interface DeleteDeviceMqttMessage {
  message_type: DropletMqttMessages.DELETE_DEVICE;
  device_unique_id: string;
  error_message: string;
}

export type MqttMessage =
  | InventoryMqttMessage
  | StartDiscoveryMqttMessage
  | SearchDeviceMqttMessage
  | DeleteDeviceMqttMessage;

export interface LegacyDeviceType {
  id: string;
  name: string;
  short_name: string;
}

export interface DeviceConnectionTypes {
  id: string;
  name: DeviceConnectionTypesNames;
}

// Device type configuration
// when a device gets discovered, there are certain things that needed to be collected
// like meter role or something else
// this is the interface for that
// API response is going to send the full object for Device Type but we only need device_type_attributes
// so we are going to use this interface to filter out the unnecessary data
/**
 * API response
 * {
    "id": "07fad372",
    "uuid": "6d078e8e-dd27-11ea-8f8e-0242ac110004",
    "model_name": "SB5.0-1AV-41",
    "model_number": 9404,
    "default_slave_id": 3,
    "manufacturer_name": "SMA",
    "logical_device_type_name": "SMA Sunny Boy Inverter",
    "logical_device_type": "6cbac374-dd27-11ea-8f8e-0242ac110004",
    "logical_device_type_short_name": "SMA-SBxx-1AV-41",
    "logical_device_type_memory_map_type": "Modbus",
    "protocol": "SMAInverter",
    "droplet_driver": "eb8071ca-92aa-11eb-9f05-0242ac110003",
    "generic_device_type": "1-Phase PV Inverter",
    "device_type_connection_attributes": {},
    "device_type_attributes": {
        "meter_role": {
            "description": "Select meter role if meter connected",
            "attribute_type": "int",
            "attribute_group": "config",
            "options": [
                {
                    "option_text": "Duplicate battery meter role",
                    "value": 205
                },
                {
                    "option_text": "Duplicate generator meter role",
                    "value": 204
                },
                {
                    "option_text": "Duplicate load meter role",
                    "value": 203
                },
                {
                    "option_text": "Duplicate hybrid meter role",
                    "value": 202
                },
                {
                    "option_text": "Duplicate PV meter role",
                    "value": 201
                },
                {
                    "option_text": "Duplicate grid meter role",
                    "value": 200
                },
                {
                    "option_text": "Battery meter role",
                    "value": 105
                },
                {
                    "option_text": "Generator meter role",
                    "value": 104
                },
                {
                    "option_text": "Load meter role",
                    "value": 103
                },
                {
                    "option_text": "Hybrid meter role",
                    "value": 102
                },
                {
                    "option_text": "PV meter role",
                    "value": 101
                },
                {
                    "option_text": "Grid meter role",
                    "value": 100
                },
                {
                    "option_text": "Ignore meter role",
                    "value": 0
                }
            ]
        }
    },
    "approved": false,
    "logical_device_type_image": null,
    "qr_code_available": false,
    "onboarding_information": [],
    "display_name": "SMA Sunny Boy Inverter - SB5.0-1AV-41"
}
 */

export interface DeviceTypeConfigurationAttributes {
  name: string;
  description: string;
  attribute_type: DataTypeStrings;
  options: {
    name: string;
    value: string | number | boolean;
  }[];

  // custom property to accomodate the UI
  selectedValue: string | number | boolean;
  // if no options available
  // treat it like user needs to enter a custom value
  takeUserCustomInput: boolean;
  customInputType: DataTypeStrings.TEXT | DataTypeStrings.NUMBER | DataTypeStrings.BOOLEAN;
}

/**
 * API Payload
 *
 * {
    "connection_descriptor": {
        "connection_is_local": false,
        "ip": "192.168.8.180",
        "mac": "00:03:ac:21:13:e3",
        "connection_is_internet": false,
        "device": "eth0",
        "slave_id": 240,
        "type": "ip",
        "port": 502,
        "connection_type_id": "53dad372"
    },
    "device_info": {
        "connection_descriptor": {
            "slave_id": 240
        },
        "agent_suffix": "73659501dbd83850e2251f19460bd38894f60596",
        "driver_identifier": "FroniusPowerMeter",
        "serial_number": "20440050",
        "model_name": "Smart Meter 63A-1",
        "agent_unique_id": "Fronius_73659501dbd83850e2251f19460bd38894f60596",
        "manufacturer": "Fronius",
        "device_unique_id": "Fronius_20440050",
        "manufacturer_name": "Fronius",
        "device_type_id": "8dcad372"
    },
    "endpoint_info": {
        "endpoint_id": "e68ad372",
        "uuid": "00000000-0000-0000-0000-b827eb46dea1"
    },
    "parameters": {
        "meter_role": "100",
        "reverse_direction": "true"
    }
  }
 */

export interface DeviceConfigurationPostApi {
  connection_descriptor: InventoryDeviceConnectionDescriptor & {
    connection_type_id: string;
  };
  device_info: InventoryDeviceInfo & {
    device_unique_id: string;
    manufacturer_name: string;
    device_type_id?: string;
  };
  endpoint_info: {
    endpoint_id: string;
    uuid: string;
  };
  parameters?: {
    [attributeName: string]: string | boolean | number;
  };
}

// IEC61850ManufacturerDevices
export type ManufacturerDeviceType = {
  approved: boolean;
  display_name: string;
  generic_device_type: string;
  default_slave_id: number;
  id: string;
  model_number: number | string | null;
  model_name: string;
  manufacturer_name: string;
  logical_device_type_name: string;
  protocol: string;
  uuid: string;
  device_type_attributes: {
    [attributeName: string]: {
      attribute_group: string;
      attribute_type: string;
      description: string;
      options?: Array<{
        option_text: string;
        value: string | number | boolean;
      }>;
    };
  };
  device_type_connection_attributes: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [connectionName: string]: any;
  };
  // additional attributes for UI
  attributeValues: {
    [attributeName: string]: string | number | boolean;
  };
};

export interface ManufacturerAndDevicesForDiscovery {
  name: string;
  uuid: string;
  device_types: ManufacturerDeviceType[];
}

/**
 * Search Device Sample
 * to be sent to droplet over mqtt
 *
 * // search device on port
  {
    "message_type": "search_device",
    "port": {
        "port_number": 13,
        "type": "usb",
        "devices": [
            {
                "model_name": "ErgonPSCModbusSlave",
                "manufacturer_name": "SwitchDin",
                "model_number": null,
                "default_slave_id": 1,
                "logical_device_type_name": "Ergon Power Station Controller",
                "protocol": "ModbusSlave",
                "generic_device_type": "VirtualDevice",
                "device_type_connection_attributes": {
                    "LAN/WIFI": {
                        "IPAddress": {
                            "default": "0.0.0.0",
                            "options": []
                        },
                        "PortNumber": {
                            "default": "502",
                            "options": [],
                            "current": 502
                        },
                        "SlaveID": {
                            "default": "1",
                            "options": [],
                            "current": 1
                        }
                    }
                },
                "approved": true,
                "port": 502,
                "slaveid": 1
            }
        ]
    }
  }
 *
 * // search device on adapter
 * {
    "message_type": "search_device",
    "port": {
        "port_number": 14,
        "type": "usb",
        "adapters": [
            {
                "devices": [
                    {
                        "model_name": "SG2K5S",
                        "manufacturer_name": "Sungrow",
                        "model_number": 288,
                        "default_slave_id": 1,
                        "logical_device_type_name": "Sungrow PV Inverter",
                        "protocol": "Modbus",
                        "generic_device_type": "1-Phase PV Inverter",
                        "device_type_connection_attributes": {
                            "Serial": {
                                "BitRate": {
                                    "options": [
                                        "9600"
                                    ],
                                    "default": null
                                },
                                "DataBits": {
                                    "options": [
                                        "8"
                                    ],
                                    "default": null
                                },
                                "FlowControl": {
                                    "options": [
                                        "Off"
                                    ],
                                    "default": null
                                },
                                "Parity": {
                                    "options": [
                                        "None",
                                        "Odd"
                                    ],
                                    "default": null
                                },
                                "StopBits": {
                                    "options": [
                                        "1"
                                    ],
                                    "default": null
                                }
                            }
                        },
                        "approved": true
                    }
                ],
                "type": "serial"
            }
        ]
    }
  }
 */
export type DeviceForDiscovery = {
  model_name: string;
  manufacturer_name: string;
  model_number: string | number | null;
  default_slave_id: number;
  logical_device_type_name: string;
  protocol: string;
  generic_device_type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  device_type_connection_attributes: any;
  approved: boolean;
  // additional attributes to be sent to help discovery
  // e.g., port, slaveid, token, name, etc
  [attributeName: string]: string | number | boolean;
};

export interface DropletPortDeviceForDiscovery {
  port_number: number;
  type: DropletPortTypes;
  // either we will be sending device to be discoverd on a port
  // or on a adapter attached to a port
  // if adapter
  // it needs to be on adapter
  devices?: DeviceForDiscovery[];
  adapters?: {
    type: string;
    devices: DeviceForDiscovery[];
  }[];
}
