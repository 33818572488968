import { HttpErrorResponse } from '@angular/common/http';

export interface ApiResponse<T> {
  Code: number;
  Data: T;
  Msg: string;
  Status: string;
}

export interface ApiErrorResponse<T> extends HttpErrorResponse {
  readonly error: ApiResponse<T>;
}

export function isApiError<T = unknown>(response?: HttpErrorResponse): response is ApiErrorResponse<T> {
  if (response?.error == null) {
    return false;
  }

  return ['Code', 'Data', 'Msg', 'Status'].reduce((result, key) => result && key in response.error, true);
}
