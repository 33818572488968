import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NgxMqttWrapperService } from '@service/core/ngx-mqtt-wrapper.service';
import { IMqttMessage } from 'ngx-mqtt';

interface UnitMqtt {
  [index: string]: number | string | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UnitMqttStore {
  private _unitMqttSubscription: Subscription;

  private _unitMqttStore = new BehaviorSubject<UnitMqtt>(null);
  unitMqttStore$ = this._unitMqttStore.asObservable();

  constructor(private _ngxMqttWrapper: NgxMqttWrapperService) {}

  establishMqttForUnit(unitUuid: string) {
    this.subscribeToUnitMqtt(unitUuid);
  }

  destroyUnitMqttStream(): void {
    this.destroyMqttSubscription();
  }

  // private methods
  private subscribeToUnitMqtt(unitUuid: string): void {
    // check fail safe as we don't want to have unlimited subscriptions un-intentionally
    // and have memory leaks
    if (this._unitMqttSubscription) {
      this.destroyMqttSubscription();
    }

    this._unitMqttSubscription = this._ngxMqttWrapper
      .observe(`${unitUuid}/metrics`)
      .subscribe((mqttMessage: IMqttMessage) => {
        const message = JSON.parse(mqttMessage.payload.toString()) as UnitMqtt;
        this._unitMqttStore.next({ ...message });
      });
  }
  private destroyMqttSubscription(): void {
    this._ngxMqttWrapper.unsubscribe(this._unitMqttSubscription);
  }
}
