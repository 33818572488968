// ------------------------------------------------------------------------------------------------------------
// Permissions Constants
// - we will store ALL permissions in a single permissions store (across all permission areas)
// - prefix each permission to avoid any duplication across areas
// IMPORTANT: Ensure 'PermissionKey' prefixes MATCH 'PermissionAreaPrefix' enum values!
// ------------------------------------------------------------------------------------------------------------

///
/// General Constants
///
enum PermissionConstant {
  AREA_SEPARATOR = '_',
}

///
/// Permission Areas
///
export enum PermissionArea {
  GLOBAL = 'GLOBAL',
  PORTFOLIO = 'PORTFOLIO',
  STAFF = 'STAFF',
  TRANSACTION = 'TRANSACTION',
  UNIT = 'UNIT',
  VPP = 'VPP',
}

///
/// Permission Area Prefix
///
// this will create a type of 'GLOBAL_' | 'PORTFOLIO_' | 'UNIT_' | 'VPP_' | 'TRANSACTION_'
export type PermissionPrefix = `${keyof typeof PermissionArea}${typeof PermissionConstant.AREA_SEPARATOR}`;
// const function to return the area prefix
export const getPermissionAreaKeyPrefix = (permissionArea: PermissionArea): PermissionPrefix => {
  return `${PermissionArea[permissionArea]}${PermissionConstant.AREA_SEPARATOR}`;
};

export const getStorePermissionAreaFromKey = (permission: PermissionKey): PermissionArea | null => {
  const perm = permission.toString();
  const indexOfPermissionSeparator = perm.indexOf(PermissionConstant.AREA_SEPARATOR);
  if (indexOfPermissionSeparator >= 0) {
    return perm.slice(0, indexOfPermissionSeparator) as PermissionArea;
  }
  return null;
};

///
/// Permission Store
///
export type PermissionSet = { [index in PermissionKey]: boolean };
export type PermissionStore = { [index in PermissionArea]: PermissionSet };

//  PermissionDTO: Permission Data is in this format ["view_role", "Can view role"]
export type PermissionDTO = [string, string];

///
/// Permission Keys
/// - These are ALL the permission keys used across the app
/// - The keys are those returned by the API's but also prefixed by the permission area to avoid clashes
///
export enum PermissionKey {
  // GLOBAL
  // prefix with enum value PermissionAreaPrefix_GLOBAL
  GLOBAL_INSTALLER = PermissionArea.GLOBAL + PermissionConstant.AREA_SEPARATOR + 'installer',
  GLOBAL_PORTFOLIO = PermissionArea.GLOBAL + PermissionConstant.AREA_SEPARATOR + 'portfolio',
  GLOBAL_STAFF = PermissionArea.GLOBAL + PermissionConstant.AREA_SEPARATOR + 'staff',
  GLOBAL_TRANSACTION = PermissionArea.GLOBAL + PermissionConstant.AREA_SEPARATOR + 'transaction',
  GLOBAL_UNIT = PermissionArea.GLOBAL + PermissionConstant.AREA_SEPARATOR + 'unit',
  GLOBAL_VPP = PermissionArea.GLOBAL + PermissionConstant.AREA_SEPARATOR + 'vpp',
  GLOBAL_ORGANISATION = PermissionArea.GLOBAL + PermissionConstant.AREA_SEPARATOR + 'organisation',

  // PORTFOLIO
  // prefix with enum value PermissionAreaPrefix_PORTFOLIO
  PORTFOLIO_ADD_PORTFOLIOMEMBERSHIP = PermissionArea.PORTFOLIO +
    PermissionConstant.AREA_SEPARATOR +
    'add_portfoliomembership',
  PORTFOLIO_ADD_UNIT = PermissionArea.PORTFOLIO + PermissionConstant.AREA_SEPARATOR + 'add_unit',
  PORTFOLIO_CHANGE_PORTFOLIO = PermissionArea.PORTFOLIO + PermissionConstant.AREA_SEPARATOR + 'change_portfolio',
  PORTFOLIO_DELETE_PORTFOLIO = PermissionArea.PORTFOLIO + PermissionConstant.AREA_SEPARATOR + 'delete_portfolio',
  PORTFOLIO_DELETE_PORTFOLIOMEMBERSHIP = PermissionArea.PORTFOLIO +
    PermissionConstant.AREA_SEPARATOR +
    'delete_portfoliomembership',
  PORTFOLIO_DELETE_UNIT = PermissionArea.PORTFOLIO + PermissionConstant.AREA_SEPARATOR + 'delete_unit',
  PORTFOLIO_VIEW_PORTFOLIOMEMBERSHIP = PermissionArea.PORTFOLIO +
    PermissionConstant.AREA_SEPARATOR +
    'view_portfoliomembership',

  // UNIT
  // prefix with enum value PermissionAreaPrefix_UNIT

  UNIT_ACCESS_TUNNELING = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'access_tunneling',
  UNIT_VIEW_PORTFOLIO = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'view_portfolio',
  UNIT_ADD_ALERTDEFINITION = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'add_alertdefinition',
  UNIT_ADD_CONTROLLER = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'add_controller',
  UNIT_ADD_DEVICE = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'add_device',
  UNIT_ADD_UNITMEMBERSHIP = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'add_unitmembership',
  UNIT_CHANGE_DEVICE = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'change_device',
  UNIT_CHANGE_ALERTDEFINITION = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'change_alertdefinition',
  UNIT_CHANGE_CONTROLLER = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'change_controller',
  UNIT_CHANGE_DEVICESETTINGVALUE = PermissionArea.UNIT +
    PermissionConstant.AREA_SEPARATOR +
    'change_devicesettingvalue',
  UNIT_CHANGE_ENDPOINT = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'change_endpoint',
  UNIT_CHANGE_UNIT = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'change_unit',
  UNIT_CHANGE_UNIT_TARIFF = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'change_unit_tariff',
  UNIT_CONFIGURE_DEVICE = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'configure_device',
  UNIT_DELETE_ALERTDEFINITION = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'delete_alertdefinition',
  UNIT_DELETE_CONTROLLER = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'delete_controller',
  UNIT_DELETE_DEVICE = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'delete_device',
  UNIT_DELETE_UNIT = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'delete_unit',
  UNIT_DELETE_UNITMEMBERSHIP = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'delete_unitmembership',
  UNIT_EXPORT_UNIT_DATA = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'export_unit_data',
  UNIT_IS_PAYEE = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'is_payee',
  UNIT_SEND_DEVICE_CONTROL = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'send_device_control',
  UNIT_SYNC_CONTROLLER = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'sync_controller',
  UNIT_VIEW_ALERT = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'view_alert',
  UNIT_VIEW_ALERTDEFINITION = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'view_alertdefinition',
  UNIT_VIEW_CONTROLLER = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'view_controller',
  UNIT_VIEW_CONTROLLERTYPE = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'view_controllertype',
  UNIT_VIEW_DEVICE = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'view_device',
  UNIT_VIEW_DEVICECONNECTIONATTRIBUTE = PermissionArea.UNIT +
    PermissionConstant.AREA_SEPARATOR +
    'view_deviceconnectionattribute',
  UNIT_VIEW_DEVICECONTROL = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'view_devicecontrol',
  UNIT_VIEW_DEVICEINFORMATION = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'view_deviceinformation',
  UNIT_VIEW_DEVICESETTINGVALUE = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'view_devicesettingvalue',
  UNIT_VIEW_DEVICETYPE = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'view_devicetype',
  UNIT_VIEW_DEVICE_DETAIL = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'view_device_detail',
  UNIT_VIEW_ENDPOINT = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'view_endpoint',
  UNIT_VIEW_ENERGY = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'view_energy',
  UNIT_VIEW_EVENT = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'view_event',
  UNIT_VIEW_LOGICALDEVICE = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'view_logicaldevice',
  UNIT_VIEW_LOGMESSAGE = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'view_logmessage',
  UNIT_VIEW_RAW_DATA = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'view_raw_data',
  UNIT_VIEW_STREAM = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'view_stream',
  UNIT_VIEW_UNITMEMBERSHIP = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'view_unitmembership',
  UNIT_VIEW_UNIT_ANALYTICS = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'view_unit_analytics',
  UNIT_VIEW_UNIT_TARIFF = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'view_unit_tariff',
  UNIT_UPDATE_OPERATIONAL_STATE = PermissionArea.UNIT + PermissionConstant.AREA_SEPARATOR + 'update_operational_state',

  // VPP
  // prefix with enum value PermissionAreaPrefix_VPP
  VPP_ADD_CONTROLGROUP = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'add_controlgroup',
  VPP_ADD_CONTROLGROUPSCHEDULE = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'add_controlgroupschedule',
  VPP_ADD_MERITORDER = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'add_meritorder',
  VPP_ADD_SCHEDULE = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'add_schedule',
  VPP_ADD_VPPMEMBERSHIP = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'add_vppmembership',
  VPP_CHANGE_CONTROLGROUP = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'change_controlgroup',
  VPP_CHANGE_CONTROLGROUPSCHEDULE = PermissionArea.VPP +
    PermissionConstant.AREA_SEPARATOR +
    'change_controlgroupschedule',
  VPP_CHANGE_CONTROLGROUP_UNIT = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'change_controlgroup_unit',
  VPP_CHANGE_MERITORDER = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'change_meritorder',
  VPP_CHANGE_POLICYATTRIBUTEOPTION = PermissionArea.VPP +
    PermissionConstant.AREA_SEPARATOR +
    'change_policyattributeoption',
  VPP_CHANGE_SCHEDULE = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'change_schedule',
  VPP_CHANGE_VPP = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'change_vpp',
  VPP_DELETE_CONTROLGROUP = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'delete_controlgroup',
  VPP_DELETE_CONTROLGROUPSCHEDULE = PermissionArea.VPP +
    PermissionConstant.AREA_SEPARATOR +
    'delete_controlgroupschedule',
  VPP_DELETE_MERITORDER = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'delete_meritorder',
  VPP_DELETE_SCHEDULE = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'delete_schedule',
  VPP_DELETE_VPPMEMBERSHIP = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'delete_vppmembership',
  VPP_DISPATCH_VPP = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'dispatch_vpp',
  VPP_EXPORT_VPP_DATA = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'export_vpp_data',
  VPP_VIEW_CONTROLGROUP = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'view_controlgroup',
  VPP_VIEW_CONTROLGROUPSCHEDULE = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'view_controlgroupschedule',
  VPP_VIEW_CONTROLGROUPSCHEDULEPOLICY = PermissionArea.VPP +
    PermissionConstant.AREA_SEPARATOR +
    'view_controlgroupschedulepolicy',
  VPP_VIEW_DISPATCHHISTORY = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'view_dispatchhistory',
  VPP_VIEW_MERITORDER = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'view_meritorder',
  VPP_VIEW_OPERATION = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'view_operation',
  VPP_VIEW_POLICY = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'view_policy',
  VPP_VIEW_POLICYATTRIBUTEOPTION = PermissionArea.VPP +
    PermissionConstant.AREA_SEPARATOR +
    'view_policyattributeoption',
  VPP_VIEW_SCHEDULE = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'view_schedule',
  VPP_VIEW_VPPMEMBERSHIP = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'view_vppmembership',
  VPP_VIEW_VPPSUBSCRIPTION = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'view_vppsubscription',
  VPP_VIEW_VPP_ANALYTICS = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'view_vpp_analytics',
  VPP_DELETE_VPP = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'delete_vpp',
  VPP_VIEW_SCADATAKEOFF = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'view_scadatakeoff',

  VPP_VIEW_VPP_SETTINGS_GENERAL = PermissionArea.VPP + PermissionConstant.AREA_SEPARATOR + 'view_vpp_settings_general',
  VPP_VIEW_VPP_SETTINGS_AUTOMATION = PermissionArea.VPP +
    PermissionConstant.AREA_SEPARATOR +
    'view_vpp_settings_automation',
}
