import { ThemeInterface } from './theme-interface';
import { AppAssets, getThemeIconsLight, ThemeFooterIcons, ThemeMode, ThemeMap } from './theme-map';

const symphonyTheme: ThemeInterface = {
  name: ThemeMap.SYMPHONY,
  type: ThemeMode.LIGHT,
  styles: {
    global: [{ themeVariable: '--ion-item-background', value: 'white' }],
    // Button Normal*
    brandPrimary: [
      { themeVariable: '--ion-color-brand-primary', value: '#0099FF' },
      { themeVariable: '--ion-color-brand-primary-rgb', value: '0, 153, 255' },
      { themeVariable: '--ion-color-brand-primary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-brand-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-brand-primary-shade', value: '#0087e0' },
      { themeVariable: '--ion-color-brand-primary-tint', value: '#1aa3ff' },
    ],
    // Text Main
    textPrimary: [
      { themeVariable: '--ion-color-text-primary', value: '#000000' },
      { themeVariable: '--ion-color-text-primary-rgb', value: '0,0,0' },
      { themeVariable: '--ion-color-text-primary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-text-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-text-primary-shade', value: '#000000' },
      { themeVariable: '--ion-color-text-primary-tint', value: '#1a1a1a' },
    ],
    textTertiary: [
      { themeVariable: '--ion-color-text-tertiary', value: '#5c646e' },
      { themeVariable: '--ion-color-text-tertiary-rgb', value: '92, 100, 110' },
      { themeVariable: '--ion-color-text-tertiary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-text-tertiary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-text-tertiary-shade', value: '#515861' },
      { themeVariable: '--ion-color-text-tertiary-tint', value: '#6c747d' },
    ],
    // Text Additional
    textSecondary: [
      { themeVariable: '--ion-color-text-secondary', value: '#999999' },
      { themeVariable: '--ion-color-text-secondary-rgb', value: '153, 153, 153' },
      { themeVariable: '--ion-color-text-secondary-contrast', value: '#000000' },
      { themeVariable: '--ion-color-text-secondary-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-text-secondary-shade', value: '#878787' },
      { themeVariable: '--ion-color-text-secondary-tint', value: '#a3a3a3' },
    ],
    // app background*
    backgroundPrimary: [
      { themeVariable: '--ion-color-background-primary', value: '#EFF6FB' },
      { themeVariable: '--ion-color-background-primary-rgb', value: '239,246,251' },
      { themeVariable: '--ion-color-background-primary-contrast', value: '#000000' },
      { themeVariable: '--ion-color-background-primary-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-background-primary-shade', value: '#d2d8dd' },
      { themeVariable: '--ion-color-background-primary-tint', value: '#f1f7fb' },
    ],
    backgroundSecondary: [
      { themeVariable: '--ion-color-background-secondary', value: '#00A98F' },
      { themeVariable: '--ion-color-background-secondary-rgb', value: '0, 169, 143' },
      { themeVariable: '--ion-color-background-secondary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-background-secondary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-background-secondary-shade', value: '#00957e' },
      { themeVariable: '--ion-color-background-secondary-tint', value: '#1ab29a' },
    ],
    backgroundTertiary: [
      { themeVariable: '--ion-color-background-tertiary', value: '#007ACC' },
      { themeVariable: '--ion-color-background-tertiary-rgb', value: '0,122,204' },
      { themeVariable: '--ion-color-background-tertiary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-background-tertiary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-background-tertiary-shade', value: '#006bb4' },
      { themeVariable: '--ion-color-background-tertiary-tint', value: '#1a87d1' },
    ],
    subTabLabels: [
      { themeVariable: '--ion-color-sub-tab-labels', value: '#37424a' },
      { themeVariable: '--ion-color-sub-tab-labels-rgb', value: '55, 66, 74' },
      // the contrast supposed to be white, but changed it
      { themeVariable: '--ion-color-sub-tab-labels-contrast', value: '#37424a' },
      { themeVariable: '--ion-color-sub-tab-labels-contrast-rgb', value: '55, 66, 74' },
      { themeVariable: '--ion-color-sub-tab-labels-shade', value: '#303a41' },
      { themeVariable: '--ion-color-sub-tab-labels-tint', value: '#4b555c' },
    ],
    solar: [
      { themeVariable: '--ion-color-solar', value: '#FF9B00' },
      { themeVariable: '--ion-color-solar-rgb', value: '255,155,0' },
      { themeVariable: '--ion-color-solar-contrast', value: '#000000' },
      { themeVariable: '--ion-color-solar-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-solar-shade', value: '#e08800' },
      { themeVariable: '--ion-color-solar-tint', value: '#ffa51a' },
    ],
    battery: [
      { themeVariable: '--ion-color-battery', value: '#00A98F' },
      { themeVariable: '--ion-color-battery-rgb', value: '0, 169, 143' },
      { themeVariable: '--ion-color-battery-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-battery-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-battery-shade', value: '#00957e' },
      { themeVariable: '--ion-color-battery-tint', value: '#1ab29a' },
    ],
    consumption: [
      { themeVariable: '--ion-color-consumption', value: '#2E4C68' },
      { themeVariable: '--ion-color-consumption-rgb', value: '46,76,104' },
      { themeVariable: '--ion-color-consumption-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-consumption-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-consumption-shade', value: '#28435c' },
      { themeVariable: '--ion-color-consumption-tint', value: '#435e77' },
    ],
    consumed: [
      { themeVariable: '--ion-color-consumed', value: '#4696ec' },
      { themeVariable: '--ion-color-consumed-rgb', value: '70, 150, 236' },
      { themeVariable: '--ion-color-consumed-contrast', value: '#000000' },
      { themeVariable: '--ion-color-consumed-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-consumed-shade', value: '#3e84d0' },
      { themeVariable: '--ion-color-consumed-tint', value: '#59a1ee' },
    ],
  },

  headerLogo: {
    path: getThemeIconsLight[ThemeMap.SWITCHDIN],
    style: {
      height: '30px',
    },
  },

  footerLogo: {
    path: `${AppAssets.FOOTER_LIGHT}${ThemeFooterIcons.DEFAULT}`,
    style: {
      height: '30px',
      width: '12em',
    },
  },
  footerColoredLogo: `${AppAssets.FOOTER_LIGHT}${ThemeFooterIcons.DEFAULT}`,
  iconsSvgPath: AppAssets.ICONS_SVG_LIGHT,
  chartColors: {
    primary: { value: '#FF9B00', opacValue: 'rgba(255, 155, 0, 0.4)' }, // solar
    light: { value: '#0099FF', opacValue: 'rgba(0, 178, 153, 0.4)' }, // consumption
    secondary: { value: '#4696EC', opacValue: 'rgba(70, 150, 236, 0.4)' }, // consumed
    tertiary: { value: '#00b299', opacValue: 'rgba(0, 169, 143, 0.4)' }, // battery

    colorFive: { value: '#7BA24B', opacValue: 'rgba(123, 162, 75, 0.4)' },
    colorSix: { value: '#3933DE', opacValue: 'rgba(57, 51, 222, 0.4)' },
    colorSeven: { value: '#AE33DE', opacValue: 'rgba(174, 51, 222, 0.4)' },
    colorEight: { value: '#6B9FAF', opacValue: 'rgba(107, 159, 175, 0.4)' },
    colorNine: { value: '#DE3355', opacValue: 'rgba(222, 51, 85, 0.4)' },
    colorTen: { value: '#96F70D', opacValue: 'rgba(150, 247, 13, 0.4)' },
  },
  vppChartColors: {
    avail: '#0099FF',
    dispatch: '#626363',
    unscheduled_dispatch: '#B03A2E',
    manual_dispatch: '#B7950B',
    unassociated_dispatch: '#9c0897',
    constraint: '#befc03',
    auto_dispatch: '#7b97b8',
    selected_avail: '#02bda1',
    selected_enabled: '#3333BE',
    selected_dispatch: '#00008B',
    selected_constraint: '#07f2da',
  },
  streamChartColor: '#00A98F',
  buttons: {
    primary: [
      { themeVariable: '--ion-color-button-primary', value: '#0099FF' },
      { themeVariable: '--ion-color-button-primary-rgb', value: '0, 153, 255' },
      { themeVariable: '--ion-color-button-primary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-button-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-button-primary-shade', value: '#0087e0' },
      { themeVariable: '--ion-color-button-primary-tint', value: '#1aa3ff' },
    ],
    // UI Elements
    secondary: [
      { themeVariable: '--ion-color-button-secondary', value: '#00A98F' },
      { themeVariable: '--ion-color-button-secondary-rgb', value: '0,169,143' },
      { themeVariable: '--ion-color-button-secondary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-button-secondary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-button-secondary-shade', value: '#00957e' },
      { themeVariable: '--ion-color-button-secondary-tint', value: '#1ab29a' },
    ],
    // Action Green
    success: [
      { themeVariable: '--ion-color-success', value: '#00A98F' },
      { themeVariable: '--ion-color-success-rgb', value: '0, 169, 143' },
      { themeVariable: '--ion-color-success-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-success-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-success-shade', value: '#00957e' },
      { themeVariable: '--ion-color-success-tint', value: '#1ab29a' },
    ],
    // Action Yellow
    warning: [
      { themeVariable: '--ion-color-warning', value: '#F4B44E' },
      { themeVariable: '--ion-color-warning-rgb', value: '244,180,78' },
      { themeVariable: '--ion-color-warning-contrast', value: '#000000' },
      { themeVariable: '--ion-color-warning-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-warning-shade', value: '#d79e45' },
      { themeVariable: '--ion-color-warning-tint', value: '#f5bc60' },
    ],
    // Action Red
    danger: [
      { themeVariable: '--ion-color-danger', value: '#E60D21' },
      { themeVariable: '--ion-color-danger-rgb', value: '230,13,33' },
      { themeVariable: '--ion-color-danger-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-danger-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-danger-shade', value: '#ca0b1d' },
      { themeVariable: '--ion-color-danger-tint', value: '#e92537' },
    ],
    successLite: [
      { themeVariable: '--ion-color-success-lite', value: '#B9E1C9' },
      { themeVariable: '--ion-color-success-lite-rgb', value: '185,225,201' },
      { themeVariable: '--ion-color-success-lite-contrast', value: '#000000' },
      { themeVariable: '--ion-color-success-lite-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-success-lite-shade', value: '#a3c6b1' },
      { themeVariable: '--ion-color-success-lite-tint', value: '#c0e4ce' },
    ],
    warningLite: [
      { themeVariable: '--ion-color-warning-lite', value: '#FEEACB' },
      { themeVariable: '--ion-color-warning-lite-rgb', value: '254,234,203' },
      { themeVariable: '--ion-color-warning-lite-contrast', value: '#000000' },
      { themeVariable: '--ion-color-warning-lite-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-warning-lite-shade', value: '#e0ceb3' },
      { themeVariable: '--ion-color-warning-lite-tint', value: '#feecd0' },
    ],
    dangerLite: [
      { themeVariable: '--ion-color-danger-lite', value: '#FCD1D5' },
      { themeVariable: '--ion-color-danger-lite-rgb', value: '252,209,213' },
      { themeVariable: '--ion-color-danger-lite-contrast', value: '#000000' },
      { themeVariable: '--ion-color-danger-lite-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-danger-lite-shade', value: '#deb8bb' },
      { themeVariable: '--ion-color-danger-lite-tint', value: '#fcd6d9' },
    ],
  },
  svgIcons: {
    // Icons / Home
    primarySvg: [
      { themeVariable: '--ion-color-primary-svg', value: '#0099FF' },
      { themeVariable: '--ion-color-primary-svg-rgb', value: '0, 153, 255' },
      { themeVariable: '--ion-color-primary-svg-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-primary-svg-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-primary-svg-shade', value: '#0087e0' },
      { themeVariable: '--ion-color-primary-svg-tint', value: '#1aa3ff' },
    ],
  },
  // Tables
  tablesRow: {
    primaryTile: [
      { themeVariable: '--ion-color-table-primary-tile', value: '#FAFAFB' },
      { themeVariable: '--ion-color-table-primary-tile-rgb', value: '250, 250, 251' },
      { themeVariable: '--ion-color-table-primary-tile-contrast', value: '#000000' },
      { themeVariable: '--ion-color-table-primary-tile-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-table-primary-tile-shade', value: '#dcdcdd' },
      { themeVariable: '--ion-color-table-primary-tile-tint', value: '#fbfbfb' },
    ],
    secondaryTile: [
      { themeVariable: '--ion-color-table-secondary-tile', value: '#FAFAFB' },
      { themeVariable: '--ion-color-table-secondary-tile-rgb', value: '250, 250, 251' },
      { themeVariable: '--ion-color-table-secondary-tile-contrast', value: '#000000' },
      { themeVariable: '--ion-color-table-secondary-tile-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-table-secondary-tile-shade', value: '#dcdcdd' },
      { themeVariable: '--ion-color-table-secondary-tile-tint', value: '#fbfbfb' },
    ],
    grayTile: [
      { themeVariable: '--ion-color-table-gray-tile', value: '#FAFAFB' },
      { themeVariable: '--ion-color-table-gray-tile-rgb', value: '250, 250, 251' },
      { themeVariable: '--ion-color-table-gray-tile-contrast', value: '#000000' },
      { themeVariable: '--ion-color-table-gray-tile-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-table-gray-tile-shade', value: '#dcdcdd' },
      { themeVariable: '--ion-color-table-gray-tile-tint', value: '#fbfbfb' },
    ],
  },
};
export { symphonyTheme };
