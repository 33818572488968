import { Component, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { App } from '@capacitor/app';
import * as LiveUpdates from '@capacitor/live-updates';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { CommonService } from './services/common/common.service';
import { TranslationsService } from './services/common/translations.service';
import { ThemeService } from './services/themes/theme.service';

import { appToastNotificationsSelector } from '@app-state/app-toast-notifications/app-toast-notifications.selectors';
import { Capacitor } from '@capacitor/core';
import { AlertButtonRoles } from '@class/alerts-toasts-props/alert-props';
import { ColBreakpoint } from '@class/commons/enums';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { Loader } from '@googlemaps/js-api-loader';
import { Store } from '@ngrx/store';
import { GenericAlertsToastsService } from '@service/common/generic-alerts-toasts.service';
import { environment } from 'environments/environment';
import { filter, tap } from 'rxjs';
import { register } from 'swiper/element/bundle';
import { AnalyticsService } from './services/common/analytics.service';
import { StorageService } from '@service/common/storage.service';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  readonly PermissionKey = PermissionKey;

  #store = inject(Store);
  appToastNotifications$ = this.#store
    .select(appToastNotificationsSelector)
    .pipe(filter((toastSettings) => !!toastSettings));

  constructor(
    private platform: Platform,
    private theme: ThemeService,
    private router: Router,
    private navController: NavController,
    private trans: TranslationsService,
    private analyticsService: AnalyticsService,
    private menu: MenuController,
    private genericAlertsToastsService: GenericAlertsToastsService,
    private common: CommonService,
    private _storageService: StorageService,
  ) {
    this.initializeApp();
  }

  private async initializeApp(): Promise<void> {
    this._storageService.googleMapsApiKey$
      .pipe(
        filter((apiKey) => !!apiKey),
        tap(async (googleApiKey) => {
          const googleMapsLoader = new Loader({
            apiKey: googleApiKey,
            version: 'quarterly',
          });
          await googleMapsLoader.importLibrary('places');
        }),
      )
      .subscribe();

    this.setRouterEvents();

    /** WORKAROUND for detecting windows touch screen devices and setting the platform to desktop
     * - this fixes an issue where Ionic detects a windows touch screen device as mobile and mobileweb devices,
     * - which causes the app to display the mobile version of the app which disallow the user to select text in the app
     * - note Ionic's recommendation to use IonicModule to customise platorm detection doesn't work in Stormcloud app:
     * - https://ionicframework.com/docs/angular/platform#customizing-platform-detection-functions
     */
    if (!this.platform.is('cordova')) {
      const classList = document.querySelector('html').classList;
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        window.navigator.userAgent,
      );
      if (!isMobile) {
        classList.add('plt-desktop');
        classList.remove('plt-mobile', 'plt-mobileweb');
      }
    }

    const platformReadyPromise = this.platform
      .ready()
      .then(() => {
        this.setPlatformResize();
        this.setHeightWidthOfPlatform();
        this.setPlatformBackButton();

        this.theme.setTheme();
      })
      .catch((error) => {
        if (window.location.href.indexOf('local') >= 0) {
          console.error(error);
        }
      });

    await platformReadyPromise;

    if (Capacitor.isNativePlatform()) {
      await LiveUpdates.setConfig({
        channel: environment.name,
      });

      App.addListener('resume', async () => {
        try {
          if (localStorage.shouldReloadApp === 'true') {
            await LiveUpdates.reload();
          } else {
            const result = await LiveUpdates.sync();

            localStorage.shouldReloadApp = result.activeApplicationPathChanged;
          }
        } catch (e) {
          this.analyticsService.logError(e, 'LiveUpdates');
        }
      });

      const result = await LiveUpdates.sync();
      localStorage.shouldReloadApp = result.activeApplicationPathChanged;
    }
  }

  private setPlatformResize(): void {
    this.platform.resize.subscribe(() => {
      this.setHeightWidthOfPlatform();
      this.common.PLATFORM_RESIZED_OCCURRED_SUBJECT.next(true);
    });
  }
  private setRouterEvents(): void {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.analyticsService.logScreenView(ev.urlAfterRedirects);
        this.menu.close();
      }
    });
  }

  private setPlatformBackButton(): void {
    this.platform.backButton.subscribe(async () => {
      if (this.router.url === '/home') {
        const { CloseApp } = this.trans.instant('General');
        const alert = await this.genericAlertsToastsService.createAlertWithCloseCancelButton(CloseApp, '', '');

        await alert.present();

        const { role } = await alert.onDidDismiss();
        if (role === AlertButtonRoles.CLOSE) {
          navigator['app'].exitApp();
        }
      } else {
        this.navController.pop();
      }
    });
  }
  private setHeightWidthOfPlatform(): void {
    this.common.PLATFORM_HEIGHT = this.platform.height();
    this.common.PLATFORM_WIDTH = this.platform.width();
    if (this.common.PLATFORM_WIDTH < ColBreakpoint.LG) {
      this.common.OPEN_DEVICE_CONTROLLER_IN_MODAL = true;
    } else {
      this.common.OPEN_DEVICE_CONTROLLER_IN_MODAL = false;
    }
  }
}
