import { createReducer, on } from '@ngrx/store';
import {
  PermissionDTO,
  PermissionPrefix,
  PermissionSet,
  PermissionStore,
  getPermissionAreaKeyPrefix,
} from '@class/commons/permissions/permission-constants';
import { applyUserPermissions, clearUserPermissions, clearUserPermissionsArea } from './permissions.actions';

const initialState: PermissionStore = null as PermissionStore;

const UNSUPPORTED_TYPE_ERROR = 'UserPermissions.apply: UNSUPPORTED TYPE!';
const createPermissionSetFromArray = (permissionData: PermissionDTO[], keyPrefix: PermissionPrefix): PermissionSet => {
  return permissionData.reduce((permissionSet, p) => {
    permissionSet[keyPrefix + p[0]] = true;
    return permissionSet;
  }, {} as PermissionSet);
};

const createPermissionSetFromObject = (permissionData: PermissionSet, keyPrefix: PermissionPrefix): PermissionSet => {
  return Object.entries(permissionData).reduce((permissionSet, [permStr, isPermEnabled]) => {
    if (isPermEnabled) {
      permissionSet[`${keyPrefix}${permStr}`] = true;
    }
    return permissionSet;
  }, {} as PermissionSet);
};

export const appUserPermissionsReducer = createReducer(
  initialState,
  on(clearUserPermissions, () => null as PermissionStore),
  on(applyUserPermissions, (state, { permissionArea, permissionData }) => {
    if (typeof permissionData === 'object') {
      const keyPrefix = getPermissionAreaKeyPrefix(permissionArea);
      const permissionSet = Array.isArray(permissionData)
        ? createPermissionSetFromArray(permissionData, keyPrefix)
        : createPermissionSetFromObject(permissionData, keyPrefix);

      return {
        ...state,
        [permissionArea]: permissionSet,
      };
    } else {
      console.error(UNSUPPORTED_TYPE_ERROR, typeof permissionData);
      return state;
    }
  }),
  on(clearUserPermissionsArea, (state, { permissionArea }) => {
    const userPermissions = { ...state };
    delete userPermissions[permissionArea];
    return { ...userPermissions };
  }),
);
