import { createReducer, on } from '@ngrx/store';
import {
  checkDropletIsClaimed,
  resetInstallerState,
  setDropletDoesNotExist,
  setDropletIsClaimed,
  setIdentifyTheDropletViewLoadingState,
  setInstallerContentView,
  setIsDropletConnectedToInternet,
} from './installer.action';
import { InstallerContentView } from '@class/commons/enums';

export interface InstallerState {
  isDropletClaimed: boolean;
  isUserHasPermission: boolean;
  dropletUuid: string;
  contentView: InstallerContentView;

  // This is for edge cases
  // if the droplet does not exist in our system, can be a glitch
  // but if it's a real case, we need to handle it
  // and the droplet will get registered in our system once it gets connected to the internet
  dropletDoesNotExist: boolean;

  isDropletConnectedToInternet: boolean;

  identifyTheDropletViewLoadingState: {
    isLoading?: boolean;
    isError?: boolean;
    error?: string | null;
  };
}

export const initialState: InstallerState = {
  isDropletClaimed: false,
  isUserHasPermission: false,
  dropletUuid: null,
  contentView: InstallerContentView.WELCOME_TO_SWITCHDIN,
  dropletDoesNotExist: false,
  isDropletConnectedToInternet: false,
  identifyTheDropletViewLoadingState: null,
} as InstallerState;

export const appInstallerReducer = createReducer(
  initialState,
  on(checkDropletIsClaimed, (state, { dropletUuid }) => {
    return {
      ...state,
      dropletUuid,
    };
  }),
  on(setDropletIsClaimed, (state, { isDropletClaimed, isUserHasPermission }) => {
    return {
      ...state,
      isDropletClaimed,
      isUserHasPermission,
    };
  }),
  on(setIdentifyTheDropletViewLoadingState, (state, { isLoading, isError, error }) => {
    return {
      ...state,
      identifyTheDropletViewLoadingState: {
        isLoading,
        isError,
        error: error?.message ?? null,
      },
    };
  }),
  on(setInstallerContentView, (state, { contentView }) => {
    return {
      ...state,
      contentView,
    };
  }),
  on(setDropletDoesNotExist, (state, { dropletDoesNotExist }) => {
    return {
      ...state,
      dropletDoesNotExist,
    };
  }),
  on(setIsDropletConnectedToInternet, (state, { isDropletConnectedToInternet }) => {
    return {
      ...state,
      isDropletConnectedToInternet,
    };
  }),
  on(resetInstallerState, () => {
    return initialState;
  }),
);
