<!--Port Selected -->
<ng-container *ngrxLet="{ dropletSelectedPort: dropletSelectedPort$ } as vm">
  <ion-row>
    <ion-col>
      <ion-card class="ion-no-margin new-design-card">
        <ion-card-content class="ion-no-padding">
          <ion-item lines="none">
            <ion-text class="text-primary-md" translate>AddDevice.SelectedPort</ion-text>
            <ion-text slot="end" class="text-primary-lg">
              {{
                vm.dropletSelectedPort.type
                  | dropletPortLabel: vm.dropletSelectedPort.port_number : vm.dropletSelectedPort.hardwareVersion
              }}
            </ion-text>
          </ion-item>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</ng-container>

<ng-container
  *ngrxLet="{
    deviceConfigView: deviceConfigView$,
    discoverableDevicesViewModel: discoverableDevicesViewModel$,
    dropletSelectedPort: dropletSelectedPort$
  } as vm"
>
  <!-- list of manufacturers -->
  <ion-row *ngIf="vm.deviceConfigView === DeviceConfigScreen.MANUFACTURERS_LIST">
    <ion-col>
      <ion-card class="ion-no-margin new-design-card">
        <ion-card-header>
          <ion-card-title class="card-title" translate>AddDevice.Manufacturers</ion-card-title>
        </ion-card-header>
        <ion-card-content class="ion-no-padding ion-padding-bottom">
          <ion-row>
            <ion-col class="bb-1 ion-padding">
              <ion-searchbar
                class="ion-no-padding custom"
                showCancelButton="never"
                [debounce]="100"
                placeholder="{{ 'General.Search' | translate }}"
                autocomplete="on"
                (ionInput)="handleManufacturerSearchInputChange($event)"
                mode="md"
              ></ion-searchbar>
            </ion-col>
          </ion-row>
          <ion-item
            *ngFor="let manufacturer of vm.discoverableDevicesViewModel.filteredManufacturers"
            detail="true"
            button
            (click)="onManufacturerHandler(manufacturer)"
          >
            <ion-label class="text-primary-lg ion-text-wrap"> {{ manufacturer.name }} </ion-label>
          </ion-item>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>

  <!-- list of device types of selected manufacturers -->
  <ion-row *ngIf="vm.deviceConfigView === DeviceConfigScreen.SELECTED_MANUFACTURER_DEVICES">
    <ion-col>
      <ion-card class="ion-no-margin new-design-card">
        <ion-card-header>
          <ion-card-title class="card-title" translate>General.Devices</ion-card-title>
        </ion-card-header>
        <ion-card-content class="ion-no-padding ion-padding-bottom">
          <ion-row>
            <ion-col class="bb-1 ion-padding">
              <ion-searchbar
                class="ion-no-padding custom"
                showCancelButton="never"
                [debounce]="100"
                placeholder="{{ 'General.Search' | translate }}"
                autocomplete="on"
                (ionInput)="handleManufacturerDevicesSearchInputChange($event)"
                mode="md"
              ></ion-searchbar>
            </ion-col>
          </ion-row>
          <ion-item
            *ngFor="let deviceType of vm.discoverableDevicesViewModel.filteredDeviceTypes"
            detail="true"
            button
            (click)="onDeviceTypeHandler(deviceType)"
          >
            <ion-label class="ion-text-wrap">
              <p class="text-primary-lg">
                {{ deviceType.display_name }}
              </p>
              <p class="text-secondary-md">
                {{ deviceType.generic_device_type }}
              </p>
              <p *ngIf="deviceType.model_name" class="text-primary-sm">
                {{ deviceType.model_name }}
              </p>
              <p *ngIf="deviceType.model_number" class="text-primary-sm">
                {{ deviceType.model_number }}
              </p>
            </ion-label>
            <ion-text
              slot="end"
              class="text-primary-md ion-text-wrap"
              *ngIf="!IsEmpty(deviceType.device_type_connection_attributes)"
            >
              <ng-container *ngFor="let connection of deviceType.device_type_connection_attributes | keyvalue">
                {{ connection.key }}
              </ng-container>
            </ion-text>
          </ion-item>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>

  <!-- show device attributes if there are any -->
  <ion-row *ngIf="vm.deviceConfigView === DeviceConfigScreen.SELECTED_DEVICE_DISCOVERY_ATTRIBUTES">
    <!-- display selected device -->
    <ion-col size="12">
      <ion-card class="ion-no-margin new-design-card">
        <ion-card-header>
          <ion-card-title class="card-title" translate>AddDevice.SelectedDevice</ion-card-title>
        </ion-card-header>
        <ion-card-content class="ion-no-padding ion-padding-bottom">
          <ion-item lines="none">
            <ion-label class="ion-text-wrap">
              <p class="text-primary-lg mb-0">
                {{ vm.discoverableDevicesViewModel.selectedDevice.display_name }}
              </p>
              <p
                class="text-secondary-md mt-0"
                [ngClass]="{ 'mb-0': vm.discoverableDevicesViewModel.selectedDevice.model_number }"
              >
                {{ vm.discoverableDevicesViewModel.selectedDevice.generic_device_type }}
              </p>
              <p *ngIf="vm.discoverableDevicesViewModel.selectedDevice.model_number" class="text-primary-sm mt-0">
                {{ vm.discoverableDevicesViewModel.selectedDevice.model_number }}
              </p>
            </ion-label>
          </ion-item>

          <ion-row
            *ngIf="
              IsEmpty(vm.discoverableDevicesViewModel.selectedDevice?.device_type_attributes) &&
              IsEmpty(
                vm.discoverableDevicesViewModel.selectedDevice?.device_type_connection_attributes[
                  DeviceConnectionTypesNames.LAN_WIFI
                ]
              )
            "
          >
            <ion-col size="12" class="ion-padding pb-0" *ngrxLet="deviceToDiscover$ as device">
              <ng-container *ngIf="device?.status == null || device.status === 'idle'">
                <app-button
                  label="{{ 'General.Find' | translate }}"
                  (onClick)="
                    onFindButtonHandler(vm.discoverableDevicesViewModel.selectedDevice, vm.dropletSelectedPort)
                  "
                />
              </ng-container>

              <ng-container *ngIf="device?.status === 'discovering'">
                <app-button isDisabled loading label="{{ 'AddDevice.Discovering' | translate }}" />
              </ng-container>

              <ng-container *ngIf="device?.status === 'success'">
                <app-button isDisabled label="{{ 'General.Success' | translate }}" />
              </ng-container>

              <ng-container *ngIf="device?.status === 'fail'">
                <app-button isDisabled label="{{ 'General.Error.Failed' | translate }}" />
              </ng-container>
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </ion-col>

    <!-- device discovery settings -->
    <ion-col
      size="12"
      *ngIf="
        !IsEmpty(vm.discoverableDevicesViewModel.selectedDevice?.device_type_attributes) ||
        !IsEmpty(
          vm.discoverableDevicesViewModel.selectedDevice?.device_type_connection_attributes[
            DeviceConnectionTypesNames.LAN_WIFI
          ]
        )
      "
    >
      <ion-card class="ion-no-margin new-design-card">
        <ion-card-header>
          <ion-card-title class="card-title" translate>AddDevice.DeviceDiscoverySettings</ion-card-title>
        </ion-card-header>
        <ion-card-content class="ion-no-padding">
          <!-- device type attributes -->
          <ion-row *ngIf="!IsEmpty(vm.discoverableDevicesViewModel.selectedDevice?.device_type_attributes)">
            <ng-container
              *ngFor="
                let attribute of vm.discoverableDevicesViewModel.selectedDevice?.device_type_attributes | keyvalue
              "
            >
              <ion-col size="12">
                <ng-container
                  *ngIf="
                    attribute.value?.attribute_type === DataTypeStrings.CHAR ||
                    attribute.value?.attribute_type === DataTypeStrings.VARCHAR ||
                    attribute.value?.attribute_type === DataTypeStrings.STRING ||
                    attribute.value?.attribute_type === DataTypeStrings.TEXT
                  "
                >
                  <app-input-stacked-label
                    [label]="attribute.value.description"
                    [value]="vm.discoverableDevicesViewModel.selectedDevice?.attributeValues[attribute.key]"
                    (ionChange)="
                      onDeviceTypeAttributeValueChange(
                        attribute.key,
                        $event?.detail?.value,
                        vm.discoverableDevicesViewModel.selectedDevice
                      )
                    "
                  ></app-input-stacked-label>
                </ng-container>
                <ng-container
                  *ngIf="
                    attribute.value?.attribute_type === DataTypeStrings.INT ||
                    attribute.value?.attribute_type === DataTypeStrings.FLOAT
                  "
                >
                  <app-input-stacked-label
                    [label]="attribute.value.description"
                    inputType="number"
                    [value]="vm.discoverableDevicesViewModel.selectedDevice?.attributeValues[attribute.key]"
                    (ionChange)="
                      onDeviceTypeAttributeValueChange(
                        attribute.key,
                        $event?.detail?.value,
                        vm.discoverableDevicesViewModel.selectedDevice
                      )
                    "
                  ></app-input-stacked-label>
                </ng-container>
                <ion-item
                  lines="full"
                  *ngIf="
                    attribute.value?.attribute_type === DataTypeStrings.BOOL ||
                    attribute.value?.attribute_type === DataTypeStrings.BOOLEAN
                  "
                >
                  <ion-toggle
                    color="button-secondary"
                    justify="space-between"
                    labelPlacement="start"
                    [value]="vm.discoverableDevicesViewModel.selectedDevice?.attributeValues[attribute.key]"
                    mode="ios"
                    (ionChange)="
                      onDeviceTypeAttributeValueChange(
                        attribute.key,
                        $event?.detail?.value,
                        vm.discoverableDevicesViewModel.selectedDevice
                      )
                    "
                  >
                    {{ attribute.value.description }}
                  </ion-toggle>
                </ion-item>
              </ion-col>
            </ng-container>
          </ion-row>

          <!-- device settings for LAN/WIFI - port and slave id -->
          <ion-row
            *ngIf="
              !IsEmpty(
                vm.discoverableDevicesViewModel.selectedDevice?.device_type_connection_attributes[
                  DeviceConnectionTypesNames.LAN_WIFI
                ]
              )
            "
          >
            <ion-col size="12">
              <ion-item lines="none">
                <app-button
                  slot="end"
                  [buttonType]="ButtonType.PRIMARY_ROUND_OUTLINE_DEFAULT"
                  label="{{ 'AddDevice.ResetSettingsToDefault' | translate }}"
                  (onClick)="
                    onResetDeviceTypeConnectionAttributesForLanWifi(vm.discoverableDevicesViewModel.selectedDevice)
                  "
                ></app-button>
              </ion-item>
            </ion-col>
            <ion-col size="12">
              <app-input-stacked-label
                label="{{ 'General.Port' | translate }}"
                inputType="number"
                placeholder="default port {{
                  vm.discoverableDevicesViewModel.selectedDevice?.device_type_connection_attributes[
                    DeviceConnectionTypesNames.LAN_WIFI
                  ]?.PortNumber?.default
                }}"
                [value]="vm.discoverableDevicesViewModel.selectedDevice?.attributeValues?.port"
                (ionChange)="
                  onDeviceTypeAttributeValueChange(
                    'port',
                    $event?.detail?.value,
                    vm.discoverableDevicesViewModel.selectedDevice
                  )
                "
              ></app-input-stacked-label>
            </ion-col>
            <ion-col size="12">
              <app-input-stacked-label
                label="{{ 'AddDevice.SlaveID' | translate }}"
                inputType="number"
                placeholder="default salve id {{
                  vm.discoverableDevicesViewModel.selectedDevice?.device_type_connection_attributes[
                    DeviceConnectionTypesNames.LAN_WIFI
                  ]?.SlaveID?.default
                }}"
                [value]="vm.discoverableDevicesViewModel.selectedDevice?.attributeValues?.slaveid"
                (ionChange)="
                  onDeviceTypeAttributeValueChange(
                    'slaveid',
                    $event?.detail?.value,
                    vm.discoverableDevicesViewModel.selectedDevice
                  )
                "
              ></app-input-stacked-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12" class="ion-padding">
              <app-button
                label="{{ 'General.Find' | translate }}"
                (onClick)="onFindButtonHandler(vm.discoverableDevicesViewModel.selectedDevice, vm.dropletSelectedPort)"
              ></app-button>
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</ng-container>
