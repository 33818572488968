import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AddDeviceStore } from '../store/add-device.store';
import { isEmpty } from 'lodash';
import {
  DeviceTypeConfigurationAttributes,
  InventoryDevicesListToDisplay,
  DeviceTypes,
} from '../utils/add-device-types';
import { BrowserLogger } from '@class/core/browser-logger';
import { produce } from 'immer';

@Component({
  selector: 'app-device-configuration-settings',
  templateUrl: './device-configuration-settings.component.html',
  styleUrls: ['./device-configuration-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceConfigurationSettingsComponent {
  @Input() device: InventoryDevicesListToDisplay;

  readonly discoveryDeviceForConfiguration$: Observable<InventoryDevicesListToDisplay>;
  readonly isEmpty = isEmpty;

  private readonly updatedAttributes$ = new BehaviorSubject<
    Record<string, Record<string, Pick<DeviceTypeConfigurationAttributes, 'selectedValue'>>>
  >({});

  private readonly updatedDeviceTypes$ = new BehaviorSubject<Record<string, Pick<DeviceTypes, 'selectedValue'>>>({});

  private _addDeviceStore = inject(AddDeviceStore);

  readonly configuring$ = this._addDeviceStore.select(({ configuring }) => configuring[this.device.device_unique_id]);

  constructor() {
    this.discoveryDeviceForConfiguration$ = this._addDeviceStore.discoveryDeviceForConfiguration$;
  }

  handleAttributeValueUpdate(value: number, attributeKey: string, device: InventoryDevicesListToDisplay): void {
    BrowserLogger.log('attribute value updated: ', value);

    const previousAttributes = this.updatedAttributes$.getValue();

    this.updatedAttributes$.next({
      ...previousAttributes,
      [device.device_unique_id]: {
        ...previousAttributes[device.device_unique_id],
        [attributeKey]: {
          selectedValue: value,
        },
      },
    });
  }

  handleDeviceTypeSelection(value: string, device: InventoryDevicesListToDisplay): void {
    BrowserLogger.log('device type selection: ', value);

    const previousDeviceTypes = this.updatedDeviceTypes$.getValue();

    this.updatedDeviceTypes$.next({
      ...previousDeviceTypes,
      [device.device_unique_id]: { selectedValue: value },
    });
  }

  async handleConfigureClick(device: InventoryDevicesListToDisplay) {
    const updatedAttributes = this.updatedAttributes$.getValue()[device.device_unique_id];

    const updatedDeviceTypes = this.updatedDeviceTypes$.getValue()[device.device_unique_id];

    BrowserLogger.log('handle configured:', device, updatedAttributes, updatedDeviceTypes);

    this._addDeviceStore.configureDiscoveredDevice(
      produce(device, (device) => {
        for (const key of Object.keys(updatedAttributes ?? {})) {
          device.deviceConfigAttributes[key].selectedValue = updatedAttributes[key].selectedValue;
        }

        if (updatedDeviceTypes && device.deviceTypes) {
          device.deviceTypes.selectedValue = updatedDeviceTypes.selectedValue;
        }
      }),
    );
  }

  isConfirmButtonDisable(device: InventoryDevicesListToDisplay): boolean {
    const deviceTypes = this.updatedDeviceTypes$.getValue()[device.device_unique_id];

    const deviceConfigAttributes = this.updatedAttributes$.getValue()[device.device_unique_id];

    let disableButton = false;

    if (!isEmpty(deviceTypes)) {
      disableButton = deviceTypes.selectedValue == null;
    }

    if (!isEmpty(deviceConfigAttributes)) {
      const keys = Object.keys(deviceConfigAttributes);
      for (let index = 0; index < keys.length; index++) {
        const deviceConfigAttributeKey = keys[index];
        if (deviceConfigAttributes[deviceConfigAttributeKey].selectedValue == null) {
          disableButton = true;
          break;
        }
      }
    }

    return disableButton;
  }
}
