<ng-container [ngSwitch]="skeletonType">
  <!-- CARD  -->
  <ng-container *ngSwitchCase="SkeletonPlaceholder.CARD">
    <!-- card template -->
    <ng-template #cardContent>
      <ion-skeleton-text animated class="width-25-pct mb-10"></ion-skeleton-text>
      <ion-skeleton-text animated class="width-40-pct mb-10"></ion-skeleton-text>
      <ion-skeleton-text animated class="width-50-pct mb-10"></ion-skeleton-text>
      <ion-skeleton-text animated class="width-40-pct mb-10"></ion-skeleton-text>
      <ion-skeleton-text animated class="width-50-pct"></ion-skeleton-text>
    </ng-template>
    <!-- skeleton with Card -->
    <ion-card *ngIf="showCard; else hideCard" class="new-design-card">
      <ion-card-content>
        <ng-container *ngTemplateOutlet="cardContent"> </ng-container>
      </ion-card-content>
    </ion-card>
    <!-- skeleton without Card -->
    <ng-template #hideCard>
      <ng-container *ngTemplateOutlet="cardContent"></ng-container>
    </ng-template>
  </ng-container>

  <!--  single ROW -->
  <ng-container *ngSwitchCase="SkeletonPlaceholder.SINGLE_ROW">
    <ion-skeleton-text animated class="w-100"></ion-skeleton-text>
  </ng-container>

  <!--  Table header four column -->
  <ng-container *ngSwitchCase="SkeletonPlaceholder.TABLE_HEADER_FOUR_COLUMN">
    <ng-template #tableHeaderFourColumnContent>
      <ion-skeleton-text animated class="width-20-pct mb-10"></ion-skeleton-text>
      <ion-skeleton-text animated class="width-80-pct"></ion-skeleton-text>
    </ng-template>
    <ion-grid>
      <ion-row>
        <ion-col size="6" size-md="3" *ngFor="let number of [0, 1, 2, 3]">
          <ion-card class="ion-no-margin new-design-card" *ngIf="showCard; else hideCard">
            <ion-card-content>
              <ng-container *ngTemplateOutlet="tableHeaderFourColumnContent"></ng-container>
            </ion-card-content>
          </ion-card>
          <ng-template #hideCard>
            <ng-container *ngTemplateOutlet="tableHeaderFourColumnContent"> </ng-container>
          </ng-template>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-container>

  <!--  Table with header and row -->
  <ng-container *ngSwitchCase="SkeletonPlaceholder.TABLE_WITH_HEADER_AND_FOOTER">
    <ng-template #tableWithHeaderAndFooterContent>
      <table class="width-100-pct">
        <tr *ngFor="let number of [0, 1, 2, 3]">
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
        </tr>
      </table>
    </ng-template>
    <ion-card class="new-design-card" *ngIf="showCard; else hideCard">
      <ion-card-header>
        <ion-skeleton-text class="width-20-pct" animated></ion-skeleton-text>
      </ion-card-header>
      <ion-card-content class="ion-no-padding">
        <ng-container *ngTemplateOutlet="tableWithHeaderAndFooterContent"></ng-container>
        <ion-item lines="none">
          <ion-skeleton-text animated class="width-70-pct" slot="start"></ion-skeleton-text>
          <ion-skeleton-text animated class="width-30-pct"></ion-skeleton-text>
        </ion-item>
      </ion-card-content>
    </ion-card>
    <ng-template #hideCard>
      <ng-container *ngTemplateOutlet="tableWithHeaderAndFooterContent"></ng-container>
    </ng-template>
  </ng-container>

  <!--  TABLE WITH HEADER -->
  <ng-container *ngSwitchCase="SkeletonPlaceholder.TABLE_WITH_HEADER">
    <ng-template #tableWithHeaderContent>
      <table class="width-100-pct">
        <tr *ngFor="let number of [0, 1, 2, 3]">
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
          <td><ion-skeleton-text animated></ion-skeleton-text></td>
        </tr>
      </table>
    </ng-template>
    <ion-card class="new-design-card" *ngIf="showCard; else hideCard">
      <ion-card-header>
        <ion-skeleton-text animated class="mb-10 width-20-pct"></ion-skeleton-text>
        <ion-skeleton-text animated class="width-50-pct"></ion-skeleton-text>
      </ion-card-header>
      <ion-card-content class="ion-no-padding">
        <ng-container *ngTemplateOutlet="tableWithHeaderContent"> </ng-container>
      </ion-card-content>
    </ion-card>
    <ng-template #hideCard>
      <ng-container *ngTemplateOutlet="tableWithHeaderContent"> </ng-container>
    </ng-template>
  </ng-container>

  <!-- WIDGET CARD -->
  <ng-container *ngSwitchCase="SkeletonPlaceholder.WIDGET">
    <ion-skeleton-text animated class="width-25-pct"></ion-skeleton-text>
    <div class="widget-row mb-10">
      <ion-skeleton-text animated class="width-50-pct"></ion-skeleton-text>
      <ion-skeleton-text animated class="width-25-pct"></ion-skeleton-text>
    </div>
    <ion-skeleton-text animated class="width-70-pct"></ion-skeleton-text>
  </ng-container>

  <!-- CARD with One Column and Row -->
  <ng-container *ngSwitchCase="SkeletonPlaceholder.CARD_WITH_COLUMN_AND_ROW">
    <ng-template #cardWithColumnContent>
      <ion-skeleton-text class="width-25-pct mb-10"></ion-skeleton-text>
      <ion-skeleton-text class="width-50-pct mb-10"></ion-skeleton-text>
      <ion-skeleton-text></ion-skeleton-text>
    </ng-template>
    <ng-template #cardWithRowContent>
      <ion-skeleton-text animated class="width-25-pct mb-10"></ion-skeleton-text>
      <ion-skeleton-text animated></ion-skeleton-text>
    </ng-template>
    <ion-row>
      <ion-col size="12" size-lg="4">
        <ion-card class="new-design-card" *ngIf="showCard; else hideCard">
          <ion-card-content>
            <ng-container *ngTemplateOutlet="cardWithColumnContent"> </ng-container>
          </ion-card-content>
        </ion-card>
        <ng-template #hideCard>
          <ng-container *ngTemplateOutlet="cardWithColumnContent"></ng-container>
        </ng-template>
      </ion-col>
      <ion-col size="12" size-lg="8">
        <ion-card class="new-design-card" *ngIf="showCard; else hideCard">
          <ion-card-content>
            <ng-container *ngTemplateOutlet="cardWithRowContent"></ng-container>
          </ion-card-content>
        </ion-card>
        <ng-template #hideCard>
          <ng-container *ngTemplateOutlet="cardWithRowContent"> </ng-container>
        </ng-template>
      </ion-col>
    </ion-row>
  </ng-container>
</ng-container>
