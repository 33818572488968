import { createReducer, on } from '@ngrx/store';
import { UserOrgMembership } from '@custom-types/user-accounts/user-org-membership.model';
import {
  setSelectedUserOrganisationMembership,
  setUserOrganisationMemberships,
} from './user-organisation-membership.action';

export interface UserOrgMembershipsState {
  orgMemberships: UserOrgMembership[];
  selectedOrgMembership: UserOrgMembership;
}

const initialState: UserOrgMembershipsState = {
  orgMemberships: [],
} as UserOrgMembershipsState;

export const appUserOrgMembershipsReducer = createReducer(
  initialState,
  on(setUserOrganisationMemberships, (state, { memberships }) => {
    const { selectedOrgMembership } = state;
    if (selectedOrgMembership?.uuid) {
      return {
        ...state,
        orgMemberships: memberships,
        selectedOrgMembership: memberships.find((m) => m.uuid === selectedOrgMembership.uuid) ?? memberships[0],
      };
    }
    return {
      ...state,
      orgMemberships: memberships,
      selectedOrgMembership: memberships[0],
    };
  }),
  on(setSelectedUserOrganisationMembership, (state, { orgUuid }) => ({
    ...state,
    selectedOrgMembership:
      state.orgMemberships.find((m) => m.uuid === orgUuid) ?? ({ uuid: orgUuid } as UserOrgMembership),
  })),
);
