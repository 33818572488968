import { isEmpty } from 'lodash';
import {
  InventoryDeviceConnectionType,
  DeviceConnectionTypesNames,
  DeviceConfigurationPostApi,
  DeviceConnectionTypes,
  DropletDetail,
  InventoryDevicesListToDisplay,
  ManufacturerDeviceType,
  DropletLayoutPort,
  DropletPortDeviceForDiscovery,
  DeviceForDiscovery,
} from './add-device-types';
import { DataTypeStrings } from '@class/commons/enums';

// this functionality can easily be maintained on server side
// but it has to be here for no reason
export const getDeviceConnectionTypeNameByInventoryDeviceConnectionType: Record<
  InventoryDeviceConnectionType,
  DeviceConnectionTypesNames[]
> = {
  [InventoryDeviceConnectionType.IP]: [
    DeviceConnectionTypesNames.LAN_WIFI,
    DeviceConnectionTypesNames.DIRECT_CONNECT_ETHERNET,
  ],
  [InventoryDeviceConnectionType.SPEED_WIRE]: [
    DeviceConnectionTypesNames.LAN_WIFI,
    DeviceConnectionTypesNames.DIRECT_CONNECT_ETHERNET,
  ],
  [InventoryDeviceConnectionType.SERIAL]: [DeviceConnectionTypesNames.SERIAL],
  [InventoryDeviceConnectionType.BLUETOOTH]: [DeviceConnectionTypesNames.BLUETOOTH],
  [InventoryDeviceConnectionType.USB]: [DeviceConnectionTypesNames.USB],
};

export const getDeviceConfigurationPostApi = (
  deviceToBeConfigured: InventoryDevicesListToDisplay,
  dropletDetails: DropletDetail,
  deviceConnectionTypes: DeviceConnectionTypes[],
): DeviceConfigurationPostApi => {
  // first get the device connection type name so we can get the id for that
  const deviceConnectionTypeNames: DeviceConnectionTypesNames[] =
    getDeviceConnectionTypeNameByInventoryDeviceConnectionType[deviceToBeConfigured.connection_descriptor.type];

  // now will find the connection id from the device connection types
  let deviceConnectionType: DeviceConnectionTypes;
  for (let index = 0; index < deviceConnectionTypeNames.length; index++) {
    const connectionTypeName = deviceConnectionTypeNames[index];
    deviceConnectionType = deviceConnectionTypes.find((item) => item.name === connectionTypeName);
    // break the loop once there is a match
    if (deviceConnectionType) break;
  }

  // create parameters list - device config attributes
  const deviceTypeConfigurationAttributes: {
    [attributeName: string]: string | boolean | number;
  } = {};
  for (const attributeName of Object.keys(deviceToBeConfigured.deviceConfigAttributes)) {
    const attribute = deviceToBeConfigured.deviceConfigAttributes[attributeName];
    deviceTypeConfigurationAttributes[attributeName] = attribute.selectedValue;
  }

  // now create the post api object and return it as we have
  const deviceConfigurationPostApi: DeviceConfigurationPostApi = {
    connection_descriptor: {
      ...deviceToBeConfigured.connection_descriptor,
      connection_type_id: deviceConnectionType?.id,
    },
    device_info: {
      ...deviceToBeConfigured.info,
      device_unique_id: deviceToBeConfigured.device_unique_id,
      manufacturer_name: deviceToBeConfigured.info.manufacturer,
    },
    endpoint_info: {
      endpoint_id: dropletDetails.id,
      uuid: dropletDetails.uuid,
    },
  };

  // set device type if there is any
  if (!isEmpty(deviceToBeConfigured.deviceTypes)) {
    deviceConfigurationPostApi.device_info.device_type_id = deviceToBeConfigured.deviceTypes.selectedValue;
  }

  if (Object.keys(deviceTypeConfigurationAttributes).length > 0) {
    deviceConfigurationPostApi.parameters = deviceTypeConfigurationAttributes;
  }

  return deviceConfigurationPostApi;
};

export const adaptToDropletPortDeviceForDiscovery = (
  selectedDevice: ManufacturerDeviceType,
  selectedPort: DropletLayoutPort,
): DropletPortDeviceForDiscovery => {
  const deviceForDiscovery: DeviceForDiscovery = {
    model_name: selectedDevice.model_name,
    manufacturer_name: selectedDevice.manufacturer_name,
    model_number: selectedDevice.model_number,
    default_slave_id: selectedDevice.default_slave_id,
    logical_device_type_name: selectedDevice.logical_device_type_name,
    protocol: selectedDevice.protocol,
    generic_device_type: selectedDevice.generic_device_type,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    device_type_connection_attributes: selectedDevice.device_type_connection_attributes as any,
    approved: selectedDevice.approved,
  };

  for (const key in selectedDevice.attributeValues) {
    if (Object.prototype.hasOwnProperty.call(selectedDevice.attributeValues, key)) {
      const attribute = selectedDevice.attributeValues[key];
      deviceForDiscovery[key] = attribute;
    }
  }

  const dropletPortDevice: DropletPortDeviceForDiscovery = {
    port_number: selectedPort.port_number,
    type: selectedPort.type,
  };
  if (selectedPort.adapterType) {
    dropletPortDevice.adapters = [
      {
        type: selectedPort.adapterType,
        devices: [deviceForDiscovery],
      },
    ];
  } else {
    dropletPortDevice.devices = [deviceForDiscovery];
  }

  return dropletPortDevice;
};

export const getCustomInputTypeFromDeviceConfigAttributeType = (
  type: DataTypeStrings,
): DataTypeStrings.TEXT | DataTypeStrings.NUMBER | DataTypeStrings.BOOLEAN => {
  switch (type) {
    case DataTypeStrings.TEXT:
    case DataTypeStrings.CHAR:
    case DataTypeStrings.VARCHAR:
    case DataTypeStrings.STRING:
      return DataTypeStrings.TEXT;
    case DataTypeStrings.NUMBER:
    case DataTypeStrings.INT:
    case DataTypeStrings.FLOAT:
      return DataTypeStrings.NUMBER;
    case DataTypeStrings.BOOLEAN:
    case DataTypeStrings.BOOL:
      return DataTypeStrings.BOOLEAN;
    default:
      return DataTypeStrings.TEXT;
  }
};
