import { ThemeInterface } from './theme-interface';
import { defaultTheme } from './default-theme';
import { getThemeIconsLight, ThemeMode, ThemeMap } from './theme-map';

const powerPlusEnergyTheme: ThemeInterface = {
  name: ThemeMap.POWER_PLUS_ENERGY,
  type: ThemeMode.LIGHT,
  styles: {
    global: [{ themeVariable: '--ion-item-background', value: 'white' }],
    brandPrimary: [
      { themeVariable: '--ion-color-brand-primary', value: '#80BE79' },
      { themeVariable: '--ion-color-brand-primary-rgb', value: '128, 190, 121' },
      { themeVariable: '--ion-color-brand-primary-contrast', value: '#fffff' },
      { themeVariable: '--ion-color-brand-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-brand-primary-shade', value: '#71a76a' },
      { themeVariable: '--ion-color-brand-primary-tint', value: '#8dc586' },
    ],
    textPrimary: [
      { themeVariable: '--ion-color-text-primary', value: '#000000' },
      { themeVariable: '--ion-color-text-primary-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-text-primary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-text-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-text-primary-shade', value: '#000000' },
      { themeVariable: '--ion-color-text-primary-tint', value: '#1a1a1a' },
    ],
    textTertiary: defaultTheme.styles.textTertiary,
    textSecondary: [
      { themeVariable: '--ion-color-text-secondary', value: '#66666' },
      { themeVariable: '--ion-color-text-secondary-rgb', value: '102, 102, 102' },
      { themeVariable: '--ion-color-text-secondary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-text-secondary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-text-secondary-shade', value: '#5a5a5a' },
      { themeVariable: '--ion-color-text-secondary-tint', value: '#757575' },
    ],
    backgroundPrimary: [
      { themeVariable: '--ion-color-background-primary', value: '#F1F1F1' },
      { themeVariable: '--ion-color-background-primary-rgb', value: '241, 241, 241' },
      { themeVariable: '--ion-color-background-primary-contrast', value: '#000000' },
      { themeVariable: '--ion-color-background-primary-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-background-primary-shade', value: '#d4d4d4' },
      { themeVariable: '--ion-color-background-primary-tint', value: '#f2f2f2' },
    ],
    backgroundSecondary: [
      { themeVariable: '--ion-color-background-secondary', value: '#3C7EAD' },
      { themeVariable: '--ion-color-background-secondary-rgb', value: '60, 126, 173' },
      { themeVariable: '--ion-color-background-secondary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-background-secondary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-background-secondary-shade', value: '#356f98' },
      { themeVariable: '--ion-color-background-secondary-tint', value: '#508bb5' },
    ],
    backgroundTertiary: [
      { themeVariable: '--ion-color-background-tertiary', value: '#004B69' },
      { themeVariable: '--ion-color-background-tertiary-rgb', value: '0, 75, 105' },
      { themeVariable: '--ion-color-background-tertiary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-background-tertiary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-background-tertiary-shade', value: '#00425c' },
      { themeVariable: '--ion-color-background-tertiary-tint', value: '#1a5d78' },
    ],
    subTabLabels: [
      { themeVariable: '--ion-color-sub-tab-labels', value: '#000000' },
      { themeVariable: '--ion-color-sub-tab-labels-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-sub-tab-labels-contrast', value: '#444444' },
      { themeVariable: '--ion-color-sub-tab-labels-contrast-rgb', value: '68, 68, 68' },
      { themeVariable: '--ion-color-sub-tab-labels-shade', value: '#000000' },
      { themeVariable: '--ion-color-sub-tab-labels-tint', value: '#1a1a1a' },
    ],
    solar: [
      { themeVariable: '--ion-color-solar', value: '#FFAB07' },
      { themeVariable: '--ion-color-solar-rgb', value: '255, 171, 7' },
      { themeVariable: '--ion-color-solar-contrast', value: '#000000' },
      { themeVariable: '--ion-color-solar-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-solar-shade', value: '#e09606' },
      { themeVariable: '--ion-color-solar-tint', value: '#ffb320' },
    ],
    battery: [
      { themeVariable: '--ion-color-battery', value: '#9AAA75' },
      { themeVariable: '--ion-color-battery-rgb', value: '154, 170, 117' },
      { themeVariable: '--ion-color-battery-contrast', value: '#000000' },
      { themeVariable: '--ion-color-battery-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-battery-shade', value: '#889667' },
      { themeVariable: '--ion-color-battery-tint', value: '#a4b383' },
    ],
    consumption: [
      { themeVariable: '--ion-color-consumption', value: '#37424A' },
      { themeVariable: '--ion-color-consumption-rgb', value: '55, 66, 74' },
      { themeVariable: '--ion-color-consumption-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-consumption-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-consumption-shade', value: '#303a41' },
      { themeVariable: '--ion-color-consumption-tint', value: '#4b555c' },
    ],
    consumed: [
      { themeVariable: '--ion-color-consumed', value: '#1380B1' },
      { themeVariable: '--ion-color-consumed-rgb', value: '19, 128, 177' },
      { themeVariable: '--ion-color-consumed-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-consumed-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-consumed-shade', value: '#11719c' },
      { themeVariable: '--ion-color-consumed-tint', value: '#2b8db9' },
    ],
  },
  headerLogo: {
    path: getThemeIconsLight[ThemeMap.POWER_PLUS_ENERGY],
    style: {
      height: '24px',
    },
  },
  footerLogo: defaultTheme.footerLogo,
  footerColoredLogo: defaultTheme.footerColoredLogo,
  iconsSvgPath: defaultTheme.iconsSvgPath,
  chartColors: {
    primary: { value: '#FFAB07', opacValue: 'rgba(255, 171, 7, 0.4)' }, // solar
    secondary: { value: '#1380B1', opacValue: 'rgba(19, 128, 177, 0.4)' }, // consumed
    light: { value: '#37424A', opacValue: 'rgba(55, 66, 74, 0.4)' }, // consumption
    tertiary: { value: '#9AAA75', opacValue: 'rgba(154, 170, 117, 0.4)' }, // battery

    colorFive: { value: '#7BA24B', opacValue: 'rgba(123, 162, 75, 0.4)' },
    colorSix: { value: '#3933DE', opacValue: 'rgba(57, 51, 222, 0.4)' },
    colorSeven: { value: '#AE33DE', opacValue: 'rgba(174, 51, 222, 0.4)' },
    colorEight: { value: '#6B9FAF', opacValue: 'rgba(107, 159, 175, 0.4)' },
    colorNine: { value: '#DE3355', opacValue: 'rgba(222, 51, 85, 0.4)' },
    colorTen: { value: '#96F70D', opacValue: 'rgba(150, 247, 13, 0.4)' },
  },
  vppChartColors: defaultTheme.vppChartColors,
  streamChartColor: '#6CC071',
  buttons: {
    primary: [
      { themeVariable: '--ion-color-button-primary', value: '#80BE79' },
      { themeVariable: '--ion-color-button-primary-rgb', value: '128, 190, 121' },
      { themeVariable: '--ion-color-button-primary-contrast', value: '#fffff' },
      { themeVariable: '--ion-color-button-primary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-button-primary-shade', value: '#71a76a' },
      { themeVariable: '--ion-color-button-primary-tint', value: '#8dc586' },
    ],
    // UI Elements
    secondary: [
      { themeVariable: '--ion-color-button-secondary', value: '#3C7EAD' },
      { themeVariable: '--ion-color-button-secondary-rgb', value: '60, 126, 173' },
      { themeVariable: '--ion-color-button-secondary-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-button-secondary-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-button-secondary-shade', value: '#356f98' },
      { themeVariable: '--ion-color-button-secondary-tint', value: '#508bb5' },
    ],
    // Action Green
    success: [
      { themeVariable: '--ion-color-success', value: '#80BE79' },
      { themeVariable: '--ion-color-success-rgb', value: '128, 190, 121' },
      { themeVariable: '--ion-color-success-contrast', value: '#FFFFF' },
      { themeVariable: '--ion-color-success-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-success-shade', value: '#71a76a' },
      { themeVariable: '--ion-color-success-tint', value: '#8dc586' },
    ],
    // Action Yellow
    warning: [
      { themeVariable: '--ion-color-warning', value: '#ECA83C' },
      { themeVariable: '--ion-color-warning-rgb', value: '236, 168, 60' },
      { themeVariable: '--ion-color-warning-contrast', value: '#FFFFF' },
      { themeVariable: '--ion-color-warning-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-warning-shade', value: '#d09435' },
      { themeVariable: '--ion-color-warning-tint', value: '#eeb150' },
    ],
    // Action Red
    danger: [
      { themeVariable: '--ion-color-danger', value: '#E60D21' },
      { themeVariable: '--ion-color-danger-rgb', value: '230, 13, 33' },
      { themeVariable: '--ion-color-danger-contrast', value: '#ffffff' },
      { themeVariable: '--ion-color-danger-contrast-rgb', value: '255, 255, 255' },
      { themeVariable: '--ion-color-danger-shade', value: '#ca0b1d' },
      { themeVariable: '--ion-color-danger-tint', value: '#e92537' },
    ],
    successLite: [
      { themeVariable: '--ion-color-success-lite', value: '#C9E7C5' },
      { themeVariable: '--ion-color-success-lite-rgb', value: '201, 231, 197' },
      { themeVariable: '--ion-color-success-lite-contrast', value: '#80BE79' },
      { themeVariable: '--ion-color-success-lite-contrast-rgb', value: '128, 190, 121' },
      { themeVariable: '--ion-color-success-lite-shade', value: '#b1cbad' },
      { themeVariable: '--ion-color-success-lite-tint', value: '#cee9cb' },
    ],
    warningLite: [
      { themeVariable: '--ion-color-warning-lite', value: '#FEEACB' },
      { themeVariable: '--ion-color-warning-lite-rgb', value: '254, 234, 203' },
      { themeVariable: '--ion-color-warning-lite-contrast', value: '#ECA83C' },
      { themeVariable: '--ion-color-warning-lite-contrast-rgb', value: '236, 168, 60' },
      { themeVariable: '--ion-color-warning-lite-shade', value: '#e0ceb3' },
      { themeVariable: '--ion-color-warning-lite-tint', value: '#feecd0' },
    ],
    dangerLite: [
      { themeVariable: '--ion-color-danger-lite', value: '#FCD1D5' },
      { themeVariable: '--ion-color-danger-lite-rgb', value: '252, 209, 213' },
      { themeVariable: '--ion-color-danger-lite-contrast', value: '#E60D21' },
      { themeVariable: '--ion-color-danger-lite-contrast-rgb', value: '230, 13, 33' },
      { themeVariable: '--ion-color-danger-lite-shade', value: '#deb8bb' },
      { themeVariable: '--ion-color-danger-lite-tint', value: '#fcd6d9' },
    ],
  },
  svgIcons: {
    // Icons / Home
    primarySvg: [
      { themeVariable: '--ion-color-primary-svg', value: '#80BE79' },
      { themeVariable: '--ion-color-primary-svg-rgb', value: '128, 190, 121' },
      { themeVariable: '--ion-color-primary-svg-contrast', value: '#000000' },
      { themeVariable: '--ion-color-primary-svg-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-primary-svg-shade', value: '#71a76a' },
      { themeVariable: '--ion-color-primary-svg-tint', value: '#8dc586' },
    ],
  },
  // Tables
  tablesRow: {
    primaryTile: [
      { themeVariable: '--ion-color-table-primary-tile', value: '#FAFAFA' },
      { themeVariable: '--ion-color-table-primary-tile-rgb', value: '250, 250, 250' },
      { themeVariable: '--ion-color-table-primary-tile-contrast', value: '#000000' },
      { themeVariable: '--ion-color-table-primary-tile-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-table-primary-tile-shade', value: '#dcdcdc' },
      { themeVariable: '--ion-color-table-primary-tile-tint', value: '#fbfbfb' },
    ],
    secondaryTile: [
      { themeVariable: '--ion-color-table-secondary-tile', value: '#F5F5F5' },
      { themeVariable: '--ion-color-table-secondary-tile-rgb', value: '245, 245, 245' },
      { themeVariable: '--ion-color-table-secondary-tile-contrast', value: '#000000' },
      { themeVariable: '--ion-color-table-secondary-tile-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-table-secondary-tile-shade', value: '#d8d8d8' },
      { themeVariable: '--ion-color-table-secondary-tile-tint', value: '#f6f6f6' },
    ],
    grayTile: [
      { themeVariable: '--ion-color-table-gray-tile', value: '#FCFCFC' },
      { themeVariable: '--ion-color-table-gray-tile-rgb', value: '252, 252, 252' },
      { themeVariable: '--ion-color-table-gray-tile-contrast', value: '#000000' },
      { themeVariable: '--ion-color-table-gray-tile-contrast-rgb', value: '0, 0, 0' },
      { themeVariable: '--ion-color-table-gray-tile-shade', value: '#dedede' },
      { themeVariable: '--ion-color-table-gray-tile-tint', value: '#fcfcfc' },
    ],
  },
};

export { powerPlusEnergyTheme };
