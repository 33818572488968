import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { defer, Observable, of } from 'rxjs';
import { catchError, map, startWith, tap } from 'rxjs/operators';
import { FacadeStates } from '../../../classes/commons/facade-states';
import { ApiWrapper, AvailableAPI, RequestMethod, UseHeaderType } from '../../common/api-wrapper.service';
import { TranslationsService } from '../../common/translations.service';

interface GetTransactionState {
  data: Transaction[];
  status: FacadeStates;
  message: string;
}

interface TransactionsGetPayload {
  amount: number;
  date: Date;
  currency: string;
  description: string;
  status: TransactionStatus;
  type?: string;
}

export enum TransactionStatus {
  PENDING = 'pending',
  PAID = 'succeeded',
  FAILED = 'failed',
  CANCELED = 'canceled',
}

interface Transaction {
  amount: number;
  date: Date;
  currency: string;
  description: string;
  status: TransactionStatus;
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  public transactions$: Observable<GetTransactionState> = defer(() => this.getTransactionsApi()).pipe(
    map((res) => this.processTransactionStatus(res)),
    startWith({
      data: undefined,
      status: FacadeStates.LOADING,
      message: null,
    }),
    catchError((err) => this.catchAccountStatusApiError(err)),
  );

  constructor(
    private api: ApiWrapper,
    private trans: TranslationsService,
  ) {}

  private processTransactionStatus(res: { data: TransactionsGetPayload[] }): GetTransactionState {
    const data = res?.data?.map((payload) => this.adaptGetPayload(payload)) ?? [];
    return {
      data,
      status: FacadeStates.COMPLETE,
      message: null,
    };
  }

  private adaptGetPayload(payload: TransactionsGetPayload): Transaction {
    if (isEmpty(payload)) return null;
    const { amount, date, currency, description, status } = payload;
    const transaction: Transaction = {
      amount,
      date,
      currency,
      description,
      status,
      type: 'Community Battery Credits',
    };
    return transaction;
  }

  private getTransactionsApi(): Promise<{ data: TransactionsGetPayload[] }> {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      `/api/v1/payments/transactions/`,
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
    ) as any;
  }

  private catchAccountStatusApiError(err: any): Observable<GetTransactionState> {
    let message = err;
    if (err instanceof HttpErrorResponse) {
      switch (err.status) {
        case 503:
        case 401:
        case 500:
          message = this.trans.instant(`UnitPage.FinancialAccount.${err.status}`);
          break;

        default:
          message = `${err.status} ${err.statusText}`;
          break;
      }
    }
    return of({
      data: undefined,
      status: FacadeStates.ERROR,
      message,
    });
  }
}
