import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { LegacyDeviceType } from '../utils/add-device-types';
import { LegacyDeviceStore } from '../store/legacy-device.store';

@Component({
  selector: 'app-legacy-devices',
  templateUrl: './legacy-devices.component.html',
  styleUrls: ['./legacy-devices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegacyDevicesComponent {
  legacyDevicesData$: Observable<{ legacyDevices: LegacyDeviceType[]; legacyDeviceFilterText: string }>;
  selectedLegacyDevice$: Observable<{ device_type_id: string; endpoint_id: string }>;

  constructor(private _legacyDeviceStore: LegacyDeviceStore) {
    this.legacyDevicesData$ = this._legacyDeviceStore.legacyDevicesData$;
    this.selectedLegacyDevice$ = this._legacyDeviceStore.selectedLegacyDevice$;
  }

  onSearchInputHandler(event: CustomEvent<{ value?: string }>): void {
    this._legacyDeviceStore.updateLegacyDeviceFilterTextAndFilteredLegacyDevices(event.detail.value ?? '');
  }

  onLegacyDeviceSelectionHandler(event: CustomEvent<{ value?: string }>): void {
    this._legacyDeviceStore.updateSelectedDevice(event?.detail.value);
  }

  ngOnDestroy(): void {
    this._legacyDeviceStore.resetSelectedLegacyDevice();
    this._legacyDeviceStore.resetSearch();
  }
}
