export const AlertTypes = [
  { name: 'warning', icon: 'warning', value: 'W' },
  { name: 'info', icon: 'information-circle', value: 'I' },
  { name: 'debug', icon: 'bug', value: 'D' },
];

export const AlertPeriods = [
  { name: 'minutes', value: 'M' },
  { name: 'hours', value: 'H' },
  { name: 'days', value: 'D' },
  { name: 'weeks', value: 'W' },
];

export const AlertExpressions = [
  { name: 'greater than', value: '>' },
  { name: 'less than', value: '<' },
  { name: 'equal to', value: '==' },
  { name: 'between', value: 'BETWEEN' },
];

export type NonCustomAlertDefinition = {
  category: CategoryValues;
  unit: string;
  pk: string;
  name: string;
  criticality: string; // 'debug' | 'warning' | 'info'
  isActive: boolean;
  remediation: string;
  period: number;
  period_unit: string; // 'M' | 'H' | 'D' | 'W'
  recipients: any[];
};

export const CategoryNameValues = [
  { translationString: 'Alerts.Custom', value: 'Custom' },
  { translationString: 'Alerts.DropletIsOffline', value: 'Droplet is offline' },
  { translationString: 'Alerts.DeviceIsDisconnected', value: 'Device is disconnected' },
] as const;

const categoryValuesArray = CategoryNameValues.map((el) => el.value);

export type CategoryValues = (typeof categoryValuesArray)[number];

export class AlertDefinition {
  public category: CategoryValues;
  public unit: string;
  public pk: string;
  public name: string;
  public criticality: string; // 'debug' | 'warning' | 'info'
  public isActive: boolean;
  public remediation: string;
  public condition: string; // 'IF'
  public metric: any;
  public metric_key: string;
  public expression: string;
  public value: number;
  public value2: number;
  public period: number;
  public period_unit: string; // 'M' | 'H' | 'D' | 'W'
  public permissions: any[];
  public recipients: any[];
  /**
   * was not sure what's the best way to add a optional stuff in here
   * Problem: if it's old style device then add metric_id otherwise add iec61850_metric_id
   */
  [key: string]: string | any;

  constructor(
    category = categoryValuesArray[0],
    unit = '',
    pk = '',
    name = '',
    criticality = '',
    isActive = true,
    remediation = '',
    condition = 'IF',
    metric = null,
    metric_key = '',
    expression = '',
    value: number = null,
    value2: number = null,
    period: number = null,
    period_unit = '',
    permissions: string[] = [],
    recipients: any[] = [],
  ) {
    this.category = category;
    this.unit = unit;
    this.pk = pk;
    this.name = name;
    this.criticality = criticality;
    this.isActive = isActive;
    this.remediation = remediation;
    this.condition = condition;
    this.metric = metric;
    this.metric_key = metric_key;
    this.expression = expression;
    this.value = value;
    this.value2 = value2;
    this.period = period;
    this.period_unit = period_unit;
    this.permissions = permissions;
    this.recipients = recipients;
    if (metric) {
      let key = metric.is_iec61850 ? 'iec61850_metric_id' : 'metric_id';
      this[key] = metric.id;
    }
  }

  public static getNonCustomAlertDefinition(alertDef: AlertDefinition): NonCustomAlertDefinition {
    return {
      category: alertDef.category,
      unit: alertDef.unit,
      pk: alertDef.pk,
      name: alertDef.name,
      criticality: alertDef.criticality,
      isActive: alertDef.isActive,
      remediation: alertDef.remediation,
      period: alertDef.period,
      period_unit: alertDef.period_unit,
      recipients: alertDef.recipients,
    };
  }
}
