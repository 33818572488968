// ------------------------------------------------------------------------------------------------------------
// Permissions Service
// - Simply a wrapper for the 'UserPermissions' class so preferences can be injected/accessed app wide
// ------------------------------------------------------------------------------------------------------------

import { Injectable, inject } from '@angular/core';
import { applyUserPermissions, clearUserPermissionsArea } from '@app-state/permissions/permissions.actions';
import { PermissionArea, PermissionDTO, PermissionKey } from '@class/commons/permissions/permission-constants';
import { UserPermissions } from '@class/commons/permissions/user-permissions';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private userPermissions: UserPermissions;
  #store = inject(Store);

  constructor() {
    this.userPermissions = new UserPermissions();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apply(permissionArea: PermissionArea, permissionData: any): void {
    this.userPermissions.apply(permissionArea, permissionData);
    this.#store.dispatch(applyUserPermissions({ permissionArea, permissionData }));
  }

  any(permissions: Array<PermissionKey>): boolean {
    return this.userPermissions.any(permissions);
  }

  all(permissions: Array<PermissionKey>): boolean {
    return this.userPermissions.all(permissions);
  }

  anyExternal(permissionsToCheck: Array<PermissionKey>, externalPermissionsList: Array<PermissionDTO>): boolean {
    return this.userPermissions.anyExternal(permissionsToCheck, externalPermissionsList);
  }

  clear(permissionArea: PermissionArea): void {
    this.userPermissions.clear(permissionArea);
    this.#store.dispatch(clearUserPermissionsArea({ permissionArea }));
  }

  has(permission: PermissionKey): boolean {
    return this.userPermissions.has(permission);
  }
}
