import { AppState } from '@app-state/index';
import { UserOrgMembershipsState } from './user-organisation-membership.reducers';
import { createSelector } from '@ngrx/store';

export const selectUserOrgMembershipState = (state: AppState): UserOrgMembershipsState => state.appUserOrgMemberships;

export const allUserOrgMemberships = createSelector(
  selectUserOrgMembershipState,
  (state: UserOrgMembershipsState) => state.orgMemberships,
);
export const selectedUserOrgMembership = createSelector(
  selectUserOrgMembershipState,
  (state: UserOrgMembershipsState) => state.selectedOrgMembership,
);
