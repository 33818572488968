import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-troubleshoot-card',
  templateUrl: './troubleshoot-card.component.html',
  styleUrls: ['./troubleshoot-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TroubleshootCardComponent {
  @Input() error = '';
  @Output() onRetryClick = new EventEmitter<void>();

  constructor() {}

  retryHandler() {
    this.onRetryClick.emit();
  }
}
