import { AlertsService } from './../alerts/alerts.service';
import { VirtualPowerPlantsService } from './../virtualPowerPlants/virtual-power-plants.service';
import { ProfileService } from './../profile/profile.service';
import { UnitsService } from './../units/units.service';
import { FavouritesService } from './../common/favourites.service';
import { PortfoliosService } from './../portfolios/portfolios.service';
import { Injectable } from '@angular/core';
import { StorageService } from '../common/storage.service';
import { MqttService } from 'ngx-mqtt';
import { AuthService } from '@service/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
// todo - this class/filename is spelt incorrectly, do we fix or leave?
export class ReseterService {
  exclusions = [];
  constructor(
    private favouritesService: FavouritesService,
    private portfoliosService: PortfoliosService,
    private unitsService: UnitsService,
    private profileService: ProfileService,
    private virtualPowerPlantsService: VirtualPowerPlantsService,
    private alertsService: AlertsService,
    private storageService: StorageService,
    private mqttService: MqttService,
    private authService: AuthService,
  ) {}

  public async run() {
    await this.storageService.clearLocalAndSessionStorage();
    this.authService.logout();
    this.favouritesService.clearFavourites();
    this.portfoliosService.resetService();
    this.unitsService.resetService();
    this.virtualPowerPlantsService.resetService();
    this.profileService.resetService();
    this.alertsService.resetService();
    this.disconnectMqtt();
  }

  private disconnectMqtt(): void {
    //console.log('ReseterService.disconnectMqtt');
    this.mqttService.disconnect();
  }
}
