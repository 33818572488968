import { Component, OnDestroy } from '@angular/core';
import { UnitFinancialAccountFacadeService } from '../../services/units/facade/unit-financial-account-facade.service';
import { FacadeStates } from '../../classes/commons/facade-states';
import { ProfileService } from '../../services/profile/profile.service';
import { ThemeService } from '../../services/themes/theme.service';
import { Subscription } from 'rxjs';
import { ThemeInterface } from 'theme/theme-interface';
import { SkeletonPlaceholder } from '@class/commons/constants';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent implements OnDestroy {
  public isAccountSetup$ = this.facade.isAccountSetup$;
  public readonly FacadeStates = FacadeStates;
  readonly SkeletonPlaceholder = SkeletonPlaceholder;

  public headerLogoPath: string = '';
  public headerLogoAltName: string = '';

  private themeSubscription: Subscription;
  constructor(
    private facade: UnitFinancialAccountFacadeService,
    public profileService: ProfileService,
    public theme: ThemeService,
  ) {
    this.themeSubscription = this.getThemeSubscription();
  }

  ngOnDestroy(): void {
    if (this.themeSubscription) this.themeSubscription.unsubscribe();
  }

  public async setupAccount(): Promise<void> {
    this.facade.getStripeLink();
  }

  private getThemeSubscription(): Subscription {
    return this.theme.userTheme$.subscribe((theme: ThemeInterface | null) => {
      if (theme) {
        this.setLogosIcons(theme);
      }
    });
  }
  private setLogosIcons(theme: ThemeInterface): void {
    this.headerLogoPath = theme.headerLogo.path;
    this.headerLogoAltName = theme.name;
  }
}
