import { Component, Input } from '@angular/core';
import { SkeletonPlaceholder } from '@class/commons/constants';

@Component({
  selector: 'app-skeleton-items',
  templateUrl: './skeleton-items.component.html',
  styleUrls: ['./skeleton-items.component.scss'],
})
export class SkeletonItemsComponent {
  @Input() skeletonType: SkeletonPlaceholder = SkeletonPlaceholder.CARD; // the default type of the skeleton
  @Input() showCard: boolean = true;

  readonly SkeletonPlaceholder = SkeletonPlaceholder;
}
