import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserLogger } from '@class/core/browser-logger';
import { BaseApiService } from 'app/api-services/base.api-service';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { DateTime } from 'luxon';
import { Subject, filter, tap } from 'rxjs';
import { STORAGE_CONSTANTS } from '../../classes/commons/constants';
import { RequestMethod, UseHeaderType } from '../common/api-wrapper.service';
import { StorageService } from '../common/storage.service';

export type TokenResponse = {
  access: string;
  refresh: string;
};

export type LoginResponse = {
  mfa: boolean;
};

export type ResendOtpResponse = {
  msg: string;
};

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseApiService {
  refreshTokenTimeout = null;

  private _tempStoreUserCred: { username: string; password: string };
  get tempStoreUserCred(): { username: string; password: string } {
    return this._tempStoreUserCred;
  }
  set tempStoreUserCred(value: { username: string; password: string }) {
    this._tempStoreUserCred = value;
  }

  readonly #newAuthAndRefreshToken = new Subject<TokenResponse>();

  constructor(
    private router: Router,
    private storageService: StorageService,
  ) {
    super('/api/v1/', {
      useHeader: UseHeaderType.DEFAULT,
    });

    this.#newAuthAndRefreshToken
      .pipe(
        filter((tokenResult) => tokenResult != null),
        tap((tokenResult) => this.storageService.setAuthToken(tokenResult.access)),
        tap((tokenResult) => this.storageService.setRefreshToken(tokenResult.refresh)),
        tap((tokenResult) => this.startTimerToRenewAccessToken(tokenResult)),
      )
      .subscribe();
  }

  async loginOTP(username: string, password: string): Promise<LoginResponse> {
    const res = await this.request<LoginResponse>({
      path: 'login-otp',
      method: RequestMethod.POST,
      data: { username, password },
    });

    return res;
  }

  async resendOTP(): Promise<ResendOtpResponse> {
    const { username, password } = this.tempStoreUserCred;

    const res = await this.request<ResendOtpResponse>({
      path: 'resend-otp',
      method: RequestMethod.POST,
      data: { username, password },
    });

    return res;
  }

  async verifyOneTimePasscode(code?: string): Promise<TokenResponse> {
    const { username, password } = this.tempStoreUserCred;
    const payload = code ? { username, password, code } : { username, password };

    const tokenResult = await this.request<TokenResponse>({
      path: 'token/jwt-obtain/',
      method: RequestMethod.POST,
      data: payload,
    });
    this.#newAuthAndRefreshToken.next(tokenResult);

    return tokenResult;
  }

  async logout(): Promise<void> {
    this.stopRenewAccessTokenTimer();
    await this.storageService.clearLocalAndSessionStorage();
    this.router.navigate(['/account/login']);
  }

  private getTokenExpiry(jwt: JwtPayload | string): DateTime {
    return DateTime.fromSeconds((typeof jwt === 'string' ? jwtDecode(jwt) : jwt).exp);
  }

  private isTokenExpired(jwt: JwtPayload | string) {
    const expiry = this.getTokenExpiry(jwt);

    return expiry < DateTime.now();
  }

  async isAccessTokenExpired() {
    BrowserLogger.log('Checking if access token is expired');

    const accessToken = await this.getAuthToken();

    return this.isTokenExpired(accessToken);
  }

  async refreshAccessTokenIfExpired(refreshToken: string, authToken: string) {
    BrowserLogger.log('refreshAccessTokenIfExpired');

    const accessTokenIsExpired = await this.isAccessTokenExpired();

    if (accessTokenIsExpired) {
      BrowserLogger.log('Access token is expired, refreshing token');
      await this.refreshAccessToken(refreshToken);
    } else {
      BrowserLogger.log('Access token is not expired');
      this.startTimerToRenewAccessToken({ refresh: refreshToken, access: authToken });
    }
  }

  async refreshAccessToken(refreshToken: string): Promise<void> {
    try {
      const refreshResult = await this.request<TokenResponse>({
        baseUrl: '/',
        path: 'token/refresh/',
        method: RequestMethod.POST,
        data: { refresh: refreshToken },
      });

      this.#newAuthAndRefreshToken.next(refreshResult);
    } catch (err) {
      console.error('Failed to refresh access token: ', err);
      this.logout();
      throw err;
    }
  }

  private calculateTokenTimeout(jwt: JwtPayload | string): number {
    const expires = this.getTokenExpiry(jwt).minus({ seconds: 60 });

    const expiresMillis = expires.diffNow().as('milliseconds');

    return Math.max(0, expiresMillis);
  }

  private async startTimerToRenewAccessToken({ access, refresh }: TokenResponse): Promise<void> {
    BrowserLogger.log('startTimerToRenewAccessToken', { access, refresh });

    try {
      this.stopRenewAccessTokenTimer();

      const timeout = this.calculateTokenTimeout(access);

      this.refreshTokenTimeout = setTimeout(() => {
        this.refreshAccessToken(refresh);
      }, timeout);
    } catch (err) {
      console.error('Failed to start the refresh timer: ', err);
      this.logout();
    }
  }

  private stopRenewAccessTokenTimer(): void {
    if (this.refreshTokenTimeout) {
      clearTimeout(this.refreshTokenTimeout);
    }
  }

  async getAuthToken(): Promise<string> {
    const authToken: string = await this.storageService.getFromLocalOrSessionStorage(STORAGE_CONSTANTS.AUTH_TOKEN);
    return authToken;
  }

  async getRefreshToken(): Promise<string> {
    const refreshToken: string = await this.storageService.getFromLocalOrSessionStorage(
      STORAGE_CONSTANTS.REFRESH_TOKEN,
    );
    return refreshToken;
  }
}
