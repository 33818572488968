import { Injectable } from '@angular/core';
import { BaseApiService } from 'app/api-services/base.api-service';

export interface GetDeviceRequestParams {
  id: string;
}

export const queryKeys = {
  all: ['devices'] as const,
  lists: () => [...queryKeys.all, 'lists'] as const,
  details: () => [...queryKeys.all, 'details'] as const,
  detail: ({ id }: GetDeviceRequestParams) => [...queryKeys.details(), id] as const,
};

@Injectable({
  providedIn: 'root',
})
export class DevicesService extends BaseApiService {
  constructor() {
    super('/api/v2/devices');
  }

  getDevice(params: GetDeviceRequestParams) {
    return this._query({
      queryKey: queryKeys.detail(params),
      queryFn: () => this.request({ path: params.id }),
    });
  }
}
