import { FavouritesService } from './favourites.service';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { AccountService } from '../accounts/account.service';
import { TranslationsService } from './translations.service';
import { ThemeService } from '../themes/theme.service';
import { ProfileService } from '../profile/profile.service';
import { AuthService } from '../auth/auth.service';
import { PermissionsService } from '@service/permissions/permissions.service';
import { PermissionArea } from '@class/commons/permissions/permission-constants';
import { combineLatestWith, first, firstValueFrom, from, tap } from 'rxjs';
import { BrowserLogger } from '@class/core/browser-logger';
import { filterSuccessResult } from '@ngneat/query';

@Injectable({
  providedIn: 'root',
})
export class LoadConfigurationService {
  constructor(
    private commonService: CommonService,
    private accountService: AccountService,
    private translationService: TranslationsService,
    private themeService: ThemeService,
    private profileService: ProfileService,
    private favouritesService: FavouritesService,
    private authService: AuthService,
    private permissionsService: PermissionsService,
  ) {}

  public async initialise(): Promise<void> {
    const refreshToken = await this.authService.getRefreshToken();
    const authToken = await this.authService.getAuthToken();

    if (!refreshToken) {
      return;
    }

    await this.authService.refreshAccessTokenIfExpired(refreshToken, authToken);

    if (!authToken) {
      return; // abort
    }

    // initialise user if have auth tokens etc
    try {
      await Promise.all([this.initialiseUser()]);
    } catch (error) {
      console.error('Failed to load configuration: ', error);
      throw error;
    }
  }

  public initialiseUser() {
    const getAvailableLanguagesPromise = this.translationService.getAvailableLanguages();
    const getLoggedInUserDetails = this.accountService.getLoggedInUserDetails();

    const result = getLoggedInUserDetails.result$.pipe(
      filterSuccessResult(),
      first(),
      combineLatestWith(from(getAvailableLanguagesPromise)),
      tap(async ([getUserDetailsResult, availableLanguages]) => {
        BrowserLogger.log('getUserDetailsResult', { getUserDetailsResult });

        // set Global permission in the common service object
        this.permissionsService.apply(PermissionArea.GLOBAL, getUserDetailsResult.data.permissions);

        // move this from login component to here as we need permissions to check
        // this is just a quick fix
        // apparently we shouldn't show the menu unless we get the user profile
        // but this will do for now
        this.commonService.loggedIn = true;

        // set the persisted profile in the profile service
        this.profileService.setPersistedUserProfile(getUserDetailsResult.data);
        this.themeService.setTheme();

        // first get the available languages and then set the language
        if (!this.translationService.transLanguages.dataExist) {
          try {
            this.translationService.transLanguages.apiCall = true;
            this.translationService.transLanguages.languages = availableLanguages.data;
            this.translationService.transLanguages.dataExist = true;
            this.translationService.transLanguages.ready.next(true);
            this.translationService.setUserLanguage(getUserDetailsResult.data.profile.language);
          } catch (err) {
            console.error('Failed to load translation data: ', err);

            this.translationService.transLanguages.apiCallFailed = true;
            this.translationService.transLanguages.error = err;

            throw err;
          }
        } else {
          this.translationService.setUserLanguage(getUserDetailsResult.data.profile.language);
        }

        if (getUserDetailsResult.data.profile.favourites) {
          this.favouritesService.setFavourites(getUserDetailsResult.data.profile.favourites);
        } else {
          this.favouritesService.setFavourites([]);
        }
      }),
    );

    return firstValueFrom(result);
  }
}
