import { Portfolio, PortfolioDTO, PortfolioUnitStatusDisplay } from './../../classes/commons/portfolio.model';
import { Unit } from './../../classes/commons/unit.model';
import { Injectable } from '@angular/core';
import { ApiWrapper } from '../common/api-wrapper.service';
import { AvailableAPI, RequestMethod, UseHeaderType } from '../../classes/commons/request-api.model';
import { HomeCardDataType, HomepageCards, UnitOperationalStates } from '@class/commons/enums';
import { BehaviorSubject } from 'rxjs';

enum AvailableKeys {
  PORTFOLIO = 'Recent Portfolios',
  UNIT = 'Recent Units',
}

export enum FavKeys {
  PORTFOLIOS = 'portfolios',
  UNITS = 'units',
}

const FAV_KEYS_TO_AVAILABLE_KEYS_MAP: Record<FavKeys, AvailableKeys> = {
  [FavKeys.PORTFOLIOS]: AvailableKeys.PORTFOLIO,
  [FavKeys.UNITS]: AvailableKeys.UNIT,
};

export type UserFavDTO = {
  id: string;
  key: AvailableKeys;
  data: FavPortfolio[] | FavUnit[] | Unit[];
};

// we don't need to store all the elements of a portfolio or a unit
// just certain data that is required for display purposes
// no need to store permissions list or unitSet
type FavUnitPortfolio = {
  readonly id: string;
  readonly uuid: string;
  name: string;
  dataType: HomeCardDataType;
  onlineStatus: number;
};
export type FavPortfolio = FavUnitPortfolio & {
  locationDescription: string;
  unitStatus: PortfolioUnitStatusDisplay[];
  unitCount: number;
};

export type FavUnit = FavUnitPortfolio & {
  address: string;
  actionPending: boolean;
  operationalState: UnitOperationalStates;
};

const adaptToFavPortfolio = (portfolio: Portfolio): FavPortfolio => {
  return {
    id: portfolio.id,
    uuid: portfolio.uuid,
    name: portfolio.name,
    locationDescription: portfolio.locationDescription,
    onlineStatus: portfolio.onlineStatus,
    unitStatus: portfolio.unitStatus,
    unitCount: portfolio.unitCount,
    dataType: portfolio.dataType,
  };
};
const adaptToFavUnit = (unit: Unit): FavUnit => {
  return {
    id: unit.id,
    uuid: unit.uuid,
    name: unit.name,
    address: unit.address,
    onlineStatus: unit.onlineStatus,
    dataType: unit.dataType,
    operationalState: unit.operationalState,
    actionPending: unit.actionPending,
  };
};

@Injectable({
  providedIn: 'root',
})
export class FavouritesService {
  private apiCalled = false;
  private favourites = { [FavKeys.PORTFOLIOS]: Array<FavPortfolio>(), [FavKeys.UNITS]: Array<FavUnit>() };
  public favourites$ = new BehaviorSubject({
    fav: null,
    apiCalled: this.apiCalled,
  });
  private ids = { [FavKeys.PORTFOLIOS]: String(), [FavKeys.UNITS]: String() };
  private cardsNumber = {
    [FavKeys.PORTFOLIOS]: HomepageCards.PORTFOLIOS_NUMBER,
    [FavKeys.UNITS]: HomepageCards.UNITS_NUMBER,
  };

  constructor(private api: ApiWrapper) {}

  clearFavourites() {
    this.apiCalled = false;
    this.ids = { [FavKeys.PORTFOLIOS]: String(), [FavKeys.UNITS]: String() };
    this.favourites = { [FavKeys.PORTFOLIOS]: [], [FavKeys.UNITS]: [] };
  }

  setFavourites(userFavourites: Array<UserFavDTO>) {
    userFavourites.forEach((fav: UserFavDTO) => {
      if (fav.key === AvailableKeys.PORTFOLIO) {
        // Portfolio DTO is just to support legacy data there
        // for example if someone has a portfolio saved in user fav as PortfolioDTO
        const portfolios: Array<FavPortfolio | Portfolio | PortfolioDTO> = fav.data as unknown as Array<
          FavPortfolio | Portfolio | PortfolioDTO
        >;

        this.favourites[FavKeys.PORTFOLIOS] = portfolios
          .map((portfolio: FavPortfolio | Portfolio | PortfolioDTO) =>
            this.isTypePortfolio(portfolio)
              ? adaptToFavPortfolio(portfolio)
              : this.isTypePortfolioDTO(portfolio)
                ? adaptToFavPortfolio(PortfolioDTO.adaptToPortfolio(portfolio))
                : portfolio,
          )
          .filter((portfolio: FavPortfolio) => portfolio.id != null);

        this.ids[FavKeys.PORTFOLIOS] = fav.id;
      } else if (fav.key === AvailableKeys.UNIT) {
        this.favourites[FavKeys.UNITS] = fav.data
          .map((unit): FavUnit => (unit instanceof Unit ? adaptToFavUnit(unit) : unit))
          .filter((unit: Unit) => unit.id != null);

        this.ids[FavKeys.UNITS] = fav.id;
      }
    });
    this.apiCalled = true;
    this.favourites$.next({
      fav: this.favourites,
      apiCalled: this.apiCalled,
    });
  }

  private checkForId(favourites: Array<FavPortfolio | FavUnit>, uuid: string): boolean {
    return favourites.filter((fav: FavPortfolio | FavUnit) => fav.uuid === uuid).length > 0;
  }

  add(addThis: Portfolio | Unit, type: FavKeys): void {
    let portfolioOrUnit: FavUnit | FavPortfolio;
    if (type === FavKeys.PORTFOLIOS && this.isTypePortfolio(addThis)) {
      portfolioOrUnit = adaptToFavPortfolio(addThis);
    } else if (type === FavKeys.UNITS && this.isTypeUnit(addThis)) {
      portfolioOrUnit = adaptToFavUnit(addThis);
    }

    if (!portfolioOrUnit) return;

    if (this.favourites[type].length > 0) {
      this.update(portfolioOrUnit, type);
    } else {
      this.create(portfolioOrUnit, type);
    }
  }

  async remove(favToBeRemoved: Portfolio | { id: string }, type: string) {
    if (!(type in this.favourites)) {
      console.log('Warning, no favourite type found when attempting to remove favourite');
      return;
    }
    this.favourites[type] = this.favourites[type].filter((fav: FavPortfolio | FavUnit) => fav.id !== favToBeRemoved.id);
    const favTypeID = this.ids[type];
    const faves = { key: FAV_KEYS_TO_AVAILABLE_KEYS_MAP[type], data: this.favourites[type] };
    await this.updateFavourites(favTypeID, faves);
    this.favourites$.next({
      fav: this.favourites,
      apiCalled: this.apiCalled,
    });
  }

  private create(addThis: FavUnit | FavPortfolio, type: FavKeys): void {
    const favourite = { key: FAV_KEYS_TO_AVAILABLE_KEYS_MAP[type], data: [addThis] };

    this.unshiftInTheList(addThis);

    this.addFavourites(favourite).then((created: { data: { id: string } }) => {
      this.ids[type] = created.data.id;
    });
    this.favourites$.next({
      fav: this.favourites,
      apiCalled: this.apiCalled,
    });
  }

  private update(addThis: FavUnit | FavPortfolio, type: FavKeys): void {
    const favourites = this.favourites[type];
    const favouriteId = this.ids[type];

    // check if data for portfolio or unit already exist in the fav
    const alreadyExist = this.checkForId(favourites, addThis.uuid);

    if (alreadyExist) {
      // update the existed data in fav and shift that to 0 index
      favourites.splice(
        favourites.findIndex((val: FavUnit | FavPortfolio) => val.uuid === addThis.uuid),
        1,
      );
      this.unshiftInTheList(addThis);
    } else {
      while (favourites.length >= this.cardsNumber[type]) {
        favourites.pop();
      }
      this.unshiftInTheList(addThis);
    }
    this.updateFavourites(favouriteId, { key: FAV_KEYS_TO_AVAILABLE_KEYS_MAP[type], data: favourites });
    this.favourites$.next({
      fav: this.favourites,
      apiCalled: this.apiCalled,
    });
  }

  private unshiftInTheList(addThis: FavUnit | FavPortfolio): void {
    this.isTypeFavPortfolio(addThis)
      ? this.favourites[FavKeys.PORTFOLIOS].unshift(addThis)
      : this.favourites[FavKeys.UNITS].unshift(addThis);
  }

  private isTypePortfolio(
    unitOrPortfolio: FavPortfolio | Portfolio | PortfolioDTO | Unit,
  ): unitOrPortfolio is Portfolio {
    return (unitOrPortfolio as Portfolio).unitSet !== undefined;
  }
  private isTypeUnit(unitOrPortfolio: Portfolio | Unit): unitOrPortfolio is Unit {
    return (unitOrPortfolio as Unit).address !== undefined;
  }
  private isTypePortfolioDTO(portfolio: FavPortfolio | Portfolio | PortfolioDTO): portfolio is PortfolioDTO {
    return (portfolio as PortfolioDTO).online_status !== undefined;
  }
  private isTypeFavPortfolio(unitOrPortfolio: FavPortfolio | FavUnit): unitOrPortfolio is FavPortfolio {
    return (unitOrPortfolio as FavPortfolio).locationDescription !== undefined;
  }

  private addFavourites(data) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v2/favourites/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }

  private updateFavourites(id: string, data) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      `/api/v2/favourites/${id}/`,
      RequestMethod.PUT,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }

  private deleteFavourite(id: string) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      `/api/v2/favourites/${id}/`,
      RequestMethod.DELETE,
      UseHeaderType.AUTHORIZED_SWDIN,
    );
  }
}
