import { Component } from '@angular/core';
import { Observable, Subject, merge, of, map, filter } from 'rxjs';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-updates-notification',
  templateUrl: './app-updates-notification.component.html',
  styleUrls: ['./app-updates-notification.component.scss'],
})
export class AppUpdatesNotificationComponent {
  updateAvailable$: Observable<boolean>;
  closed$ = new Subject<void>();

  constructor(private updates: SwUpdate) {
    this.updateAvailable$ = merge(
      of(false),
      this.updates.versionUpdates.pipe(
        // need to filter out the version event to only get the 'VERSION_READY' event
        // otherwise the update will be activated twice
        // and the banner will be showed even if fired NoNewVersionDetectedEvent i.e., NO_NEW_VERSION_DETECTED
        filter((v: VersionEvent) => v.type === 'VERSION_READY'),
        map(() => true),
      ),
      this.closed$.pipe(map(() => false)),
    );
  }
  activateUpdate() {
    if (environment.production) {
      this.updates.activateUpdate().then(() => {
        location.reload();
      });
    }
  }
}
