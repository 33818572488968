import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ToastInputSettings } from '@custom-types/core/app-toast-notifications.model';
import { IonicModule, ToastButton } from '@ionic/angular';
import { LetDirective } from '@ngrx/component';
import { BehaviorSubject } from 'rxjs';

const BUTTONS = [
  {
    icon: 'close',
    side: 'end',
    role: 'cancel',
    htmlAttributes: {
      'aria-label': 'close',
    },
  },
];

const SUCCESS_TOAST = {
  buttons: BUTTONS,
  duration: 5000, // milliseconds
  color: 'success-lite',
};
const WARNING_TOAST = {
  buttons: BUTTONS,
  duration: 10000, // milliseconds
  color: 'warning-lite',
};
const ERROR_TOAST = {
  buttons: BUTTONS,
  duration: 15000, // milliseconds
  color: 'danger-lite',
};

interface ToastSettings {
  duration: number;
  color: 'success-lite' | 'warning-lite' | 'danger-lite';
  header: string;
  message: string;
  buttons: ToastButton[];
  isToastOpen: boolean;
}

@Component({
  selector: 'app-toast-notifications',
  templateUrl: './app-toast-notifications.component.html',
  standalone: true,
  imports: [IonicModule, LetDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppToastNotificationsComponent {
  @Input()
  set toastSettings(value: ToastInputSettings) {
    if (!value) return;
    this.dismissExistingToastToShowNewNotification();

    const { toastType, header, message } = value;
    let toastProps;
    switch (toastType) {
      case 'success':
        toastProps = { ...SUCCESS_TOAST, header, message };
        break;
      case 'warning':
        toastProps = { ...WARNING_TOAST, header, message };
        break;
      case 'error':
        toastProps = { ...ERROR_TOAST, header, message };
        break;
    }
    this.#toastSettings.next({ ...toastProps, isToastOpen: true });
  }

  #toastSettings = new BehaviorSubject<ToastSettings>({ isToastOpen: false } as ToastSettings);
  toastSettings$ = this.#toastSettings.asObservable();

  handleToastDismiss() {
    this.#toastSettings.next({ ...this.#toastSettings.value, isToastOpen: false });
  }

  private dismissExistingToastToShowNewNotification() {
    this.handleToastDismiss();
  }
}
