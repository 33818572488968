import { Observable } from 'rxjs';
import { VppControlGroupUnitDevices } from './vpp-types';

export interface VppUnitGetPayload {
  [VppUnitDeviceTypes.PV_INV]: number;
  [VppUnitDeviceTypes.BESS]: number;
  [VppUnitDeviceTypes.HW]: number;
  [VppUnitDeviceTypes.AC]: number;
  [VppUnitDeviceTypes.HYBRID]: number;
  control_group_full_name?: string;
  control_groups: Record<string, VppControlGroupUnitDevices[]>;
  devices: VppControlGroupUnitDevices[];
  id: string;
  name: string;
  uuid: string;
  external_identifier: string;
  nmi: string;
}

export interface VppUnit {
  controlGroupNames: string[];
  unitVppMetrics$: Observable<VppUnitMetricsMqttMsg>;
  devices: VppControlGroupUnitDevices[];
  deviceCounts: Record<VppUnitDeviceTypes, number>;
  id: string;
  name: string;
  uuid: string;
  external_identifier: string;
  nmi: string;
}

export interface VppSettingUnitsDisplayRow {
  unitName: string;
  deviceCount: Record<VppUnitDeviceTypes, number>;
  power: VppDemandTypeAndSettingUnitPower;
}

export enum VppUnitDeviceTypes {
  PV_INV = 'PV',
  BESS = 'BATT',
  HYBRID = 'HYB',
  AC = 'AC',
  HW = 'HW',
}

export const VPP_CONTROL_GROUP_UNIT_TABLE_DEVICE_TYPE_INFO = {
  [VppUnitDeviceTypes.PV_INV]: { iconName: 'sunny', iconHoverText: 'VirtualPowerPlant.PV-Inv' },
  [VppUnitDeviceTypes.AC]: { iconName: 'snow', iconHoverText: 'VirtualPowerPlant.AC' },
  [VppUnitDeviceTypes.BESS]: { iconName: 'battery-charging', iconHoverText: 'VirtualPowerPlant.BESS' },
  [VppUnitDeviceTypes.HW]: { iconName: 'water', iconHoverText: 'VirtualPowerPlant.Hotwater' },
  [VppUnitDeviceTypes.HYBRID]: { iconName: 'contrast-outline', iconHoverText: 'VirtualPowerPlant.Hybrid' },
};

export interface VppDemandTypeAndSettingUnitPower {
  raise: VppSettingUnitsDisplayPowerColumns;
  lower: VppSettingUnitsDisplayPowerColumns;
}

export interface VppSettingUnitsDisplayPowerColumns {
  available$: Observable<number>;
  dispatched$: Observable<number>;
}

export interface VppUnitMetricsMqttMsg {
  measurements: VppUnitMetricsMqttMsgMeasurement;
  timestamp: number;
}

export type VppUnitMetricsMqttMsgMeasurement = Record<string, number>;

export enum VppUnitMetricKeyType {
  AVAILABLE = 'Avail',
  DISPATCH = 'Disp',
}
