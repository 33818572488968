<ng-container
  *ngrxLet="{
    dropletAvailablePortsToDiscoverDevice: dropletAvailablePortsToDiscoverDevice$,
    ports: ports$
  } as vm"
>
  <!--Droplet image -->
  <ion-row>
    <ion-col class="ion-text-center">
      <img
        class="width-100-pct"
        [src]="
          vm.dropletAvailablePortsToDiscoverDevice.hardwareVersion
            | dropletImageSrc: vm.dropletAvailablePortsToDiscoverDevice.dropletUuid
        "
      />
    </ion-col>
  </ion-row>

  <!-- title -->
  <ion-row>
    <ion-col>
      <ion-text class="ion-text-wrap ion-text-center">
        <p class="text-primary-xl ion-padding-horizontal" translate>AddDevice.SelectDevicePort</p>
      </ion-text>
    </ion-col>
  </ion-row>

  <!-- Buttons -->
  <ion-row>
    <ion-col size="12">
      <ion-card class="ion-no-margin new-design-card">
        <ion-card-header>
          <ion-card-title class="card-title" translate>AddDevice.Ports</ion-card-title>
        </ion-card-header>
        <ion-card-content class="ion-no-padding ion-padding-bottom">
          <ion-item *ngFor="let port of vm.ports" detail="true" button (click)="onPortHandler(port)">
            <ion-label class="ion-text-wrap">
              <p class="text-primary-lg">
                {{
                  port.type
                    | dropletPortLabel: port.port_number : vm.dropletAvailablePortsToDiscoverDevice.hardwareVersion
                }}
              </p>
              <p
                class="text-secondary-md"
                *ngIf="port.adapterType"
                translate
                [translateParams]="{ portAdapterType: upperFirst(port.adapterType) }"
              >
                AddDevice.PortAdapterTypePluggedIn
              </p>
            </ion-label>
          </ion-item>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</ng-container>
