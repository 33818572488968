import { Directive, EventEmitter, Input, Output } from '@angular/core';

export enum CustomButtonSize {
  SMALL,
  LARGE,
}

@Directive()
export class CustomButtons {
  @Input() isDisabled = false;
  @Input() size: CustomButtonSize = CustomButtonSize.SMALL; // button size, currently only used by remove button, but adding support to all
  @Input() label: string; // ability to override default label, currently only used by remove button, but adding support to all
  @Output() onClick: EventEmitter<void> = new EventEmitter();

  readonly CustomButtonSize = CustomButtonSize;

  public handleClick(): void {
    if (!this.isDisabled) {
      this.onClick.emit();
    }
  }
}
