import { BrowserLogger } from '@class/core/browser-logger';
import { TranslationsService } from '@service/common/translations.service';
import { DropletDeviceControl } from './droplet-device-control.model';

export class DropletControllerControlKey {
  amountKey: string;
  apiAvailable: boolean;
  apiKey: string;
  controlType: string;
  controllerTypeId: string;
  description: string;
  displayConfig: string;
  id: string;
  name: string;
  setKey: string;
  statusKey: string;

  // Need to map from an 'DropletControllerControlKey'
  // in order to update Endpoint Controller Controls using the
  // DeviceControlService
  private static adaptToDropletDeviceControl(
    controllerControlKey: DropletControllerControlKey,
    translationsService: TranslationsService,
  ): DropletDeviceControl {
    const displayConfigJson = JSON.parse(controllerControlKey.displayConfig);
    const controlType = DropletDeviceControl.getControlTypeFromDisplayConfig(displayConfigJson);
    const placeholder = DropletDeviceControl.getControlPlaceholderFromDisplayConfig(
      displayConfigJson,
      controlType,
      translationsService,
    );
    const result = new DropletDeviceControl();
    result.description = controllerControlKey.description;
    result.displayConfig = controllerControlKey.displayConfig;
    result.displayConfigJson = displayConfigJson;
    result.setKey = controllerControlKey.setKey;
    result.statusKey = controllerControlKey.statusKey;
    result.statusKeyWithDeviceNumber = controllerControlKey.statusKey; // same as statusKey for controllers
    result.id = controllerControlKey.id;
    result.isIec61850 = false; // false for controllers
    result.name = controllerControlKey.name;
    result.type = controlType;
    result.isSetting = undefined;
    result.backendValue = undefined;
    result.placeholder = placeholder;
    BrowserLogger.log('DropletControllerControlKey.adaptToDropletDeviceControl', { result, controllerControlKey });
    return result;
  }

  public static adaptToDropletDeviceControls(
    controlKeys: DropletControllerControlKey[],
    translationsService: TranslationsService,
  ): DropletDeviceControl[] {
    const result = controlKeys?.length
      ? controlKeys.map((controlKey) =>
          DropletControllerControlKey.adaptToDropletDeviceControl(controlKey, translationsService),
        )
      : [];
    BrowserLogger.log('DropletDeviceControl.adaptToDropletDeviceControls', { result, controlKeys });
    return result;
  }
}
