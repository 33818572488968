// ------------------------------------------------------------------------------------------------------------
// [ifHasPermission] Structural Directive
// - Render if 'true' or ANY of the given permissions are enabled for the current user
// ------------------------------------------------------------------------------------------------------------
import { Directive, Input, OnInit, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { userHasPermission } from '@app-state/permissions/permissions.selectors';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { Store } from '@ngrx/store';
import { isBoolean } from 'lodash';

export enum PermissionMatch {
  Any = 1,
  All = 2,
}

@Directive({
  selector: '[ifHasPermission]',
  standalone: true,
})
export class IfHasPermissionDirective implements OnInit {
  #store = inject(Store);

  private _permissions: Array<PermissionKey> | boolean;
  @Input() set ifHasPermission(value: Array<PermissionKey> | boolean) {
    this._permissions = value;
    // debug log
    // console.log('IfHasPermissionDirective.set', { permissions: this._permissions });
  }
  private _match: PermissionMatch = PermissionMatch.Any;
  @Input() set ifHasPermissionMatch(value: PermissionMatch) {
    this._match = value;
    // debug log
    // console.log('IfHasPermissionDirective.Match', { value });
  }
  private _disableOnFail: boolean = false;
  @Input() set ifHasPermissionDisableOnFail(value: boolean) {
    this._disableOnFail = value;
    // debug log
    // console.log('IfHasPermissionDirective.DisableOnFail', { value });
  }

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    /**
     * executing the directive on ngOnInit, if we execute it on AfterViewInit
     * the ng hooks will in the main component where we are using this directive
     * will throw errors that view has changed
     * and that is true
     * e.g., parent-component & child-component and
     * <parent-component> <child-component *ifHasPermission=[somePermission]></child-component> </parent-component>
     * if we have permission, it'll render the component otherwise it'll hide it
     * onAfterViewInit - it'll create the component when the parent component view has been init
     * but the parent component will run ngAfterViewChecked and will see a new component there that wasn't there before
     * and will throw error
     * so has to execute it onInit
     */
    this.execute(this._permissions, this._match, this._disableOnFail);
  }

  private execute(permissions: Array<PermissionKey> | boolean, match: PermissionMatch, onFail: boolean): void {
    // looks like this function is committing crime of hidden state but not sure we can get over this
    // we need to update the view container...

    // if we were given a boolean (true/false) use that
    // otherwise use the permissions service to check current user permissions

    // debug log
    // console.log('IfHasPermissionDirective.execute', { this: this });

    if (isBoolean(permissions)) {
      this.renderTemplateAccordingToPerms(permissions, onFail);
    } else {
      this.#store
        .select(userHasPermission(permissions, !(match === PermissionMatch.Any)))
        .subscribe((hasPermission) => {
          this.renderTemplateAccordingToPerms(hasPermission, onFail);
        });
    }
  }

  private renderTemplateAccordingToPerms(hasPermission: boolean, onFail: boolean) {
    if (hasPermission) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      // default (not disable) - do not render the element
      if (!onFail) {
        this.viewContainer.clear();
      }
      // disable - still render but with the disabled attribute set
      else {
        // render the view
        const viewContainer = this.viewContainer.createEmbeddedView(this.templateRef);
        // disable the root element
        const rootElem = viewContainer.rootNodes[0];
        if (rootElem) {
          // console.log('IfHasPermissionDirective.execute.disable', { rootElem });
          rootElem.disabled = true;
        }
      }
    }
  }
}
