<ng-container *ngrxLet="{ configuring: configuring$ } as vm">
  <ion-row>
    <ion-col>
      <ion-card class="ion-no-margin new-design-card">
        <ion-card-header>
          <ion-card-title class="card-title">{{ device.info.manufacturer }}</ion-card-title>
        </ion-card-header>
        <ion-card-content class="ion-no-padding">
          <!-- Device Detail -->
          <ion-item lines="none">
            <ion-label class="ion-text-wrap">
              <p class="text-secondary-md">{{ 'UnitPage.Model' | translate }}</p>
              <p class="text-primary-lg">{{ device.info.model_name }}</p>
            </ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-label class="ion-text-wrap">
              <p class="text-secondary-md">{{ 'UnitPage.Serial' | translate }}</p>
              <p class="text-primary-lg">{{ device.info.serial_number }}</p>
            </ion-label>
          </ion-item>

          <!-- <ion-item lines="full">
            <ion-text class="text-primary-xl">Device settings</ion-text>
          </ion-item> -->

          <!-- Device Type Selection -->
          <div *ngIf="!isEmpty(device.deviceTypes)" class="ion-padding-top">
            <ion-item lines="none">
              <ion-label class="ion-text-wrap text-primary-lg" translate>AddDevice.SelectDeviceType</ion-label>
            </ion-item>
            <ion-radio-group
              [value]="device.deviceTypes.selectedValue"
              (ionChange)="handleDeviceTypeSelection($event?.detail?.value, device)"
            >
              <ng-container *ngFor="let option of device.deviceTypes.options">
                <ion-item>
                  <ion-radio
                    class="text-primary"
                    color="button-secondary"
                    [value]="option.value"
                    justify="start"
                    labelPlacement="end"
                    mode="md"
                  >
                    {{ option.name }}
                  </ion-radio>
                </ion-item>
              </ng-container>
            </ion-radio-group>
          </div>

          <!-- Device Attributes -->
          <ng-container *ngIf="!isEmpty(device.deviceConfigAttributes)">
            <div *ngFor="let attribute of device.deviceConfigAttributes | keyvalue" class="ion-padding-top">
              <ion-item lines="none">
                <ion-label class="ion-text-wrap text-primary-lg">
                  {{ attribute.value.description }}
                </ion-label>
              </ion-item>
              <!-- Device Config Attribute Custom User Input -->
              @if (attribute.value.takeUserCustomInput) {
                <ion-row>
                  <ion-col class="ion-padding-horizontal">
                    @switch (attribute.value.customInputType) {
                      @case ('number') {
                        <ion-input
                          class="simple-user-input"
                          [autofocus]="true"
                          [clearInput]="true"
                          fill="outline"
                          mode="md"
                          [value]="attribute.value.selectedValue"
                          (ionInput)="handleAttributeValueUpdate($event?.detail?.value, attribute.key, device)"
                          inputmode="numeric"
                          type="number"
                        ></ion-input>
                      }
                      @default {
                        <ion-input
                          class="simple-user-input"
                          [autofocus]="true"
                          [clearInput]="true"
                          fill="outline"
                          mode="md"
                          [value]="attribute.value.selectedValue"
                          (ionInput)="handleAttributeValueUpdate($event?.detail?.value, attribute.key, device)"
                        ></ion-input>
                      }
                    }
                  </ion-col>
                </ion-row>
              } @else {
                <!-- Device Config Attribute from Options List -->
                <ion-radio-group
                  [value]="attribute.value.selectedValue"
                  (ionChange)="handleAttributeValueUpdate($event?.detail?.value, attribute.key, device)"
                >
                  <ng-container *ngFor="let option of attribute.value.options">
                    <ion-item>
                      <ion-radio
                        class="text-primary"
                        color="button-secondary"
                        [value]="option.value"
                        justify="start"
                        labelPlacement="end"
                        mode="md"
                      >
                        {{ option.name }}
                      </ion-radio>
                    </ion-item>
                  </ng-container>
                </ion-radio-group>
              }
            </div>
          </ng-container>

          <!-- configure button -->
          <div class="ion-padding">
            <app-button
              label="{{ (vm.configuring ? 'AddDevice.ConfiguringDevice' : 'General.Confirm') | translate }}"
              (onClick)="handleConfigureClick(device)"
              [isDisabled]="vm.configuring || isConfirmButtonDisable(device)"
            ></app-button>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</ng-container>
