import { Injectable } from '@angular/core';
import { BaseApiService } from '../base.api-service';
import { UserOrgMembership } from '@custom-types/user-accounts/user-org-membership.model';

const queryKeys = {
  all: ['accounts-user-org-membership'] as const,
};

@Injectable({
  providedIn: 'root',
})
export class UserOrgMembershipsApiService extends BaseApiService {
  constructor() {
    super('/api/v1/user-organisation-memberships/');
  }

  getUserOrganisationMemberships() {
    return this._query<UserOrgMembership[]>({
      queryKey: queryKeys.all,
      queryFn: () =>
        this.requestObs({
          path: '',
        }),
    });
  }
}
