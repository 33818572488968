<ion-row *ngrxLet="{ legacyDevicesData: legacyDevicesData$, selectedLegacyDevice: selectedLegacyDevice$ } as vm">
  <ion-col>
    <ion-card class="ion-no-margin new-design-card">
      <ion-card-header>
        <ion-card-title class="card-title" translate>AddDevice.LegacyDevices.Heading</ion-card-title>
      </ion-card-header>
      <ion-card-content class="ion-no-padding ion-margin-bottom">
        <ion-row>
          <ion-col class="bb-1 ion-padding">
            <ion-searchbar
              class="ion-no-padding custom"
              showCancelButton="never"
              [value]="vm.legacyDevicesData.legacyDeviceFilterText"
              [debounce]="100"
              placeholder="{{ 'General.Search' | translate }}"
              autocomplete="on"
              (ionInput)="onSearchInputHandler($event)"
              mode="md"
            ></ion-searchbar>
          </ion-col>
        </ion-row>
        <ion-radio-group
          (ionChange)="onLegacyDeviceSelectionHandler($event)"
          [value]="vm.selectedLegacyDevice?.device_type_id"
        >
          <ion-item *ngFor="let device of vm.legacyDevicesData.legacyDevices">
            <ion-radio
              [value]="device.id"
              justify="space-between"
              color="button-primary"
              class="text-primary-lg ion-text-wrap"
              >{{ device.name }}</ion-radio
            >
          </ion-item>
        </ion-radio-group>
        <ion-item lines="none" *ngIf="vm.legacyDevicesData.legacyDevices.length <= 0">
          <ion-label class="text-primary-lg ion-text-wrap" translate>AddDevice.LegacyDevices.NoSearchResults</ion-label>
        </ion-item>
      </ion-card-content>
    </ion-card>
  </ion-col>
</ion-row>
