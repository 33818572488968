import { IMqttMessage } from 'ngx-mqtt';
import { CommonService } from './../common/common.service';
import { ApiWrapper } from './../common/api-wrapper.service';
import { AvailableAPI, RequestMethod, UseHeaderType } from '../../classes/commons/request-api.model';
import { Injectable } from '@angular/core';
import { apiStatus } from '../../classes/commons/common';
import { ToastController, AlertController } from '@ionic/angular';
import { TranslationsService } from '../common/translations.service';
import { BehaviorSubject } from 'rxjs';
import { InviteRoleInterface } from '../../classes/commons/invite-role-interface';
import { Vpp, VppServerType } from '../../classes/vpp/vpp-types';
import { VppDemandResponse } from '../../classes/vpp/vpp-demand-response.model';
import { VppMetricMqtt, VppStateMqtt } from '../../classes/vpp/vpp-mqtt.model';
import { VppDemandTypes } from '../../classes/vpp/vpp-demand-type-mode.model';
import { VppMarketGetPayload } from '../../classes/vpp/vpp-markets.model';
import { VppPatchPayload, VppPostPutPayload } from '../../classes/vpp/vpp.model';
import { isEmpty } from 'lodash';
import { DeviceMqttKey } from '../../classes/units/droplet/droplet-metric.model';
import { HttpErrorResponse } from '@angular/common/http';
import { VppDispatchType } from '@class/vpp/vpp-dispatch.model';
import { PermissionsService } from '@service/permissions/permissions.service';
import { PermissionArea, PermissionKey } from '@class/commons/permissions/permission-constants';
import { NgxMqttWrapperService } from '@service/core/ngx-mqtt-wrapper.service';

export interface VppState {
  time_window: number;
  name: string; // for vpp
  uuid: string; // for vpp
  units: []; // all units for all control groups under vpp
  groups: []; // all control groups in vpp
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  device_classes: any[]; // device classes frpm device type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transformer_data: any[]; // transformer metada from postgres (not tested - #TODO)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  group_merit_data: any[]; // group + merit order combination metada from postgres
}

export enum VirtualPowerPlantTypes {
  INTERNAL = 'Internal',
  SCADA = 'Scada',
}

@Injectable({
  providedIn: 'root',
})
export class VirtualPowerPlantsService {
  // Define Properties
  vppList = {
    vpps: [], // all the portfolios list
    apiCall: false, // if the api call has been made or not
    dataExist: false, // data is null or undefined
    apiCallFailed: false, // if by any chance the api call didn't go through, internet is down or server error
    error: {}, // if any error occured,
  };
  vppListPage = {
    init: false,
    mqttServiceStarted: false,
  };
  vppsMetricsSubscription = []; // vpp uuid subscription
  // TODO: apicall, dataexist need to swap with apiStatus
  unitMetricsList = {
    metrics: [], // all the portfolios list
    selectedUnitEndpointData: [],
    apiCall: false, // if the api call has been made or not
    dataExist: false, // data is null or undefined
    apiCallFailed: false, // if by any chance the api call didn't go through, internet is down or server error
    error: {}, // if any error occured,
  };
  demandReduction = {
    meritOrders: [],
    origCopy: [],
    tempList: [],
    apiCall: false,
    dataExist: false,
    apiCallFailed: false,
    error: {},
  };
  demandIncrease = {
    meritOrders: [],
    origCopy: [],
    tempList: [],
    apiCall: false,
    dataExist: false,
    apiCallFailed: false,
    error: {},
  };
  drMeritsChanged = false;
  diMeritsChanged = false;
  policiesList = {
    policies: [],
    status: new apiStatus(),
  };
  selectedVpp: Vpp = {} as Vpp;
  vppState: VppState = {
    name: null, // for vpp
    uuid: null, // for vpp
    units: [], // all units for all control groups under vpp
    groups: [], // all control groups in vpp
    device_classes: [], // device classes frpm device type
    transformer_data: [], // transformer metada from postgres (not tested - #TODO)
    group_merit_data: [], // group + merit order combination metada from postgres
    time_window: null,
  };
  vppStateSubs;
  vppSchedules = {
    schedules: [],
    status: new apiStatus(),
  };

  public vppDeviceTypesList = {
    devicesList: [],
    status: new apiStatus(),
  };
  allSchedulesList = {
    schedules: [],
    status: new apiStatus(),
  };
  operationHistory = {
    history: {},
    status: new apiStatus(),
  };
  serviceInitialized = false;
  vppSelectError: string;

  vppInvitesRoles = {
    roles: Array<InviteRoleInterface>(),
    status: new apiStatus(),
  };

  private vppSelectedSubject$ = new BehaviorSubject<Vpp>(null);
  public vppSelected$ = this.vppSelectedSubject$.asObservable();

  private vppStateMqttSubject$ = new BehaviorSubject<VppStateMqtt>(null);
  public vppStateMqtt$ = this.vppStateMqttSubject$.asObservable();

  constructor(
    private api: ApiWrapper,
    private commonService: CommonService,
    private permissionsService: PermissionsService,
    private _ngxMqttWrapper: NgxMqttWrapperService,
    private toast: ToastController,
    private alertController: AlertController,
    private trans: TranslationsService,
  ) {}

  init(): void {
    // initialize function being called from app.comp.ts
    // in intializeApp function, to call the api so as soon as it recieves the data, it'll render the view
    this.VppsList();
    this.serviceInitialized = true;
  }

  public async vppSelected(vppId: string): Promise<void> {
    this.vppSelectError = null; // HACK. will fix next PR
    try {
      await this.getSeletectedVppData(vppId);
    } catch (err) {
      console.error(err);
      this.vppSelectError = err instanceof HttpErrorResponse ? `${err.name} ${err.status}: ${err.statusText}` : '';
      return;
    }
    this.setMetricDataOfSelectedVpp();
    this.startVppState();
    this.getVppSchedules();
    this.getallSchedules();
    this.getDemandIncreaseMerits();
    this.getDemandReductionMerits();
    this.getVppPolicies();
    this.getVppDeviceTypes();
  }

  resetService() {
    this.resetSelectedVpp();
    this.resetVppList();
    this.resetVppStateAndVppStateSubs();
    this.resetVppListSubs();
    this.resetMeritORders();
    this.resetAllVppList();
  }

  resetAllVppList() {
    this.vppList = {
      vpps: [],
      apiCall: false,
      dataExist: false,
      apiCallFailed: false,
      error: {},
    };
  }

  resetSelectedVpp() {
    this.resetVppStateAndVppStateSubs();
    this.selectedVpp = {} as Vpp;
    this.vppDeviceTypesList.devicesList = [];
    this.vppDeviceTypesList.status.reset();
    this.permissionsService.clear(PermissionArea.VPP);
    this.vppInvitesRoles = {
      roles: Array<InviteRoleInterface>(),
      status: new apiStatus(),
    };
    this.vppSelectedSubject$.next(null);
  }

  resetVppList() {
    this.resetVppListSubs();
    this.vppListPage.init = false;
  }

  resetVppStateAndVppStateSubs() {
    this._ngxMqttWrapper.unsubscribe(this.vppStateSubs);
    this.vppState.time_window = null;
    this.vppState.name = null;
    this.vppState.uuid = null;
    this.vppState.units = [];
    this.vppState.groups = [];
    this.vppState.device_classes = [];
    this.vppState.transformer_data = [];
    this.vppState.group_merit_data = [];
  }

  resetVppListSubs() {
    if (this.vppsMetricsSubscription.length > 0) {
      this.vppsMetricsSubscription.forEach((subs) => {
        this._ngxMqttWrapper.unsubscribe(subs.obj);
      });
    }
    this.vppsMetricsSubscription = [];
    this.vppListPage.mqttServiceStarted = false;
  }

  resetMeritORders() {
    this.demandReduction = {
      meritOrders: [],
      origCopy: [],
      tempList: [],
      apiCall: false,
      dataExist: false,
      apiCallFailed: false,
      error: {},
    };
    this.demandIncrease = {
      meritOrders: [],
      origCopy: [],
      tempList: [],
      apiCall: false,
      dataExist: false,
      apiCallFailed: false,
      error: {},
    };
    this.drMeritsChanged = false;
    this.diMeritsChanged = false;
  }

  resetAndGetControlGroupUnitsPoliciesAndMerits() {
    this.getVppPolicies();
    this.getVppDeviceTypes();
    this.getDemandIncreaseMerits();
    this.getDemandReductionMerits();
  }

  public VppsList(loader = null) {
    this.getVPPSList().then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: any) => {
        if (loader) {
          loader.dismiss();
        }
        this.vppList.vpps = response.data;

        this.vppList.apiCall = true;
        if (this.vppListPage.init && !this.vppListPage.mqttServiceStarted) {
          // vpp list page is initialized and the mqtt service is not yet started to read the values of reduction & increase of the vpp,
          // so have to start that
          this.vppListPage.mqttServiceStarted = true;
          this.VppsStartMqttService(this.vppList.vpps);
        }
        if (this.vppList.vpps.length > 0) {
          this.vppList.dataExist = true;
        }
      },
      () => {
        this.vppList.apiCall = true;
        this.vppList.apiCallFailed = true;
      },
    );
  }
  VppsStartMqttService(vpps, startVppState = false) {
    if (this.vppsMetricsSubscription.length > 0) {
      this.vppsMetricsSubscription.forEach((subs) => {
        this._ngxMqttWrapper.unsubscribe(subs.obj);
      });
    }
    vpps.forEach((vpp, index) => {
      vpp['activity'] = false;
      this.vppsMetricsSubscription[index] = {
        obj: this._ngxMqttWrapper.observe(vpp.uuid + '/vpp_metrics').subscribe((message: IMqttMessage) => {
          if (message.payload) {
            const msg: VppMetricMqtt = JSON.parse(message.payload.toString());
            let measurements;
            if (msg.measurements) {
              measurements = msg.measurements;
            }
            const timestamp = msg.timestamp;
            // pass the message to a message handler
            this.handleVppMetricsMqttMsg(measurements, timestamp, vpp);
          }
        }),
      };
    });
    if (startVppState) {
      this.startVppState();
    }
  }
  handleVppMetricsMqttMsg(measurements, timestamp: number, vpp) {
    vpp.activity = !vpp.activity;
    if (measurements.hasOwnProperty(DeviceMqttKey.DEMAND_REDUCTION_TOTAL)) {
      vpp.demandReduction = measurements[DeviceMqttKey.DEMAND_REDUCTION_TOTAL].toFixed(2);
    }
    if (measurements.hasOwnProperty(DeviceMqttKey.DEMAND_INCREASE_TOTAL)) {
      vpp.demandIncrease = measurements[DeviceMqttKey.DEMAND_INCREASE_TOTAL].toFixed(2);
    }
    if (vpp.id === this.selectedVpp.id) {
      // getting values for the selected vpp only to view DI & DR chart on operation tab
      // console.log('measurements: ', measurements);
      // console.log('timestamp: ', timestamp);
      // var timestamp = timestamp;
      let newTime = new Date(timestamp * 1000);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let hours: any = newTime.getHours();
      if (hours < 10) {
        hours = '0' + hours;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let min: any = newTime.getMinutes();
      if (min < 10) {
        min = '0' + min;
      }
      let timeNow = hours + ':' + min + ':' + newTime.getSeconds();
      const disAvail = {
        DiDispatch: 0,
        DiAvail: 0,
        Demand: 0,
        DrDispatch: 0,
        DrAvail: 0,
        timeNow: '',
        demandTimeNow: '',
      };
      disAvail.timeNow = timeNow;
      newTime = new Date(timestamp * 1000);
      hours = newTime.getHours();
      if (hours < 10) {
        hours = '0' + hours;
      }
      min = newTime.getMinutes();
      if (min < 10) {
        min = '0' + min;
      }
      timeNow = hours + ':' + min + ':' + newTime.getSeconds();
      disAvail.demandTimeNow = timeNow;
      if (measurements['SWDINPV.DT[DI].TotW[Avail]']) {
        disAvail.DiAvail = measurements['SWDINPV.DT[DI].TotW[Avail]'].toFixed(2);
        // console.log(payload);
      }
      if (measurements['SWDINPV.DT[DI].TotW[Disp]']) {
        disAvail.DiDispatch = measurements['SWDINPV.DT[DI].TotW[Disp]'].toFixed(2);
      }
      if (measurements['SWDINPV.DT[DR].TotW[Avail]']) {
        disAvail.DrAvail = measurements['SWDINPV.DT[DR].TotW[Avail]'].toFixed(2);
      }
      if (measurements['SWDINPV.DT[DR].TotW[Disp]']) {
        disAvail.DrDispatch = measurements['SWDINPV.DT[DR].TotW[Disp]'].toFixed(2);
      }
      this.selectedVpp.vppMetricData.DiDispatch = disAvail.DiDispatch;
      this.selectedVpp.vppMetricData.DiAvail = disAvail.DiAvail;
      this.selectedVpp.vppMetricData.DrDispatch = disAvail.DrDispatch;
      this.selectedVpp.vppMetricData.DrAvail = disAvail.DrAvail;
      this.selectedVpp.vppMetricData.timeNow = disAvail.timeNow;
      this.selectedVpp.vppMetricData.demandTimeNow = disAvail.demandTimeNow;
    }
  }
  async startVppState(): Promise<void> {
    const topic = this.selectedVpp.uuid + '/state';
    this._ngxMqttWrapper.unsubscribe(this.vppStateSubs);
    this.vppStateSubs = this._ngxMqttWrapper.observe(topic).subscribe((message: IMqttMessage) => {
      const msg: VppStateMqtt = JSON.parse(message.payload.toString());
      this.vppStateMqttSubject$.next(msg);
    });
    // now inform the server to start the vpp state feed
    try {
      await this.informServerToStartVppStateFeed(this.selectedVpp.id);
    } catch (error) {
      let msg = this.trans.str.vpp.InviteUser.UserFail.CreateMsg;
      if (error.hasOwnProperty('status')) {
        msg = msg + ' ' + error.status + ' ' + error.statusText + '. ';
      }
      this.presentAlertSimpleOk(this.trans.str.vpp.Info, this.trans.str.vpp.FailedToInformTheServer, msg);
    }
  }

  createDispatchSequenceForDispatchType(demandResponses: VppDemandResponse[]): void {
    demandResponses.forEach((demandResponse) => {
      const auto_dptch_seq = [];
      const manual_dptch_seq = [];
      const unscheduled_dptch_seq = [];
      const avail_to_display = [];
      const unassociated_dptch_seq = [];
      demandResponse.dptch_seq.forEach((dispatch, index) => {
        const dispatchType =
          demandResponse.dtype_seq && demandResponse.dtype_seq.length > 0 ? demandResponse.dtype_seq[index] : '';
        switch (dispatchType) {
          case VppDispatchType.AUTO:
            auto_dptch_seq.push(dispatch);
            manual_dptch_seq.push(0);
            unscheduled_dptch_seq.push(0);
            unassociated_dptch_seq.push(0);
            break;
          case VppDispatchType.MANUAL:
            auto_dptch_seq.push(0);
            manual_dptch_seq.push(dispatch);
            unscheduled_dptch_seq.push(0);
            unassociated_dptch_seq.push(0);
            break;
          case VppDispatchType.UNSCHEDULED:
            auto_dptch_seq.push(0);
            manual_dptch_seq.push(0);
            unscheduled_dptch_seq.push(dispatch);
            unassociated_dptch_seq.push(0);
            break;
          default:
            // unassociated_dptch_seq.push(dispatch);
            unassociated_dptch_seq.push(dispatch);
            auto_dptch_seq.push(0);
            manual_dptch_seq.push(0);
            unscheduled_dptch_seq.push(0);
            break;
        }
      });
      demandResponse.avail_seq.forEach((avail) => {
        const constraint = 0; // demandResponse.constr_seq[index];
        if (constraint > 0) {
          avail_to_display.push(avail);
          // avail_to_display.push(0);
        } else {
          avail_to_display.push(avail);
        }
      });
      demandResponse.auto_dptch_seq = auto_dptch_seq;
      demandResponse.manual_dptch_seq = manual_dptch_seq;
      demandResponse.unscheduled_dptch_seq = unscheduled_dptch_seq;
      demandResponse.avail_to_display = avail_to_display;
      demandResponse.unassociated_dptch_seq = unassociated_dptch_seq;
    });
  }

  // FUNCTIONS FOR ADD or EDIT VPP => 1
  public async unitMetrics(
    unitId: string,
    loader = null,
  ): Promise<{
    apiCallFailed: boolean;
    metrics: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dashboard_config: any;
      deviceId: string;
      deviceMetricIdCombination: string;
      display_decimals: number;
      display_order: number;
      id: string;
      is_iec61850: boolean;
      key: string;
      name: string;
      units_abbr: string;
      units_desc: string;
    }[];
  }> {
    this.resetProperties(this.unitMetricsList, 'metrics');
    this.resetProperties(this.unitMetricsList, 'selectedUnitEndpointData');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = (await this.getUnitMetrics(unitId)) as any;
    try {
      if (loader) {
        loader.dismiss();
      }
      this.unitMetricsList.apiCall = true;
      if (!isEmpty(response?.data)) {
        this.unitMetricsList.dataExist = true;
        this.formatMetrics(response.data);
        this.unitMetricsList.selectedUnitEndpointData = response.data;
      }
    } catch (error) {
      if (loader) {
        loader.dismiss();
      }
      this.unitMetricsList.apiCall = true;
      this.unitMetricsList.apiCallFailed = true;
    } finally {
      return this.unitMetricsList;
    }
  }
  formatMetrics(data) {
    data.forEach((endpoint) => {
      endpoint.devices.forEach((device) => {
        device.metrics.forEach((metric) => {
          metric.deviceMetricIdCombination = device.id + '_' + metric.id;
          metric.deviceId = device.id;
          this.unitMetricsList.metrics.push(metric);
        });
      });
    });
  }
  // END => 1

  resetProperties(obj, prop) {
    // prop means the property, like is it a metrics list or vpp list etc.
    obj.apiCall = false;
    obj.dataExist = false;
    obj.apiCallFailed = false;
    obj.error = {};
    obj[prop] = [];
  }

  /**
   * getSeletectedVpp
   Data
   */
  public async getSeletectedVppData(vppId: string): Promise<void> {
    if (vppId == null) return;
    const response = (await this.getVPPById(vppId)) as { data: VppServerType };
    this.selectedVpp = new Vpp(response?.data);
    this.vppSelectedSubject$.next(this.selectedVpp);
    this.permissionsService.apply(PermissionArea.VPP, response.data.permissions);

    this.setMetricDataOfSelectedVpp();
    this.vppState.name = this.selectedVpp.name;
    this.vppState.uuid = this.selectedVpp.uuid;
    if (this.permissionsService.any([PermissionKey.VPP_ADD_VPPMEMBERSHIP])) {
      this.commonService.getInvitesRolesList(this.vppInvitesRoles, this.selectedVpp.id, 'vpps');
    }
    this.getVppPolicies();
    if (this.vppsMetricsSubscription.length <= 0) {
      this.VppsStartMqttService([this.selectedVpp], true);
    }
  }
  public setMetricDataOfSelectedVpp() {
    this.selectedVpp.vppMetricData = {
      DiDispatch: null,
      DiAvail: null,
      Demand: null,
      DrDispatch: null,
      DrAvail: null,
      currentDemand: null,
      demandTimeNow: null,
      timeNow: null,
    };
  }

  getVppSchedules(loader = null) {
    this.vppSchedules.status.reset();
    this.VppSchedules(this.selectedVpp.id).then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: any) => {
        setTimeout(() => {
          if (loader) {
            loader.dismiss();
          }
        }, 500);
        this.vppSchedules.status.$callMade = true;
        this.vppSchedules.schedules = response.data;
        if (response.data.length > 0) {
          this.vppSchedules.status.$dataExist = true;
        }
      },
      (error) => {
        if (loader) {
          loader.dismiss();
        }
        this.vppSchedules.status.$callMade = true;
        this.vppSchedules.status.$dataExist = false;
        this.vppSchedules.status.$callFailed = true;
        this.vppSchedules.status.$error = error;
      },
    );
  }

  getallSchedules(loader = null) {
    this.allSchedulesList.status.reset();
    this.allSchedules().then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: any) => {
        setTimeout(() => {
          if (loader) {
            loader.dismiss();
          }
        }, 500);
        this.allSchedulesList.status.$callMade = true;
        this.allSchedulesList.schedules = response.data;
        if (response.data.length > 0) {
          this.allSchedulesList.status.$dataExist = true;
        }
      },
      (error) => {
        if (loader) {
          loader.dismiss();
        }
        this.allSchedulesList.status.$callMade = true;
        this.allSchedulesList.status.$dataExist = false;
        this.allSchedulesList.status.$callFailed = true;
        this.allSchedulesList.status.$error = error;
      },
    );
  }
  getDemandReductionMerits(loader = null): void {
    if (loader) {
      setTimeout(() => {
        loader.dismiss();
      }, 500);
    }
    this.drMeritsChanged = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.vppMeritOrder(this.selectedVpp.id, VppDemandTypes.DEMAND_REDUCTION).then((response: any) => {
      // var uniqueMerits = [];
      // uniqueMerits = this.formatMeritOrders(response);

      this.demandReduction.meritOrders = response.data;
      this.demandReduction.origCopy = this.demandReduction.meritOrders.map((x) => Object.assign({}, x));
    });
  }
  getDemandIncreaseMerits(loader = null): void {
    if (loader) {
      setTimeout(() => {
        loader.dismiss();
      }, 500);
    }
    this.diMeritsChanged = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.vppMeritOrder(this.selectedVpp.id, VppDemandTypes.DEMAND_INCREASE).then((response: any) => {
      this.demandIncrease.meritOrders = response.data;
      this.demandIncrease.origCopy = this.demandIncrease.meritOrders.map((x) => Object.assign({}, x));
    });
  }

  formatMeritOrders(response) {
    const uniqueMerits = [];
    response.data.forEach((merit) => {
      let found = false;
      for (let index = 0; index < uniqueMerits.length; index++) {
        const element = uniqueMerits[index];
        if (
          element.control_code === merit.control_code &&
          element.control_description === merit.control_description &&
          element.device_class === merit.device_class &&
          element.order === merit.order
        ) {
          found = true;
          break;
        }
      }
      if (!found) {
        uniqueMerits.push(merit);
      }
    });
  }
  getVppPolicies(loader = null) {
    if (!this.permissionsService.any([PermissionKey.VPP_VIEW_POLICY])) {
      return;
    }
    this.policiesList.status.reset();
    this.vppPolicies(this.selectedVpp.id).then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: any) => {
        setTimeout(() => {
          if (loader) {
            loader.dismiss();
          }
        }, 500);
        this.policiesList.status.$callMade = true;
        this.policiesList.policies = response.data;
        this.policiesList.policies.forEach((policy) => {
          policy['toggle'] = false;
        });
        if (response.data.length > 0) {
          this.policiesList.status.$dataExist = true;
        }
      },
      (error) => {
        if (loader) {
          loader.dismiss();
        }
        this.policiesList.status.$callMade = true;
        this.policiesList.status.$dataExist = false;
        this.policiesList.status.$callFailed = true;
        this.policiesList.status.$error = error;
      },
    );
  }
  getVppDeviceTypes(loader = null) {
    this.vppDeviceTypesList.status.reset();
    this.vppDeviceTypes(this.selectedVpp.id).then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: any) => {
        setTimeout(() => {
          if (loader) {
            loader.dismiss();
          }
        }, 500);
        this.vppDeviceTypesList.status.$callMade = true;
        this.vppDeviceTypesList.devicesList = response.data;
        if (response.data.length > 0) {
          this.vppDeviceTypesList.status.$dataExist = true;
        }
      },
      (error) => {
        if (loader) {
          loader.dismiss();
        }
        this.vppDeviceTypesList.status.$callMade = true;
        this.vppDeviceTypesList.status.$dataExist = false;
        this.vppDeviceTypesList.status.$callFailed = true;
        this.vppDeviceTypesList.status.$error = error;
      },
    );
  }

  presentToast(message, duration = 3000) {
    this.toast
      .create({
        message: message,
        duration: duration,
        position: 'top',
      })
      .then((toast) => {
        toast.present();
      });
  }
  async presentAlertSimpleOk(header, subheader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subheader,
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async updateVppDetails(editPayload: VppPostPutPayload, vppId: string): Promise<void> {
    await this.updateVppApi(editPayload, vppId);
    this.getSeletectedVppData(vppId);
  }

  async patchVppDetails(editPayload: VppPatchPayload, vppId: string): Promise<void> {
    await this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/vpps/' + vppId + '/',
      RequestMethod.PATCH,
      UseHeaderType.AUTHORIZED_SWDIN,
      editPayload,
    );
    this.getSeletectedVppData(vppId);
  }

  // API CALLS
  public createVPP(newVPP) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/vpps/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      newVPP,
    );
  }
  private updateVppApi(editVPP: VppPostPutPayload, vppId: string) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/vpps/' + vppId + '/',
      RequestMethod.PUT,
      UseHeaderType.AUTHORIZED_SWDIN,
      editVPP,
    );
  }
  private getVPPSList() {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/vpps/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }
  private getVPPById(vppId: string) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/vpps/' + vppId + '/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }
  public vppUsersByVppId(vppId) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/vpps/' + vppId + '/users/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }
  public InviteUsersByVppId(vppId, user) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/vpps/' + vppId + '/invite_user/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      user,
    );
  }
  public removeVppMembership(vppMembershipId) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/vpp-memberships/' + vppMembershipId + '/',
      RequestMethod.DELETE,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }
  public removeVpp(vppId) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/vpps/' + vppId + '/',
      RequestMethod.DELETE,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }
  public vppMeritOrder(vppId, demandType = null) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/vpps/' + vppId + '/merit_orders/?demand=' + demandType,
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  public informServerToStartVppStateFeed(vppId) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/vpps/' + vppId + '/feed/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }
  public updateMeritOrders(merits) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/update-merit-orders/',
      RequestMethod.PUT,
      UseHeaderType.AUTHORIZED_SWDIN,
      merits,
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getUnitMetrics(unitid: any) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/units/' + unitid + '/endpoints/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }
  public VppSchedules(vppId) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/vpps/' + vppId + '/schedules/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }
  public allSchedules() {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/schedules/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }
  public createSchedule(data) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/schedules/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }
  public createvPPSchedule(vppId, data) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/vpps/' + vppId + '/add_schedules/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }
  public removeSchedule(scheduleId) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/schedules/' + scheduleId + '/',
      RequestMethod.DELETE,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }
  public updateSchedule(schedule) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/schedules/' + schedule.id + '/',
      RequestMethod.PUT,
      UseHeaderType.AUTHORIZED_SWDIN,
      schedule,
    );
  }
  public vppPolicies(vppId) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/vpps/' + vppId + '/policies/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }
  public vppDeviceTypes(vppId) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/vpps/' + vppId + '/device_types/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }
  public vppUpdatePolicy(policyId, data) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/policies/' + policyId + '/',
      RequestMethod.PUT,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }
  public vppUpdatePolicyAttributeOption(optionId, data) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/policy-attribute-options/' + optionId + '/',
      RequestMethod.PUT,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }

  async vppMarketsApi(): Promise<VppMarketGetPayload[]> {
    const res: { data: VppMarketGetPayload[] } = (await this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/market-regions/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    )) as any;
    return res?.data ?? [];
  }
}
