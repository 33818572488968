<ng-container *ngrxLet="toastSettings$ as vm">
  <ion-toast
    [isOpen]="vm.isToastOpen"
    [message]="vm.message"
    [header]="vm.header"
    [duration]="vm.duration"
    position="top"
    [color]="vm.color"
    class="custom-app-toast-notification"
    [buttons]="vm.buttons"
    (didDismiss)="handleToastDismiss()"
  ></ion-toast>
</ng-container>
